
import React, { Component } from 'react'

export default class StartPage extends Component {
  render() {
    return (
      <div>
        i am the start page
      </div>
    )
  }
}


// import React,{ useState } from 'react';

//  export default  function Example() {
//      const [count, setCount] = useState(0);
// 
//      return (
//        <div>
//          <p>You clicked {count} times</p>
//          <button onClick={() => setCount(count + 1)}>
//          Click me
//         </button>
//       </div>
//     );
//   }