import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'

//#region How to use

//#endregion


export default class MessageBox extends Component {
    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            //animation={false}
            >
                {this.props.title &&
                <Modal.Header closeButton>
                    
                    <Modal.Title id="contained-modal-title-vcenter">
                        {this.props.title}
                    </Modal.Title>
                      
                </Modal.Header>
    }
                <Modal.Body>
                    <div style={{ width: '100%' }}>
                       {this.props.body}
                    </div>
                </Modal.Body>
                {(this.props.left || this.props.middle || this.props.right) &&
                <Modal.Footer>
                    <div className='flexrow start  '>
                        {this.props.left && <Button variant={this.props.left.variant} onClick={this.props.left.action} className='mr-3'>{this.props.left.text}</Button>}
                        {this.props.middle && <Button variant={this.props.middle.variant} onClick={this.props.middle.action} className='mr-3'>{this.props.middle.text}</Button>}
                        {this.props.right && <Button variant={this.props.right.variant} onClick={()=>{this.props.right.action();this.props.onHide()}}>{this.props.right.text}</Button>}
                    </div>

                </Modal.Footer>
    }
            </Modal>

        )
    }
}
