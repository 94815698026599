import React, { Component, ReactDOM, createRef } from 'react'
import { Image, Button, Badge, Spinner } from 'react-bootstrap';
import * as G from '../components/global'
import { renderPageContainer, renderPageHeader, renderDivider, getData } from '../components/global'
import history from '../components/history';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import MessageBox from '../components/MessageBox';
import emailtempl from '../components/assets/emailtempl.png'
import { translateScreen as ts , translateDate as td, translateScreen2 as ts2} from '../components/dictionary'
import ReactPaginate from 'react-paginate';
import 'rsuite/dist/rsuite.min.css';
import {DateRangePicker, DatePicker} from 'rsuite';
import Select from 'react-select'
import chroma from 'chroma'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
  import "slick-carousel/slick/slick-theme.css";
import qs from 'qs'
import dateFormat from 'dateformat';
class ScannedTickets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeScreen: 0,
            detailsloading:false,
            tickets: [],
            sitestores:[{key:'', value:'Please choose'}],
			stats:{},
            fields:{},
            errors:{},
            ticketrule:null,
            loadingTickets: true,
			searchfields:{},
            showRedundancyModal:false,
            showAmountLimitModal:false,
            showScanningLimitModal:false,
            showGlobalLimit:false,
            showFlagsModal:false,
            showTicketEditModal:false,
            reporter:{fullName:''},
            oldtickets:[],
            modalShow: false,
            modalBody: '',
            modalSubject: '',
            selectedTicket:{},
            scannedStore:null,
            alerts:[],
            ticketscount:0,
            storeticketscount:0,
            shouldRefreshData:false
        }
		this.handleChange = this.handleChange.bind(this);
		this.handleDateChange = this.handleDateChange.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleStatusSearch = this.handleStatusSearch.bind(this);
        this.clearStatusSearch = this.clearStatusSearch.bind(this);
        this.settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1
          };
    }
    componentWillUnmount(){
        this.setState({shouldRefreshData:false})
    }
    
    async componentDidMount() {
		this.selectInputRef = createRef();
		if (this.props.userLogin.site){
            this.refreshData(this.props.userLogin.site.id);
            /*if(!this.state.loadingTickets)
            {
                var _this = this;
                this.setState({loadingTickets:true});
                setTimeout(function(){
                    
                        //alert("1");
                        
                    
                },300);
            }*/
            var storesresult = await getData("sitestore/query",[["filter","siteid=" + this.props.userLogin.site.id]]);
            storesresult = await storesresult.data;
            this.state.sitestores = [{key:'', value:'Please choose'}];
            for(var i=0;i<storesresult.length;i++)
            {
                this.state.sitestores.push({key:storesresult[i].id, value:storesresult[i].name});
            } 
            this.setState({sitestores:this.state.sitestores});
            var result = await getData("ticketscanningrules/query",[["filter","siteid=" + this.props.userLogin.site.id + " and enabled=1"]]);
		result = await result.data;
        if(result.length>0)
            this.setState({ticketrule: result[0]});
        }
        history.listen((location, action) => {
            if(location.pathname == "/ScannedTickets" || location.pathname == "/ScannedTickets/")
            {
                console.log("location",history);
		        this.setState({activeScreen:0, fields:{}, selectedGift:null, giftCategory:[], giftRedeems:[]});
                //this.loadDataTables();
                var _this = this;
                setTimeout(function(){
                    if(_this.state.shouldRefreshData)
                    {
                        //alert("2");
                        _this.refreshData(_this.props.userLogin.site.id);
                    }
                },300);
                
               
	        }
          });
        // if (this.state.allCompanies.length === 0) {
        //refreshDataClients()
        // }
    }
    getSnapshotBeforeUpdate(prevProps) {
            
        return { notifyRequired: prevProps.userLogin.site !== this.props.userLogin.site };
      }
      
      async componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot.notifyRequired) {
            if(this.props.userLogin.site)
            {
                if(!this.state.loadingTickets)
                {
                    //alert("3");
                    await this.refreshData(this.props.userLogin.site.id);
                }
            var storesresult = await getData("sitestore/query",[["filter","siteid=" + this.props.userLogin.site.id]]);
            storesresult = await storesresult.data;
            
            this.state.sitestores = [{key:'', value:'Please choose'}];
            for(var i=0;i<storesresult.length;i++)
            {
                this.state.sitestores.push({key:storesresult[i].id, value:storesresult[i].name});
            } 
            this.setState({sitestores:this.state.sitestores});
            var result = await getData("ticketscanningrules/query",[["filter","siteid=" + this.props.userLogin.site.id + " and enabled=1"]]);
		result = await result.data;
        if(result.length>0)
            this.setState({ticketrule: result[0]});
            }
        }
        
      }
      
    refreshData = async (company) => {
        this.setState({ loadingTickets: true, shouldRefreshData:false })
        let { searchfields, fields } = this.state;
        let filter = '', statsfilter='';
        if(searchfields['searchrange']!= undefined && searchfields['searchrange']!=null)
        {
            var range = searchfields['searchrange'];
            var startdate = new Date(range[0])
            var enddate = new Date(range[1])
            var starts = dateFormat(startdate,"yyyy-mm-dd");
            var ends = dateFormat(enddate.setDate(enddate.getDate() + 1),"yyyy-mm-dd")
            filter += ` and datein between '${starts}' and '${ends}'`
            statsfilter += ` and datein between '${starts}' and '${ends}'`
        }
        
        
        var {tickets} = this.state;
        if(searchfields["ticketstatus"])
        {
            /*var status = searchfields["ticketstaus"];
            switch(status)
            {
                case "All":
                    tickets = await getData('gifts/get', [['filter', 'customerid in (select id from profile where clientID=' + company + ")" + filter]]);
                    tickets = await tickets.data;
                    break;
                case "Validated":
                    tickets = await getData('customerticket/get', [['filter', 'customerid in (select id from profile where clientID=' + company + ")" + " status='Validated'" + filter]]);
                    tickets = await tickets.data;
                    break;
                case "Rejected":
                    tickets = await getData('customerticket/get', [['filter', 'customerid in (select id from profile where clientID=' + company + ")" + " status='Rejected'" + filter]]);
                    tickets = await tickets.data;
                    break;
                case "Pending":
                    tickets = await getData('customerticket/get', [['filter', 'customerid in (select id from profile where clientID=' + company + ")" + " status='Pending'" + filter]]);
                    tickets = await tickets.data;
                    break;
                
                default:
                    tickets = await getData('gifts/get', [['filter', 'customerid in (select id from profile where clientID=' + company + ")" + filter]]);
                    tickets = await tickets.data;
                    break;
            }*/
            var statuses = searchfields["ticketstatus"];
            var s = "";
            console.log("Statuses",statuses);
            statuses.map(elem=>{
                s += ",'" + elem.value+"'"
            })
            if(s!="")
            {
                s = s.substring(1);
                filter += " and status in ("+s+")"
            }
        }
        console.log("Filter", filter)
            tickets = await getData('customerticket/get', [['filter', 'customerid in (select id from profile where clientID=' + company + ") " + filter + " order by datein desc"]]);
            tickets = await tickets.data;
            this.setState({ tickets: tickets });
		var stats = await getData('sitetickets/stats', [['filter', 'customerid in (select id from profile where clientID=' + company + ") " + statsfilter]]);
        stats = await stats.data;
        this.setState({ stats: stats });
        var qid = qs.parse(history.location.search, { ignoreQueryPrefix: true }).id
        if(qid)
        {
            tickets.map(element=>{
                if(element.id == qid)
                {
                    this.OpenDetails(element, false);
                }
            })
        }
        this.loadDataTables();
    }
    showNextTicket = ()=>{
        let {selectedTicket, tickets} = this.state;
        var nextticket = tickets.sort((a, b) => a.datein < b.datein ? 1:-1).filter(x=>x.datein<selectedTicket.datein);
        if(nextticket.length>0)
        {
            this.OpenDetails(nextticket[0]);
            
        }
        else{
            this.OpenDetails(tickets[0]);
        }
    }
    showPreviousTicket = ()=>{
        let {selectedTicket, tickets} = this.state;
        var nextticket = tickets.sort((a, b) => a.datein > b.datein ? 1:-1).filter(x=>x.datein>selectedTicket.datein);
        if(nextticket.length>0)
        {
            this.OpenDetails(nextticket[0]);
            
        }
        else{
            this.OpenDetails(tickets[tickets.length - 1]);
        }
    }
    handleStatusSearch = (e)=>
    {
        let { searchfields } = this.state;
        searchfields["ticketstatus"] = e;
		 this.setState({ searchfields });
         if(!this.state.loadingTickets)
         {
            //alert("4");
            this.refreshData(this.props.userLogin.site.id);
         }
    }
    clearStatusSearch = ()=>
    {
        let { searchfields } = this.state;
        delete searchfields["ticketstatus"];
        
		 if(!this.state.loadingTickets)
         {
            //alert("4");
            this.refreshData(this.props.userLogin.site.id);
         }
    }
     handleDateRangeChange = async(range)=>{
        let { searchfields } = this.state;
        searchfields["searchrange"] = range;
		 this.setState({ searchfields });
         if(!this.state.loadingTickets)
         {
            //alert("5");
            this.refreshData(this.props.userLogin.site.id)
         }
        
    }
	
      
	OpenDetails = async(element, redirect = true)=>{
        this.setState({activeScreen:1,detailsloading:true, shouldRefreshData:true});
        var flags = await G.getData("customeralerts/query",[["filter","customerid=" + element.customerid + " and status=1 order by id desc"]]);
                flags = await flags.data;
                this.setState({alerts:flags});
            if(element.sitestoreid)
            {
                var result = await G.getData("sitestore/query",[["filter","id=" + element.sitestoreid]]);
                result = await result.data;
                this.setState({scannedStore:result[0]});
                console.log("Store:",result[0]);
                console.log(element.sitestoreid)
                
                /*var cresult = await G.getData('customerticket/count',[['customerid',element.customerid],['sitestoreid',element.sitestoreid],['ticketdate',dateFormat(element.datein,"yyyy-mm-dd")]]);
                cresult = await cresult.data;
                var ticketscount = cresult.count;
                var storeticketscount = cresult.storecount;
                
                this.setState({ticketscount:ticketscount, storeticketscount:storeticketscount});*/
            } 
            else{
                this.setState({scannedStore:null});
            } 
            if(element.previouscustomerticketid){
                var previousticket = await G.getData("customerticket/query",[["filter","id=" + element.previouscustomerticketid + " and status='Validated'"]]);
                previousticket = await previousticket.data;
                if(previousticket.length == 0)
                    element.previouscustomerticketid = null;

            }
        this.setState({activeScreen:1, selectedTicket:element,detailsloading:false})
        if(redirect == true)
            history.push("/ScannedTickets/details/?id=" + element.id)
    }  
    loadDataTables = ()=>{
       var _this = this;
       
       
       _this.setState({ loadingTickets: false })
           console.log("Tickets", this.state.tickets); 
            
        const script = document.createElement("script");
    script.id = "dttablestats";
    script.src = '/dist/js/ticketstable.js';
    script.async = true;
    document.body.appendChild(script);  

    
        
}
DistroyDataTables = ()=>{
    var _this = this;
                 const script = document.createElement("script");
             script.src = '/dist/js/destroytable.js';
             script.async = true;
             document.body.appendChild(script);
     
}  
    
    
	handleChange(e) {
		let { fields, errors } = this.state;
		 fields[e.target.name] = e.target.value;
		 delete errors[e.target.name];
 
		 this.setState({ fields });
	 }
	 handleDateChange(name, date){
		var d = new Date(date)
		var dates = dateFormat(d,"yyyy-mm-dd");
		let { fields, errors } = this.state;
		fields[name] = dates;
		delete errors[name];
		this.setState({ fields });
	 }
	 handleSelectChange(name, e){
		let { fields, errors } = this.state;
		fields[name] = e;
		delete errors[name];
		console.log("fields",fields);
		console.log("field e",e);
		this.setState({ fields });
        if(!this.state.loadingTickets)
        {
            //alert("6");
            this.refreshData(this.props.userLogin.site.id)
        }
	 }
	 
     
     openRedundancyModal = async()=>{
        let {selectedTicket} = this.state;
        var oldtickets = await G.getData("customerticket/query",[["filter","(id=" + selectedTicket.previouscustomerticketid + " or (previouscustomerticketid=" + selectedTicket.previouscustomerticketid + " and id <>" + selectedTicket.id+")) and status='Validated'"]])
        oldtickets = await oldtickets.data;
        this.setState({showRedundancyModal:true, oldtickets:oldtickets})
     }
     openFlagsModal = async()=>{
         var reporter = await G.getData("user/query",[["filter","id=" + this.state.alerts[0].creationuserid]])
         reporter = await reporter.data;
         if(reporter.length>0)
         {
            this.setState({reporter:reporter[0]});
         }
        this.setState({showFlagsModal:true});
     }
     openAmountLimitModal = (showglobal)=>{
        this.setState({showAmountLimitModal:true, showGlobalLimit:showglobal?true:false})
     }
     openScanningLimitModal = (showglobal)=>{
        this.setState({showScanningLimitModal:true, showGlobalLimit:showglobal?true:false})
     }
     ResolveTicket = ()=>{
         let {selectedTicket} = this.state;
         var rdate = new Date(selectedTicket.receiptdate)
         this.setState({errors:{}, fields:{receiptdate:dateFormat(rdate,"yyyy-mm-dd'T'HH:MM"), sitename:selectedTicket.sitename,storename:selectedTicket.storename,amount:selectedTicket.amount,status:selectedTicket.status, sitestoreid:(this.state.scannedStore?this.state.scannedStore.id:null)}});
         this.setState({showScanningLimitModal:false, showAmountLimitModal:false, showFlagsModal:false,showRedundancyModal:false, showTicketEditModal:true});
     }
     SaveTicket = async()=>{
         let {fields, errors} = this.state;
         var formValid = true;
         if(fields["receiptdate"] == null || fields["receiptdate"] == "")
         {
            errors["receiptdate"] = "This field is required";
            formValid = false;
         }
         if(fields["sitename"] == null || fields["sitename"] == "")
         {
            errors["sitename"] = "This field is required";
            formValid = false;
         }
         if(fields["sitestoreid"] == null || fields["sitestoreid"] == "")
         {
            errors["sitestoreid"] = "This field is required";
            formValid = false;
         }
         if(fields["amount"] == null || fields["amount"] == "")
         {
            errors["amount"] = "This field is required";
            formValid = false;
         }
         else if(isNaN(fields["amount"]))
         {
            errors["amount"] = "Please enter a valid amount";
            formValid = false;
         }
         if(fields["status"] == null || fields["status"] == "")
         {
            errors["status"] = "This field is required";
            formValid = false;
         }
         if(formValid)
         {
            var logtxt = this.props.userLogin.username + " edited ticket details with id:" + this.state.selectedTicket.id + " from (Receipt Date: " + this.state.selectedTicket.receiptdate + " | Store name: " + this.state.selectedTicket.storename + " | Amount: " + this.state.selectedTicket.amount + " | Status: "  + this.state.selectedTicket.status + ") to (Receipt Date: " + fields["receiptdate"] + " | Store name: " + this.state.sitestores.find(x=>x.key.toString() == fields["sitestoreid"]).value + " | Amount: " + fields["amount"] + " | Status: "  + fields["status"] + ")";
            G.SaveUserLog(logtxt, this.props.userLogin.id)
            var result = await G.getData("ticket/edit",[["ticketId",this.state.selectedTicket.id], ["receiptdate",fields["receiptdate"]], ["sitename",fields["sitename"]], ["sitestoreid",fields["sitestoreid"]], ["amount",fields["amount"]], ["status",fields["status"]]]);
            if(result.status ===200)
            {
                //this.state.selectedTicket.receiptdate = fields["receiptdate"];
                //this.state.selectedTicket.sitename = fields["sitename"];
                //this.state.selectedTicket.storename = this.state.sitestores.find(x=>x.key.toString() == fields["sitestoreid"]).value;
               // this.state.selectedTicket.sitestoreid = fields["sitestoreid"];
                //this.state.selectedTicket.amount = fields["amount"];
                //this.state.selectedTicket.status = fields["status"];
               // this.setState({selectedTicket:this.state.selectedTicket, fields:{}, errors:{},showTicketEditModal:false});
               // var result = await G.getData("sitestore/query",[["filter","id=" + this.state.selectedTicket.sitestoreid]]);
               // result = await result.data;
               // this.setState({scannedStore:result[0]});
               if(!this.state.loadingTickets)
               {
                //alert("7");
                this.refreshData(this.props.userLogin.site.id);
               }
               this.setState({fields:{}, errors:{},showTicketEditModal:false});
            }
         }
         else{
             this.setState({errors});
         }
     }
	 render() {
		const { errors, fields } = this.state;
        /*if (this.state.allCompanies != null) {

            if (this.state.allCompanies.length === 0) { this.refreshDataClients(); }

        }*/
        return (
            <>
                {/*<section className='content-header'>
                    <div className='container-fluid'>
                        <div className='row mb-2'>
                            <div className='col-sm-6'>
                                <h1>{ts('Email templates',this.props.userLogin.language)}</h1>
                            </div>
                        </div>
                    </div>
        </section>*/}
                
                
                {this.state.activeScreen === 0 &&

                    renderPageContainer(
                        <>
                        <section className='content' >                
                            
                            
                            
							<h3 style={{marginTop:"50px"}}>{ts('Scanned Tickets',this.props.userLogin.language)}&nbsp;&nbsp;
                             <DateRangePicker onChange={(value)=>{this.handleDateRangeChange(value)}}  placeholder={ts("Filter by date range", this.props.userLogin.language)} />
                            </h3><div className="col-md-12" style={{height:"20px"}}></div>
                            <div className="row">
                                <div className="col-md-2">
                                <div className="info-box handCursor mainScreenCard" onClick={()=>{this.clearStatusSearch()}}>
                                <div className="info-box-content">
                                <span style={{color:"black"}}>{ts("Total scanned Tickets", this.props.userLogin.language)}</span>
                                <span style={{fontSize:"40px", color:"black"}}>{this.state.stats.totalscannedtickets}</span>
                        
                                </div>
                                    
                                    </div>
                                </div>
                                <div className="col-md-2" onClick={()=>{this.handleStatusSearch([{value:'Validated', label:'Validated'}])}}>
                                <div className="info-box handCursor mainScreenCard" onClick={()=>{this.handleStatusSearch([{value:'Validated', label:'Validated'}])}}>
                                <div className="info-box-content">
                                <span style={{color:"black"}}>{ts("Validated tickets", this.props.userLogin.language)}</span>
                                <span style={{fontSize:"40px", color:"#37A64A"}}>{this.state.stats.validatedtickets}</span>
                        
                                </div>
                                    
                                    </div>
                               
                                    
                                </div>
                                <div className="col-md-2">
                                <div className="info-box handCursor mainScreenCard" onClick={()=>{this.handleStatusSearch([{value:'Rejected', label:'Rejected'}])}}>
                                <div className="info-box-content">
                                <span style={{color:"black"}}>{ts("Rejected tickets", this.props.userLogin.language)}</span>
                                <span style={{fontSize:"40px", color:"#DA3849"}}>{this.state.stats.rejectedtickets}</span>
                        
                                </div>
                                    
                                    </div>
                                    
                                </div>
                                <div className="col-md-2" >
                                <div className="info-box handCursor mainScreenCard" onClick={()=>{this.handleStatusSearch([{value:'Pending', label:'Pending'}])}}>
                                <div className="info-box-content">
                                <span style={{color:"black"}}>{ts("Pending tickets", this.props.userLogin.language)}</span>
                                <span style={{fontSize:"40px", color:"#FDB83B"}}>{this.state.stats.pendingtickets}</span>
                        
                                </div>
                                    
                                    </div>
                                   
                                </div>
                            </div>
                            <div className="col-md-12" style={{height:"10px"}}></div>
							<div className="row">
                            <div className="col-md-12"><h5>{ts('List of Scanned Tickets',this.props.userLogin.language)} </h5></div>
								<div className='form-group col-md-3' style={{marginTop:"20px"}}>
								<label for="searchfield">Filter table by ticket status</label>
								{/*<select className='custom-select' onChange={this.handleStatusSearch}>
									<option>All</option>
									<option>Validated</option>
									<option>Rejected</option>
									<option>Pending</option>
                    </select>*/}
                    <Select ref={this.selectInputRef} menuPortalTarget={document.body} isClearable={true}
       value={this.state.searchfields["ticketstatus"]}    
    closeMenuOnSelect={false}
    isMulti
    styles={colourStyles}
    options={[{value:'Validated', label:'Validated'},{value:'Rejected', label:'Rejected'},{value:'Pending', label:'Pending'}]}
	id="ticketstatus" name="ticketstatus" onChange={(e)=>this.handleStatusSearch(e)}
  />
								</div>
								</div>
                            <div className='row'>
                                <div className="col-md-12">
                                <table className='table table-bordered' id="ticketstable">
                                    <thead><tr>
                                        <th>{ts("Action", this.props.userLogin.language.id)}</th>
                                        <th >{ts("Date", this.props.userLogin.language.id)}</th>
                                        <th>{ts("Time", this.props.userLogin.language.id)}</th>
                                        <th>{ts("Store name", this.props.userLogin.language.id)}</th>
                                        <th>{ts("Customer name", this.props.userLogin.language.id)}</th>
                                        <th>{ts("Customer email", this.props.userLogin.language.id)}</th>
                                        <th>{ts("Amount (EUR)", this.props.userLogin.language.id)}</th>
                                        <th>{ts("Status", this.props.userLogin.language.id)}</th>
                                        </tr></thead>
    {this.state.loadingTickets == false && <tbody>
        {this.state.tickets.map(el=>{
            
            return (
            <tr>
            <td><Badge style={{backgroundColor:"#1A98AC", color:"white", cursor:"pointer"}} onClick={()=>{
                this.OpenDetails(el);
            }}>View Details</Badge></td>
            <td data-sort={el.datein}>{dateFormat(el.datein,"yyyy-mm-dd")}</td>
            <td data-sort={el.datein}>{dateFormat(el.datein,"hh:MM TT")}</td>
            {el.storename?<td>{el.sitestoreid?el.sitestorename:el.storename}</td>:<td style={{color:"red"}}>Not found</td>}
            <td>{el.firstName} {el.lastName}</td>
            <td>{el.emailAddress}</td>
            <td>{el.amount}</td>
            <td>
                {el.status=='Validated' &&<Badge style={{backgroundColor:"#37A64A", color:"white"}}>{ts("validated", this.props.userLogin.language.id)}</Badge>}
                {el.status=='Pending' &&<Badge style={{backgroundColor:"#FDB83B", color:"black"}}>{ts("pending", this.props.userLogin.language.id)}</Badge>}
                {el.status=='Rejected' &&<Badge style={{backgroundColor:"#DA3849", color:"white"}}>{ts("rejected", this.props.userLogin.language.id)}</Badge>}
                </td>
        </tr>)
        })}
        
        </tbody>}
                            </table>
                            </div>
                            {this.state.loadingTickets &&
                                <div className='flexcolumn center verticalcenter p-5 fullWidth hint mt-5 mb-4'>
                                    {/*changed by youssef to meet the selected site requirment*/}
                                    {/*{ts('Loading templates for',this.props.userLogin.language)} {this.state.activeCompany ? this.state.activeCompany.companyName : ''}*/}
                                    {ts('Loading data for',this.props.userLogin.language)} {this.props.userLogin.site ? this.props.userLogin.site.companyName : ''}
                                    <Spinner animation="border" className='mt-3' variant="warning" />

                                </div>
                            }
                            </div>
                            {!this.state.loadingTickets &&<div className='row'> <div className='flexrow start wrap mt-2   pt-3 '>
								
                                {this.state.tickets.length === 0&&
                                    <div className='col-md-12'>
                                    <div className='flexcolumn center verticalcenter p-5 fullWidth cSignInBack boxRadius5'>
                                       {ts(' No Tickets scanned yet for',this.props.userLogin.language)} {this.props.userLogin.site ? this.props.userLogin.site.companyName : ''}
                                        
                                    </div>
                                    </div>
                                }

                            </div>
                            </div>}
                            
                            
                            
                            
                            </section>
                                
                            
                            
                        
                        </>
                    )

                }
                {
                    
                    this.state.activeScreen === 1 &&
                    renderPageContainer(
                        <>
                        {this.state.detailsloading && 
                        <div className='flexcolumn verticalcenter  text-center col-12' style={{ marginTop: '25%' }}>
                        <div className='flexrow center fullWidth '>
                            <Spinner animation="grow" className='m-3' variant="warning" />
                            <Spinner animation="grow" className='m-3' variant="info" />
                            <Spinner animation="grow" className='m-3' variant="danger" />
            
                        </div>
                        <div className='hint remarks xlowalpha'>Loading Data</div>
                    </div>
                        }
                        {!this.state.detailsloading &&
                        <section className='content'>
                            {this.state.customersloading &&
                                <div className='flexcolumn center verticalcenter p-5 fullWidth hint mt-5 mb-4'>
                                    {/*changed by youssef to meet the selected site requirment*/}
                                    {/*{ts('Loading templates for',this.props.userLogin.language)} {this.state.activeCompany ? this.state.activeCompany.companyName : ''}*/}
                                    {ts('Loading',this.props.userLogin.language)}
                                    <Spinner animation="border" className='mt-3' variant="warning" />

                                </div>
                            }
                            
                            <div className='row'>
                            <div className='col-md-12'>
							<Button className='mb-4 mt-5' variant='warning' onClick={() => { this.setState({activeScreen:0, fields:{}, selectedGift:null, giftCategory:[], giftRedeems:[]});history.push('/ScannedTickets');if(!this.state.loadingTickets){this.refreshData(this.props.userLogin.site.id)} }} >{ts('Cancel / Return', this.props.userLogin.language)}</Button>
							{this.state.tickets.sort((a, b) => a.datein < b.datein ? 1:-1).filter(x=>x.datein<this.state.selectedTicket.datein).length>0 && <Button className='float-right mb-4 mt-5' onClick={()=>{
                                this.showNextTicket();
                            }} style={{backgroundColor:"#1A98AC", color:"white", border:"0px"}}>Next &gt;</Button>}
                            {this.state.tickets.sort((a, b) => a.datein > b.datein ? 1:-1).filter(x=>x.datein>this.state.selectedTicket.datein).length>0 && <Button className='float-right mb-4 mt-5' onClick={()=>{
                                this.showPreviousTicket();
                            }} style={{backgroundColor:"transparent", color:"#1A98AC", border:"0px", marginRight:"10px"}}>&lt; Previous</Button>}
                            </div>
                            <div className='col-md-6'>
                                <div className='row'>
                                <div className='col-md-12'><h4>{ts("Ticket Details",this.props.userLogin.language)}</h4></div>
                            <div className='col-md-12'>
							<p class="  ssstitle bold  boxTopRadius5 cunlayer  p-2  ">{ts("Info",this.props.userLogin.language )} </p>
							</div>
							<div className='col-md-12' style={{height:"20px"}}></div>
                            <div className='col-md-12'>
                                <ul className='ticketscanninglist'><li>{ts("Scanning date & time",this.props.userLogin.language)}: <b>{td(dateFormat(this.state.selectedTicket.datein,"dddd, mmm dd, yyyy hh:MM TT"),this.props.userLogin.language)}</b></li>
                                <li>{ts("Customer name",this.props.userLogin.language)}: <b>{this.state.selectedTicket.firstName} {this.state.selectedTicket.lastName}</b><a onClick={()=>{window.location.href='/ProfileManagement/customers/edit?id=' + this.state.selectedTicket.customerid}} style={{color:"#0070FA", cursor:"pointer"}}>view profile</a></li>
                                <li>{ts("Customer email",this.props.userLogin.language)}: <b>{this.state.selectedTicket.emailAddress}</b></li>
                                
                                {this.state.scannedStore && this.state.selectedTicket.previouscustomerticketid==null &&<li style={{color:"#33A02C"}}><span style={{color:"#33A02C"}}>✓</span> New ticket</li>}
                                {this.state.scannedStore && this.state.selectedTicket.previouscustomerticketid!=null &&<li style={{color:"#DA3849"}}><span style={{color:"#DA3849"}}>×</span> Ticket already used <a onClick={()=>{this.openRedundancyModal()}} style={{color:"#0070FA", cursor:"pointer"}}>view details</a></li>}
                                
                                <li>{this.state.ticketrule && parseFloat(this.state.selectedTicket.amount)> this.state.ticketrule.maxamountlimit?<span style={{color:"#DA3849"}}>× Customer exceeded amount limit <a onClick={()=>{this.openAmountLimitModal(true)}} style={{color:"#0070FA", cursor:"pointer"}}>view details</a></span>:<span style={{color:"#33A02C"}}>✓ Customer did not exceed amount limit</span> }</li>
                                {this.state.scannedStore &&
                                    <li>{this.state.scannedStore.maxamountperreceipt == null || (!isNaN(this.state.selectedTicket.amount) && this.state.scannedStore.maxamountperreceipt && this.state.scannedStore.maxamountperreceipt>=parseFloat(this.state.selectedTicket.amount))?<span style={{color:"#33A02C"}}>✓ Customer did not exceed amount limit in {this.state.scannedStore.name}</span>:<span style={{color:"#DA3849"}}>× Customer exceeded amount limit in {this.state.scannedStore.name} <a onClick={()=>{this.openAmountLimitModal(false)}} style={{color:"#0070FA", cursor:"pointer"}}>view details</a></span> }</li>
                                }
                                {this.state.alerts.length>0?<li style={{color:"#DA3849"}}><span style={{color:"#DA3849"}}>×</span> Customer is flagged with alerts <a onClick={()=>{this.openFlagsModal()}} style={{color:"#0070FA", cursor:"pointer"}}>view details</a></li>:<li style={{color:"#33A02C"}}><span style={{color:"#33A02C"}}>✓</span> Customer is not flagged with alerts</li>}
                                <li>
                                    {this.state.ticketrule && (this.state.selectedTicket.scannedtickets)>this.state.ticketrule.ticketscanlimitperday?<span style={{color:"#DA3849"}}>× Customer exceeded scanning limit <a onClick={()=>{this.openScanningLimitModal(true)}} style={{color:"#0070FA", cursor:"pointer"}}>view details</a></span>:<span style={{color:"#33A02C"}}>✓ Customer did not exceed scanning limit</span>}
                                </li>
                                
                                {
                                    this.state.scannedStore &&
                                    <li>
                                        {this.state.scannedStore.maxnumberofscanspercustomerperday && this.state.selectedTicket.storescannedtickets - 1>this.state.scannedStore.maxnumberofscanspercustomerperday?<span style={{color:"#DA3849"}}>× Customer exceeded scanning limit in {this.state.scannedStore.name} <a onClick={()=>{this.openScanningLimitModal(false)}} style={{color:"#0070FA", cursor:"pointer"}}>view details</a></span>:<span style={{color:"#33A02C"}}>✓ Customer did not exceed scanning limit in {this.state.scannedStore.name}</span>}
                                    </li>
                                }
                                </ul>
                            </div>
                            <div className='col-md-12' style={{height:"20px"}}></div>
                            <div className='col-md-12'>
							<p class="  ssstitle bold  boxTopRadius5 cunlayer  p-2  ">{ts("Scanned details",this.props.userLogin.language )} </p>
							</div>
                            <div className='col-md-12' style={{height:"20px"}}></div>
                            
                            <div className='col-md-12'>
                                <ul className='ticketscanninglist'>
                               {this.state.selectedTicket.receiptdate?<li><span style={{color:"#33A02C"}}>✓</span> {ts("Date & time",this.props.userLogin.language)}: <b>{this.state.selectedTicket.receiptdate}</b></li>:<li><span style={{color:"#DA3849"}}>×</span>{ts("Date & time",this.props.userLogin.language)}: <b>Not detected</b></li>} 
                                {/*<li>{this.state.selectedTicket.sitenamescore>=0.85?<span><span style={{color:"#33A02C"}}>✓</span> {ts("Site name",this.props.userLogin.language)}</span>:<span style={{color:"#DA3849"}}><span >×</span> {ts("Site name",this.props.userLogin.language)}</span>}: <b>{this.state.selectedTicket.sitename}</b></li>*/}
                                <li>{this.state.selectedTicket.storenamescore>=0.5?<span><span style={{color:"#33A02C"}}>✓</span> {ts("Detected store name",this.props.userLogin.language)}: {this.state.selectedTicket.storename?<b>{this.state.selectedTicket.storename}</b>:<b>Not found</b>}  {this.state.scannedStore && <span style={{color:"#33A02C"}}>- matched with store {this.state.scannedStore.name}</span>}</span>:<span><span style={{color:"#DA3849"}}>×</span> {ts("Detected store name",this.props.userLogin.language)}: {this.state.selectedTicket.storename?<b>{this.state.selectedTicket.storename}</b>:<b>Not found</b>} <span style={{color:"#DA3849"}}>- did not match with any store</span></span>}</li>
                                {/*<li>{this.state.selectedTicket.storecodescore>=0.85?<span><span style={{color:"#33A02C"}}>✓</span> {ts("Store code",this.props.userLogin.language)}</span>:<span style={{color:"#DA3849"}}><span >×</span> {ts("Store code",this.props.userLogin.language)}</span>}: <b>{this.state.selectedTicket.storecode}</b></li>*/}
                                <li>{this.state.selectedTicket.siteaddresscore>=0.5?<span><span style={{color:"#33A02C"}}>✓</span> {ts("Address",this.props.userLogin.language)}</span>:<span style={{color:"#DA3849"}}><span >×</span> {ts("Address",this.props.userLogin.language)}</span>}: {this.state.selectedTicket.siteaddress?<b>{this.state.selectedTicket.siteaddress}</b>:<b>Not found</b>}{this.state.selectedTicket.siteaddresscore>=0.5?<span style={{color:"#33A02C"}}> - matched with site address <b>{this.state.selectedTicket.clientaddress?this.state.selectedTicket.clientaddress:this.props.userLogin.site.address}</b></span>:<span style={{color:"#DA3849"}}> - no match with site address <b>{this.props.userLogin.site.address}</b></span>}</li>
                                { this.state.scannedStore && <>
                                {isNaN(this.state.selectedTicket.amount)?<li><span style={{color:"#DA3849"}}>×</span> {ts("Amount",this.props.userLogin.language)}: <b>{this.state.selectedTicket.amount} EUR</b></li>:(this.state.scannedStore.maxamountperreceipt==null || (!isNaN(this.state.selectedTicket.amount) && this.state.scannedStore!=null && this.state.scannedStore.maxamountperreceipt>=parseFloat(this.state.selectedTicket.amount))?<li><span style={{color:"#33A02C"}}>✓</span> {ts("Amount",this.props.userLogin.language)}: <b>{this.state.selectedTicket.amount} EUR</b></li>:(this.state.scannedStore!=null?<li style={{color:"#DA3849"}}><span style={{color:"#DA3849"}}>×</span> {ts("Amount",this.props.userLogin.language)}: <b>{this.state.selectedTicket.amount} EUR</b> <a onClick={()=>{this.openAmountLimitModal()}} style={{color:"#0070FA", cursor:"pointer"}}>view details</a></li>:<li><span style={{color:"#33A02C"}}>✓</span> {ts("Amount",this.props.userLogin.language)}: <b>{this.state.selectedTicket.amount} EUR</b></li>))}
                                {!isNaN(this.state.selectedTicket.points) && <li><span style={{color:"#33A02C"}}>✓</span><span>{ts("Points",this.props.userLogin)}: <b>{this.state.selectedTicket.points}</b></span></li>}
                                </>}
                                    
                                    
                                
                                </ul>
                                <p><b>Status:</b>&nbsp;
                                {this.state.selectedTicket.status=='Validated' &&<Badge style={{backgroundColor:"#37A64A", color:"white"}}>{ts("validated", this.props.userLogin.language.id)}</Badge>}
                {this.state.selectedTicket.status=='Pending' &&<Badge style={{backgroundColor:"#FDB83B", color:"black"}}>{ts("pending", this.props.userLogin.language.id)}</Badge>}
                {this.state.selectedTicket.status=='Rejected' &&<Badge style={{backgroundColor:"#DA3849", color:"white"}}>{ts("rejected", this.props.userLogin.language.id)}</Badge>}
                                </p>
                            </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <a target="_blank" href={G.nodeServerSite + "mediaFiles/ocrfiles/" + this.state.selectedTicket.ticketimage} style={{position:'absolute', left:'240px', width:"50px", padding:"3px", top:"10px", color:"white", backgroundColor:"#0070FA", textAlign:"center", borderRadius:"5px"}}>View</a>
                                {/*<h5>Ticket image will be placed here</h5>*/}
                                {this.state.selectedTicket.ticketimage?<img src={G.nodeServerSite + "mediaFiles/ocrfiles/" + this.state.selectedTicket.ticketimage} style={{width:300}}/>:<h5>Image not available</h5>}
                            </div>
                            <div className='col-md-12' style={{height:"20px"}}></div>
                            <div className='col-md-12'>
							<Button className='mb-4 mt-5' variant='primary' onClick={() => { this.ResolveTicket() }} >{ts('Edit details', this.props.userLogin.language)}</Button>&nbsp;
                            <Button className='mb-4 mt-5' variant='warning' onClick={() => { this.setState({activeScreen:0, fields:{}, selectedTicket:null});history.push('/ScannedTickets');if(!this.state.loadingTickets){this.refreshData(this.props.userLogin.site.id)} }} >{ts('Cancel / Return', this.props.userLogin.language)}</Button>
							
                            {this.state.tickets.sort((a, b) => a.datein < b.datein ? 1:-1).filter(x=>x.datein<this.state.selectedTicket.datein).length>0 && <Button className='float-right mb-4 mt-5' onClick={()=>{
                                this.showNextTicket();
                            }} style={{backgroundColor:"#1A98AC", color:"white", border:"0px"}}>Next &gt;</Button>}
                            {this.state.tickets.sort((a, b) => a.datein > b.datein ? 1:-1).filter(x=>x.datein>this.state.selectedTicket.datein).length>0 && <Button className='float-right mb-4 mt-5' onClick={()=>{
                                this.showPreviousTicket();
                            }} style={{backgroundColor:"transparent", color:"#1A98AC", border:"0px", marginRight:"10px"}}>&lt; Previous</Button>}
                            </div>
                            </div>
     <MessageBox
     show={this.state.showRedundancyModal}
     body={
         <div ><center><img src="/dist/img/alerthead.png" style={{width:"50px", margin:"auto"}}/></center><br/>
         <h4 style={{textAlign:"center"}}>This ticket is conflicting with another ticket</h4>
         <Slider {...this.settings}>
         <div style={{width:"100%"}}>
                 <span style={{color:"#DA3849"}}>Current ticket</span><br/><span>ID:{this.state.selectedTicket.id}</span><br/>
                 {this.state.selectedTicket.ticketimage?<img src={G.nodeServerSite + "mediaFiles/ocrfiles/" + this.state.selectedTicket.ticketimage} style={{width:"95%"}}/>:<div style={{width:"100%",height:"300px", backgroundColor:"lightgray"}}>Image not available</div>}
                 
             </div>
         {this.state.oldtickets.map(el=>{
             return (
                 <div style={{width:"100%"}}>
                 <span style={{color:"#33A02C"}}>Previously validated ticket</span><br/><span>ID:{el.id}</span><br/>
                 {el.ticketimage?<img src={G.nodeServerSite + "mediaFiles/ocrfiles/" + el.ticketimage} style={{width:"95%"}}/>:<div style={{width:"95%",height:"300px", backgroundColor:"lightgray"}}>Image no available</div>}
                
                 </div>
             );
         })}
         </Slider>
         {/*<Carousel showArrows={this.state.oldtickets.length>2?true:false} showStatus={false} centerMode={true} selectedItem={0} showThumbs={false} centerSlidePercentage="33" showIndicators={false} renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                    <button type="button"  onClick={onClickHandler} title={label} style={{ position: 'absolute',
                    zIndex: 2,
                    top: 'calc(50% - 15px)',
                    width: 35,
                    height: 35,
                    backgroundColor:"transparent",
                    cursor: 'pointer', left: 15 }}>
                        <img src="/dist/img/prev.png" style={{height:35}}/>
                    </button>
                )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                    <button type="button" onClick={onClickHandler} title={label} style={{ position: 'absolute',
                    zIndex: 2,
                    top: 'calc(50% - 15px)',
                    width: 35,
                    height: 35,
                    backgroundColor:"transparent",
                    cursor: 'pointer', right: 15 }}>
                        <img src="/dist/img/next.png" style={{height:35}}/>
                    </button>
                )
            }>
             <div style={{width:"100%"}}>
                 <span style={{color:"#DA3849"}}>Current ticket</span><br/><span>ID:{this.state.selectedTicket.id}</span><br/>
                 {this.state.selectedTicket.ticketimage?<img src={G.nodeServerSite + "mediaFiles/ocrfiles/" + this.state.selectedTicket.ticketimage} style={{width:"95%"}}/>:<div style={{width:"100%",height:"300px", backgroundColor:"lightgray"}}>Image not available</div>}
                 
             </div>
         {this.state.oldtickets.map(el=>{
             return (
                 <div style={{width:"100%"}}>
                 <span style={{color:"#33A02C"}}>Previously validated ticket</span><br/><span>ID:{el.id}</span><br/>
                 {el.ticketimage?<img src={G.nodeServerSite + "mediaFiles/ocrfiles/" + el.ticketimage} style={{width:"95%"}}/>:<div style={{width:"95%",height:"300px", backgroundColor:"lightgray"}}>Image no available</div>}
                
                 </div>
             );
         })}
        </Carousel>*/}
         <center>
         {/*<Button variant="success" style={{marginTop:"50px", width:"150px"}} onClick={()=>{this.ResolveTicket()}}>Resolve</Button>*/}
         <br/>
             <Button variant="warning" style={{marginTop:"10px", width:"150px"}} onClick={()=>{this.setState({ showRedundancyModal: false })}}>{ts("Close",this.props.userLogin.language)}</Button></center>
         </div>
     }
     onHide={() => { this.setState({ showRedundancyModal: false }) }}
     />
     <MessageBox
     show={this.state.showTicketEditModal}
     body={this.state.showTicketEditModal &&
         <div className="row">
             <div className="col-md-7">
                 <div className="row">
                     <div className="col-md-12"><h4 style={{textAlign:"left"}}>{ts("Edit ticket details",this.props.userLogin.language)}</h4></div>
                     <div className="col-md-12">
  {G.renderTextField("Date & time","datetime-local","",this.handleChange,this.state.fields,this.state.errors, "","",false,this.props.userLogin.language,true,"receiptdate")}
  </div>
                     
  {/*<div className="col-md-12">
  {G.renderTextField("Site name","text","Enter site name",this.handleChange,this.state.fields,this.state.errors, "","",false,this.props.userLogin.language,true,"sitename")}
     </div>*/}
     <div className="col-md-12">
     <b className='fullWidth '>
            {ts("Detected store name",this.props.userLogin.language)}</b><br/>
            
            <div >{this.state.fields["storename"]}</div><br/>
        
     </div>
  <div className="col-md-12">
  {G.renderDropDownWithIdAndName("Matched shop name","sitestoreid",this.state.sitestores,this.handleChange,this.state.fields,this.state.errors, (this.state.scannedStore?this.state.scannedStore.id.toString():''),false,this.props.userLogin.language)}
  </div>
  <div className="col-md-12">
  {G.renderTextField("Amount","text","Enter shop name",this.handleChange,this.state.fields,this.state.errors, "","",false,this.props.userLogin.language,true,"amount")}
  </div>
  <div className="col-md-12">
  {G.renderHotspotDropDown("Status",["Validated","Pending","Rejected"],this.handleChange,this.state.fields,this.state.errors, "",false,this.props.userLogin.language,true)}
  </div>
                     <div className="col-md-12">
                     <Button variant="success" style={{width:"100px"}} onClick={()=>{this.SaveTicket()}}>Save</Button>&nbsp;
                     <Button variant="warning" style={{width:"100px"}} onClick={()=>{this.setState({ showTicketEditModal: false })}}>Cancel</Button>
                     </div>
                 </div>
                 
             
             </div>
             <div className="col-md-5">
             {this.state.selectedTicket.ticketimage?<img src={G.nodeServerSite + "mediaFiles/ocrfiles/" + this.state.selectedTicket.ticketimage} style={{width:"100%"}}/>:<h5>Image not available</h5>}
             </div>
         
         
         <center><br/><br/>
             
         
         <br/>
             </center>
         </div>
     }
     onHide={() => { this.setState({ showTicketEditModal: false }) }}
     />
     <MessageBox
     show={this.state.showAmountLimitModal}
     body={
         <div ><center><img src="/dist/img/alerthead.png" style={{width:"50px", margin:"auto"}}/></center><br/>
         <h4 style={{textAlign:"center"}}>This customer has exceeded the amount scanning limit</h4>
         
         <center><br/><br/>
             <p>{ts("Scanning amount limit", this.props.userLogin.language)}: <b>EUR {this.state.showGlobalLimit && this.state.ticketrule && this.state.ticketrule.maxamountlimit?this.state.ticketrule.maxamountlimit:(this.state.scannedStore?this.state.scannedStore.maxamountperreceipt:"unlimited")}</b></p>
             {!this.state.showGlobalLimit && this.state.scannedStore?<p>{ts("Limit store", this.props.userLogin.language)}: <b>{this.state.scannedStore.name}</b></p>:<p></p>}
             <p style={{color:"#DA3849"}}>{ts("Customer amount", this.props.userLogin.language)}: <b>EUR {this.state.selectedTicket.amount}</b></p>
         {/*<Button variant="success" style={{marginTop:"50px", width:"150px"}} onClick={()=>{this.ResolveTicket()}}>Resolve</Button>*/}
         <br/>
             <Button variant="warning" style={{marginTop:"10px", width:"150px"}} onClick={()=>{this.setState({ showAmountLimitModal: false })}}>{ts("Close",this.props.userLogin.language)}</Button></center>
         </div>
     }
     onHide={() => { this.setState({ showAmountLimitModal: false }) }}
     />
     <MessageBox
     show={this.state.showScanningLimitModal}
     body={
         <div ><center><img src="/dist/img/alerthead.png" style={{width:"50px", margin:"auto"}}/></center><br/>
         <h4 style={{textAlign:"center"}}>This customer has exceeded the scanning limit</h4>
         
         <center><br/><br/>
             <p>{ts("Scanning limit per customer", this.props.userLogin.language)}: <b>{this.state.showGlobalLimit && this.state.ticketrule && this.state.ticketrule.ticketscanlimitperday?this.state.ticketrule.ticketscanlimitperday:(this.state.scannedStore?this.state.scannedStore.maxnumberofscanspercustomerperday:"unlimited")}</b></p>
             {!this.state.showGlobalLimit && this.state.scannedStore?<p>{ts("Limit store", this.props.userLogin.language)}: <b>{this.state.scannedStore.name}</b></p>:<p></p>}
             <p>{ts("Limit period", this.props.userLogin.language)}: <b>{ts("1 day", this.props.userLogin.language)}</b></p>
             <p style={{color:"#DA3849"}}>{ts("Customer scanning", this.props.userLogin.language)}: <b>{!this.state.showGlobalLimit && this.state.scannedStore?this.state.selectedTicket.storescannedtickets:this.state.selectedTicket.scannedtickets}</b></p>
         {/*<Button variant="success" style={{marginTop:"50px", width:"150px"}} onClick={()=>{this.ResolveTicket()}}>Resolve</Button>*/}
         <br/>
             <Button variant="warning" style={{marginTop:"10px", width:"150px"}} onClick={()=>{this.setState({ showScanningLimitModal: false })}}>{ts("Close",this.props.userLogin.language)}</Button></center>
         </div>
     }
     onHide={() => { this.setState({ showScanningLimitModal: false }) }}
     />
     <MessageBox
     show={this.state.showFlagsModal}
     body={
         <div ><center><img src="/dist/img/alerthead.png" style={{width:"50px", margin:"auto"}}/></center><br/>
         <h4 style={{textAlign:"center"}}>This customer is flagged with an alert</h4>
         
         <center><br/><br/>
             <p>{ts("Flagged by user", this.props.userLogin.language)}: <b>{this.state.reporter.fullName}</b></p>
             {this.state.alerts.length>0 && <p>{ts("Date of flagging", this.props.userLogin.language)}: <b>{td(dateFormat(this.state.alerts[0].datein,"dddd, mmm dd, yyyy hh:MM TT"),this.props.userLogin.language)}</b></p>}
             {this.state.alerts.length>0 && <p>{ts("Reason", this.props.userLogin.language)}: <b><i>"{this.state.alerts[0].description}"</i></b></p>}
             <p><a style={{color:"#0070FA", cursor:"pointer"}} onClick={()=>{window.location.href='/ProfileManagement?p=' + this.state.selectedTicket.customerid + '&a=1'}}>{ts("view alerts history", this.props.userLogin.language)}</a></p>
         {/*<Button variant="success" style={{marginTop:"50px", width:"150px"}} onClick={()=>{this.ResolveTicket()}}>Resolve</Button>*/}
         <br/>
             <Button variant="warning" style={{marginTop:"10px", width:"150px"}} onClick={()=>{this.setState({ showFlagsModal: false })}}>{ts("Close",this.props.userLogin.language)}</Button></center>
         </div>
     }
     onHide={() => { this.setState({ showFlagsModal: false }) }}
     />
     <MessageBox
                    show={this.state.modalShow}
                    body={
                        <div>
                        <h4 style={{textAlign:"center"}}>Details</h4>
                        
                                <center><Button variant="warning" style={{marginTop:"50px", width:"150px"}} onClick={()=>{this.setState({ modalShow: false })}}>{ts("Close",this.props.userLogin.language)}</Button></center>
                                </div>
                    }
                    onHide={() => { this.setState({ modalShow: false }) }}
                />
                        </section>
                        }
                        </>
                    )
                }
               
            </>
        )
    }
}
const mapStateToProps = state => {

    return {
        userLogin: state.SaveLogin.userData
    };
};
const colourStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma("#9B00FA");
      const disabledcolor = chroma("gray")
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#9B00FA"
          : isFocused
          ? disabledcolor
          : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : "#9B00FA",
        cursor: isDisabled ? 'not-allowed' : 'default',
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#9B00FA"
              : "#9B00FA30"
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma("#9B00FA");
      return {
        ...styles,
        backgroundColor: "#9B00FA",
        color:"#ffffff"
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#ffffff",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "#ffffff",
      ':hover': {
        backgroundColor: "#9B00FA",
        color: 'white',
      },
    }),
  };
  
export default connect(
    mapStateToProps
)(ScannedTickets)