import React, { Component, ReactDOM, createRef } from 'react'
import { Image, Button, Badge, Spinner } from 'react-bootstrap';
import * as G from '../components/global'
import { renderPageContainer, renderPageHeader, renderDivider, getData } from '../components/global'
import history from '../components/history';
import { connect } from 'react-redux';
import MessageBox from '../components/MessageBox';
import emailtempl from '../components/assets/emailtempl.png'
import { translateScreen as ts , translateDate as td, translateScreen2 as ts2} from '../components/dictionary'
import ReactPaginate from 'react-paginate';
import 'rsuite/dist/rsuite.min.css';
import {DateRangePicker, DatePicker} from 'rsuite';
import Select from 'react-select'
import chroma from 'chroma'
import qs from 'qs'
import dateFormat from 'dateformat';
class Competitions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeScreen: 0,
            upcomingCompetitions:[],
            activeCompetitions: [],
			expiredCompetitions:[],
			disabledCompetitions:[],
			CompetitionCategoryOptions:[],
			CompetitionCategory:[],
			stats:{},
			CompetitionId:-1,
            selectedCompetition:null,
            loadingCompetitions: true,
			languagesList:[],
			errors:{},
			fields:{},
            searchfields:{},
            CompetitionRedeems:[],
            customersloading:false,
            modalShow: false,
            modalBody: '',
            modalSubject: '',
            selectedRedemption:{},
            questions:[],
            answers:[],
            answeredcustomers:0,
            questionscount:0,
            fieldchanging:false,
            successmodalShow:false
        }
		this.handleChange = this.handleChange.bind(this);        
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
		this.handleDateChange = this.handleDateChange.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleStatusSearch = this.handleStatusSearch.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }
    
    
    async componentDidMount() {
		this.selectInputRef = createRef();
		
        if (this.props.userLogin.site){
            this.getcategories();
            this.refreshData(this.props.userLogin.site.id)
        }
        history.listen((location, action) => {
            if(location.pathname == "/Competitions" || location.pathname == "/Competitions/")
            {
		        this.setState({activeScreen:0, fields:{}});
	        }
          });
        // if (this.state.allCompanies.length === 0) {
        //refreshDataClients()
        // }
    }
    getSnapshotBeforeUpdate(prevProps) {
        var searchfield = document.getElementById("searchfield");
            if(searchfield!=undefined && searchfield.value !="")
            {
                var nodes = document.getElementsByClassName('templatecard');
           Array.prototype.map.call(nodes, (elem)=>{
            if (elem.innerText.toLowerCase().includes(searchfield.value)) {
                elem.style.display = "block";
            } else {
                elem.style.display = "none";
            }
        });
            }
        return { notifyRequired: prevProps.userLogin !== this.props.userLogin };
      }
      
      async componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot.notifyRequired) {
            this.getcategories();
            await this.refreshData(this.props.userLogin.site.id);
        }
        
      }
    refreshData = async (company, redirect = true) => {
		this.setState({ loadingCompetitions: true })
        let { searchfields } = this.state;
        let upcomingfilter = '', activefilter = '', expiredfilter = '', redeemedfilter = '', disabledfilter = '';
        if(searchfields['searchrange']!= undefined && searchfields['searchrange']!=null)
        {
            var range = searchfields['searchrange'];
            var startdate = new Date(range[0])
            var enddate = new Date(range[1])
            var starts = td(dateFormat(startdate,"yyyy-mm-dd"));
            var ends = td(dateFormat(enddate,"yyyy-mm-dd"))
            activefilter += ` and DATEDIFF(a.enddate,'`+starts + `') >= 0 and DATEDIFF(a.enddate,'`+ends + `') <= 0`
            expiredfilter +=` and DATEDIFF(a.enddate,'`+starts + `') >= 0 and DATEDIFF(a.enddate,'`+ends + `') <= 0`
            upcomingfilter +=` and DATEDIFF(a.startdate,'`+starts + `') >= 0 and DATEDIFF(a.startdate,'`+ends + `') <= 0`
            disabledfilter +=` and DATEDIFF(a.enddate,'`+starts + `') >= 0 and DATEDIFF(a.enddate,'`+ends + `') <= 0`
            redeemedfilter += ` and DATEDIFF(datein,'`+starts + `') >= 0 and DATEDIFF(datein,'`+ends + `') <= 0`
        }
        if(searchfields['category'])
        {
            var categories = searchfields['category'];
            var s = "";
            categories.map(elem=>{
                s += "," + elem.value
            })
            if(s!="")
            {
                s = s.substring(1);
                activefilter += " and a.id in (select CompetitionId from CompetitionCategoryrelation where categoryid in ("+s+"))"
                expiredfilter += " and a.id in (select CompetitionId from CompetitionCategoryrelation where categoryid in ("+s+"))"
                upcomingfilter += " and a.id in (select CompetitionId from CompetitionCategoryrelation where categoryid in ("+s+"))"
                disabledfilter += " and a.id in (select CompetitionId from CompetitionCategoryrelation where categoryid in ("+s+"))"
                redeemedfilter += " and CompetitionId in (select CompetitionId from CompetitionCategoryrelation where categoryid in ("+s+"))"
            }
        }
        if(searchfields["searchname"])
        {
            var name = searchfields["searchname"];
            if(name!="")
            {
            activefilter += " and a.name like '%"+name+"%'"
            expiredfilter += " and a.name like '%"+name+"%'"            
            upcomingfilter += " and a.name like '%"+name+"%'"
            disabledfilter += " and a.name like '%"+name+"%'"
            }
        }
        var active = [], expired = [], disabled = [], stats = [], upcoming=[];
        if(searchfields["Competitionstatus"])
        {
            var status = searchfields["Competitionstatus"];
            switch(status)
            {
                case "All":
                    upcoming = await getData('competition/get', [['filter', 'a.siteid = ' + company + " and  a.startdate>now() and a.isactive=1 " + activefilter]]);
                    upcoming = await upcoming.data;    
                    active = await getData('competition/get', [['filter', 'a.siteid = ' + company + " and  a.enddate>=now() and a.startdate<=now() and a.isactive=1 " + activefilter]]);
                    active = await active.data;
                    expired = await getData('competition/get', [['filter', 'a.siteid = ' + company + " and a.enddate<now() " + expiredfilter]]);
                    expired = await expired.data;
                    disabled = await getData('competition/get', [['filter', 'a.siteid = ' + company + " and a.isactive<>1 "]]);
                    disabled = await disabled.data;
                    break;
                case "Upcoming":
                    upcoming = await getData('competition/get', [['filter', 'a.siteid = ' + company + " and a.startdate>now() and a.isactive=1 " + activefilter]]);
                    upcoming = await upcoming.data;
                    break;
                case "Open":
                    active = await getData('competition/get', [['filter', 'a.siteid = ' + company + " and a.enddate>=now() and a.isactive=1 " + activefilter]]);
                    active = await active.data;
                    break;
                case "Expired":
                    expired = await getData('competition/get', [['filter', 'a.siteid = ' + company + " and a.enddate<now() " + expiredfilter]]);
                    expired = await expired.data;
                    break;
                case "Disabled":
                    disabled = await getData('competition/get', [['filter', 'a.siteid = ' + company + " and a.isactive<>1 " + disabledfilter]]);
                    disabled = await disabled.data;
                    break;
                default:
                    
                    break;
            }
        }
        else{
            upcoming = await getData('competition/get', [['filter', 'a.siteid = ' + company + " and  a.startdate>now() and a.isactive=1 " + activefilter]]);
                    upcoming = await upcoming.data;
            active = await getData('competition/get', [['filter', 'a.siteid = ' + company + " and a.enddate>=now() and a.isactive=1 " + activefilter]]);
                    active = await active.data;
                    expired = await getData('competition/get', [['filter', 'a.siteid = ' + company + " and a.enddate<now() " + expiredfilter]]);
                    expired = await expired.data;
                    disabled = await getData('competition/get', [['filter', 'a.siteid = ' + company + " and a.isactive<>1 " + disabledfilter]]);
                    disabled = await disabled.data;
        }
       
		stats = await getData('Competitions/stats', [['siteid', company], ['upcomingfilter', ' and startdate>=now() and isactive=1 ' + upcomingfilter],
         ['activefilter', ' and enddate>=now() and isactive=1 ' + activefilter],['expiredfilter',' and isactive=1 and enddate<now() ' + expiredfilter]]);
        stats = await stats.data;
        this.setState({ activeCompetitions: active, upcomingCompetitions:upcoming, expiredCompetitions: expired, disabledCompetitions: disabled, stats: stats })
        this.setState({ loadingCompetitions: false })
        var isduplicate = qs.parse(history.location.search, { ignoreQueryPrefix: true }).isduplicate
        var detailid = qs.parse(history.location.search, { ignoreQueryPrefix: true }).detailid
        var editid = qs.parse(history.location.search, { ignoreQueryPrefix: true }).editid
        var opened = false;
        if(redirect)
        {
            active.map(el=>{
                if(el.id == detailid && !opened)
                {
                    opened = true;
                    this.OpenDetails(el.id, el, false);
                }
                else if(el.id == editid && !opened)
                {
                    opened = true;
                    this.OpenEdit((isduplicate == '1'?'-1':el.id), el, false);
                }
            })
            upcoming.map(el=>{
                if(el.id == detailid && !opened)
                {
                    opened = true;
                    this.OpenDetails(el.id, el, false);
                }
                else if(el.id == editid && !opened)
                {
                    opened = true;
                    this.OpenEdit((isduplicate == '1'?'-1':el.id), el, false);
                }
            })
            expired.map(el=>{
                if(el.id == detailid && !opened)
                {
                    opened = true;
                    this.OpenDetails(el.id, el, false);
                }
                else if(el.id == editid && !opened)
                {
                    opened = true;
                    this.OpenEdit((isduplicate == '1'?'-1':el.id), el, false);
                }
            })
            disabled.map(el=>{
                if(el.id == detailid && !opened)
                {
                    opened = true;
                    this.OpenDetails(el.id, el, false);
                }
                else if(el.id == editid && !opened)
                {
                    opened = true;
                    this.OpenEdit((isduplicate == '1'?'-1':el.id), el, false);
                }
            })
        }
    }
    handleStatusSearch = (e)=>
    {
        let { searchfields } = this.state;
        searchfields["Competitionstatus"] = e.target.value;
		 this.setState({ searchfields });
         this.refreshData(this.props.userLogin.site.id);
    }
    handleClickStatusSearch = (val)=>
    {
        let { searchfields } = this.state;
        searchfields["Competitionstatus"] = val;
		 this.setState({ searchfields });
         this.refreshData(this.props.userLogin.site.id);
    }
    handleCategorySearch = (name, e)=>{
        let { searchfields } = this.state;
        searchfields[name] = e;
		 this.setState({ searchfields });
         this.refreshData(this.props.userLogin.site.id, false);
    }
    handleSearchKeyUp = (e)=>{
        let { searchfields } = this.state;
        searchfields["searchname"] = e.target.value;
		 this.setState({ searchfields });
         this.refreshData(this.props.userLogin.site.id);
     }
     handleDateRangeChange = async(range)=>{
        let { searchfields } = this.state;
        searchfields["searchrange"] = range;
		 this.setState({ searchfields });
        this.refreshData(this.props.userLogin.site.id)
        
    }
	getlanguages = async()=>{
		let array = [];
		array = await G.getData("languages/query",[["filter"," 1=1 order by orderindex"]]);
		array = await array.data;
		this.setState({languagesList: array});
	}
	getcategories = async (company) => {
        if(this.selectInputRef.current!=null)
            this.selectInputRef.current.select.clearValue();
        let array = []
        array = await G.getData("CompetitionCategory/query", [["filter","a.siteid=" + this.props.userLogin.site.id]])
        array = await array.data
        this.setState({ CompetitionCategory: array })
        let arrayNames = [];
        array.map(element => {
          arrayNames.push(element.name)
        })
        this.setState({ CompetitionCategoryNames: arrayNames })
        console.log(this.state.CompetitionCategory)
        const categoryOptions = [];
        array.map(element => {
          categoryOptions.push({value:element.id, label:element.name});
        })
        this.setState({ CompetitionCategoryOptions: categoryOptions })
      }
      OpenEdit = async (id, element, redirect = true)=>
    {
        this.setState({fields:{}, questions:[]});
        var comp = await G.getData("competitions/get",[["competitionId",element.id]]);
        comp = await comp.data;
        console.log("competition", comp)
        var _this = this;
        this.getlanguages();
        var starts = td(dateFormat(element.startdate,"yyyy-mm-dd"));
        var ends = td(dateFormat(element.enddate,"yyyy-mm-dd"))
            var dbcats = [];
                var result = await G.getData("CompetitionCategory/query",[["filter"," id in (select categoryid from CompetitionCategoryrelation where CompetitionId ="+element.id+")"]]);
                result = await result.data;
                result.map(elem=>{
                    dbcats.push({value:elem.id, label:elem.name})
                })
        //var langresult = await G.getData("Competitiontranslation/query",[["filter","CompetitionId=" + element.id]])    ;
        //langresult = await langresult.data;
        //category, startdate, enddate,code,customersallowedlimit, type, value, enabled
        this.setState({CompetitionId:id, selectedCompetition:element, fields:{category:dbcats, startdate: starts, enddate: ends,code: element.code,customersallowedlimit: element.customersallowedlimit, enabled: element.isactive, value:element.value, redemptiontype:element.redemptiontype, neededtokens:element.neededtokens}, errors:{}});
        
        var {fields} = this.state;
        console.log(element)
        for(var i=0;i<comp.translations.length;i++)
            {
                var elem = comp.translations[i];
                fields[elem.languageid + "_name"] = elem.name;
                fields[elem.languageid + "_description"] = elem.description;
                /*setTimeout(function(){
                    document.getElementById(elem.languageid + "_description").value = elem.description; 
                },100);*/
                
            }
            _this.setState({ fields,activeScreen:1 });
        /*comp.translations.map(rec=>{
            fields[rec.languageid + "_name"] = rec.name;
            fields[rec.languageid + "_description"] = rec.description;
            
        })*/
        //var qresults = await G.getData("competitionquestions/query",[["filter","competitionid=" + element.id]]);
        //qresults = await qresults.data;
        var fetchedqs = 0;
        if(comp.questions)
        {
        comp.questions.sort((a, b) => a.orderindex > b.orderindex ? 1:-1).map(async(q)=>{
            this.state.questions.push({id:q.orderindex, name:"Competition question " +(_this.state.questions.length + 1).toString(), dbid:(id==-1?id:q.id), adbid:q.id});
            fetchedqs++;
            var langs = 0;
            q.translations && q.translations.map((l)=>{
                langs++;
                //var langres = await G.getData("competitionquestiontranslation/query",[["filter","languageid=" + l.id+" and competitionquestionid=" + q.id]])
                //langres = await langres.data;
                //if(langres.length>0)
                var selectedq = _this.state.questions.filter(x=>x.adbid == q.id)[0];
                    fields[l.languageid + "_" + selectedq.id + "_question"] = l.description;
                fields[l.languageid + "_" + selectedq.id + "_answer_category"] = q.answertype;
                fields[l.languageid + "_" + selectedq.id + "_onlynumbers"]  = q.onlynumbers==1?"on":"";
                _this.setState({ fields,questions:_this.state.questions });
                if(fetchedqs == comp.questions.length && langs == this.state.languagesList.length)
                {
                    _this.setState({ fields,questions:this.state.questions, answers:this.state.answers, activeScreen:1 });
                    if(redirect == true)
                    history.push("/Competitions/edit?editid=" + element.id+"&isduplicate=" + (id == '-1'?'1':'0'))
                }
            })
            //var answersresult = await G.getData("competitionquestionoptions/query",[["filter","questionid=" + q.id]]);
            //answersresult = await  answersresult.data;
            var fetchedas = 0;
            q.options && q.options.map(a=>{
                fetchedas++;
            _this.state.answers.push({id:a.orderindex, questionId: (this.state.questions.length), languageid:a.languageid, dbid:(id==-1?id:a.id), adbid:a.id})
            var las = 0;
            a.translations.map(async(l)=>{
                las++;
                //var langres = await G.getData("competitionquestionoptionstranslation/query",[["filter","languageid=" + l.id+" and competitionquestionoptionid=" + a.id]])
            //langres = await langres.data;
            _this.state.questions.map(sq=>{
                _this.state.answers.filter(x=>x.questionId == sq.id && x.adbid == a.id).map(sa=>{
                    if(!fields[l.languageid + "_" + sq.id +"_" + sa.id + "_answer"])
                    fields[l.languageid + "_" + sq.id +"_" + sa.id + "_answer"] = l.description;
                    if(!fields[l.languageid + "_" + sq.id +"_" + sa.id + "_correctanswer"])
                    fields[l.languageid + "_" + sq.id +"_" + sa.id + "_correctanswer"] = a.iscorrect == 1?"on":"";
                    if(fetchedas == q.options.length && las == a.translations.length)
                    {
                        _this.setState({ answers:this.state.answers, fields });
                    } 
                })
            })
            
            
            })
            
        })
        }) 
    }
        /*await G.getData("competitionquestions/query",[["filter","competitionid=" + element.id]]).then(async(questionsresult)=>{
            await questionsresult.data.then(async(questionsresult)=>{
                await questionsresult.map(async(q)=>{
                    this.state.questions.push({id:_this.state.questions.length + 1, name:"Competition question " +(_this.state.questions.length + 1).toString(), dbid:(id==-1?id:q.id)})
                    this.state.languagesList.map(async(l)=>{
                        var langres = await G.getData("competitionquestiontranslation/query",[["filter","languageid=" + l.id+" and competitionquestionid=" + q.id]])
                        langres = await langres.data;
                        if(langres.length>0)
                            fields[l.id + "_" + _this.state.questions.length + "_question"] = langres[0].description;
                        fields[l.id + "_" + _this.state.questions.length + "_answer_category"] = q.answertype;
                        fields[l.id + "_" + _this.state.questions.length + "_onlynumbers"]  = q.onlynumbers==1?"on":"";
                        _this.setState({ fields,questions:_this.state.questions });
                    })
                    var answersresult = await G.getData("competitionquestionoptions/query",[["filter","questionid=" + q.id]]);
                    answersresult = await  answersresult.data;
                    answersresult.map(a=>{
                        _this.state.answers.push({id:(this.state.answers.length + 1).toString(), questionId: (this.state.questions.length), languageid:a.languageid, dbid:(id==-1?id:a.id)})
                        this.state.languagesList.map(async(l)=>{
                            var langres = await G.getData("competitionquestionoptionstranslation/query",[["filter","languageid=" + l.id+" and competitionquestionoptionid=" + a.id]])
                        langres = await langres.data;
                        if(langres.length>0)
                            fields[l.id + "_" + _this.state.questions.length +"_" + this.state.answers.length + "_answer"] = langres[0].description;
                            fields[l.id + "_" + _this.state.questions.length +"_" + this.state.answers.length + "_correctanswer"] = a.iscorrect == 1?"on":"";
                            _this.setState({ fields,answers:_this.state.answers });
                        })
                       
                    })
                    //_this.setState({ answers:this.state.answers });
                })
                _this.setState({ fields,questions:this.state.questions, answers:this.state.answers, activeScreen:1 });
                history.push("/Competitions/edit")
            });
        })*/
            
            
        
    }
	OpenDetails = async(id, element, redirect = true)=>{
        this.setState({ customersloading: true })
        var dbcats = [];
                var result = await G.getData("CompetitionCategory/query",[["filter"," id in (select categoryid from CompetitionCategoryrelation where CompetitionId ="+element.id+")"]]);
                result = await result.data;
                result.map(elem=>{
                    dbcats.push(elem.name)
                })
                var redeems = await G.getData("competitionanswers/get",[["filter","cg.competitionid=" + id]])
                redeems = await redeems.data;
                var answeredcustomers = await G.getData("distinctcompetitioncustomers",[["filter","competitionid=" + id]])
                answeredcustomers = await answeredcustomers.data;
                var questions = await G.getData("competitionquestions/query",[["filter","competitionid=" + id + " order by orderindex"]])
                questions = await questions.data
                
                await redeems.map(async(item)=>{
                    var correctresult = await G.getData("competitionanswerslist/query",[["filter","competitionanswersid in (select id from competitionanswers where competitionid=" + item.competitionid+" and customerid="+item.customerid + ") and answerid in (select id from competitionquestionoptions where iscorrect=1)"]])
         correctresult = await correctresult.data;
            item.totalquestions = questions.length;
            item.correctanswers = correctresult.length;
            this.setState({CompetitionRedeems:redeems})
                });
        this.setState({activeScreen:2, selectedCompetition:element, CompetitionCategory:dbcats, answeredcustomers: answeredcustomers.length, questionscount: questions.length, questions:questions})
        if(redirect == true)
            history.push("/Competitions/details?detailid=" + id)
           if(this.state.CompetitionRedeems)
           {
            this.loadDataTables();
           } 
        
    }  
    loadDataTables = ()=>{
       var _this = this;
       setTimeout(() => {
       
                const script = document.createElement("script");
            script.id = "dttablestats";
            script.src = '/dist/js/competitiontable.js';
            script.async = true;
            document.body.appendChild(script);
            
            _this.setState({ customersloading: false })
    }, 500);
        
}
    
renderUpcomingButton = (id, element, cats) => {
		
    return (
    <div className="col-md-3 templatecard" onClick={(event)=>{if(event.target.className!='fa fa-ellipsis-v' && event.target.className!='dropdown-item')this.OpenDetails(id, element)}}>
        <div className="info-box handCursor shadow-sm" style={{backgroundColor:"#59ADFF", color:"white"}}>
          {/*<span className="info-box-icon bg-info" style={{padding:"30px"}} onClick={() => { this.openCampaignEditor(id, element) }}><i className="far fa-envelope"></i></span>*/}
          <div className="info-box-content">
            <div className='info-icon'><i className="fa fa-ellipsis-v" style={{padding:"5px 10px"}} data-toggle="dropdown" href="#" aria-expanded="false"></i>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <a onClick={()=>{this.OpenEdit(-1,element)}} className="dropdown-item">
                {ts2("Duplicate",this.props.userLogin.language)}
                </a>
                <div className="dropdown-divider"></div>
                <a onClick={()=>{this.OpenEdit(element.id,element)}} className="dropdown-item">
                {ts('Edit', this.props.userLogin.language)}
                </a>
                <div className="dropdown-divider"></div>
                <a onClick={()=>{this.DisableCompetition(id)}} className="dropdown-item">
                {ts('Disable', this.props.userLogin.language)}
                </a>
            </div>
            </div>
            <h3>{element.answers}<br/></h3>
            <small>answers</small>
            <span className="info-box-text bold" style={{width:"70%"}}>{element.name}</span>
            <span className="info-box-text" style={{width:"70%"}}>{element.category}</span>
            <span className="info-box-number" style={{fontStyle:"italic"}}> {element.startdate !== '' ? td(dateFormat(element.startdate, 'mmm dd, yyyy'),this.props.userLogin.language) : ''} till {element.enddate !== '' ? td(dateFormat(element.enddate, 'mmm dd, yyyy'),this.props.userLogin.language) : ''}</span>
          </div>
        </div>
    </div>
    )
}
    renderActiveButton = (id, element, cats) => {
		
        return (
        <div className="col-md-3 templatecard" onClick={(event)=>{if(event.target.className!='fa fa-ellipsis-v' && event.target.className!='dropdown-item')this.OpenDetails(id, element)}}>
            <div className="info-box handCursor shadow-sm" style={{backgroundColor:"#3FCAE0", color:"white"}}>
              {/*<span className="info-box-icon bg-info" style={{padding:"30px"}} onClick={() => { this.openCampaignEditor(id, element) }}><i className="far fa-envelope"></i></span>*/}
              <div className="info-box-content">
                <div className='info-icon'><i className="fa fa-ellipsis-v" style={{padding:"5px 10px"}} data-toggle="dropdown" href="#" aria-expanded="false"></i>
                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <a onClick={()=>{this.OpenEdit(-1,element)}} className="dropdown-item">
                    {ts2("Duplicate",this.props.userLogin.language)}
                    </a>
                    <div className="dropdown-divider"></div>
                    <a onClick={()=>{this.OpenEdit(element.id,element)}} className="dropdown-item">
                    {ts('Edit', this.props.userLogin.language)}
                    </a>
					<div className="dropdown-divider"></div>
                    <a onClick={()=>{this.DisableCompetition(id)}} className="dropdown-item">
                    {ts('Disable', this.props.userLogin.language)}
                    </a>
                </div>
                </div>
				<h3>{element.answers}<br/></h3>
				<small>answers</small>
                <span className="info-box-text bold" style={{width:"70%"}}>{element.name}</span>
                <span className="info-box-text" style={{width:"70%"}}>{element.category}</span>
                <span className="info-box-number" style={{fontStyle:"italic"}}> {element.startdate !== '' ? td(dateFormat(element.startdate, 'mmm dd, yyyy'),this.props.userLogin.language) : ''} till {element.enddate !== '' ? td(dateFormat(element.enddate, 'mmm dd, yyyy'),this.props.userLogin.language) : ''}</span>
              </div>
            </div>
        </div>
        )
    }
	renderExpiredButton = (id, element, cats) => {
		
        return (
        <div className="col-md-3 templatecard" onClick={(event)=>{if(event.target.className!='fa fa-ellipsis-v' && event.target.className!='dropdown-item')this.OpenDetails(id, element)}}>
            <div className="info-box  handCursor shadow-sm" style={{backgroundColor:"#8CB6CC", color:"white"}}>
              {/*<span className="info-box-icon bg-info" style={{padding:"30px"}} onClick={() => { this.openCampaignEditor(id, element) }}><i className="far fa-envelope"></i></span>*/}
              <div className="info-box-content">
                <div className='info-icon'><i className="fa fa-ellipsis-v" style={{padding:"5px 10px"}} data-toggle="dropdown" href="#" aria-expanded="false"></i>
                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <a onClick={()=>{this.OpenEdit(-1,element)}} className="dropdown-item">
                    {ts2("Duplicate",this.props.userLogin.language)}
                    </a>
                    <div className="dropdown-divider"></div>
                    <a onClick={()=>{this.OpenEdit(element.id,element)}} className="dropdown-item">
                    {ts('Edit', this.props.userLogin.language)}
                    </a>
                </div>
                </div>
				<h3>{element.answers}<br/></h3>
				<small>answers</small>
                <span className="info-box-text bold" style={{width:"70%"}}>{element.name}</span>
                <span className="info-box-text" style={{width:"70%"}}>{element.category}</span>
                <span className="info-box-number" style={{fontStyle:"italic"}}> {element.startdate !== '' ? td(dateFormat(element.startdate, 'mmm dd, yyyy'),this.props.userLogin.language) : ''} till {element.enddate !== '' ? td(dateFormat(element.enddate, 'mmm dd, yyyy'),this.props.userLogin.language) : ''}</span>
              </div>
            </div>
        </div>
        )
    }
    renderDisabledButton = (id, element, cats) => {
		
        return (
        <div className="col-md-3 templatecard" onClick={(event)=>{if(event.target.className!='fa fa-ellipsis-v' && event.target.className!='dropdown-item')this.OpenDetails(id, element)}}>
            <div className="info-box  handCursor shadow-sm" style={{backgroundColor:"#B9C0C9", color:"white"}}>
              {/*<span className="info-box-icon bg-info" style={{padding:"30px"}} onClick={() => { this.openCampaignEditor(id, element) }}><i className="far fa-envelope"></i></span>*/}
              <div className="info-box-content">
                <div className='info-icon'><i className="fa fa-ellipsis-v" style={{padding:"5px 10px"}} data-toggle="dropdown" href="#" aria-expanded="false"></i>
                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <a onClick={()=>{this.OpenEdit(-1,element)}} className="dropdown-item">
                    {ts2("Duplicate",this.props.userLogin.language)}
                    </a>
                    <div className="dropdown-divider"></div>
                    <a onClick={()=>{this.OpenEdit(element.id,element)}} className="dropdown-item">
                    {ts('Edit', this.props.userLogin.language)}
                    </a>
                </div>
                </div>
				<h3>{element.answers}<br/></h3>
				<small>answers</small>
                <span className="info-box-text bold" style={{width:"70%"}}>{element.name}</span>
                <span className="info-box-text" style={{width:"70%"}}>{element.category}</span>
                <span className="info-box-number" style={{fontStyle:"italic"}}> {element.startdate !== '' ? td(dateFormat(element.startdate, 'mmm dd, yyyy'),this.props.userLogin.language) : ''} till {element.enddate !== '' ? td(dateFormat(element.enddate, 'mmm dd, yyyy'),this.props.userLogin.language) : ''}</span>
              </div>
            </div>
        </div>
        )
    }
    /*EnableGift = async(id)=>{
        await getData('gifts/enable',[['id',id]]);
        this.refreshArchivedData(this.props.userLogin.site.id);
        this.refreshData(this.props.userLogin.site.id);
    }*/
    DisableCompetition = async(id)=>{
        await getData('competition/disable',[['id',id]]);
        this.refreshData(this.props.userLogin.site.id);
    }
    
    
	handleChange(e) {
		let { fields, errors } = this.state;
		 fields[e.target.name] = e.target.value;
		 delete errors[e.target.name];
 
		 this.setState({ fields });
	 }
     onBlur(e){
        this.setState({fieldchanging:false});
    }
    handleLanguageChange(e) {
		let { fields, errors,languagesList } = this.state;
		 fields[e.target.name] = e.target.value;
		 delete errors[e.target.name];

         for(var i=0;i<languagesList.length;i++)
         {
             var elem = languagesList[i];
             
             if(e.target.name.includes('_name'))
             {
                if(!fields[elem.id + "_name"] || fields[elem.id + "_name"]=='' || this.state.fieldchanging)
                {
                fields[elem.id + "_name"] = e.target.value;
                delete errors[elem.id + "_name"];
                this.setState({fieldchanging:true});
                }
             }
             if(e.target.name.includes('_description'))
             {
                if(!fields[elem.id + "_description"] || fields[elem.id + "_description"]=='' || this.state.fieldchanging)
                {
                    fields[elem.id + "_description"] = e.target.value;
                    document.getElementById(elem.id + "_description").value = e.target.value;
                    delete errors[elem.id + "_description"];
                    this.setState({fieldchanging:true});
                }
             }
         }
		 this.setState({ fields });
	 }
     handleQuestionLanguageChange(e, qid, aid = '') {
		let { fields, errors,languagesList } = this.state;
        if(e.target.name.includes('_correctanswer'))
             {
                var inputs = document.getElementsByTagName("input");
                for(var i=0;i<languagesList.length;i++)
         {
             var elem = languagesList[i];
                if(fields[elem.id + "_" + qid + "_answer_category"] == "single choice")
                {
                    for(var j = 0; j < inputs.length; j++) {
                        if(inputs[j].name.indexOf('_correctanswer') !=-1 && inputs[j].name.indexOf(elem.id + "_" + qid ) !=-1 ) {
                            fields[inputs[j].id] = "";
                            //document.getElementById(inputs[i].id).checked = false; 
                            
                        }
                    }
                }
            }
                
             }
		 fields[e.target.name] = e.target.value;
		 delete errors[e.target.name];
         for(var i=0;i<languagesList.length;i++)
         {
             var elem = languagesList[i];
             
             
             if(e.target.name.includes('_question'))
             {
                fields[elem.id + "_" + qid + "_question"] = e.target.value;
                delete errors[elem.id + "_" + qid + "_question"];
             }//_answer_category
             if(e.target.name.includes('_answer_category'))
             {
                console.log(e.target)
                fields[elem.id + "_" + qid + "_answer_category"] = e.target.value;
                delete errors[elem.id + "_" + qid + "_answer_category"];
                setTimeout(function(){

                    var inputs = document.getElementsByTagName("input");
                for(var j = 0; j < inputs.length; j++) {
                    if(inputs[j].name.indexOf('_correctanswer') !=-1) {
                        document.getElementById(inputs[j].id).checked = false; 
                        delete fields[inputs[j].id];
                    }
                }
                },100)
                
             }//_onlynumbers
             if(e.target.name.includes('_onlynumbers'))
             {
                fields[elem.id + "_" + qid + "_onlynumbers"] = e.target.checked?"on":"";
                delete errors[elem.id + "_" + qid + "_onlynumbers"];
             }
             if(e.target.name.includes('_answer'))
             {
                fields[elem.id + "_" + qid + "_" + aid + "_answer"] = e.target.value;
                delete errors[elem.id + "_" + qid + "_" + aid + "_answer"];
             }
             if(e.target.name.includes('_correctanswer'))
             {
                
                document.getElementById(elem.id + "_" + qid + "_" + aid + "_correctanswer").checked = e.target.checked
                    fields[elem.id + "_" + qid + "_" + aid + "_correctanswer"] = e.target.checked?"on":"";
                delete errors[elem.id + "_" + qid + "_" + aid + "_correctanswer"];
                
                
             }
         }
		 this.setState({ fields });
	 }
	 handleDateChange(name, date){
		var d = new Date(date)
		var dates = td(dateFormat(d,"yyyy-mm-dd"));
		let { fields, errors } = this.state;
		fields[name] = dates;
		delete errors[name];
		this.setState({ fields });
	 }
	 handleSelectChange(name, e){
		let { fields, errors } = this.state;
		fields[name] = e;
		delete errors[name];
		console.log("fields",fields);
		console.log("field e",e);
		this.setState({ fields });
	 }
	 createCompetition = async(CompetitionId)=>{
        var {errors} = this.state;
		const { category, startdate, enddate, enabled, neededtokens, redemptiontype } = this.state.fields;
		var status = enabled;
		if(status == undefined)
		{
			status = "1";
		}
        
		var translation = [];
        var valid = true;
        if(startdate==undefined || startdate == '')
        {
            valid = false;
            errors["startdate"] = "This field is required";
        }
        if(enddate==undefined || enddate == '')
        {
            valid = false;
            errors["enddate"] = "This field is required";
        }
        if(neededtokens==undefined || neededtokens == '')
        {
            valid = false;
            errors["neededtokens"] = "This field is required";
        }
        if(redemptiontype==undefined || redemptiontype == '')
        {
            valid = false;
            errors["redemptiontype"] = "This field is required";
        }
        if(enddate != undefined && enddate != null && enddate !='' && startdate != undefined && startdate != null && startdate !='')
        {
            var date1 = new Date(startdate);
            var date2 = new Date(enddate);
            if(date2<=date1)
            {
                errors["enddate"] = "End date should be newer then start date";
                errors["startdate"] = "Start date should be older then end date";
                valid = false;
            }
        }
        if(this.state.questions.length == 0)
        {
            valid = false;
            errors["questions"] = "Please add at least one question!";
        }
        if(status==undefined || status == '')
        {
            valid = false;
            errors["enabled"] = "This field is required";
        }
        if(category == undefined || category.length == 0)
        {
            valid = false;
            errors["category"] = "This field is required";
        }
		var params = [["CompetitionId",CompetitionId],["siteid",this.props.userLogin.site.id],
		["enabled", status], ["category", JSON.stringify(category)], ["startdate", startdate], ["enddate", enddate], ["neededtokens", neededtokens], ["redemptiontype", redemptiontype]];
        params.push(["questions", JSON.stringify(this.state.questions)])
            params.push(["answers", JSON.stringify(this.state.answers)])
            var logname = "";
		this.state.languagesList.map((elem)=>{
			var name = this.state.fields[elem.id + "_name"];
			var description =  this.state.fields[elem.id + "_description"];
			translation.push({langid: elem.id, name:name, description: description});
			//["name", translation[0].name],["description", translation[0].description],
            if(name == undefined || name=='')
            {
                errors[elem.id+"_name"] = "This field is required";
                valid = false;
            }
            if(description == undefined || description == '')
            {
                errors[elem.id+"_description"] = "This field is required";
                valid = false;
            }
			if(elem.code == "en")
			{
                logname = name;
				params.push(["name", name])
				params.push(["description", description])
			}
			params.push(["name_"+elem.code, name])
			params.push(["description_"+elem.code, description])
            
            this.state.questions.map((q)=>{
                var question = this.state.fields[elem.id + "_" + q.id + "_question"];
                var answer_category = this.state.fields[elem.id + "_" + q.id + "_answer_category"]
                var onlynumbers = this.state.fields[elem.id + "_" + q.id + "_onlynumbers"] == "on"?1:0
                if(question == undefined || question == '')
                {
                    errors[elem.id + "_" + q.id + "_question"] = "This field is required";
                    valid = false;
                }
                if((answer_category == undefined || answer_category == '') && elem.code == "en")
                {
                    errors[elem.id + "_" + q.id + "_answer_category"] = "This field is required";
                    valid = false;
                }
                /*if(onlynumbers == undefined || onlynumbers == '')
                {
                    errors[elem.id + "_" + q.id + "_onlynumbers"] = "This field is required";
                    valid = false;
                }*/
                params.push(["question_" + elem.code + "_" + q.id, question])
                params.push(["answer_category_" + elem.code + "_" + q.id, answer_category])
                params.push(["onlynumbers_" + elem.code + "_" + q.id, onlynumbers])
            });
            this.state.answers.map((a)=>{
                var answer = this.state.fields[elem.id + "_" + a.questionId + "_" + a.id + "_answer"];
                var correctanswer = this.state.fields[elem.id + "_" + a.questionId + "_" + a.id + "_correctanswer"] == "on"?1:0;
                if(answer == undefined || answer == '')
                {
                    errors[elem.id + "_" + a.questionId + "_" + a.id + "_answer"] = "This field is required";
                    valid = false;
                }
                /*if(correctanswer == undefined || correctanswer == '')
                {
                    errors[elem.id + "_" + a.questionId + "_" + a.id + "_correctanswer"] = "This field is required";
                    valid = false;
                }*/
                params.push(["answer_" + elem.code + "_" + a.questionId + "_" + a.id, answer])
                params.push(["correctanswer_" + elem.code + "_" + a.questionId + "_" + a.id, correctanswer])
            })
		});
        if(valid)
        {
		var result = await G.getData("competitions/upload",params)
		let success = (result.status === 200)
            if (success) {
                if(CompetitionId === -1)
                {
                    var logtxt = this.props.userLogin.username + " has created a competition " + logname;
                    await G.SaveUserLog(logtxt, this.props.userLogin.id);
                }
                else{
                    var logtxt = this.props.userLogin.username + " has edited the competition info of  " + logname;
                    await G.SaveUserLog(logtxt, this.props.userLogin.id);
                }
                this.setState({ activeScreen: 0, fields: {}, questions:[], answers:[], successmodalShow: CompetitionId!==-1})
                this.refreshData(this.props.userLogin.site.id)
                history.push("/Competitions")
            }
        }
        else{
            this.setState({errors});
        }
	 }
     openModal = async()=>{
         var item = this.state.selectedRedemption;
         var correctresult = await G.getData("competitionanswerslist/query",[["filter","competitionanswersid in (select id from competitionanswers where competitionid=" + this.state.selectedRedemption.competitionid+" and customerid="+this.state.selectedRedemption.customerid + ") and answerid in (select id from competitionquestionoptions where iscorrect=1)"]])
         correctresult = await correctresult.data;
         var questions = this.state.questions
         questions.map(async(q)=>{
            var answers = await G.getData("questioncustomeranswers/get",[["questionid",q.id], ["customerid",item.customerid]])
            q.answers = await answers.data;
            var correct = await G.getData("competitionquestionoptions/query",[["filter","questionid=" + q.id + " and iscorrect=1"]])
            correct = await correct.data
            q.correctanswer = correct.length>0?correct[0].description:""
            this.setState({
                questions:questions
            })
        })
         this.setState({
            modalShow: true,
            selectedRedemption: item,
            questions:questions
        })
     }
     generateQuestion = ()=>{
        
         var {questions, errors} = this.state;
         delete errors["questions"];
         questions.push({id:(questions.length + 1).toString(), name:"Competition question " +(questions.length + 1).toString(), dbid:-1})
         this.setState({questions});
     }
     generateAnswer = (questionId, languageid)=>
     {
        var {answers} = this.state;
        answers.push({id:(answers.length + 1).toString(), questionId: questionId, languageid:languageid, dbid:-1})
        this.setState({answers});
     }
     removeAnswer = (id, questionId)=>{
        var {answers} = this.state;
        var filteredArray = answers.filter(e=>e.id !== id)
        
        answers = filteredArray;
        this.setState({answers});
     }
     removeQuestion = async(id)=>{
        var {questions, answers} = this.state;
        var question = questions.filter(e=>e.id == id)[0]
        if(question.adbid)
        {
            await G.getData("competitionquestions/delete",[["filter","id=" + question.adbid]])
            answers.filter(x=>x.questionId == id).map(async(el)=>{
                if(el.adbid)
                {
                    await G.getData("competitionquestionoptions/delete",[["filter","id=" + el.adbid]])
                }
            })

        }
        var filteredArray = questions.filter(e=>e.id !== id)
        var i = 1;
        filteredArray.map(e=>{
            //e.id = i;
            e.name = "Competition question " +i
            i++;
        })
        questions = filteredArray;
        this.setState({questions});
     }
	 render() {
		const { errors, fields } = this.state;
        /*if (this.state.allCompanies != null) {

            if (this.state.allCompanies.length === 0) { this.refreshDataClients(); }

        }*/
        return (
            <>
                {/*<section className='content-header'>
                    <div className='container-fluid'>
                        <div className='row mb-2'>
                            <div className='col-sm-6'>
                                <h1>{ts('Email templates',this.props.userLogin.language)}</h1>
                            </div>
                        </div>
                    </div>
        </section>*/}
                
                
                {this.state.activeScreen === 0 &&

                    renderPageContainer(
                        <>
                        <section className='content'>                
                            
                            
                            <div className='row'><div className='col-sm-6'><Button onClick={() => { this.getlanguages();this.setState({activeScreen:1, CompetitionId:-1, fields:{}});history.push('/Competitions/create')  }} variant='success' className='mt-5 mb-4' >{ts('Create New Competition',this.props.userLogin.language)}</Button></div></div>
                            <h3>{ts('Competitions',this.props.userLogin.language)}&nbsp;&nbsp;
                             <DateRangePicker onChange={(value)=>{this.handleDateRangeChange(value)}}  placeholder={ts("Filter by date range", this.props.userLogin.language)} />
                            </h3><div className="col-md-12" style={{height:"20px"}}></div>
                            <div className="row">
                            <div className="col-md-2">
                                <div className="info-box handCursor mainScreenCard" onClick={()=>{this.handleClickStatusSearch("")}}>
                                <div className="info-box-content" style={{color:"black"}}>
                                <span>{ts("All", this.props.userLogin.language)}</span>
                                    <span style={{fontSize:"40px", color:"#000000"}}>{!isNaN(this.state.stats.upcoming + this.state.stats.active + this.state.stats.expired + this.state.stats.disabled) && <span>{this.state.stats.upcoming + this.state.stats.active + this.state.stats.expired + this.state.stats.disabled}</span>}</span>
                        
                                </div>
                                    
                                    </div>
                                
                                </div>
                                <div className="col-md-2">
                                <div className="info-box handCursor mainScreenCard" onClick={()=>{this.handleClickStatusSearch("Upcoming")}}>
                                <div className="info-box-content" style={{color:"black"}}>
                                <span>{ts("Upcoming", this.props.userLogin.language)}</span>
                                    <span style={{fontSize:"40px", color:"#2A72CC"}}>{this.state.stats.upcoming}</span>
                        
                                </div>
                                    
                                    </div>
                                
                                </div>
                                <div className="col-md-2">
                                <div className="info-box handCursor mainScreenCard" onClick={()=>{this.handleClickStatusSearch("Open")}}>
                                <div className="info-box-content" style={{color:"black"}}>
                                <span>{ts("Open", this.props.userLogin.language)}</span>
                                    <span style={{fontSize:"40px", color:"#3FCAE0"}}>{this.state.stats.active}</span>
                        
                                </div>
                                    
                                    </div>
                                
                                </div>
                                <div className="col-md-2">
                                <div className="info-box handCursor mainScreenCard" onClick={()=>{this.handleClickStatusSearch("Expired")}}>
                                <div className="info-box-content" style={{color:"black"}}>
                                <span>{ts("Expired", this.props.userLogin.language)}</span>
                                    <span style={{fontSize:"40px", color:"#8CB6CC"}}>{this.state.stats.expired}</span>
                        
                                </div>
                                    
                                    </div>
                                
                                </div>
                                <div className="col-md-2">
                                <div className="info-box handCursor mainScreenCard" onClick={()=>{this.handleClickStatusSearch("Disabled")}}>
                                <div className="info-box-content" style={{color:"black"}}>
                                <span>{ts("Disabled", this.props.userLogin.language)}</span>
                                    <span style={{fontSize:"40px", color:"#8D949D"}}>{this.state.stats.disabled}</span>
                        
                                </div>
                                    
                                    </div>
                                
                                </div>
                            </div>
                            <div className="col-md-12" style={{height:"10px"}}></div>
							<div className="row">
								<div className='form-group col-md-3'>
								<label for="searchfield">Select Competition status</label>
								<select className='custom-select' onChange={this.handleStatusSearch}>
                                {this.state.searchfields["Competitionstatus"]=="All"?<option selected>All</option>:<option>All</option>}
									{this.state.searchfields["Competitionstatus"]=="Upcoming"?<option selected>Upcoming</option>:<option>Upcoming</option>}
                                    {this.state.searchfields["Competitionstatus"]=="Open"?<option selected>Open</option>:<option>Open</option>}
                                    {this.state.searchfields["Competitionstatus"]=="Expired"?<option selected>Expired</option>:<option>Expired</option>}
                                    {this.state.searchfields["Competitionstatus"]=="Disabled"?<option selected>Disabled</option>:<option>Disabled</option>}
								</select>
								</div>
								<div className='form-group col-md-3'>
								<label>Filter by Competition category</label>
								<Select ref={this.selectInputRef} menuPortalTarget={document.body} isClearable={true}
            onChange={(e)=>this.handleCategorySearch("category", e)}
    closeMenuOnSelect={false}
    isMulti
    styles={colourStyles}
    options={this.state.CompetitionCategoryOptions}
  />
								</div>
                            <div className="form-group col-md-3">
                            <label for="searchfield">Search</label>
                            <div class="input-group">
                                
                  <input id="searchfield" type="text" class="form-control" onKeyUp={(e)=>this.handleSearchKeyUp(e)}/>
                  <div class="input-group-append">
                    <span class="input-group-text"><i class="fas fa-search"></i></span>
                  </div>
                </div>
                            </div>
                            </div>
                            <div className='row'>
    
                            {/*{renderPageHeader(<span>{ts('Saved Campaigns List',this.props.userLogin.language)} <Badge variant='warning ssstitle'>{this.state.activeCompany ? this.state.activeCompany.companyName : ''}</Badge></span>)}*/}
                            {this.state.loadingCompetitions &&
                                <div className='flexcolumn center verticalcenter p-5 fullWidth hint mt-5 mb-4'>
                                    {/*changed by youssef to meet the selected site requirment*/}
                                    {/*{ts('Loading templates for',this.props.userLogin.language)} {this.state.activeCompany ? this.state.activeCompany.companyName : ''}*/}
                                    {ts('Loading Competitions for',this.props.userLogin.language)} {this.props.userLogin.site ? this.props.userLogin.site.companyName : ''}
                                    <Spinner animation="border" className='mt-3' variant="warning" />

                                </div>
                            }
                            </div>
                            {!this.state.loadingCompetitions &&<div className='row'> <div className='flexrow start wrap mt-2   pt-3 '>
                            {this.state.upcomingCompetitions.filter(e=>e.id!=null).length>0 && 
							<div className="col-md-12" style={{margin:"30px 0"}}><center><h6>upcoming</h6></center></div>}
                                {
								
								this.state.upcomingCompetitions.map((element) => {
									/*var cats = await G.getData("gifts/categories",[["CompetitionId",element.id]]);
									cats = await cats.data;*/
									console.log(element)
									if(element.id)
                                    	return (this.renderUpcomingButton(element.id, element))

                                })}
								{this.state.activeCompetitions.filter(e=>e.id!=null).length>0 && 
							<div className="col-md-12" style={{margin:"30px 0"}}><center><h6>open</h6></center></div>}
                                {
								
								this.state.activeCompetitions.map((element) => {
									/*var cats = await G.getData("gifts/categories",[["CompetitionId",element.id]]);
									cats = await cats.data;*/
									console.log(element)
									if(element.id)
                                    	return (this.renderActiveButton(element.id, element))

                                })}
{this.state.expiredCompetitions.filter(e=>e.id!=null).length>0 && 
							<div className="col-md-12" style={{margin:"30px 0"}}><center><h6>expired</h6></center></div>}
                                {
								
								this.state.expiredCompetitions.map((element) => {
									/*var cats = await G.getData("gifts/categories",[["CompetitionId",element.id]]);
									cats = await cats.data;*/
                                    if(element.id)
										return (this.renderExpiredButton(element.id, element))

                                })}
								{this.state.disabledCompetitions.filter(e=>e.id!=null).length>0 && 
							<div className="col-md-12" style={{margin:"30px 0"}}><center><h6>disabled</h6></center></div>}
                                {
								
								this.state.disabledCompetitions.map((element) => {
									/*var cats = await G.getData("gifts/categories",[["CompetitionId",element.id]]);
									cats = await cats.data;*/
									
									if(element.id)
                                    	return (this.renderDisabledButton(element.id, element))

                                })}
                                {this.state.activeCompetitions.length === 0 && this.state.disabledCompetitions.length === 0 && this.state.expiredCompetitions.length === 0 &&
                                    <div className='col-md-12'>
                                    <div className='flexcolumn center verticalcenter p-5 fullWidth cSignInBack boxRadius5'>
                                       {ts(' No Competitions available for',this.props.userLogin.language)} {this.props.userLogin.site ? this.props.userLogin.site.companyName : ''}
                                        <Button onClick={() => { this.getlanguages();this.setState({activeScreen:1});history.push('/Competitions/create') }} variant='success' className='m-4' >Create First Competition</Button>
                                    </div>
                                    </div>
                                }

                            </div>
                            </div>}
                            
                            
                            
                            
                            </section>
                            <MessageBox
                    show={this.state.successmodalShow}
                    title={ts("Changes are saved",this.props.userLogin.language)}
                    body={<div><center><Button variant="success" onClick={()=>{this.setState({ successmodalShow: false });}}>OK</Button></center></div>}
                    onHide={() => { this.setState({ modalShow: false }) }}

                />  
                            
                            
                        
                        </>
                    )

                }
				{this.state.activeScreen === 1 && 
					renderPageContainer(
						<section className='content'>
							<div className='row'>
							<div className='col-md-12'>
							<Button className='mb-4 mt-5' variant='warning' onClick={() => { this.setState({activeScreen:0, fields:{}, questions:[], answers:[]});history.go(-1) }} >{ts('Cancel / Return', this.props.userLogin.language)}</Button>
							</div>
							<div className='col-md-12'>
							<h3>{ts('Competition details',this.props.userLogin.language)}
                            </h3>
							</div>
							<div className='col-md-12' style={{height:"20px"}}></div>
							<div className='col-md-12'>
							<p class="  ssstitle bold  boxTopRadius5 cunlayer  p-2  ">{ts("Competition info",this.props.userLogin.language )} </p>
							</div>
							<div className='col-md-12' style={{height:"20px"}}></div>
							<div class="col-12 col-sm-6">
<div class="card card-primary card-outline card-tabs">
<div class="card-header p-0 pt-1 border-bottom-0">
<ul class="nav nav-tabs" id="custom-tabs-two-tab" role="tablist">
<li class="pt-2 px-3"><h6 class="card-title" style={{margin:"0px"}}>Entry by language</h6></li>
{
	this.state.languagesList.map((elem)=>{
		return <li class="nav-item">
<a class={elem.code == "fr"?"nav-link active":"nav-link"} id={"custom-tabs-two-" + elem.code + "-tab"} data-toggle="pill" href={"#custom-tabs-two-" + elem.code} role="tab" aria-controls={"custom-tabs-two-" + elem.code} aria-selected="true">{elem.code}</a>
</li>
	})
}
</ul>
</div>
<div class="card-body">
<div class="tab-content" id="custom-tabs-two-tabContent">
	{
		this.state.languagesList.map((elem)=>{
			return <div class={elem.code == "fr"?"tab-pane fade show active":"tab-pane fade"} id={"custom-tabs-two-" + elem.code} role="tabpanel" aria-labelledby={"custom-tabs-two-"+elem.code+"-tab"}>
				{G.renderTextField("Title", 'text', "enter Competition title here", (elem.code == "fr"?this.handleLanguageChange:this.handleChange), fields, errors, null, '',false, this.props.userLogin.language, true,elem.id + "_name",this.onBlur)}
				{G.renderTextArea("Description", 'textarea', "enter Competition description here", (elem.code == "fr"?this.handleLanguageChange:this.handleChange), fields, errors, null, '',false, this.props.userLogin.language, true,elem.id + "_description",this.onBlur)}
			</div>
		})
	}

</div>
</div>

</div>
</div>
<div className='col-md-12' style={{height:"20px"}}></div>
							{/*<div className='col-md-12'>
							<p class="  ssstitle bold  boxTopRadius5 cunlayer  p-2  ">{ts("Promo code rules",this.props.userLogin.language )} </p>
							</div>
<div className='col-md-12' style={{height:"20px"}}></div>*/}
							<div className='col-md-6'>
							<label>Category</label>
								<Select ref={this.selectInputRef} menuPortalTarget={document.body} isClearable={true}
       value={this.state.fields["category"]}    
    closeMenuOnSelect={false}
    isMulti
    styles={colourStyles}
    options={this.state.CompetitionCategoryOptions}
	id="category" name="category" onChange={(e)=>this.handleSelectChange("category", e)}
  />
  {this.state.errors['category'] && <div className='remarks notValidColor'>{this.state.errors['category']}</div>}
							</div>
							<div className="col-md-12" style={{height:"20px"}}></div>
							<div class="form-group col-md-3">
                  <label>Start date</label>
                  {
                      this.state.fields["startdate"] &&
                      
                        <DatePicker  name="startdate" oneTap={true} onSelect={(value)=>{this.handleDateChange("startdate",value)}} closeMenuOnSelect={true} closeOverlay="true" defaultValue={new Date(this.state.fields["startdate"])}  onChange={(value)=>this.handleDateChange("startdate",value)} style={{width:"100%",border:this.state.errors['startdate']?"1px solid " + G.errorColor:""}}/>
                  }
                  {  
                      !this.state.fields["startdate"] &&
                        <DatePicker name="startdate" oneTap={true} onSelect={(value)=>{this.handleDateChange("startdate",value)}}  closeMenuOnSelect={true} closeOverlay="true" onChange={(value)=>this.handleDateChange("startdate",value)} style={{width:"100%",border:this.state.errors['startdate']?"1px solid " + G.errorColor:""}}/>

                  }
				  {this.state.errors['startdate'] && <div className='remarks notValidColor'>{this.state.errors['startdate']}</div>}
                </div>
				<div class="form-group col-md-3">
                  <label>End date</label>
				  {
                      this.state.fields["enddate"] &&
                      
                        <DatePicker name="enddate" oneTap={true} onSelect={(value)=>{this.handleDateChange("enddate",value)}}  closeMenuOnSelect={true} defaultValue={new Date(this.state.fields["enddate"])}  onChange={(value)=>this.handleDateChange("enddate",value)} style={{width:"100%",border:this.state.errors['enddate']?"1px solid " + G.errorColor:""}}/>
                  }
                  {  
                      !this.state.fields["enddate"] &&
                        <DatePicker name="enddate" oneTap={true} onSelect={(value)=>{this.handleDateChange("enddate",value)}}  closeMenuOnSelect={true} onChange={(value)=>this.handleDateChange("enddate",value)} style={{width:"100%",border:this.state.errors['enddate']?"1px solid " + G.errorColor:""}}/>

                  }
                  {this.state.errors['enddate'] && <div className='remarks notValidColor'>{this.state.errors['enddate']}</div>}
                </div>				
				
				<div className="col-md-12" style={{height:"20px"}}></div>
				
                <div class="form-group col-md-3">
                <label for="type">Type</label>
				  <select name="redemptiontype" id="redemptiontype" placeholder='select competition redemption type' onChange={this.handleChange} className="custom-select">
                  <option value="">Please select</option>
                  {this.state.fields["redemptiontype"]=="Token" ?<option selected>Token</option>:<option >Token</option>}
                      {this.state.fields["redemptiontype"]=="Points" ?<option selected>Points</option>:<option>Points</option>}
				  </select>
                  {errors["redemptiontype"] && <div className='remarks notValidColor'>{errors["redemptiontype"]}</div>}
                </div>
                <div class="col-md-3">
                {G.renderTextField("Value", 'text', "enter competition value (points or tokens)", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language, true,"neededtokens")}
                </div>
                <div className="col-md-12" style={{height:"20px"}}></div>
				<div class="form-group col-md-3">
					<label for="enabled">Status</label>
				  <select name="enabled" id="enabled" onChange={this.handleChange} className="custom-select">
                  <option value="">Please select</option>
                      {this.state.fields["enabled"] == "1" ? <option value="1" selected>Enabled</option>:<option value="1" >Enabled</option>}
					  {this.state.fields["enabled"] == "0" ? <option value="0" selected>Disabled</option>:<option value="0">Disabled</option>}
				  </select>
                  {this.state.errors['enabled'] && <div className='remarks notValidColor'>{this.state.errors['enabled']}</div>}
                </div>
                <div className="col-md-12" style={{height:"20px"}}></div>
                {
                    this.state.questions.map(q=>{
                        return (
                            <>
                            <div className='col-md-12'>
							<p class="  ssstitle bold  boxTopRadius5 cunlayer  p-2  ">{q.name} </p>
							</div>
                            <div className="col-md-12" style={{height:"20px"}}></div>
                            <div class="col-12 col-sm-6">
<div class="card card-primary card-outline card-tabs">
<div class="card-header p-0 pt-1 border-bottom-0">
<ul class="nav nav-tabs" id="custom-tabs-two-tab" role="tablist">
<li class="pt-2 px-3"><h6 class="card-title" style={{margin:"0px"}}>Entry by language</h6></li>
{
	this.state.languagesList.map((elem)=>{
		return <li class="nav-item">
<a class={elem.code == "fr"?"nav-link active":"nav-link"} id={"custom-tabs-two-" + elem.code + "-" + q.id + "-tab"} data-toggle="pill" href={"#custom-tabs-two-" + elem.code + "-" + q.id} role="tab" aria-controls={"custom-tabs-two-" + elem.code + "-" + q.id} aria-selected="true">{elem.code}</a>
</li>
	})
}
</ul>
</div>
<div class="card-body">
<div class="tab-content" id="custom-tabs-two-tabContent">
	{
		this.state.languagesList.map((elem)=>{
			return <div class={elem.code == "fr"?"tab-pane fade show active":"tab-pane fade"} id={"custom-tabs-two-" + elem.code + "-" + q.id} role="tabpanel" aria-labelledby={"custom-tabs-two-"+elem.code + "-" + q.id+"-tab"}>
				{G.renderTextField("Question", 'text', "enter question here", (elem.code == "fr"?(e)=>{
                    this.handleQuestionLanguageChange(e,q.id)
                }:this.handleChange), fields, errors, null, '',false, this.props.userLogin.language, true,elem.id + "_" + q.id + "_question")}
				<div className="form-group">
                    <label for={elem.id + "_" + q.id + "_answer_category"}>Answer category</label>
                    <select name={elem.id + "_" + q.id + "_answer_category"} id={elem.id + "_" + q.id + "_answer_category"} onChange={(elem.code == "fr"?(e)=>{
                    this.handleQuestionLanguageChange(e,q.id)
                }:this.handleChange)} className="custom-select">
                    <option value="">Please choose</option>
                    {fields[elem.id + "_" + q.id + "_answer_category"] == "single choice" ? <option selected>single choice</option>:<option>single choice</option>}
                    {fields[elem.id + "_" + q.id + "_answer_category"] == "multiple choice" ? <option selected>multiple choice</option>:<option>multiple choice</option>}
                    {fields[elem.id + "_" + q.id + "_answer_category"] == "free text" ? <option selected>free text</option>:<option>free text</option>}
                    
                </select>
                {this.state.errors[elem.id + "_" + q.id + "_answer_category"] && <div className='remarks notValidColor'>{this.state.errors[elem.id + "_" + q.id + "_answer_category"]}</div> }
                </div>
                {this.state.fields[elem.id + "_" + q.id + "_answer_category"] == "free text" &&
                
                <div class="form-check">
                    {fields[elem.id + "_" + q.id + "_onlynumbers"]=="on" && <input type="checkbox" checked class="form-check-input" onChange={(elem.code == "fr"?(e)=>{
                    this.handleQuestionLanguageChange(e,q.id)
                }:this.handleChange)} id={elem.id + "_" + q.id + "_onlynumbers"} name={elem.id + "_" + q.id + "_onlynumbers"}/>}
                    {fields[elem.id + "_" + q.id + "_onlynumbers"]!="on" && <input type="checkbox" class="form-check-input" onChange={(elem.code == "fr"?(e)=>{
                    this.handleQuestionLanguageChange(e,q.id)
                }:this.handleChange)} id={elem.id + "_" + q.id + "_onlynumbers"} name={elem.id + "_" + q.id + "_onlynumbers"}/>}
                <label class="form-check-label" for={elem.id + "_" + q.id + "_onlynumbers"}>Only numbers</label>
                </div>
                }
                {this.state.fields[elem.id + "_" + q.id + "_answer_category"] != "free text" &&
                <h5>Answers options</h5>
                }
                {
                    this.state.answers.filter(x=>x.questionId == q.id).map(el=>{
                        return (
                            <div className="row">
                                <div className="col-md-8">
                                {G.renderTextField("", 'text', "add answer here", (elem.code == "fr"?(e)=>{
                    this.handleQuestionLanguageChange(e,q.id, el.id)
                }:this.handleChange), fields, errors, null, '',false, this.props.userLogin.language, true,(elem.id + "_" + q.id + "_" + el.id + "_answer"))}
                                </div>
                                <div className="col-md-2" style={{paddingTop:"27px"}}>
                                <div class="form-check">
                                    {
                                        this.state.fields[elem.id + "_" + q.id +"_" + el.id+ "_correctanswer"]==="on" &&
                                        <input type={fields[elem.id + "_" + q.id + "_answer_category"] == "single choice"?"radio":"checkbox"} class="form-check-input" checked onChange={(elem.code == "fr"?(e)=>{
                                            this.handleQuestionLanguageChange(e,q.id, el.id)
                                        }:this.handleChange)} id={elem.id + "_" + q.id +"_" + el.id+ "_correctanswer"} name={elem.id + "_" + q.id + "_correctanswer"}/>
                                    }
                                    {
                                        this.state.fields[elem.id + "_" + q.id +"_" + el.id+ "_correctanswer"]!=="on" &&
                                        <input type={fields[elem.id + "_" + q.id + "_answer_category"] == "single choice"?"radio":"checkbox"} class="form-check-input" onChange={(elem.code == "fr"?(e)=>{
                                            this.handleQuestionLanguageChange(e,q.id, el.id)
                                        }:this.handleChange)} id={elem.id + "_" + q.id +"_" + el.id+ "_correctanswer"} name={elem.id + "_" + q.id + "_correctanswer"}/>
                                    }
                
                <label class="form-check-label" for={elem.id + "_" + q.id +"_" + el.id+ "_correctanswer"}>is correct</label>
                </div>
                                </div>
                                <div className="col-md-2" style={{paddingTop:"20px"}} >
                                <Button onClick={() => { this.removeAnswer(el.id, q.id)  }} variant='outline-danger'  >{ts('X',this.props.userLogin.language)}</Button>
                                </div>
                            </div>
                    )
                    })
                }
                {this.state.fields[elem.id + "_" + q.id + "_answer_category"] != "free text" &&
                
                <div className="form-group" style={{marginTop:"10px"}}>
                <Button onClick={() => { this.generateAnswer(q.id, elem.id)  }} variant='outline-primary'  >{ts('+ Add answer',this.props.userLogin.language)}</Button>
                </div>
        }
			</div>
		})
	}

</div>
</div>

</div>
</div>
                            <div className="col-md-12" style={{height:"20px"}}></div>
                            <div className="col-md-3">
                            <Button onClick={() => { this.removeQuestion(q.id)  }} variant='outline-danger'  >{ts('X Remove question',this.props.userLogin.language)}</Button>
                            </div>
                            </>
                        );
                    })
                }
				<div className="col-md-12" style={{height:"20px"}}>
                    {errors["questions"] &&<span style={{color:"red"}}>{errors["questions"]}</span>}
                </div>
				<div className="col-md-3">
                <Button onClick={() => { this.generateQuestion()  }} variant='primary'  >{ts('+ Add question',this.props.userLogin.language)}</Button>
                </div>
                <div className="col-md-12" style={{height:"20px"}}></div>
				<div className="col-md-3">
				<Button onClick={() => { this.createCompetition(this.state.CompetitionId)  }} variant='success'  >{ts('Save',this.props.userLogin.language)}</Button>&nbsp;&nbsp;&nbsp;
				<Button variant='warning' onClick={() => { this.setState({activeScreen:0, fields:{}, questions:[], answers:[]});history.go(-1) }} >{ts('Return', this.props.userLogin.language)}</Button>
				</div>
				<div className="col-md-12" style={{height:"20px"}}></div>
							</div>
						</section>
					)
				}
                {
                    
                    this.state.activeScreen === 2 &&
                    renderPageContainer(
                        
                        <section className='content'>
                            {this.state.customersloading &&
                                <div className='flexcolumn center verticalcenter p-5 fullWidth hint mt-5 mb-4'>
                                    {/*changed by youssef to meet the selected site requirment*/}
                                    {/*{ts('Loading templates for',this.props.userLogin.language)} {this.state.activeCompany ? this.state.activeCompany.companyName : ''}*/}
                                    {ts('Loading',this.props.userLogin.language)}
                                    <Spinner animation="border" className='mt-3' variant="warning" />

                                </div>
                            }
                            {!this.state.customersloading &&
                            <div className='row'>
                            <div className='col-md-12'>
							<Button className='mb-4 mt-5' variant='warning' onClick={() => { this.setState({activeScreen:0, fields:{}, selectedCompetition:null, CompetitionCategory:[], CompetitionRedeems:[]});history.go(-1) }} >{ts('Cancel / Return', this.props.userLogin.language)}</Button>
							</div>
                            
                            <div className='col-md-12'>
							<p class="  ssstitle bold  boxTopRadius5 cunlayer  p-2  ">{ts("Competition info",this.props.userLogin.language )} </p>
							</div>
							<div className='col-md-12' style={{height:"20px"}}></div>
                            <div className='col-md-12'>
                                <ul><li>Title:<b>{this.state.selectedCompetition.name}</b></li>
                                <li>Category: <b>{ this.state.CompetitionCategory.map(el=>el+" | ")}</b></li>
                                <li>Description: <b>{this.state.selectedCompetition.description}</b></li>
                                <li>Start date: <b>{td(dateFormat(this.state.selectedCompetition.startdate,"ddd dd, mmm yyyy"), this.props.userLogin.language)}</b></li>
                                <li>End date: <b>{td(dateFormat(this.state.selectedCompetition.enddate,"ddd dd, mmm yyyy"), this.props.userLogin.language)}</b></li>
                                <li>Status: <Badge variant={this.state.selectedCompetition.isactive==1?'primary':'danger'} style={{backgroundColor:this.state.selectedCompetition.isactive==1 && new Date(this.state.selectedCompetition.enddate)>new Date()?'#3FCAE0':'lightgray'}} className=' remarks'>
{this.state.selectedCompetition.isactive==1 && new Date(this.state.selectedCompetition.enddate)>new Date()?'Active':'Disabled'}
</Badge></li>
<li>Number of questions: <b>{this.state.questionscount}</b></li>
<li>Number of customers who answered: <b>{this.state.answeredcustomers}</b></li>
                                </ul>
                            </div>
                            <div className='col-md-12' style={{height:"20px"}}></div>
                            <div className='col-md-12'>
							<p class="  ssstitle bold  boxTopRadius5 cunlayer  p-2  ">{ts("List of participants",this.props.userLogin.language )} </p>
							</div>
                            <div className='col-md-12' style={{height:"20px"}}></div>
                            <div className='col-md-12'>
                                {this.state.CompetitionRedeems && <table id="competitiontable" className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>{ts2('Action',this.props.userLogin.language)}</th>
                                        <th>{ts2('Date',this.props.userLogin.language)}</th>
                                        <th>{ts2('Time',this.props.userLogin.language)}</th>
                                        <th>{ts2('Customer name',this.props.userLogin.language)}</th>
                                        <th>{ts2('Customer email',this.props.userLogin.language)}</th>
                                        <th>{ts2('Correct answers',this.props.userLogin.language)}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.CompetitionRedeems.map(item=>{
                                        
                                        return (
                                            
                                            <tr>
                                                <td>
                                                <Badge variant="success"  className='handCursor remarks' style={{backgroundColor:"#1A98AC"}} onClick={()=>{this.state.selectedRedemption = item; this.openModal()}}>
View Details
</Badge>
                                                </td>
                                                <td>{td(dateFormat(item.datein,"mmm dd, yyyy"), this.props.userLogin.language)}</td>
                                                <td>{td(dateFormat(item.datein,"hh:mm tt"), this.props.userLogin.language)}</td>
                                                <td>{item.firstName} {item.lastName}</td>
                                                <td>{item.emailAddress}</td>
                                                <td>{item.correctanswers}/{item.totalquestions}</td>
                                            </tr>
                                            
                                        )
                                    })
                                }</tbody>
                                    </table>}
                            
                            </div>
                            <div className='col-md-12' style={{height:"20px"}}></div>
                            <div className='col-md-12'>
							<Button className='mb-4 mt-5' variant='warning' onClick={() => { this.setState({activeScreen:0, fields:{}, selectedCompetition:null, CompetitionCategory:[], CompetitionRedeems:[]});history.go(-1) }} >{ts('Cancel / Return', this.props.userLogin.language)}</Button>
							</div>
                            </div>
     }
     <MessageBox
                    show={this.state.modalShow}
                    
                    body={
                        <>
                        <h3>Customer answers</h3><br/>
                        <h5>Customer details:</h5><br/>
                        <ul>
                                <li>Customer name: {this.state.selectedRedemption.firstName} {this.state.selectedRedemption.lastName}</li>
<li>Customer email: {this.state.selectedRedemption.emailAddress}</li>
<li>Correct answers: {this.state.selectedRedemption.correctanswers}/{this.state.selectedRedemption.totalquestions}</li>
<li>Participation date: {td(dateFormat(this.state.selectedRedemption.datein,"mmm dd, yyyy"), this.props.userLogin.language)}</li>
                                <li>Participation time: {td(dateFormat(this.state.selectedRedemption.datein,"hh:mm tt"), this.props.userLogin.language)}</li>
                            
                                </ul>
{
    
    this.state.questions.map((q, index)=>{
        return(
        <>
        <div className='col-md-12'>
							<p class="  ssstitle bold  boxTopRadius5 cunlayer  p-2  ">{ts("Competition question " + (index + 1).toString(),this.props.userLogin.language )} </p>
							</div>
							<div className='col-md-12' style={{height:"20px"}}></div>
                            <div className="row" style={{marginLeft:"10px"}}>
                                <div className="col-md-12">
                                    <b>Question</b>
                                </div>
                                <div className="col-md-12">
                                    {q.description}
                                </div>
                                <div className='col-md-12' style={{height:"10px"}}></div>
                                <div className="col-md-12">
                                    <b>Customer answer</b>
                                </div>
                                {
                                    q.answers && q.answers.map(a=>{
                                        return(
                                            <>
                                            
                                <div className="col-md-12">
                                    {a.freeanswer!=null && a.freeanswer!=''?a.freeanswer:a.answer}<br/>
                                    {((a.freeanswer==null || a.freeanswer=='') && a.iscorrect) ? <span style={{color:"#33A02C"}}>correct answer<br/></span>:<span></span>}
                                    {((a.freeanswer==null || a.freeanswer=='') && !a.iscorrect) ? <span style={{color:"#DA3849"}}>wrong answer<br/></span>:<span></span>}
                                    {((a.freeanswer==null || a.freeanswer=='') && !a.iscorrect && (q.answertype=='single choice' || q.answertype=='multiple choices')) ? 
                                    <span style={{color:"#0046FA"}}>Correct answer: {q.correctanswer}</span>:<span></span>
                                    }
                                </div>
                                            </>
                                        )
                                    })
                                }
                                {
                                    q.answers && q.answers.length == 0 && <div className="col-md-12"><span style={{color:"#DA3849"}}> not answered</span></div>
                                    
                                }
                                {
                                    q.answers && q.answers.length == 0 && (q.answertype=='single choice' || q.answertype=='multiple choices') ? 
                                    <div className="col-md-12"><span style={{color:"#0046FA"}}> Correct answer: {q.correctanswer}</span></div>:<div className="col-md-12"></div>
                                }
                            </div>
                            </>)
    })
}
                                </>
                    }
                    left={{ action: () => { this.setState({ modalShow: false, questions:[], questionscount:0,selectedRedemption:{} }) }, text: ts2('Cancel / Return',this.props.userLogin.language), variant: 'warning' }}
                    onHide={() => { this.setState({ modalShow: false, questions:[], questionscount:0,selectedRedemption:{} }) }}
                />
                        </section>
                        
                    )
                }
               
            </>
        )
    }
}
const mapStateToProps = state => {

    return {
        userLogin: state.SaveLogin.userData
    };
};
const colourStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma("#9B00FA");
      const disabledcolor = chroma("gray")
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#9B00FA"
          : isFocused
          ? disabledcolor
          : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : "#9B00FA",
        cursor: isDisabled ? 'not-allowed' : 'default',
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#9B00FA"
              : "#9B00FA30"
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma("#9B00FA");
      return {
        ...styles,
        backgroundColor: "#9B00FA",
        color:"#ffffff"
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#ffffff",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "#ffffff",
      ':hover': {
        backgroundColor: "#9B00FA",
        color: 'white',
      },
    }),
  };
export default connect(
    mapStateToProps
)(Competitions)