import React, { Component } from 'react';
import { Button, Container, Form,Row, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import '../styles/glob.css'
import * as G from '../components/global';
import { connect } from 'react-redux';
import { translateScreen as ts , translateScreen2 as ts2 , translateDate as td} from '../components/dictionary'


class SignInForm extends Component {


	constructor(props) {
		super(props);
		this.state = {
			fields: {},
			errors: {},
			result: [],
			load: false,
			language:0,
			showPasswordReset: false,
			successemail: false,
			loginFailed: false,
			failureMessage: 'Your username and password are not available'
		}
		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount() {
		const token = sessionStorage.getItem('UserData') // your saved token in localstorage

		console.log('params', this.props.params)
		if (token && token !== 'undefined') {            // check for not undefined
			this.props.history.push('/')               // now you can redirect your desired route
		}
		if(sessionStorage.getItem("language")=='1')this.setState({language:1})
	}

	//#region Form Validation
	handleChange(e) {
		let { fields, errors } = this.state;
		fields[e.target.name] = e.target.value;
		delete errors[e.target.name];
		this.setState({ loginFailed: false })
		this.setState({ fields });
	}

	signInSubmit = async (e) => {
		e.preventDefault();
		this.setState({ load: true })
		this.setState({ submitted: true });
		let errors = {};
		let formIsValid = true;
		const { email, password } = this.state.fields;
		if (!email) { formIsValid = false; errors["email"] =  ts2("Email cannot be empty",this.state.language); } else {
			if (!G.isValideEmail(email)) { formIsValid = false; errors["email"] = ts2('This is not a valid Email',this.state.language); }
		}


		if (!this.state.showPasswordReset) if (!password) { formIsValid = false; errors["password"] = ts2("Password cannot be empty",this.state.language); }

		this.setState({ errors });
		console.log(errors)
		if (!formIsValid) {
			this.setState({ load: false })
			return
		}



		if (!this.state.showPasswordReset) {
			G.log('Form is valid. fetching username and password...')
			var result = await G.getData('SignIn', [['username', email], ['password', password]])
			if (result.status === 200) {
				this.setState({ result: await result.data })
				if (this.state.result.length === 0) {
					this.setState({ loginFailed: true, failureMessage:  ts2('Your username and password are not available',this.state.language) })

				} else {
					if (this.state.result[0].active == 0) {
						this.setState({ loginFailed: true, failureMessage: ts2( 'Your username is now deactivated. Please contact the Givenmine',this.state.language) })
					}
					else {
						if (this.state.result[0].active == 0) {
							this.setState({ loginFailed: true, failureMessage:  ts2('Your username is now deactivated. Please contact the Givenmine',this.state.language) })
						} else {
							this.props.saveUserData(this.state.result[0])
							const tokenString = { token: this.state.result[0].Token };
							sessionStorage.setItem(G.sessionStorageVariables.userData, JSON.stringify(tokenString))
							//alert("Welcome " + this.state.result[0].name + ' (Group: ' + G.userType[this.state.result[0].userType] + ')');
							this.setState({ fields: {} })
							//this.props.history.push( {pathname : '/ResetPassword', state: { rs : true }}  );
							this.props.history.push(this.state.result[0].rs ? { pathname: '/ResetPassword', state: { rs: true } } : G.websiteRoute.home);
						}
					}


				}
			}
		} else {
			G.log('Sending reset password email')
			var result = await G.getData('resetPasswordEmail', [['email', email], ['log', ''],['frontend', G.frontend]])
			console.log(result)
			if (result.status === 200) {
				this.setState({ successemail: true })

			}

		}

		this.setState({ load: false })
	}
	//#endregion

	render() {
		const { errors, fields } = this.state;
		return (
			<div className='fullWidth' style={{position:"absolute", left:0, top:0, margin:0, padding:0, width:"93vw"}}>
				<div className='row'>
					<div className='col-md-6' style={{zIndex:"999999"}}>
					<div className="formcontainer">
							<img style={{ objectFit: 'cover', width: '160px' }} src="/dist/img/logo.png" />
							
							<div style={{width:'100%',  textAlign:'left', marginBottom: 30, marginTop:"30px"}}><p style={{color:"#24A2B6", fontSize:"30px", fontWeight:"700"}}>{ts2(this.state.showPasswordReset ? 'Reset Password' : 'Login',this.state.language)}</p></div>
						</div>
						<div className='flexrow verticalcenter handCursor ' style={{marginTop:"50px", marginBottom:"50px", position:"absolute", right:"0px", top:"25px", width:"auto"}}>
							<img width={20} height={20} src={this.state.language  === 0 ? "/dist/img/france.png" : "/dist/img/english.png"} style={{marginTop:"-5px"}} />
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="button-tooltip" > {this.state.language === 0 ? 'Cliquez pour passer en français' : 'Click to switch to English'}</Tooltip>}
                            >
                                <div className=' ml-2 remarks hint' onClick={() => {
                                    this.setState({ language: this.state.language === 0 ? 1 : 0 },()=>{sessionStorage.setItem('language',this.state.language)});
                                    
                                }}>{this.state.language !== 0 ? 'English' : 'Francais'} </div>
                            </OverlayTrigger>
							</div>
					<Form name="form" className="formcontainer" style={{marginTop:"0px"}} onSubmit={this.signInSubmit.bind(this)} >
							<Form.Row style={{marginLeft:"0px", marginRight:"0px", marginTop:"10px"}}>
								{G.renderTextField("Email", 'email', ts2("Enter your Email address",this.state.language), this.handleChange, fields, errors,'','',false,this.state.language)}
							</Form.Row>
							{!this.state.showPasswordReset && <Form.Row style={{marginLeft:"0px", marginRight:"0px", marginTop:"10px"}}>
								{G.renderTextField("Password", 'password', ts2("Enter password",this.state.language), this.handleChange, fields, errors,'','',false,this.state.language)}
							</Form.Row>}
							{this.state.showPasswordReset && (!this.state.successemail) &&
								<div className='midremarks hint'>{ts2('Enter your email and tap on Change password. A reset password email will be sent to your inbox',this.state.language)} </div>

							}
							{!this.state.showPasswordReset && <div className='fullWidth handCursor' style={{color:"#1A73F9", marginTop:"10px"}} onClick={() => { this.setState({ showPasswordReset: true }) }}>{ts2('Forget Password',this.state.language)}?</div>}
							{this.state.showPasswordReset && this.state.successemail &&
								<div className='flexcolumn titles text-center successColor'>
									<i className="far fa-check-circle"></i>
									<div className='midremarks hint'>{ts2('A password reset email has been sent to your email. Please check your inbox',this.state.language)}</div>
								</div>
							}

							{this.state.loginFailed && !this.state.showPasswordReset &&
								<div className='flexrow remarks warningYellow fullWidth mt-3 verticalcenter center'>
									<i className="fas fa-exclamation-circle"></i>
									<span className='midremarks warningColor ml-2'>{this.state.failureMessage}</span>
								</div>
							}
							<div className='flexrow center pt-3 '>
								{!this.state.successemail && <Button variant='success' className='mt-3 pl-5 pr-5 text-center' style={{width:"100%", backgroundColor:"#24A2B6", border:"1px solid #24A2B6"}} type="submit">
									{this.state.load && <Spinner
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
										className='mr-2'
									/>}
									 {ts2(this.state.showPasswordReset ? 'Send Password Reset' : 'Log In',this.state.language)}</Button>}
								{this.state.showPasswordReset && <Button variant='warning' className=' ml-3 mt-3 pl-5 pr-5 text-center' onClick={() => { this.setState({ showPasswordReset: false, successemail: false }) }} > {ts(this.state.successemail ? 'Return' : 'Cancel',this.state.language)}</Button>}
								
							</div>
							<div className='center pt-3'>
							{!this.state.showPasswordReset && <div onClick={() => { this.props.history.push(G.websiteRoute.registrationRequest) }}
							className='fullWidth text-center  hint'>{ts2("Don't have an Account?",this.state.language)}<a className='handCursor' style={{color:"#1A73F9"}}> {ts2('Register now',this.state.language)}</a></div>
						}
						
							</div>
						</Form>
						
					</div>
					<div className='col-md-6 d-none d-md-block' style={{position:"fixed",right:"0px",top:"0px",padding:"0px"}}><img src="/dist/img/loginback.png" style={{float:"right"}}/></div>
				</div>
			</div>
			
		)
	}
}
//#region Redux-Component Link
const mapDispachToProps = dispatch => {
	return {

		saveUserData: (data) => { G.log('dispatch', data); dispatch({ type: '', payLoad: data }) },

	};
};

const mapStateToProps = state => {
	return {
		userLogin: state.SaveLogin.userData
	};
};

//#endregion


export default connect(
	mapStateToProps,
	mapDispachToProps
)(SignInForm);
