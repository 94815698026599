const initialState = {
	userData: {}
}

const reducer = (state = initialState, action) => {
	const newState = { ...state };
	
	newState.userData = action.payLoad;
	 
	return newState;
}


export default reducer;