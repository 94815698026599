import React, { Component } from 'react';
import '../styles/glob.css'
import { Button } from 'react-bootstrap'
import history from '../components/history';
import { translateScreen as ts , translateScreen2 as ts2 , translateDate as td} from '../components/dictionary'
import { connect } from 'react-redux';

class notFound extends Component {

	constructor(props) {
		super(props);

		this.state = {

		}

	}
	render() {
		let language=0;
		if(this.props.userLogin!=null)language=this.props.userLogin.language
		//if (this.props.userLogin.language)language=this.props.userLogin.language;
		return (
			<div className=' miniContentHeight flexcolumn center verticalCenter fullWidth animateNotFoundAppear'>
				<div className='text-center '>
					<span className='text-center notFount404 ctheme whiteColor p-3 boxTop15Bottom25 ' style={{ fontSize: '5em' }}> 404</span>
				</div>
				<h3 className='text-center notFount404 primColor ' style={{ fontSize: '2em' }}>{ts2('Page Not Found',language)}</h3>

				<div className='text-center mt-5'>
					<p className='hint '>{ts2('We are sorry, the page you requested could not be found or',language)} <span className='bold'>{ts2('you do not have access',language)}</span></p>
					<p className='hint'>{ts2('Please go back to the home page',language)}</p>
					<Button variant='success' className='mt-3' onClick={() => { history.push("/") }}>{ts2('Home Page',language)} </Button>
				</div>
					
			 
			</div>
		)
	}
}


const mapStateToProps = state => {
    return {
        userLogin: state.SaveLogin.userData
    };
};

export default connect(
    mapStateToProps
)(notFound);