import React, { Component, ReactDOM, createRef } from 'react'
import { Image, Button, Badge, Spinner } from 'react-bootstrap';
import * as G from '../components/global'
import { renderPageContainer, renderPageHeader, renderDivider, getData } from '../components/global'
import history from '../components/history';
import { connect } from 'react-redux';
import MessageBox from '../components/MessageBox';
import emailtempl from '../components/assets/emailtempl.png'
import { translateScreen as ts , translateDate as td, translateScreen2 as ts2} from '../components/dictionary'
import ReactPaginate from 'react-paginate';
import 'rsuite/dist/rsuite.min.css';
import {DateRangePicker, DatePicker} from 'rsuite';
import qs from 'qs'
import Select from 'react-select'
import chroma from 'chroma'
import dateFormat from 'dateformat';
class PromoCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeScreen: 0,
            activePromoCodes: [],
			expiredPromoCodes:[],
			disabledPromoCodes:[],
			promocodeCategoryOptions:[],
			promocodeCategory:[],
			stats:{},
			promocodeId:-1,
            selectedPromoCode:null,
            loadingPromocodes: true,
			languagesList:[],
			errors:{},
			fields:{},
            searchfields:{},
            promocodeRedeems:[],
            customersloading:false,
            modalShow: false,
            modalBody: '',
            modalSubject: '',
            selectedRedemption:{},
            fieldchanging:false,
            successmodalShow:false
        }
		this.handleChange = this.handleChange.bind(this);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
		this.handleDateChange = this.handleDateChange.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleStatusSearch = this.handleStatusSearch.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }
    
    
    async componentDidMount() {
		this.selectInputRef = createRef();
		
        if (this.props.userLogin.site){
            this.getcategories();
            this.refreshData(this.props.userLogin.site.id)
        }
        history.listen((location, action) => {
            if(location.pathname == "/PromoCode" || location.pathname == "/PromoCode/")
            {
		        this.setState({activeScreen:0, fields:{}});
	        }
          });
        // if (this.state.allCompanies.length === 0) {
        //refreshDataClients()
        // }
    }
    getSnapshotBeforeUpdate(prevProps) {
        var searchfield = document.getElementById("searchfield");
            if(searchfield!=undefined && searchfield.value !="")
            {
                var nodes = document.getElementsByClassName('templatecard');
           Array.prototype.map.call(nodes, (elem)=>{
            if (elem.innerText.toLowerCase().includes(searchfield.value)) {
                elem.style.display = "block";
            } else {
                elem.style.display = "none";
            }
        });
            }
        return { notifyRequired: prevProps.userLogin !== this.props.userLogin };
      }
      
      async componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot.notifyRequired) {
            this.getcategories();
            await this.refreshData(this.props.userLogin.site.id);
        }
        
      }
    refreshData = async (company) => {
		this.setState({ loadingPromocodes: true })
        let { searchfields } = this.state;
        let upcomingfilter = '', activefilter = '', expiredfilter = '', redeemedfilter = '', disabledfilter = '';
        if(searchfields['searchrange']!= undefined && searchfields['searchrange']!=null)
        {
            var range = searchfields['searchrange'];
            var startdate = new Date(range[0])
            var enddate = new Date(range[1])
            var starts = td(dateFormat(startdate,"yyyy-mm-dd"));
            var ends = td(dateFormat(enddate,"yyyy-mm-dd"))
            var datefilter = `and (a.startdate is null or (DATEDIFF(a.startdate,'`+starts + `') >= 0 and DATEDIFF(a.startdate,'`+ends + `') <= 0) or a.enddate is null or (DATEDIFF(a.enddate,'`+starts + `') >= 0 and DATEDIFF(a.enddate,'`+ends + `') <= 0) )`
            activefilter += datefilter;//` and (a.startdate is null or (DATEDIFF(a.startdate,'`+starts + `') >= 0 and DATEDIFF(a.startdate,'`+ends + `') <= 0) or a.enddate is null or (DATEDIFF(a.enddate,'`+starts + `') >= 0 and DATEDIFF(a.enddate,'`+ends + `') <= 0) )`
            expiredfilter +=` and ((DATEDIFF(a.startdate,'`+starts + `') >= 0 and DATEDIFF(a.startdate,'`+ends + `') <= 0) or (DATEDIFF(a.enddate,'`+starts + `') >= 0 and DATEDIFF(a.enddate,'`+ends + `') <= 0))`
            upcomingfilter +=` and ((DATEDIFF(a.startdate,'`+starts + `') >= 0 and DATEDIFF(a.startdate,'`+ends + `') <= 0) or (DATEDIFF(a.enddate,'`+starts + `') >= 0 and DATEDIFF(a.enddate,'`+ends + `') <= 0))`
            redeemedfilter += ` and DATEDIFF(datein,'`+starts + `') >= 0 and DATEDIFF(datein,'`+ends + `') <= 0`
            disabledfilter += datefilter;//` and ((DATEDIFF(a.startdate,'`+starts + `') >= 0 and DATEDIFF(a.startdate,'`+ends + `') <= 0) or (DATEDIFF(a.enddate,'`+starts + `') >= 0 and DATEDIFF(a.enddate,'`+ends + `') <= 0))`
        }
        if(searchfields['category'])
        {
            var categories = searchfields['category'];
            var s = "";
            categories.map(elem=>{
                s += "," + elem.value
            })
            if(s!="")
            {
                s = s.substring(1);
                activefilter += " and a.id in (select promocodeId from promocodeCategoryrelation where categoryid in ("+s+"))"
                expiredfilter += " and a.id in (select promocodeId from promocodeCategoryrelation where categoryid in ("+s+"))"
                upcomingfilter += " and a.id in (select promocodeId from promocodeCategoryrelation where categoryid in ("+s+"))"
                redeemedfilter += " and promocodeId in (select promocodeId from promocodeCategoryrelation where categoryid in ("+s+"))"
                disabledfilter += " and a.id in (select promocodeId from promocodeCategoryrelation where categoryid in ("+s+"))"
            }
        }
        if(searchfields["searchname"])
        {
            var name = searchfields["searchname"];
            if(name!="")
            {
            activefilter += " and a.name like '%"+name+"%'"
            expiredfilter += " and a.name like '%"+name+"%'"            
            upcomingfilter += " and a.name like '%"+name+"%'"
            disabledfilter += " and a.name like '%"+name+"%'"
            }
        }
        var active = [], expired = [], disabled = [], stats = [];
        if(searchfields["promocodestatus"])
        {
            var status = searchfields["promocodestatus"];
            switch(status)
            {
                case "All":
                    active = await getData('promocodes/get', [['filter', 'a.siteid = ' + company + " and a.enddate>=now() and a.enabled=1 " + activefilter]]);
                    active = await active.data;
                    expired = await getData('promocodes/get', [['filter', 'a.siteid = ' + company + " and a.enddate<now() " + expiredfilter]]);
                    expired = await expired.data;
                    disabled = await getData('promocodes/get', [['filter', 'a.siteid = ' + company + " and a.enabled<>1 "]]);
                    disabled = await disabled.data;
                    break;
                case "Upcoming":
                    active = await getData('promocodes/get', [['filter', 'a.siteid = ' + company + " and a.startdate>=now() and a.enabled=1 " + activefilter]]);
                    active = await active.data;
                    break;
                case "Active":
                    active = await getData('promocodes/get', [['filter', 'a.siteid = ' + company + " and a.enddate>=now() and a.enabled=1 " + activefilter]]);
                    active = await active.data;
                    break;
                case "Expired":
                    expired = await getData('promocodes/get', [['filter', 'a.siteid = ' + company + " and a.enddate<now() " + expiredfilter]]);
                    expired = await expired.data;
                    break;
                case "Disabled":
                    disabled = await getData('promocodes/get', [['filter', 'a.siteid = ' + company + " and a.enabled<>1 " + disabledfilter]]);
                    disabled = await disabled.data;
                    break;
                default:
                    
                    break;
            }
        }
        else{
            active = await getData('promocodes/get', [['filter', 'a.siteid = ' + company + " and (a.enddate is null or a.startdate is null or a.enddate>=now()) and a.enabled=1 " + activefilter]]);
                    active = await active.data;
                    expired = await getData('promocodes/get', [['filter', 'a.siteid = ' + company + " and a.enddate<now() " + expiredfilter]]);
                    expired = await expired.data;
                    disabled = await getData('promocodes/get', [['filter', 'a.siteid = ' + company + " and a.enabled<>1 " + disabledfilter]]);
                    disabled = await disabled.data;
        }
       
		stats = await getData('promocodes/stats', [['siteid', company], ['redeemedfilter', ' and used = 1 ' + redeemedfilter], ['upcomingfilter', ' and startdate>=now() and enabled=1 ' + upcomingfilter],
         ['activefilter', ' and enddate>=now() and enabled=1 ' + activefilter],['expiredfilter',' and enddate<now() ' + expiredfilter]]);
        stats = await stats.data;
        this.setState({ activePromoCodes: active, expiredPromoCodes: expired, disabledPromoCodes: disabled, stats: stats })
        this.setState({ loadingPromocodes: false })
        var isduplicate = qs.parse(history.location.search, { ignoreQueryPrefix: true }).isduplicate
        var detailid = qs.parse(history.location.search, { ignoreQueryPrefix: true }).detailid
        var editid = qs.parse(history.location.search, { ignoreQueryPrefix: true }).editid
        active.map(el=>{
            if(el.id == detailid)
            {
                this.OpenDetails(el.id, el, false);
            }
            else if(el.id == editid)
            {
                this.OpenEdit((isduplicate == '1'?'-1':el.id), el, false);
            }
        })
        expired.map(el=>{
            if(el.id == detailid)
            {
                this.OpenDetails(el.id, el, false);
            }
            else if(el.id == editid)
            {
                this.OpenEdit((isduplicate == '1'?'-1':el.id), el, false);
            }
        })
        disabled.map(el=>{
            if(el.id == detailid)
            {
                this.OpenDetails(el.id, el, false);
            }
            else if(el.id == editid)
            {
                this.OpenEdit((isduplicate == '1'?'-1':el.id), el, false);
            }
        })
        
    }
    handleStatusSearch = (e)=>
    {
        let { searchfields } = this.state;
        searchfields["promocodestatus"] = e.target.value;
		 this.setState({ searchfields });
         this.refreshData(this.props.userLogin.site.id);
    }
    handleClickStatusSearch = (val)=>
    {
        let { searchfields } = this.state;
        searchfields["promocodestatus"] = val;
		 this.setState({ searchfields });
         this.refreshData(this.props.userLogin.site.id);
    }
    handleCategorySearch = (name, e)=>{
        let { searchfields } = this.state;
        searchfields[name] = e;
		 this.setState({ searchfields });
         this.refreshData(this.props.userLogin.site.id);
    }
    handleSearchKeyUp = (e)=>{
        let { searchfields } = this.state;
        searchfields["searchname"] = e.target.value;
		 this.setState({ searchfields });
         this.refreshData(this.props.userLogin.site.id);
     }
     handleDateRangeChange = async(range)=>{
        let { searchfields } = this.state;
        searchfields["searchrange"] = range;
		 this.setState({ searchfields });
        this.refreshData(this.props.userLogin.site.id)
        
    }
	getlanguages = async()=>{
		let array = [];
		array = await G.getData("languages/query",[["filter"," 1=1 order by orderindex"]]);
		array = await array.data;
		this.setState({languagesList: array});
	}
	getcategories = async (company) => {
        if(this.selectInputRef.current!=null)
            this.selectInputRef.current.select.clearValue();
        let array = []
        array = await G.getData("promocodeCategory/query", [["filter","a.siteid=" + this.props.userLogin.site.id]])
        array = await array.data
        this.setState({ promocodeCategory: array })
        let arrayNames = [];
        array.map(element => {
          arrayNames.push(element.name)
        })
        this.setState({ promocodeCategoryNames: arrayNames })
        console.log(this.state.promocodeCategory)
        const categoryOptions = [];
        array.map(element => {
          categoryOptions.push({value:element.id, label:element.name});
        })
        this.setState({ promocodeCategoryOptions: categoryOptions })
      }
      OpenEdit = async (id, element, redirect = true)=>
    {
        this.getlanguages();
        var starts = element.startdate?td(dateFormat(element.startdate,"yyyy-mm-dd")):'';
        var ends = element.enddate?td(dateFormat(element.enddate,"yyyy-mm-dd")):'';
            var dbcats = [];
                var result = await G.getData("promocodeCategory/query",[["filter"," id in (select categoryid from promocodeCategoryrelation where promocodeId ="+element.id+")"]]);
                result = await result.data;
                result.map(elem=>{
                    dbcats.push({value:elem.id, label:elem.name})
                })
        var langresult = await G.getData("promocodetranslation/query",[["filter","promocodeId=" + element.id]])    ;
        langresult = await langresult.data;
        //category, startdate, enddate,code,customersallowedlimit, type, value, enabled
        this.setState({promocodeId:id, selectedPromoCode:element, fields:{category:dbcats, startdate: starts, enddate: ends,code: element.code,customersallowedlimit: element.customersallowedlimit, enabled: element.enabled, value:element.value, type:element.redemptiontype},errors:{}});
        var {fields} = this.state;
        langresult.map(rec=>{
            fields[rec.languageid + "_name"] = rec.name;
            fields[rec.languageid + "_description"] = rec.description;
        })
        this.setState({ fields, activeScreen:1 });
        if(redirect == true)
            history.push("/PromoCode/edit?editid=" + element.id+"&isduplicate=" + (id == '-1'?'1':'0'))
    }
	OpenDetails = async(id, element, redirect = true)=>{
        this.setState({ customersloading: true })
        var dbcats = [];
                var result = await G.getData("promocodeCategory/query",[["filter"," id in (select categoryid from promocodeCategoryrelation where promocodeId ="+element.id+")"]]);
                result = await result.data;
                result.map(elem=>{
                    dbcats.push(elem.name)
                })
                var redeems = await G.getData("customerpromocode/get",[["filter","used = 1 and cg.promocodeId=" + id]])
                redeems = await redeems.data;
                
        this.setState({activeScreen:2, selectedPromoCode:element, promocodeCategory:dbcats, promocodeRedeems:redeems})
        if(redirect == true)
            history.push("/PromoCode/details?detailid=" + id)
           if(this.state.promocodeRedeems)
           {
            this.loadDataTables();
           } 
        
    }  
    loadDataTables = ()=>{
       var _this = this;
		setTimeout(() => {
           
                    const script = document.createElement("script");
                script.id = "dttablestats";
                script.src = '/dist/js/promotable.js';
                script.async = true;
                document.body.appendChild(script);
                
                _this.setState({ customersloading: false })
        }, 100);
        
}
    
    
    renderActiveButton = (id, element, cats) => {
		
        return (
        <div className="col-md-3 templatecard" onClick={(event)=>{if(event.target.className!='fa fa-ellipsis-v' && event.target.className!='dropdown-item')this.OpenDetails(id, element)}}>
            <div className="info-box handCursor shadow-sm" style={{backgroundColor:"#3FCAE0", color:"white"}}>
              {/*<span className="info-box-icon bg-info" style={{padding:"30px"}} onClick={() => { this.openCampaignEditor(id, element) }}><i className="far fa-envelope"></i></span>*/}
              <div className="info-box-content">
                <div className='info-icon'><i className="fa fa-ellipsis-v" style={{padding:"5px 10px"}} data-toggle="dropdown" href="#" aria-expanded="false"></i>
                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <a onClick={()=>{this.OpenEdit(-1,element)}} className="dropdown-item">
                    {ts2("Duplicate",this.props.userLogin.language)}
                    </a>
                    <div className="dropdown-divider"></div>
                    <a onClick={()=>{this.OpenEdit(element.id,element)}} className="dropdown-item">
                    {ts('Edit', this.props.userLogin.language)}
                    </a>
					<div className="dropdown-divider"></div>
                    <a onClick={()=>{this.DisablePromoCode(id)}} className="dropdown-item">
                    {ts('Disable', this.props.userLogin.language)}
                    </a>
                </div>
                </div>
				<h3>{element.usage}<br/></h3>
				<small>usage</small>
                <span className="info-box-text bold" style={{width:"70%"}}>{element.name}</span>
                <span className="info-box-text" style={{width:"70%"}}>{element.category}</span>
                <span className="info-box-number" style={{fontStyle:"italic"}}> {element.startdate && element.startdate !== '' ? td(dateFormat(element.startdate, 'mmm dd, yyyy'),this.props.userLogin.language) : <>&nbsp;</>} {element.enddate && element.enddate !== '' ? "till " + td(dateFormat(element.enddate, 'mmm dd, yyyy'),this.props.userLogin.language) : <>&nbsp;</>}</span>
              </div>
            </div>
        </div>
        )
    }
	renderExpiredButton = (id, element, cats) => {
		
        return (
        <div className="col-md-3 templatecard" onClick={(event)=>{if(event.target.className!='fa fa-ellipsis-v' && event.target.className!='dropdown-item')this.OpenDetails(id, element)}}>
            <div className="info-box  handCursor shadow-sm" style={{backgroundColor:"#8CB6CC", color:"white"}}>
              {/*<span className="info-box-icon bg-info" style={{padding:"30px"}} onClick={() => { this.openCampaignEditor(id, element) }}><i className="far fa-envelope"></i></span>*/}
              <div className="info-box-content">
                <div className='info-icon'><i className="fa fa-ellipsis-v" style={{padding:"5px 10px"}} data-toggle="dropdown" href="#" aria-expanded="false"></i>
                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <a onClick={()=>{this.OpenEdit(-1,element)}} className="dropdown-item">
                    {ts2("Duplicate",this.props.userLogin.language)}
                    </a>
                    <div className="dropdown-divider"></div>
                    <a onClick={()=>{this.OpenEdit(element.id,element)}} className="dropdown-item">
                    {ts('Edit', this.props.userLogin.language)}
                    </a>
                </div>
                </div>
				<h3>{element.usage}<br/></h3>
				<small>usage</small>
                <span className="info-box-text bold" style={{width:"70%"}}>{element.name}</span>
                <span className="info-box-text" style={{width:"70%"}} >{element.category}</span>
                <span className="info-box-number" style={{fontStyle:"italic"}}> {element.startdate && element.startdate !== '' ? td(dateFormat(element.startdate, 'mmm dd, yyyy'),this.props.userLogin.language) : <>&nbsp;</>} {element.enddate && element.enddate !== '' ? "till " + td(dateFormat(element.enddate, 'mmm dd, yyyy'),this.props.userLogin.language) : <>&nbsp;</>}</span>
              </div>
            </div>
        </div>
        )
    }
    renderDisabledButton = (id, element, cats) => {
		
        return (
        <div className="col-md-3 templatecard" onClick={(event)=>{if(event.target.className!='fa fa-ellipsis-v' && event.target.className!='dropdown-item')this.OpenDetails(id, element)}}>
            <div className="info-box  handCursor shadow-sm" style={{backgroundColor:"#B9C0C9", color:"white"}}>
              {/*<span className="info-box-icon bg-info" style={{padding:"30px"}} onClick={() => { this.openCampaignEditor(id, element) }}><i className="far fa-envelope"></i></span>*/}
              <div className="info-box-content">
                <div className='info-icon'><i className="fa fa-ellipsis-v" style={{padding:"5px 10px"}} data-toggle="dropdown" href="#" aria-expanded="false"></i>
                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <a onClick={()=>{this.OpenEdit(-1,element)}} className="dropdown-item">
                    {ts2("Duplicate",this.props.userLogin.language)}
                    </a>
                    <div className="dropdown-divider"></div>
                    <a onClick={()=>{this.OpenEdit(element.id,element)}} className="dropdown-item">
                    {ts('Edit', this.props.userLogin.language)}
                    </a>
                </div>
                </div>
				<h3>{element.usage}<br/></h3>
				<small>usage</small>
                <span className="info-box-text bold" style={{width:"70%"}}>{element.name}</span>
                <span className="info-box-text" style={{width:"70%"}}>{element.category}</span>
                <span className="info-box-number" style={{fontStyle:"italic"}}> {element.startdate && element.startdate !== '' ? td(dateFormat(element.startdate, 'mmm dd, yyyy'),this.props.userLogin.language) : <>&nbsp;</>} {element.enddate && element.enddate !== '' ? "till " + td(dateFormat(element.enddate, 'mmm dd, yyyy'),this.props.userLogin.language) : <>&nbsp;</>}</span>
              </div>
            </div>
        </div>
        )
    }
    /*EnableGift = async(id)=>{
        await getData('gifts/enable',[['id',id]]);
        this.refreshArchivedData(this.props.userLogin.site.id);
        this.refreshData(this.props.userLogin.site.id);
    }*/
    DisablePromoCode = async(id)=>{
        await getData('PromoCode/disable',[['id',id]]);
        this.refreshData(this.props.userLogin.site.id);
    }
    
    onBlur(e){
        this.setState({fieldchanging:false});
    }
    handleLanguageChange(e) {
		let { fields, errors,languagesList } = this.state;
		 fields[e.target.name] = e.target.value;
		 delete errors[e.target.name];

         for(var i=0;i<languagesList.length;i++)
         {
             var elem = languagesList[i];
             
             if(e.target.name.includes('_name'))
             {
                if(!fields[elem.id + "_name"] || fields[elem.id + "_name"]=='' || this.state.fieldchanging)
                {
                fields[elem.id + "_name"] = e.target.value;
                delete errors[elem.id + "_name"];
                this.setState({fieldchanging:true});
                }
             }
             if(e.target.name.includes('_description'))
             {
                if(!fields[elem.id + "_description"] || fields[elem.id + "_description"]=='' || this.state.fieldchanging)
                {
                    fields[elem.id + "_description"] = e.target.value;
                    document.getElementById(elem.id + "_description").value = e.target.value;
                    delete errors[elem.id + "_description"];
                    this.setState({fieldchanging:true});
                }
             }
         }
		 this.setState({ fields });
	 }
	handleChange(e) {
		let { fields, errors } = this.state;
		 fields[e.target.name] = e.target.value;
		 delete errors[e.target.name];
 
		 this.setState({ fields });
	 }
	 handleDateChange(name, date){
		var d = new Date(date)
		var dates = td(dateFormat(d,"yyyy-mm-dd"));
		let { fields, errors } = this.state;
		fields[name] = dates;
		delete errors[name];
		this.setState({ fields });
	 }
	 handleSelectChange(name, e){
		let { fields, errors } = this.state;
		fields[name] = e;
		delete errors[name];
		console.log("fields",fields);
		console.log("field e",e);
		this.setState({ fields });
	 }
	 createPromoCode = async(promocodeId)=>{
        var {errors} = this.state;
		const { category, startdate, enddate,code,customersallowedlimit, type, value, enabled } = this.state.fields;
		var status = enabled;
		
        var promotype = type; 
        if(promotype == undefined)
		{
			promotype = "Token";
		}
		var translation = [];
        var valid = true;
        if(code==undefined || code == '')
        {
            valid = false;
            errors["code"] = "This field is required";
        }
        if(value==undefined || value == '')
        {
            valid = false;
            errors["value"] = "This field is required";
        }
        /*if(customersallowedlimit==undefined || customersallowedlimit == '')
        {
            valid = false;
            errors["customersallowedlimit"] = "This field is required";
        }
        if(startdate==undefined || startdate == '')
        {
            valid = false;
            errors["startdate"] = "This field is required";
        }
        if(enddate==undefined || enddate == '')
        {
            valid = false;
            errors["enddate"] = "This field is required";
        }*/
        if(enddate != undefined && enddate != null && enddate !='' && startdate != undefined && startdate != null && startdate !='')
        {
            var date1 = new Date(startdate);
            var date2 = new Date(enddate);
            if(date2<=date1)
            {
                errors["enddate"] = "End date should be newer then start date";
                errors["startdate"] = "Start date should be older then end date";
                valid = false;
            }
        }
        if(status==undefined || status == '')
        {
            valid = false;
            errors["enabled"] = "This field is required";
        }
        if(category == undefined || category.length == 0)
        {
            valid = false;
            errors["category"] = "This field is required";
        }
		var params = [["promocodeId",promocodeId],["siteid",this.props.userLogin.site.id],["code", code],
		["enabled", status], ["category", JSON.stringify(category)], ["startdate", startdate], ["enddate", enddate], ["customersallowedlimit", customersallowedlimit]
        , ["type", promotype], ["value", value]];
		this.state.languagesList.map((elem)=>{
			var name = this.state.fields[elem.id + "_name"];
			var description =  this.state.fields[elem.id + "_description"];
			translation.push({langid: elem.id, name:name, description: description});
			//["name", translation[0].name],["description", translation[0].description],
			if(name == undefined || name=='')
            {
                errors[elem.id+"_name"] = "This field is required";
                valid = false;
            }
            if(description == undefined || description == '')
            {
                errors[elem.id+"_description"] = "This field is required";
                valid = false;
            }
            if(elem.code == "en")
			{
				params.push(["name", name])
				params.push(["description", description])
			}
			params.push(["name_"+elem.code, name])
			params.push(["description_"+elem.code, description])
		});
        if(valid)
        {
		var result = await G.getData("promocodes/upload",params)
		let success = (result.status === 200)
            if (success) {
                if(promocodeId === -1)
                {
                    var logtxt = this.props.userLogin.username + " has created a promo code number " + code;
                    await G.SaveUserLog(logtxt, this.props.userLogin.id);
                }
                else{
                    var logtxt = this.props.userLogin.username + " has edited the promo code info of promo code number " + code;
                    await G.SaveUserLog(logtxt, this.props.userLogin.id);
                }
                this.setState({ activeScreen: 0, fields: {}, successmodalShow: promocodeId !== -1})
                this.refreshData(this.props.userLogin.site.id)
                history.push("/PromoCode")
            }
            else if(result.status === 600){
                alert("Promo code is already created!");
            }
        }
        else{
            this.setState({errors});
        }
	 }
     openModal = async()=>{
         var item = this.state.selectedRedemption;
         var result = await G.getData("customertokens/query",[["filter","customerid=" + this.state.selectedRedemption.customerid]])
    result = await result.data;
    var score = 0;
    result.map(el=>score += el.score);
    item.tokens = score;
        this.setState({
            modalShow: true,
            selectedRedemption: item
        })
     }
	 render() {
		const { errors, fields } = this.state;
        /*if (this.state.allCompanies != null) {

            if (this.state.allCompanies.length === 0) { this.refreshDataClients(); }

        }*/
        return (
            <>
                {/*<section className='content-header'>
                    <div className='container-fluid'>
                        <div className='row mb-2'>
                            <div className='col-sm-6'>
                                <h1>{ts('Email templates',this.props.userLogin.language)}</h1>
                            </div>
                        </div>
                    </div>
        </section>*/}
                
                
                {this.state.activeScreen === 0 &&

                    renderPageContainer(
                        <>
                        <section className='content'>                
                            
                            
                            <div className='row'><div className='col-sm-6'><Button onClick={() => { this.getlanguages();this.setState({activeScreen:1});history.push('/PromoCode/create')  }} variant='success' className='mt-5 mb-4' >{ts('Create New Promocode',this.props.userLogin.language)}</Button></div></div>
                            <h3>{ts('PromoCodes',this.props.userLogin.language)}&nbsp;&nbsp;
                             <DateRangePicker onChange={(value)=>{this.handleDateRangeChange(value)}}  placeholder={ts("Filter by date range", this.props.userLogin.language)} />
                            </h3><div className="col-md-12" style={{height:"20px"}}></div>
                            <div className="row">
                                <div className="col-md-2">
                                <div className="info-box handCursor mainScreenCard" onClick={()=>{this.handleClickStatusSearch("")}} style={{padding:"15px 5px"}}>
                                <div className="info-box-content" style={{color:"black"}}>
                                <span>{ts("Total used", this.props.userLogin.language)}</span>
                                    <span style={{fontSize:"40px"}}>{this.state.stats.redeemed}</span>
                        
                                </div>
                                    
                                    </div>
                                    
                                </div>
                                <div className="col-md-2">
                                <div className="info-box handCursor mainScreenCard" onClick={()=>{this.handleClickStatusSearch("Upcoming")}}>
                                <div className="info-box-content" style={{color:"black"}}>
                                <span>{ts("Upcoming", this.props.userLogin.language)}</span>
                                    <span style={{fontSize:"40px", color:"#2A72CC"}}>{this.state.stats.upcoming}</span>
                        
                                </div>
                                    
                                    </div>
                                
                                </div>
                                <div className="col-md-2">
                                <div className="info-box handCursor mainScreenCard" onClick={()=>{this.handleClickStatusSearch("Active")}}>
                                <div className="info-box-content" style={{color:"black"}}>
                                <span>{ts("Active", this.props.userLogin.language)}</span>
                                    <span style={{fontSize:"40px", color:"#3FCAE0"}}>{this.state.stats.active}</span>
                        
                                </div>
                                    
                                    </div>
                                
                                </div>
                                <div className="col-md-2">
                                <div className="info-box handCursor mainScreenCard" onClick={()=>{this.handleClickStatusSearch("Expired")}}>
                                <div className="info-box-content" style={{color:"black"}}>
                                <span>{ts("Expired", this.props.userLogin.language)}</span>
                                    <span style={{fontSize:"40px", color:"#8CB6CC"}}>{this.state.stats.expired}</span>
                        
                                </div>
                                    
                                    </div>
                                
                                </div>
                                <div className="col-md-2">
                                <div className="info-box handCursor mainScreenCard" onClick={()=>{this.handleClickStatusSearch("Disabled")}}>
                                <div className="info-box-content" style={{color:"black"}}>
                                <span>{ts("Disabled", this.props.userLogin.language)}</span>
                                    <span style={{fontSize:"40px", color:"#8D949D"}}>{this.state.stats.disabled}</span>
                        
                                </div>
                                    
                                    </div>
                                
                                </div>
                            </div>
                            <div className="col-md-12" style={{height:"10px"}}></div>
							<div className="row">
								<div className='form-group col-md-3'>
								<label for="searchfield">Select promocode status</label>
								<select className='custom-select' onChange={this.handleStatusSearch}>
                                {this.state.searchfields["promocodestatus"]=="All"?<option selected>All</option>:<option>All</option>}
									{this.state.searchfields["promocodestatus"]=="Upcoming"?<option selected>Upcoming</option>:<option>Upcoming</option>}
                                    {this.state.searchfields["promocodestatus"]=="Active"?<option selected>Active</option>:<option>Active</option>}
                                    {this.state.searchfields["promocodestatus"]=="Expired"?<option selected>Expired</option>:<option>Expired</option>}
                                    {this.state.searchfields["promocodestatus"]=="Disabled"?<option selected>Disabled</option>:<option>Disabled</option>}
									
								</select>
								</div>
								<div className='form-group col-md-3'>
								<label>Filter by promocode category</label>
								<Select ref={this.selectInputRef} menuPortalTarget={document.body} isClearable={true}
            onChange={(e)=>this.handleCategorySearch("category", e)}
    closeMenuOnSelect={false}
    isMulti
    styles={colourStyles}
    options={this.state.promocodeCategoryOptions}
  />
								</div>
                            <div className="form-group col-md-3">
                            <label for="searchfield">Search</label>
                            <div class="input-group">
                                
                  <input id="searchfield" type="text" class="form-control" onKeyUp={(e)=>this.handleSearchKeyUp(e)}/>
                  <div class="input-group-append">
                    <span class="input-group-text"><i class="fas fa-search"></i></span>
                  </div>
                </div>
                            </div>
                            </div>
                            <div className='row'>
    
                            {/*{renderPageHeader(<span>{ts('Saved Campaigns List',this.props.userLogin.language)} <Badge variant='warning ssstitle'>{this.state.activeCompany ? this.state.activeCompany.companyName : ''}</Badge></span>)}*/}
                            {this.state.loadingPromocodes &&
                                <div className='flexcolumn center verticalcenter p-5 fullWidth hint mt-5 mb-4'>
                                    {/*changed by youssef to meet the selected site requirment*/}
                                    {/*{ts('Loading templates for',this.props.userLogin.language)} {this.state.activeCompany ? this.state.activeCompany.companyName : ''}*/}
                                    {ts('Loading promocodes for',this.props.userLogin.language)} {this.props.userLogin.site ? this.props.userLogin.site.companyName : ''}
                                    <Spinner animation="border" className='mt-3' variant="warning" />

                                </div>
                            }
                            </div>
                            {!this.state.loadingPromocodes &&<div className='row'> <div className='flexrow start wrap mt-2   pt-3 '>
								{this.state.activePromoCodes.filter(e=>e.id!=null).length>0 && 
							<div className="col-md-12" style={{margin:"30px 0px"}}><center><h6>active</h6></center></div>}
                                {
								
								this.state.activePromoCodes.map((element) => {
									/*var cats = await G.getData("gifts/categories",[["promocodeId",element.id]]);
									cats = await cats.data;*/
									console.log(element)
									if(element.id)
                                    	return (this.renderActiveButton(element.id, element))

                                })}
{this.state.expiredPromoCodes.filter(e=>e.id!=null).length>0 && 
							<div className="col-md-12" style={{margin:"30px 0px"}}><center><h6>expired</h6></center></div>}
                                {
								
								this.state.expiredPromoCodes.map((element) => {
									/*var cats = await G.getData("gifts/categories",[["promocodeId",element.id]]);
									cats = await cats.data;*/
                                    if(element.id)
										return (this.renderExpiredButton(element.id, element))

                                })}
								{this.state.disabledPromoCodes.filter(e=>e.id!=null).length>0 && 
							<div className="col-md-12" style={{margin:"30px 0px"}}><center><h6>disabled</h6></center></div>}
                                {
								
								this.state.disabledPromoCodes.map((element) => {
									/*var cats = await G.getData("gifts/categories",[["promocodeId",element.id]]);
									cats = await cats.data;*/
									
									if(element.id)
                                    	return (this.renderDisabledButton(element.id, element))

                                })}
                                {this.state.activePromoCodes.length === 0 && this.state.disabledPromoCodes.length === 0 && this.state.expiredPromoCodes.length === 0 &&
                                    <div className='col-md-12'>
                                    <div className='flexcolumn center verticalcenter p-5 fullWidth cSignInBack boxRadius5'>
                                       {ts(' No PromoCodes available for',this.props.userLogin.language)} {this.props.userLogin.site ? this.props.userLogin.site.companyName : ''}
                                        <Button onClick={() => { this.getlanguages();this.setState({activeScreen:1});history.push('/PromoCode/create') }} variant='success' className='m-4' >Create First Promocode</Button>
                                    </div>
                                    </div>
                                }

                            </div>
                            </div>}
                            
                            
                            
                            
                            </section>
                            <MessageBox
                    show={this.state.successmodalShow}
                    title={ts("Changes are saved",this.props.userLogin.language)}
                    body={<div><center><Button variant="success" onClick={()=>{this.setState({ successmodalShow: false });}}>OK</Button></center></div>}
                    onHide={() => { this.setState({ modalShow: false }) }}

                />       
                            
                            
                        
                        </>
                    )

                }
				{this.state.activeScreen === 1 && 
					renderPageContainer(
						<section className='content'>
							<div className='row'>
							<div className='col-md-12'>
							<Button className='mb-4 mt-5' variant='warning' onClick={() => { this.setState({activeScreen:0, fields:{}});history.go(-1) }} >{ts('Cancel / Return', this.props.userLogin.language)}</Button>
							</div>
							<div className='col-md-12'>
							<h3>{ts('Promocode details',this.props.userLogin.language)}
                            </h3>
							</div>
							<div className='col-md-12' style={{height:"20px"}}></div>
							<div className='col-md-12'>
							<p class="  ssstitle bold  boxTopRadius5 cunlayer  p-2  ">{ts("Promocode info",this.props.userLogin.language )} </p>
							</div>
							<div className='col-md-12' style={{height:"20px"}}></div>
							<div class="col-12 col-sm-6">
<div class="card card-primary card-outline card-tabs">
<div class="card-header p-0 pt-1 border-bottom-0">
<ul class="nav nav-tabs" id="custom-tabs-two-tab" role="tablist">
<li class="pt-2 px-3"><h6 class="card-title" style={{margin:"0px"}}>Entry by language</h6></li>
{
	this.state.languagesList.map((elem)=>{
		return <li class="nav-item">
<a class={elem.code == "fr"?"nav-link active":"nav-link"} id={"custom-tabs-two-" + elem.code + "-tab"} data-toggle="pill" href={"#custom-tabs-two-" + elem.code} role="tab" aria-controls={"custom-tabs-two-" + elem.code} aria-selected="true">{elem.code}</a>
</li>
	})
}
</ul>
</div>
<div class="card-body">
<div class="tab-content" id="custom-tabs-two-tabContent">
	{
		this.state.languagesList.map((elem)=>{
			return <div class={elem.code == "fr"?"tab-pane fade show active":"tab-pane fade"} id={"custom-tabs-two-" + elem.code} role="tabpanel" aria-labelledby={"custom-tabs-two-"+elem.code+"-tab"}>
				{G.renderTextField("Title", 'text', "enter promocode title here", (elem.code == "fr"?this.handleLanguageChange:this.handleChange), fields, errors, null, '',false, this.props.userLogin.language, true,elem.id + "_name",this.onBlur)}
				{G.renderTextArea("Description", 'textarea', "enter promocode description here", (elem.code == "fr"?this.handleLanguageChange:this.handleChange), fields, errors, null, '',false, this.props.userLogin.language, true,elem.id + "_description",this.onBlur)}
			</div>
		})
	}

</div>
</div>

</div>
</div>
<div className='col-md-12' style={{height:"20px"}}></div>
							<div className='col-md-12'>
							<p class="  ssstitle bold  boxTopRadius5 cunlayer  p-2  ">{ts("Promo code rules",this.props.userLogin.language )} </p>
							</div>
							<div className='col-md-12' style={{height:"20px"}}></div>
							<div className='col-md-6'>
							<label>Category</label>
								<Select ref={this.selectInputRef} menuPortalTarget={document.body} isClearable={true}
       value={this.state.fields["category"]}    
    closeMenuOnSelect={false}
    isMulti
    styles={colourStyles}
    options={this.state.promocodeCategoryOptions}
	id="category" name="category" onChange={(e)=>this.handleSelectChange("category", e)}
  />
  {this.state.errors['category'] && <div className='remarks notValidColor'>{this.state.errors['category']}</div>}
							</div>
							<div className="col-md-12" style={{height:"20px"}}></div>
							<div class="form-group col-md-3">
                  <label>Start date</label>
                  {
                      this.state.fields["startdate"] &&
                      
                        <DatePicker name="startdate" oneTap={true} defaultValue={this.state.fields["startdate"]?new Date(this.state.fields["startdate"]):''}  onChange={(value)=>this.handleDateChange("startdate",value)} style={{width:"100%",border:this.state.errors['startdate']?"1px solid " + G.errorColor:""}}/>
                        
                  }
                  {  
                      !this.state.fields["startdate"] &&
                        <DatePicker name="startdate" oneTap={true} onChange={(value)=>this.handleDateChange("startdate",value)} style={{width:"100%",border:this.state.errors['startdate']?"1px solid " + G.errorColor:""}}/>

                  }
				  {this.state.errors['startdate'] && <div className='remarks notValidColor'>{this.state.errors['startdate']}</div>}
                </div>
				<div class="form-group col-md-3">
                  <label>End date</label>
				  {
                      this.state.fields["enddate"] &&
                      
                        <DatePicker name="enddate" oneTap={true} defaultValue={new Date(this.state.fields["enddate"])}  onChange={(value)=>this.handleDateChange("enddate",value)} style={{width:"100%",border:this.state.errors['enddate']?"1px solid " + G.errorColor:""}}/>
                  }
                  {  
                      !this.state.fields["enddate"] &&
                        <DatePicker name="enddate" oneTap={true} onChange={(value)=>this.handleDateChange("enddate",value)} style={{width:"100%",border:this.state.errors['enddate']?"1px solid " + G.errorColor:""}}/>

                  }
                  {this.state.errors['enddate'] && <div className='remarks notValidColor'>{this.state.errors['enddate']}</div>}
                </div>				
				<div className="col-md-12" style={{height:"20px"}}></div>
				<div class="form-group col-md-3">
				  {G.renderTextField("Code","text", "enter promo code", this.handleChange, fields, errors, null,'',false,this.props.userLogin.language, true, "code")}
                </div>
				<div class="form-group col-md-3">
				  {G.renderTextField("Limit","number", "enter redemption limit", this.handleChange, fields, errors, null,'',false,this.props.userLogin.language, true,"customersallowedlimit")}
                </div>
                <div className="col-md-12" style={{height:"20px"}}></div>
				<div class="form-group col-md-3">
                <label for="type">Type</label>
				  <select name="type" id="type" placeholder='select promo redemption type' onChange={this.handleChange} className="custom-select">
                  {this.state.fields["type"]=="Token" ?<option selected>Token</option>:<option >Token</option>}
                      {this.state.fields["type"]=="Points" ?<option selected>Points</option>:<option>Points</option>}
				  </select>
                </div>
				<div class="form-group col-md-3">
				  {G.renderTextField("Value","number", "enter promo value (points or tokens)", this.handleChange, fields, errors, null,'',false,this.props.userLogin.language, true,"value")}
                </div>
				<div className="col-md-12" style={{height:"20px"}}></div>
				<div class="form-group col-md-3">
					<label for="enabled">Status</label>
                    <select name="enabled" id="enabled" style={this.state.errors["enabled"] ? { borderColor: G.errorColor } : {}} onChange={this.handleChange} className="custom-select">
					  <option value="">Please select</option>
                      {this.state.fields["enabled"]=="1" &&<option value="1" selected>Enabled</option>}
                      {this.state.fields["enabled"]!="1" &&<option value="1">Enabled</option>}
                      {this.state.fields["enabled"]=="0" &&<option value="0" selected>Disabled</option>}
                      {this.state.fields["enabled"]!="0" &&<option value="0">Disabled</option>}
				  </select>
                  {this.state.errors['enabled'] && <div className='remarks notValidColor'>{this.state.errors['enabled']}</div>}
                </div>
				<div className="col-md-12" style={{height:"20px"}}></div>
				<div className="col-md-3">
				<Button onClick={() => { this.createPromoCode(this.state.promocodeId)  }} variant='success'  >{ts('Save',this.props.userLogin.language)}</Button>&nbsp;&nbsp;&nbsp;
				<Button variant='warning' onClick={() => { this.setState({activeScreen:0, fields:{}});history.go(-1) }} >{ts('Return', this.props.userLogin.language)}</Button>
				</div>
				<div className="col-md-12" style={{height:"20px"}}></div>
							</div>
						</section>
					)
				}
                {
                    
                    this.state.activeScreen === 2 &&
                    renderPageContainer(
                        
                        <section className='content'>
                            {this.state.customersloading &&
                                <div className='flexcolumn center verticalcenter p-5 fullWidth hint mt-5 mb-4'>
                                    {/*changed by youssef to meet the selected site requirment*/}
                                    {/*{ts('Loading templates for',this.props.userLogin.language)} {this.state.activeCompany ? this.state.activeCompany.companyName : ''}*/}
                                    {ts('Loading',this.props.userLogin.language)}
                                    <Spinner animation="border" className='mt-3' variant="warning" />

                                </div>
                            }
                            {!this.state.customersloading &&
                            <div className='row'>
                            <div className='col-md-12'>
							<Button className='mb-4 mt-5' variant='warning' onClick={() => { this.setState({activeScreen:0, fields:{}, selectedPromoCode:null, promocodeCategory:[], promocodeRedeems:[]});history.go(-1) }} >{ts('Cancel / Return', this.props.userLogin.language)}</Button>
							</div>
                            <div className='col-md-12'>
							<p class="  ssstitle bold  boxTopRadius5 cunlayer  p-2  ">{ts("Promo code info",this.props.userLogin.language )} </p>
							</div>
							<div className='col-md-12' style={{height:"20px"}}></div>
                            <div className='col-md-12'>
                                <ul><li>Title:<b>{this.state.selectedPromoCode.name}</b></li>
                                <li>Category: <b>{ this.state.promocodeCategory.map(el=>el+" | ")}</b></li>
                                <li>Type: <b>{this.state.selectedPromoCode.redemptiontype}</b></li>
                                <li>Description: <b>{this.state.selectedPromoCode.description}</b></li>
                                <li>Start date: <b>{this.state.selectedPromoCode.startdate?td(dateFormat(this.state.selectedPromoCode.startdate,"ddd dd, mmm yyyy"), this.props.userLogin.language):td(dateFormat(this.state.selectedPromoCode.datein,"ddd dd, mmm yyyy"), this.props.userLogin.language)}</b></li>
                                <li>End date: <b>{this.state.selectedPromoCode.enddate?td(dateFormat(this.state.selectedPromoCode.enddate,"ddd dd, mmm yyyy"), this.props.userLogin.language):ts2("No limit", this.props.userLogin.language)}</b></li>
                                <li>Status: <Badge variant={this.state.selectedPromoCode.enabled==1 && (!this.state.selectedPromoCode.enddate || (new Date(this.state.selectedPromoCode.enddate)>new Date()))?'primary':'danger'} style={{backgroundColor:this.state.selectedPromoCode.enabled==1 && (!this.state.selectedPromoCode.enddate || new Date(this.state.selectedPromoCode.enddate)>new Date())?'#3FCAE0':'lightgray'}} className=' remarks'>
{this.state.selectedPromoCode.enabled==1 && (!this.state.selectedPromoCode.enddate || (this.state.selectedPromoCode.enddate && new Date(this.state.selectedPromoCode.enddate)>new Date()))?'Active':'Disabled'}
</Badge></li>
<li>Code: <b>{this.state.selectedPromoCode.code}</b></li>
<li>Usage limit: <b>{this.state.selectedPromoCode.customersallowedlimit?this.state.selectedPromoCode.customersallowedlimit:ts2("No limit", this.props.userLogin.language)}</b></li>
<li>Remaining usage: <b>{this.state.selectedPromoCode.customersallowedlimit?(this.state.selectedPromoCode.customersallowedlimit - this.state.promocodeRedeems.length>=0?this.state.selectedPromoCode.customersallowedlimit - this.state.promocodeRedeems.length:0):ts2("No limit", this.props.userLogin.language)}</b></li>
<li>Number of customers who used it: <b>{this.state.promocodeRedeems.length}</b></li>
                                </ul>
                            </div>
                            <div className='col-md-12' style={{height:"20px"}}></div>
                            <div className='col-md-12'>
							<p class="  ssstitle bold  boxTopRadius5 cunlayer  p-2  ">{ts("List of participants",this.props.userLogin.language )} </p>
							</div>
                            <div className='col-md-12' style={{height:"20px"}}></div>
                            <div className='col-md-12'>
                            <table id="promotable" className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>{ts2('Action',this.props.userLogin.language)}</th>
                                        <th>{ts2('Date',this.props.userLogin.language)}</th>
                                        <th>{ts2('Time',this.props.userLogin.language)}</th>
                                        <th>{ts2('Promo code name',this.props.userLogin.language)}</th>
                                        <th>{ts2('Category',this.props.userLogin.language)}</th>
                                        <th>{ts2('Customer name',this.props.userLogin.language)}</th>
                                        <th>{ts2('Customer email',this.props.userLogin.language)}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.promocodeRedeems.map(item=>{
                                        return (
                                            <tr>
                                                <td>
                                                <Badge variant="success"  className='handCursor remarks' style={{backgroundColor:"#1A98AC"}} onClick={()=>{this.state.selectedRedemption = item; this.openModal()}}>
View Details
</Badge>
                                                </td>
                                                <td>{td(dateFormat(item.datein,"mmm dd, yyyy"), this.props.userLogin.language)}</td>
                                                <td>{td(dateFormat(item.datein,"hh:mm tt"), this.props.userLogin.language)}</td>
                                                <td>{this.state.selectedPromoCode.name}</td>
                                                <td>{ this.state.promocodeCategory.map(el=>
                                                    {
                                                       return( <Badge variant="primary" style={{backgroundColor:"rgba(63, 202, 224, 0.19)", marginLeft:"10px", color:"black"}} className=' remarks'>
{el}
</Badge>)
                                                    }
                                                    )}
                                                </td>
                                                <td>{item.firstName} {item.lastName}</td>
                                                <td>{item.emailAddress}</td>
                                            </tr>
                                        )
                                    })
                                }
                                    </tbody>
                                    </table>
                            </div>
                            <div className='col-md-12' style={{height:"20px"}}></div>
                            <div className='col-md-12'>
							<Button className='mb-4 mt-5' variant='warning' onClick={() => { this.setState({activeScreen:0, fields:{}, selectedPromoCode:null, promocodeCategory:[], promocodeRedeems:[]});history.go(-1) }} >{ts('Cancel / Return', this.props.userLogin.language)}</Button>
							</div>
                            </div>
     }
     <MessageBox
                    show={this.state.modalShow}
                    body={
                        
                        <div>
                        <h4 style={{textAlign:"center"}}>Details</h4>
                        <ul style={{marginTop:"50px"}}><li>Title:<b>{this.state.selectedPromoCode.name}</b></li>                        
                        <li>Description: <b>{this.state.selectedPromoCode.description}</b></li>
                                <li>Category: <b>{ this.state.promocodeCategory.map(el=>el+" | ")}</b></li>
                                <li>Usage date: <b>{td(dateFormat(this.state.selectedRedemption.usagedate,"mmm dd, yyyy"), this.props.userLogin.language)}</b></li>
                                <li>Redemption time: <b>{td(dateFormat(this.state.selectedRedemption.usagedate,"hh:mm tt"), this.props.userLogin.language)}</b></li>
                                <li>Customer name: <b>{this.state.selectedRedemption.firstName} {this.state.selectedRedemption.lastName}</b></li>
<li>Customer email: <b>{this.state.selectedRedemption.emailAddress}</b></li>
                                </ul>
                                <center><Button variant="warning" style={{marginTop:"50px", width:"150px"}} onClick={()=>{this.setState({ modalShow: false })}}>Close</Button></center>
                                </div>
                    }
                    onHide={() => { this.setState({ modalShow: false }) }}
                />
                        </section>
                        
                    )
                }
               
            </>
        )
    }
}
const mapStateToProps = state => {

    return {
        userLogin: state.SaveLogin.userData
    };
};
const colourStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma("#9B00FA");
      const disabledcolor = chroma("gray")
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#9B00FA"
          : isFocused
          ? disabledcolor
          : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : "#9B00FA",
        cursor: isDisabled ? 'not-allowed' : 'default',
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#9B00FA"
              : "#9B00FA30"
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma("#9B00FA");
      return {
        ...styles,
        backgroundColor: "#9B00FA",
        color:"#ffffff"
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#ffffff",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "#ffffff",
      ':hover': {
        backgroundColor: "#9B00FA",
        color: 'white',
      },
    }),
  };
export default connect(
    mapStateToProps
)(PromoCode)