const initialState = {
    siteid:3
}

const reducer = (state = initialState, action) => {
    const newState = { ...state };
	newState.siteid = action.siteid;
	return newState;
}


export default reducer; 