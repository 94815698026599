import React, { Component, ReactDOM } from 'react'
import { Image, Button, Badge, Spinner } from 'react-bootstrap';
import * as G from '../components/global'
import { renderPageContainer, renderPageHeader, renderDivider, getData } from '../components/global'
import history from '../components/history';
import { connect } from 'react-redux';
import emailtempl from '../components/assets/emailtempl.png'
import { translateScreen as ts , translateDate as td, translateScreen2 as ts2} from '../components/dictionary'
import ReactPaginate from 'react-paginate';
import 'rsuite/dist/rsuite.min.css';
import {DateRangePicker} from 'rsuite';
class CampaignsTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeScreen: 0,
            allCompanies: [],
            activeCompany: {},
            ClientTemplates: [],
            archivedTemplates:[],
            originalList:[],
            displayedList:[],
            offset:0,
            originalArchiveList:[],
            displayedArchiveList:[],
            loadingTemplates: true,
            loadingArchive:true,
            sortvalue:"newest",
            startdate:'',
            enddate:'',
            searchfield:'',            
            perPage: 12,
            currentPage: 0,
            pageCount: 0,
            currentArchivePage: 0,
            pageArchiveCount: 0,
            customtemplates:[]
        }
        this.handleSort = this.handleSort.bind(this);
    }
    dateFormat = require('dateformat');
    
    async componentDidMount() {
        if (this.props.userLogin.site){
            this.refreshData(this.props.userLogin.site.id)
        }
        // if (this.state.allCompanies.length === 0) {
        //refreshDataClients()
        // }
    }
    getSnapshotBeforeUpdate(prevProps) {
        var searchfield = this.state.searchfield;
            /*if(searchfield!=undefined && searchfield.value !="")
            {
                var nodes = document.getElementsByClassName('templatecard');
           Array.prototype.map.call(nodes, (elem)=>{
            if (elem.innerText.toLowerCase().includes(searchfield.value)) {
                elem.style.display = "block";
            } else {
                elem.style.display = "none";
            }
        });
            }*/
            
        return { notifyRequired: prevProps.userLogin !== this.props.userLogin };
      }
      
      async componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot.notifyRequired) {
            await this.refreshData(this.props.userLogin.site.id);
        }
        
      }
    refreshDataClients = async () => {
        if (this.props.userLogin == null || this.state.allCompanies.length > 0) return;
        let paramArr = [['addColumns', ' (select count(id) from campaigntemplate where clientid = a.id) Count']]
        if (this.props.userLogin.clientID > 0) paramArr.push(['filter', " a.id = " + this.props.userLogin.clientID])
        let result = await getData('client/query', paramArr);
        result = await result.data;
        this.setState({ allCompanies: result })
        if (result.length > 0) {
            this.setState({ activeCompany: result[0] })
            //changed by youssef to meet the selected site//
            this.refreshData(this.props.userLogin.site.id)
        }


    }
    refreshData = async (company) => {
        var filter = 'clientID = ' + company + " and archived<>1";
        if(this.state.searchfield!='')
        {
            filter += ` and name like '%${this.state.searchfield}%'`;
        }
        if(this.state.sortvalue == "newest")
        {
            
            
            filter += " order by creationdate desc"
            
        }
        else{
            filter += " order by creationdate"
        }
        
        let result = await getData('campaigntemplate/query', [['filter', filter]]);
        result = await result.data;
        this.setState({ ClientTemplates: result })
        this.setState({ loadingTemplates: false })
        let pageCounts = result.length / this.state.perPage
                this.setState({ ClientTemplates: result, pageCount: pageCounts, originalList: result })
                this.setdisplayList(0)
                result = await getData("clienttemplates/query",[["filter","ispublic=1 or clientId=" + company]]);
        result = await result.data;
        this.setState({customtemplates:result})
    }
    refreshArchivedData = async (company) => {
        this.setState({ loadingArchive: true });
        var filter = 'clientID = ' + company + " and archived=1";
        if(this.state.startdate!='' && this.state.enddate!='')
            {
                var startdate = new Date(this.state.startdate)
            var enddate = new Date(this.state.enddate)
                var starts = td(this.dateFormat(startdate,"yyyy-mm-dd"));
                var ends = td(this.dateFormat(enddate,"yyyy-mm-dd"))
                filter += ` and DATEDIFF(creationdate,'`+starts + `') >= 0 and DATEDIFF(creationdate,'`+ends + `') <= 0`
                if(starts == ends)
                {
                    filter = 'clientID = ' + company + ` and archived=1 and DATEDIFF(creationdate,'`+starts + `') = 0`
                }
            }
        if(this.state.searchfield!='')
        {
            filter += ` and name like '%${this.state.searchfield}%'`;
        }
        
        if(this.state.sortvalue == "newest")
        {
            
            
            filter += " order by creationdate desc"
            
        }
        else{
            filter += " order by creationdate"
        }
        let result = await getData('campaigntemplate/query', [['filter', filter]]);
        result = await result.data;
        this.setState({ archivedTemplates: result })
        this.setState({ loadingArchive: false });
        let pageCounts = result.length / this.state.perPage
                this.setState({ pageArchiveCount: pageCounts, originalArchiveList: result })
                this.setdisplayArchiveList(0)
    }

    setdisplayList(startnumber) {
        let sliceArray = [...this.state.ClientTemplates]

        if (this.state.ClientTemplates.length > this.state.perPage) {
            sliceArray = sliceArray.slice(startnumber * this.state.perPage, startnumber * this.state.perPage + this.state.perPage)
           
        }
        this.setState({ displayedList: sliceArray })
    }
    setdisplayArchiveList(startnumber) {
        let sliceArray = [...this.state.archivedTemplates]

        if (this.state.archivedTemplates.length > this.state.perPage) {
            sliceArray = sliceArray.slice(startnumber * this.state.perPage, startnumber * this.state.perPage + this.state.perPage)
           
        }
        this.setState({ displayedArchiveList: sliceArray })
    }
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.setdisplayList(selectedPage)
        });

    };
    handleArchivePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentArchivePage: selectedPage,
            offsetArchive: offset
        }, () => {
            this.setdisplayArchiveList(selectedPage)
        });

    };
    openCampaignEditor = (templateID = -1, element = {}, htmlId="") => {
        var userLogin = this.props.userLogin;
        const activeCompany = this.state.activeCompany
        history.push({
            pathname: '/CampaignEditor',
            state: { data: { userLogin, templateID, activeCompany, element, htmlId } },
        });
        window.scrollTo(0, 0)
    }
    listTemplates = (companyId, companyDetails) => {
        this.setState({ loadingTemplates: true })
        this.setState({ ClientTemplates: [], displayedList: [], activeCompany: companyDetails })
        this.refreshData(companyId);

    }
    renderTemplateButton = (id, element) => {
        return (
            <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6 templatecard">
<div class="info-box handCursor" style={{width:"100%"}}>
<span class="info-box-icon bg-info" style={{padding:"0 50px"}} onClick={() => { this.openCampaignEditor(id, element) }}><i class="far fa-envelope"></i></span>
<div class="info-box-content" style={{width:"100%"}}>
<div className='info-icon' style={{position:"absolute", right:"10px", top:"10px", cursor:"pointer"}}><i className="fa fa-ellipsis-v" data-toggle="dropdown" href="#" style={{padding:"5px 10px"}} aria-expanded="false"></i>
                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <a onClick={()=>{this.openCampaignEditor(-1, element)}} className="dropdown-item">
                    {ts2("Duplicate",this.props.userLogin.language)}
                    </a>
                    <div className="dropdown-divider"></div>
                    <a onClick={()=>{this.ArchiveTemplate(id)}} className="dropdown-item">
                    {ts('Archive', this.props.userLogin.language)}
                    </a>
                </div>
                </div>
<span className="info-box-text bold" style={{width:"70%", lineHeight:"1.5"}}  onClick={() => { this.openCampaignEditor(id, element) }}>{element.name}</span>
                <span className="info-box-text" style={{width:"70%", lineHeight:"1.5"}} onClick={() => { this.openCampaignEditor(id, element) }}>{ts('Subject',this.props.userLogin.language)}: {element.subject}</span>
                <span className="info-box-number" style={{fontStyle:"italic", color:"#1998AB", marginTop:"0px"}} onClick={() => { this.openCampaignEditor(id, element) }}> {element.creationDate !== '' ? td(this.dateFormat(element.creationDate, 'dd mmm yyyy'),this.props.userLogin.language) : ''}</span>
</div>

</div>

</div>
        )
    }
    renderArchivedTemplateButton = (id, element) => {
        return (
            <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6 templatecard">
<div class="info-box handCursor" style={{width:"100%"}}>
<span class="info-box-icon bg-info" style={{padding:"0 30px"}} onClick={() => { this.openCampaignEditor(id, element) }}><i class="far fa-envelope"></i></span>
<div class="info-box-content"  style={{width:"100%"}}>
<div className='info-icon' style={{position:"absolute", right:"10px", top:"10px", cursor:"pointer"}}><i className="fa fa-ellipsis-v" data-toggle="dropdown" style={{padding:"5px 10px"}} href="#" aria-expanded="false"></i>
                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <a onClick={()=>{this.openCampaignEditor(-1, element)}} className="dropdown-item">
                    {ts2("Duplicate",this.props.userLogin.language)}
                    </a>
                    <div className="dropdown-divider"></div>
                    <a onClick={()=>{this.ActivateTemplate(id)}} className="dropdown-item">
                    {ts('Activate', this.props.userLogin.language)}
                    </a>
                </div>
                </div>
<span className="info-box-text bold" style={{width:"70%", lineHeight:"1.5"}}  onClick={() => { this.openCampaignEditor(id, element) }}>{element.name}</span>
                <span className="info-box-text" style={{width:"70%", lineHeight:"1.5"}} onClick={() => { this.openCampaignEditor(id, element) }}>{ts('Subject',this.props.userLogin.language)}: {element.subject}</span>
                <span className="info-box-number" style={{fontStyle:"italic", color:"#1998AB", marginTop:"0px"}} onClick={() => { this.openCampaignEditor(id, element) }}> {element.creationDate !== '' ? td(this.dateFormat(element.creationDate, 'dd mmm yyyy'),this.props.userLogin.language) : ''}</span>
</div>

</div>

</div>
        
        )
    }
    ArchiveTemplate = async(id)=>{
        await getData('campaigntemplate/archive',[['id',id]]);
        
        this.refreshData(this.props.userLogin.site.id);
        this.refreshArchivedData(this.props.userLogin.site.id);
    }
    ActivateTemplate = async(id)=>{
        await getData('campaigntemplate/activate',[['id',id]]);
        
        this.refreshData(this.props.userLogin.site.id);
        this.refreshArchivedData(this.props.userLogin.site.id);
    }
    handleDateRangeChange = async(range)=>{
        /*var company = this.props.userLogin.site.id;
        let result = await getData('campaigntemplate/query', [['filter', 'clientID = ' + company + ' and creationDate>' + startdate]]);
        result = await result.data;
        this.setState({ ClientTemplates: result })
        this.setState({ loadingTemplates: false })*/
        if(range!=undefined && range!=null && range[0]!=undefined && range[0]!="" && range[1]!=undefined && range[1]!="")
        {
            var startdate = new Date(range[0])
            var enddate = new Date(range[1])
            var starts = td(this.dateFormat(startdate,"yyyy-mm-dd"));
            var ends = td(this.dateFormat(enddate,"yyyy-mm-dd"))
            var filter = 'clientID = ' + this.props.userLogin.site.id + ` and archived<>1 and DATEDIFF(creationdate,'`+starts + `') >= 0 and DATEDIFF(creationdate,'`+ends + `') <= 0`
            if(starts == ends)
            {
                filter = 'clientID = ' + this.props.userLogin.site.id + ` and archived<>1 and DATEDIFF(creationdate,'`+starts + `') = 0`
            }
            if(this.state.searchfield!='')
            {
                filter += ` and name like '%${this.state.searchfield}%'`;
            }
            if(this.state.sortvalue == "newest")
        {
            filter += " order by creationdate desc"
        }
        else{
            filter += " order by creationdate"
        }
            let result = await getData('campaigntemplate/query', [['filter', filter]]);
        result = await result.data;
        this.setState({ ClientTemplates: result, startdate:starts, enddate:ends })
        this.setState({ loadingTemplates: false })
        this.setdisplayList(0);
        }
        else{
            this.setState({ startdate:'', enddate:'' })
            this.refreshData(this.props.userLogin.site.id);
            
        }
        var _this = this;
           setTimeout(function(){
            
            _this.refreshArchivedData(_this.props.userLogin.site.id);
           },500); 
        
    }
    handleSort = async (e)=>{
        var sortval = e.target.value;
        this.setState({ loadingTemplates: true })
        var filter ='clientID = ' + this.props.userLogin.site.id + " and archived<>1" ;
        if(this.state.startdate!='' && this.state.enddate!='')
            {
                var startdate = new Date(this.state.startdate)
            var enddate = new Date(this.state.enddate)
                var starts = td(this.dateFormat(startdate,"yyyy-mm-dd"));
                var ends = td(this.dateFormat(enddate,"yyyy-mm-dd"))
                var filter = 'clientID = ' + this.props.userLogin.site.id + ` and archived<>1 and DATEDIFF(creationdate,'`+starts + `') >= 0 and DATEDIFF(creationdate,'`+ends + `') <= 0`
                if(starts == ends)
                {
                    filter = 'clientID = ' + this.props.userLogin.site.id + ` and archived<>1 and DATEDIFF(creationdate,'`+starts + `') = 0`
                }
            }
            if(this.state.searchfield!='')
            {
                filter += ` and name like '%${this.state.searchfield}%'`;
            }
        if(sortval == "newest")
        {
            
            
            filter += " order by creationdate desc"
            
        }
        else{
            filter += " order by creationdate"
        }
        
        let result = await getData('campaigntemplate/query', [['filter', filter]]);
        result = await result.data;
            this.setState({ ClientTemplates: result, sortvalue: sortval })
            this.setState({ loadingTemplates: false })
            /*setTimeout(function(){
                var searchfield = document.getElementById("searchfield");
            if(searchfield!=undefined && searchfield.value !="")
            {
                var nodes = document.getElementsByClassName('templatecard');
           Array.prototype.map.call(nodes, (elem)=>{
            if (elem.innerText.toLowerCase().includes(searchfield.value)) {
                elem.style.display = "block";
            } else {
                elem.style.display = "none";
            }
        });
            }
            },500);*/
            this.setdisplayList(0);
            this.refreshArchivedData(this.props.userLogin.site.id);
    }
    searchByText = async()=>{
        var searchfield = document.getElementById("searchfield").value;
        this.setState({searchfield:searchfield});
        var filter ='clientID = ' + this.props.userLogin.site.id + " and archived<>1" ;
        if(this.state.startdate!='' && this.state.enddate!='')
            {
                var startdate = new Date(this.state.startdate)
            var enddate = new Date(this.state.enddate)
            var starts = td(this.dateFormat(startdate,"yyyy-mm-dd"));
            var ends = td(this.dateFormat(enddate,"yyyy-mm-dd"))
            var filter = 'clientID = ' + this.props.userLogin.site.id + ` and archived<>1 and DATEDIFF(creationdate,'`+starts + `') >= 0 and DATEDIFF(creationdate,'`+ends + `') <= 0`
            if(starts == ends)
            {
                filter = 'clientID = ' + this.props.userLogin.site.id + ` and archived<>1 and DATEDIFF(creationdate,'`+starts + `') = 0`
            }
            }
            if(searchfield!='')
            {
                filter += ` and name like '%${searchfield}%'`;
            }
            if(this.state.sortvalue == "newest")
        {
            
            
            filter += " order by creationdate desc"
            
        }
        else{
            filter += " order by creationdate"
        }
        let result = await getData('campaigntemplate/query', [['filter', filter]]);
        result = await result.data;
            this.setState({ ClientTemplates: result })
            this.setState({ loadingTemplates: false });
            this.setdisplayList(0);
            this.refreshArchivedData(this.props.userLogin.site.id);
    }
    handleSearchKeyUp = (e)=>{
       // console.log("Search value",e.target.value)
       var filter = e.target.value
       var nodes = document.getElementsByClassName('templatecard');
       Array.prototype.map.call(nodes, (elem)=>{
        if (elem.innerText.toLowerCase().includes(filter)) {
            elem.style.display = "block";
        } else {
            elem.style.display = "none";
        }
    })
            
            /*for (i = 0; i < nodes.length; i++) {
                if (nodes[i].innerText.toLowerCase().includes(filter)) {
                    nodes[i].style.display = "block";
                } else {
                    nodes[i].style.display = "none";
                }
            }*/
    }
    render() {
        /*if (this.state.allCompanies != null) {

            if (this.state.allCompanies.length === 0) { this.refreshDataClients(); }

        }*/
        return (
            <>
                {/*<section className='content-header'>
                    <div className='container-fluid'>
                        <div className='row mb-2'>
                            <div className='col-sm-6'>
                                <h1>{ts('Email templates',this.props.userLogin.language)}</h1>
                            </div>
                        </div>
                    </div>
        </section>*/}
                
                
                {this.state.activeScreen === 0 &&

                    renderPageContainer(
                        <>
                        <section className='content'>                
                            <div className='row'>
    
                            {/*{renderPageHeader(<span>{ts('Saved Campaigns List',this.props.userLogin.language)} <Badge variant='warning ssstitle'>{this.state.activeCompany ? this.state.activeCompany.companyName : ''}</Badge></span>)}*/}
                            {this.state.loadingTemplates &&
                                <div className='flexcolumn center verticalcenter p-5 fullWidth hint '>
                                    {/*changed by youssef to meet the selected site requirment*/}
                                    {/*{ts('Loading templates for',this.props.userLogin.language)} {this.state.activeCompany ? this.state.activeCompany.companyName : ''}*/}
                                    {ts('Loading templates for',this.props.userLogin.language)} {this.props.userLogin.site ? this.props.userLogin.site.companyName : ''}
                                    <Spinner animation="border" className='mt-3' variant="warning" />

                                </div>
                            }
                            </div>
                            
                            {!this.state.loadingTemplates && this.state.ClientTemplates.length > 0 && <div className='row'><div className='col-sm-6'><Button onClick={() => { this.openCampaignEditor(-1) }} variant='success' className='mt-5 mb-4' >{ts('Create New Template',this.props.userLogin.language)}</Button></div></div>}
                            <h3>{ts('Email templates',this.props.userLogin.language)}&nbsp;&nbsp;
                            <DateRangePicker onChange={(value)=>{this.handleDateRangeChange(value)}}  placeholder={ts("Filter by date range", this.props.userLogin.language)} />
                            </h3><div className="col-md-12" style={{height:"10px"}}></div>
                            <div className="row">
                            <div className="form-group col-md-3">
                            <label for="searchfield">Search</label>
                            <div class="input-group">
                                
                  <input id="searchfield" type="text" class="form-control" onKeyUp={()=>{this.searchByText()}} onchange={()=>{this.searchByText()}}/>
                  <div class="input-group-append handCursor" onClick={()=>{this.searchByText()}}>
                    <span class="input-group-text"><i class="fas fa-search"></i></span>
                  </div>
                </div>
                            </div>
                            <div className="col-md-9">
                                <div style={{float:"right"}}>
                                    <label for="sortfield">Sort by</label>
                                <select id="sortfield" name="sortfield" onChange={this.handleSort} className="custom-select">
                                    <option></option>
                                    <option value="newest">{ts("Newest to oldest",this.props.userLogin.language)}</option>
                                    <option value="oldest">{ts("Oldest to newest",this.props.userLogin.language)}</option>
                                </select>
                                </div>
                                <div style={{clear:"both"}}></div>
                            </div></div>
                            <div class="col-md-12"></div>
                            {!this.state.loadingTemplates &&<div className='row'> <div className='flexrow start wrap mt-2   pt-3 '>

                                {this.state.displayedList.map(element => {
                                    return (this.renderTemplateButton(element.id, element))

                                })}

                                {this.state.ClientTemplates.length === 0 &&
                                    <div className='col-md-12'>
                                    <div className='flexcolumn center verticalcenter p-5 fullWidth cSignInBack boxRadius5'>
                                       {ts(' No Templates available for',this.props.userLogin.language)} {this.state.activeCompany ? this.state.activeCompany.companyName : ''}
                                        <Button onClick={() => { this.openCampaignEditor(-1) }} variant='success' className='m-4' >Create First Tempalte</Button>
                                    </div>
                                    </div>
                                }

                            </div>
                            </div>}
                            <div style={{float:"right"}} className="rightpaging">
                            {!this.state.loadingTemplates && this.state.ClientTemplates.length > this.state.perPage && <ReactPaginate
                            previousLabel={ts("Previous",this.props.userLogin.language)}
                            nextLabel={ts("Next",this.props.userLogin.language)}
                            breakLabel={"..."}
                            pageCount={this.state.pageCount}
                            marginPagesDisplayed={this.state.perPage}
                            pageRangeDisplayed={2}
                            onPageChange={this.handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"subPagination"}
                            activeClassName={"activePage "}
                            breakClassName={'eachPage'}
                            previousClassName={'eachPagePrevNex'}
                            nextClassName={'eachPagePrevNex'}
                            pageClassName={'eachPage'}
                        />}</div><div style={{clear:"both"}}></div>
                            
                            <button onClick={()=>{
                                this.refreshArchivedData(this.props.userLogin.site.id);
                                if(document.getElementById("archiveddata").style.display == 'none')
                                {
                                    document.getElementById("archiveddata").style.display = 'block';
                                    document.getElementById("plusar").innerText = "-";
                                }
                                else
                                {
                                    document.getElementById("archiveddata").style.display = 'none';
                                    document.getElementById("plusar").innerText = "+";
                            }
                            }}><span id="plusar">+</span> {ts("Archived templates", this.props.userLogin.language)}</button>
                            <div id="archiveddata" style={{display:"none"}}>
                            {!this.state.loadingArchive &&<div className='row'> <div className='flexrow start wrap mt-2   pt-3 '>

                                {this.state.displayedArchiveList.map(element => {
                                    return (this.renderArchivedTemplateButton(element.id, element))

                                })}

                                

                            </div>
                            </div>}</div>
                            <div style={{float:"right"}} className="rightpaging">
                            {!this.state.loadingArchive && this.state.archivedTemplates.length > this.state.perPage && <ReactPaginate
                            previousLabel={ts("Previous",this.props.userLogin.language)}
                            nextLabel={ts("Next",this.props.userLogin.language)}
                            breakLabel={"..."}
                            pageCount={this.state.pageArchiveCount}
                            marginPagesDisplayed={this.state.perPage}
                            pageRangeDisplayed={2}
                            onPageChange={this.handleArchivePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"subPagination"}
                            activeClassName={"activePage "}
                            breakClassName={'eachPage'}
                            previousClassName={'eachPagePrevNex'}
                            nextClassName={'eachPagePrevNex'}
                            pageClassName={'eachPage'}
                        />}</div><div style={{clear:"both"}}></div>
                            </section>
                                
                             
                            {renderPageHeader(ts("Designed Templates",this.props.userLogin.language))}
                            <section className='content'>                
                            <div className='row'>
                            
                                {/*[{id:'1',img:'https://cdn.templates.unlayer.com/previews/easter-egg-hunt-sports/1629658913652.png'}, {id:'2',img:'https://cdn.templates.unlayer.com/previews/user-quick-survey/1623672660914.png'}, {id:'3',img:'https://cdn.templates.unlayer.com/previews/women-equality-day/1613543554270.png'}, {id:'4',img:'https://cdn.templates.unlayer.com/previews/herbal-cosmetics/1618315318779.png'}, {id:'5',img:'https://cdn.templates.unlayer.com/previews/gardening/1614346259022.png'}].map(element => {
                                    return (<div className='m-3 handCursor' style={{ borderRadius: 8 }} onClick={() => { this.openCampaignEditor(-1, {},element.id) }}>
                                        <img src={element.img} width="180" className='' style={{ objectFit: 'contain', height:'200px' }} />
                                        <div className='text-center'>template {element.id}   </div>
                                    </div>)
                                })*/}
                            {this.state.customtemplates && this.state.customtemplates.map(element=>{
                                    return (<div className='m-3 handCursor' style={{ borderRadius: 8 }} onClick={() => { this.openCampaignEditor(-1, {},element.id) }}>
                                        <img src={element.thumb} width="180" className='' style={{ objectFit: 'contain', height:'200px' }} />
                                        <div className='text-center'>{element.name}   </div>
                                    </div>)
                                })
                                }
                                <div className='m-3 handCursor' style={{ borderRadius: 8, width:"180px", height:"200px", lineHeight:"180px", backgroundColor:"#c3c3c3" }} onClick={() => { this.openCampaignEditor(-2) }}>
                                        
                                        <div className='text-center' >+ Add   </div>
                                        </div>
                            </div>
                            </section>
                            
                        
                        </>
                    )

                }

               
            </>
        )
    }
}
const mapStateToProps = state => {

    return {
        userLogin: state.SaveLogin.userData
    };
};
export default connect(
    mapStateToProps
)(CampaignsTemplate)