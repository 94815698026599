import React, { Component } from 'react';
import { Container, Spinner, Badge, Button, Form } from 'react-bootstrap'
import MessageBox from '../components/MessageBox';
import * as G from '../components/global'
import '../styles/glob.css'
import history from '../components/history';
import { translateScreen as ts , translateScreen2 as ts2 , translateDate as td} from '../components/dictionary'
import { connect } from 'react-redux';
import qs from 'qs'

class PromoCodeCategories extends Component {

	constructor(props) {
		super(props);

		this.state = {
				list:[],
				listloading:false,
				updateMode:false,
				fields: {},
				modalShow: false,
				modalSubject: 'Delete Promocode Category',
				modalBody: 'Are you sure you want to delete this Promocode Category',
				justinfo: true,
				errors: {},
				deleteID: -1,
                successmodalShow:false
		}
		this.handleChange = this.handleChange.bind(this);
	}
    getSnapshotBeforeUpdate(prevProps) {
        
        return { notifyRequired: prevProps.userLogin !== this.props.userLogin };
      }
      
      async componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot.notifyRequired) {
            if(this.props.userLogin.site)
            {
                this.setState({list:[]});
                this.loadData();
            }
        }
        
      }
	componentDidMount(){
        if(this.props.userLogin.site)
		    this.loadData();
		history.listen((location, action) => {
            if(location.pathname == "/PromoCodeCategories" || location.pathname == "/PromoCodeCategories/")
            {
            this.setState({ updateMode: false });
            if(this.props.userLogin.site)
            {
                this.setState({list:[]});
            this.loadData();
            }
            }
            if(location.pathname == "/PromoCodeCategories/create" || location.pathname == "/PromoCodeCategories/create/")
            this.setState({ updateMode: true, fields: {} });
          });
          if(history.location.pathname == "/PromoCodeCategories/create" || history.location.pathname == "/PromoCodeCategories/create/")
            this.setState({ updateMode: true, fields: {} });
	}
	loadData = async ()=>{
		this.setState({listloading:true})
		var results = await G.getData("promocodecategory/query",[["filter","a.siteid=" + this.props.userLogin.site.id]]);
		results = await results.data;
		this.setState({list:results});
		this.loadDataTables();
        var qid = qs.parse(history.location.search, { ignoreQueryPrefix: true }).id
        results.map((el)=>{
            if(qid == el.id)
            {
                this.openEdit(el, false);
            }
        })
	}
	loadDataTables = ()=>{
		
        if(this.state.list)
        {
            this.setState({ listloading: false })
        setTimeout(() => {
            
                const script = document.createElement("script");
            script.id = "dttablestats";
            script.src = '/dist/js/dttable.js';
            script.async = true;
            document.body.appendChild(script);
            
            
        }, 300);
    }
	}
	handleChange(e) {
       let { fields, errors } = this.state;
        fields[e.target.name] = e.target.value;
        delete errors[e.target.name];

        this.setState({ fields });
    }
    openEdit = (item, redirect = true)=>{
        let record = { ...item }
        //record.creationdate = dateFormat(item.creationDate, "dddd, mmm dd yyyy h:MM tt")
        this.setState({ updateMode: true, fields: { uid:item.uid, categoryname:item.name, id:item.id } });
        if(redirect == true)
            history.push("/PromoCodeCategories/edit?id=" + item.id)
    }
    
	render() {
		const { errors, fields } = this.state;
        var clientslug = this.props.userLogin.site && this.props.userLogin.site.slug;
        //if (this.props.userLogin.language)language=this.props.userLogin.language;
		return (
			<>
			{!this.state.updateMode && <div>
			<section className='content-header'>
                    <div className='container-fluid'>
                    <div className='row mb-2'>
                <div className='col-sm-6'>
                    <h1 className=''>{ts('Promo codes categories',this.props.userLogin.language)} </h1>
                    </div>
                    </div>
                    </div>
                    </section>
					<section className='content'>
                        <div className='row'>
                    <Button variant='success mt-3 mb-5 ml-3' onClick={() => { this.setState({ updateMode: true, fields: {} });history.push("/PromoCodeCategories/create") }}>{ts2('Create New Category',this.props.userLogin.language)}</Button>
                    </div>
                    <div className='row'>
                    <div className='col-md-12 '>
                        <div className="">
                        {!this.state.loadList && <table id="example1" className="table table-bordered">
                                <thead>
                                    <tr>
                                    <th>{ts2('Action',this.props.userLogin.language)}</th>
                                        <th>{ts2('Name',this.props.userLogin.language)}</th>
                                        

                                    </tr>
                                </thead>

{!this.state.listloading && 
                                <tbody>
                                    {
                                        (this.state.list).map((item) => {
                                            
                                            /*this.state.clientsList.filter(val => val.key == item.clientID).map(filteredName => (
                                                clientslug = filteredName.clientslug 
                                            ))*/
                                            return (
                                                <tr key={item.id}>
                                                    <td className='xxxtitle whiteColor handCursor'
                                                        >
                                                        <Button onClick={() => {
                                                            this.openEdit(item)
                                                        }} className="btn btn-primary" style={{width:"150px"}}>{ts2('View',this.props.userLogin.language)}</Button><br/>
														<Button onClick={() => {
                                                                
                                                                
                                                                    this.setState({
                                                                        modalShow: true,
                                                                        modalSubject: ts2('Delete category',this.props.userLogin.language),
                                                                        modalBody: ts2('Are you sure you want to delete this Promo Code Category?',this.props.userLogin.language),
                                                                        justinfo: false,
                                                                        deleteID: item.id
                                                                    })
                                                                

                                                        }} className="btn btn-danger" style={{width:"150px", marginTop:"10px"}}>{ts2('Delete',this.props.userLogin.language)}</Button>
                                                    </td>
                                                    <td>{item.name}</td>
                                                    
                                                 

                                                </tr>)
                                        })
                                    }

                                </tbody>
    }   
                            </table>}
                            {this.state.loadList &&
                                <div className='flexcolumn center verticalcenter p-5 fullWidth hint '>
                                   {ts2('Loading Categories',this.props.userLogin.language)} 
                                    <Spinner animation="border" className='mt-3' variant="warning" />

                                </div>
                            }
                        </div>
                    </div>
                    </div>
                    </section>
					</div>}
					{
                    this.state.updateMode && <>
                    <div className='content-header'>
                    <div className='container-fluid'>
                        <div className='row mb-2'>
                            <div className='col-sm-6'>
                        <h1>{ts2('Edit Category Name',this.props.userLogin.language)}</h1>
                        </div>
                        </div>
                        </div>
                        </div>
                        <section className='content'>
                        <div className="row">
                        <Form style={{width:"100%"}}>
                            <div className='col-sm-12 col-md-8 '>
                                {G.renderTextField("UID", 'text', "Category ID will be generated Automatically", this.handleChange, fields, errors, null, '', true,this.props.userLogin.language)}
                                {G.renderTextField("Category name", 'text', "Add the Category Name Here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language)}
                            </div>
                        </Form>
                       </div><div className='row'>
                           <div className='col-md-12'>
                        <Button variant='success ml-3 mt-5' onClick={() => {   fields.id == null ? this.updateCategory(-1) : this.updateCategory(fields.id) }}>{ts2('Save',this.props.userLogin.language)}</Button>
                        <Button variant='warning ml-3 mt-5' onClick={() => { this.setState({ updateMode: false });history.go(-1);
                        
                        this.loadDataTables();
                    }}>{ts('Cancel',this.props.userLogin.language)}</Button>
                   </div> </div>
                <div className='row'>
                
                </div>
                </section>
                </>
    }
	<MessageBox
                    show={this.state.modalShow}
                    title={this.state.modalSubject}
                    body={this.state.modalBody}
                    onHide={() => { this.setState({ modalShow: false }) }}
                    middle={!this.state.justinfo && {
                        action: () => {
                            this.deleteCategory()


                        }, text: ts2('Delete category',this.props.userLogin.language), variant: 'danger'
                    }}
                    right={{ action: () => { this.setState({ modalShow: false }) }, text: this.state.justinfo ? 'OK' : ts('Cancel',this.props.userLogin.language), variant: 'success' }}


                />
			<MessageBox
                    show={this.state.successmodalShow}
                    title={ts("Changes are saved",this.props.userLogin.language)}
                    body={<div><center><Button variant="success" onClick={()=>{this.setState({ successmodalShow: false });}}>OK</Button></center></div>}
                    onHide={() => { this.setState({ modalShow: false }) }}

                />
            </>
		)
	}
	updateCategory = async (recordID) => {
        console.log("recordID=", recordID)
        const { categoryname } = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!categoryname) { formIsValid = false; errors["name"] = "Category Name cannot be empty"; }

        if (formIsValid) {
            let parameters = [['name', categoryname], ['description', categoryname]]
            if (recordID !== -1) parameters.push(['id', recordID])
           //parameters.push(['clientID', this.props.userLogin.site.id])
            let result = await G.getData('promocodecategory/' + (recordID === -1 ? 'insert' : 'update'), parameters)
            console.log(result)
            let success = (result.status === 200)
            if (success) {
                if(recordID === -1)
                {
                    var logtxt = this.props.userLogin.username + " has created a promocode category " + categoryname;
                    await G.SaveUserLog(logtxt, this.props.userLogin.id);
                }
                else{
                    var logtxt = this.props.userLogin.username + " has edited the promocode category info of " + categoryname;
                    await G.SaveUserLog(logtxt, this.props.userLogin.id);
                }
                this.setState({ updateMode: false, fields: {}, successmodalShow:recordID!==-1 })
                this.loadData()
                history.push("/PromoCodeCategories")
            }
        }
    }
	deleteCategory =async () => {
		let parameters = [['filter', "id=" + this.state.deleteID]]
		var result = await G.getData('promocodecategory/delete', parameters, true)
		result = await result.status;
		if (result === 200) {
			this.setState({ deleteID: -1, modalShow:false })
			//this.loadData()
			window.location.reload();
		}else{
			this.setState({
				modalShow: true,
				modalSubject: 'Error Appeared',
				modalBody: 'Problem Occured while hiding Record. Please Try Again',
				justinfo: true
			})
		}
	}
}


const mapStateToProps = state => {
    return {
        userLogin: state.SaveLogin.userData
    };
};

export default connect(
    mapStateToProps
)(PromoCodeCategories);