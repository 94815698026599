import React, { Component } from 'react';
import { renderPageContainer,Container, Spinner, Badge, Button, Form } from 'react-bootstrap'
import MessageBox from '../components/MessageBox';
import * as G from '../components/global'
import '../styles/glob.css'
import history from '../components/history';
import { translateScreen as ts , translateScreen2 as ts2 , translateDate as td} from '../components/dictionary'
import { connect } from 'react-redux';

class PointsTokens extends Component {

	constructor(props) {
		super(props);

		this.state = {
				token:{},
				point:{},
				updateMode:false,
				fields: {},
				modalShow: false,
				modalSubject: '',
				modalBody: '',
				justinfo: true,
				errors: {},
                show:'',
                successmodalShow:false

		}
		this.handlePTChange = this.handlePTChange.bind(this);
	}
	componentDidMount(){
        if(this.props.userLogin.site)
		    this.loadData();
	}
    getSnapshotBeforeUpdate(prevProps) {
        
        return { notifyRequired: prevProps.userLogin !== this.props.userLogin };
      }
      
      async componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot.notifyRequired) {
            await this.loadData();
        }
        
      }
	loadData = async ()=>{
		this.setState({listloading:true})
		var pointrulesresult = await G.getData("pointrules/query",[["filter","siteid=" + this.props.userLogin.site.id]]);
		pointrulesresult = await pointrulesresult.data;
        var tokenrulesresult = await G.getData("tokenrules/query",[["filter","siteid=" + this.props.userLogin.site.id]]);
		tokenrulesresult = await tokenrulesresult.data;
        var point = pointrulesresult.length>0?pointrulesresult[0]:{}
        var token = tokenrulesresult.length>0?tokenrulesresult[0]:{}
		this.setState({point:point, listloading:false, token: token, fields:{points:point.points, tokens: token.tokens, rateperpoint: point.rateperpoint, ratepertoken:token.ratepertoken, pointid:point.id, tokenid:token.id}});
	}
	
	handlePTChange(e) {
       //let { fields, errors } = this.state;
       delete this.state.fields[e.target.name]; 
       this.state.fields[e.target.name] = e.target.value;
        delete this.state.errors[e.target.name];

        this.setState({ fields: this.state.fields });
    }
	render() {
		const { errors, fields } = this.state;
        //if (this.props.userLogin.language)language=this.props.userLogin.language;
		return (
			<>
            <section className='content'>
			<div className="row" style={{marginTop:"200px"}}>
                <div className="col-md-12">
                    <h3>{ts("Points conversion rate", this.props.userLogin.language)}</h3>
                    
                </div>
                <div className="col-md-12">
                {this.state.point &&
                    <span style={{fontSize:"20px"}}>{this.state.point.points} Point{this.state.point.points>1?"s":""} = {this.state.point.rateperpoint} Euro{this.state.point.rateperpoint>1?"s":""}</span>
                }
                <br/>
                <Button variant='primary mt-3 mb-5 ml-3' onClick={() => { this.setState({ modalShow: true,modalSubject:"Edit Points conversion rate", show:'points'
                 }); }}>{ts2('Edit',this.props.userLogin.language)}</Button>
                </div>
            </div>
            <div className="row" style={{marginTop:"100px"}}>
                <div className="col-md-12">
                    <h3>{ts("Tokens conversion rate", this.props.userLogin.language)}</h3>
                </div>
                <div className="col-md-12">
                {this.state.token &&
                    <span style={{fontSize:"20px"}}>{this.state.token.tokens} Token{this.state.token.tokens>1?"s":""} = {this.state.token.ratepertoken} Point{this.state.token.ratepertoken>1?"s":""}</span>
                }
                <br/>
                <Button variant='primary mt-3 mb-5 ml-3' onClick={() => { this.setState({ modalShow: true,modalSubject:"Edit Tokens conversion rate", show:'tokens'
                 }); }}>{ts2('Edit',this.props.userLogin.language)}</Button>
                </div>
            </div>
            </section>
	<MessageBox
                    show={this.state.modalShow}
                    
                    body={
                        <>
                        {this.state.show == "points" &&
                        <div className="container">
                            <div className="row" style={{marginTop:"20px"}}>
                                <div class="col-md-12">
                                    <h4 style={{textAlign:"center"}}>{this.state.modalSubject}</h4>
                                </div>
                            </div>
                            <div className="row" style={{marginTop:"20px"}}>
                                <table style={{margin:"auto"}}><tr><td>
                                    {G.renderTextField("Point", 'number', "Enter points here", this.handlePTChange, fields, errors, null, '', false,this.props.userLogin.language, true, "points")}
                                    </td><td style={{width:"10px"}}></td><th>=</th><td style={{width:"10px"}}></td><td>
                                    {G.renderTextField("Euro", 'number', "Enter rate here", this.handlePTChange, fields, errors, null, '', false,this.props.userLogin.language, true, "rateperpoint")}
                                        </td></tr></table>
                            </div>
                            <div className="row" style={{marginTop:"20px"}}>
                            <div className='col-md-12'>
                            <table style={{margin:"auto"}}>
                                    <tr><td><Button variant='success' style={{width:"150px"}} onClick={async() => { 
                                        this.setState({ show:'pointsconfirm' })
                                        
                                     }}>{ts2('Save',this.props.userLogin.language)}</Button></td></tr>
                                    <tr><td style={{height:"10px"}}></td></tr>
                                    <tr><td><Button variant='warning' style={{width:"150px"}} onClick={() => { this.setState({ modalShow: false, show:'',modalSubject:'' })  }}>{ts2('Cancel',this.props.userLogin.language)}</Button></td></tr>
                                    </table>
                            </div>
                        </div>
                        </div>}
                        {this.state.show == "tokens" &&
                        <div className="container">
                            <div className="row" style={{marginTop:"20px"}}>
                                <div class="col-md-12">
                                    <h4 style={{textAlign:"center"}}>{this.state.modalSubject}</h4>
                                </div>
                            </div>
                        <div className="row" style={{marginTop:"20px"}}>
                            <table  style={{margin:"auto"}}><tr><td>
                                {G.renderTextField("Token", 'number', "Enter tokens here", this.handlePTChange, fields, errors, null, '', false,this.props.userLogin.language, true, "tokens")}
                                </td><td style={{width:"10px"}}></td><th>=</th><td style={{width:"10px"}}></td><td>
                                {G.renderTextField("Point", 'number', "Enter rate here", this.handlePTChange, fields, errors, null, '', false,this.props.userLogin.language, true, "ratepertoken")}
                                    </td></tr></table>
                        </div>
                        <div className="row" style={{marginTop:"20px"}}>
                            <div className='col-md-12'>
                                <table style={{margin:"auto"}}>
                                    <tr><td><Button variant='success' style={{width:"150px"}} onClick={async() => {
                                        this.setState({ show:'tokensconfirm' })
                                      }}>{ts2('Save',this.props.userLogin.language)}</Button></td></tr>
                                    <tr><td style={{height:"10px"}}></td></tr>
                                    <tr><td><Button variant='warning' style={{width:"150px"}} onClick={() => { this.setState({ modalShow: false, show:'',modalSubject:'' })  }}>{ts2('Cancel',this.props.userLogin.language)}</Button></td></tr>
                                    </table>
                                
                                
                                
                            </div>
                        </div>
                    </div>
                        }
                        {this.state.show == "pointsconfirm" &&
                        <div className="container">
                            <div className="row" style={{marginTop:"20px"}}>
                                <div class="col-md-12">
                                    <h4 style={{textAlign:"center"}}>{ts2('Attention, you are going to modify an important configuration. Please confirm.',this.props.userLogin.language)}</h4>
                                </div>
                            </div>
                            <div className="row" style={{marginTop:"20px"}}>
                            <div className='col-md-12'>
                            <table style={{margin:"auto"}}>
                                    <tr><td><Button variant='success' style={{width:"150px"}} onClick={async() => { 
                                        if(this.state.fields["pointid"])
                                            await G.getData("pointrules/update",[["siteid",this.props.userLogin.site.id],["rateperpoint", this.state.fields["rateperpoint"]],["points", this.state.fields["points"]],["edituserid", this.props.userLogin.id],["id", this.state.fields["pointid"]]]);
                                            else{
                                                await G.getData("pointrules/insert",[["siteid",this.props.userLogin.site.id],["rateperpoint", this.state.fields["rateperpoint"]],["points", this.state.fields["points"]],["creationuserid", this.props.userLogin.id]]);    
                                            }
                                            var logtxt = this.props.userLogin.username + " updated rate per point settings ";
                    await G.SaveUserLog(logtxt, this.props.userLogin.id);
                                        this.setState({ modalShow: false, show:'',modalSubject:'', successmodalShow:true })
                                        this.loadData()
                                     }}>{ts2('Confirm',this.props.userLogin.language)}</Button></td></tr>
                                    <tr><td style={{height:"10px"}}></td></tr>
                                    <tr><td><Button variant='warning' style={{width:"150px"}} onClick={() => { this.setState({ modalShow: false, show:'',modalSubject:'' })  }}>{ts2('Cancel',this.props.userLogin.language)}</Button></td></tr>
                                    </table>
                            </div>
                        </div>
                        </div>
                        }
                        {this.state.show == "tokensconfirm" &&
                        <div className="container">
                            <div className="row" style={{marginTop:"20px"}}>
                                <div class="col-md-12">
                                    <h4 style={{textAlign:"center"}}>{ts2('Attention, you are going to modify an important configuration. Please confirm.',this.props.userLogin.language)}</h4>
                                </div>
                            </div>
                            <div className="row" style={{marginTop:"20px"}}>
                            <div className='col-md-12'>
                                <table style={{margin:"auto"}}>
                                    <tr><td><Button variant='success' style={{width:"150px"}} onClick={async() => {
                                        if(this.state.fields["tokenid"])
                                            await G.getData("tokenrules/update",[["siteid",this.props.userLogin.site.id],["ratepertoken", this.state.fields["ratepertoken"]],["tokens", this.state.fields["tokens"]],["edituserid", this.props.userLogin.id],["id", this.state.fields["tokenid"]]]);
                                        else
                                            await G.getData("tokenrules/insert",[["siteid",this.props.userLogin.site.id],["ratepertoken", this.state.fields["ratepertoken"]],["tokens", this.state.fields["tokens"]],["creationuserid", this.props.userLogin.id]]);
                                            var logtxt = this.props.userLogin.username + " updated rate per token settings ";
                    await G.SaveUserLog(logtxt, this.props.userLogin.id);
                                            this.setState({ modalShow: false, show:'',modalSubject:'', successmodalShow:true })
                                        this.loadData()
                                      }}>{ts2('Confirm',this.props.userLogin.language)}</Button></td></tr>
                                    <tr><td style={{height:"10px"}}></td></tr>
                                    <tr><td><Button variant='warning' style={{width:"150px"}} onClick={() => { this.setState({ modalShow: false, show:'',modalSubject:'' })  }}>{ts2('Cancel',this.props.userLogin.language)}</Button></td></tr>
                                    </table>
                                
                                
                                
                            </div>
                        </div>
                        </div>
                        }
                        
                        </>
                    }
                    onHide={() => { this.setState({ modalShow: false }) }}
                    /*middle={!this.state.justinfo && {
                        action: () => {
                            this.deleteCategory()


                        }, text: ts2('Delete category',this.props.userLogin.language), variant: 'danger'
                    }}
                    right={{ action: () => { this.setState({ modalShow: false }) }, text: this.state.justinfo ? 'OK' : ts('Cancel',this.props.userLogin.language), variant: 'success' }}*/


                />
                <MessageBox
                    show={this.state.successmodalShow}
                    title={ts("Changes are saved",this.props.userLogin.language)}
                    body={<div><center><Button variant="success" onClick={()=>{this.setState({ successmodalShow: false });}}>OK</Button></center></div>}
                    onHide={() => { this.setState({ modalShow: false }) }}

                />
			</>
            )
		
	}
	
}


const mapStateToProps = state => {
    return {
        userLogin: state.SaveLogin.userData
    };
};

export default connect(
    mapStateToProps
)(PointsTokens);