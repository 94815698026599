import counterWeight from './counterWeight';
import counterAge from './counterAge';
import SaveLogin from './saveLogin';
import SiteId from './SiteId';
import { combineReducers } from 'redux';

const allReducers = combineReducers({
    counterAge: counterAge,
	counterWeight: counterWeight,
	SaveLogin:SaveLogin,
	SiteId:SiteId

}
)

export default allReducers;