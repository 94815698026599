import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Button, Container, Form, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import '../styles/glob.css'
import * as G from '../components/global';
import history from '../components/history'
import { connect } from 'react-redux';
import { translateScreen as ts , translateScreen2 as ts2 , translateDate as td} from '../components/dictionary'
import qs from 'qs'
class Terms extends Component {


	constructor(props) {
		super(props);
		this.state = {
			fields: {},
			errors: {},
			result: [],
			load: false,
			language:0,
			showPasswordReset: false,
			successemail: false,
			loginFailed: false,
			failureMessage: 'Your username and password are not available'
		}
	}

	componentDidMount = async ()=> {
		var language = qs.parse(history.location.search, { ignoreQueryPrefix: true }).language
		var result = await G.getData("termscontent/query",[["filter","language='" + language+"'"]])
		var data = await result.data
		this.setState({result:data});
	}

	//#region Form Validation
	
	//#endregion

	render() {
		const { errors, fields } = this.state;
		return (
			<div className='fullWidth' style={{position:"absolute", left:0, top:0, margin:0, padding:0, width:"93vw"}}>
				<div className='row'>
					<div className='col-md-12'>
						<div className="formcontainer" style={{marginTop:"30px"}}>
					<img style={{ objectFit: 'cover', width: '160px' }} src="/dist/img/logo.png" /><br/><br/>
						{this.state.result.length>0 && <div dangerouslySetInnerHTML={{__html: this.state.result[0].text.replace(/\n/gi,"<br/>")}}></div>
						 }
						</div>
					</div>
					
				</div>
			</div>
			
		)
	}
}
//#region Redux-Component Link
const mapDispachToProps = dispatch => {
	return {

		saveUserData: (data) => { G.log('dispatch', data); dispatch({ type: '', payLoad: data }) },

	};
};

const mapStateToProps = state => {
	return {
		userLogin: state.SaveLogin.userData
	};
};

//#endregion


export default connect(
	mapStateToProps,
	mapDispachToProps
)(Terms);
