import React, { Component } from 'react';
import { Container, Spinner, Badge, Button, Form, Col } from 'react-bootstrap'
import MessageBox from '../components/MessageBox';
import * as G from '../components/global'
import '../styles/glob.css'
import history from '../components/history';
import { translateScreen as ts , translateScreen2 as ts2 , translateDate as td} from '../components/dictionary'
import { connect } from 'react-redux';
import qs from 'qs'

import Select from 'react-select'
class GroupInfo extends Component {

	constructor(props) {
		super(props);

		this.state = {
				list:[],
				listloading:true,
				fields: {},
				modalShow: false,
				modalSubject: 'Delete Site',
				modalBody: 'Are you sure you want to delete this Site?',
				justinfo: true,
				errors: {},
				deleteID: -1,
                filteredCountryList: [],            
                CountryList: [],
                CountriesOptions:[],
		}
		this.handleChange = this.handleChange.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
	}
    getSnapshotBeforeUpdate(prevProps) {
        
        return { notifyRequired: prevProps.userLogin !== this.props.userLogin };
      }
      
      async componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot.notifyRequired) {
            if(this.props.userLogin.site)
		        this.loadData();
        }
        
      }
	componentDidMount(){
        var countriesOptions = [];
        G.country_list.map(el=>{
           countriesOptions.push({value:el, label:el});
        });
        this.setState({CountriesOptions: countriesOptions})
        if(this.props.userLogin.site)
		    this.loadData();
            const show = qs.parse(history.location.search, { ignoreQueryPrefix: true }).show;
            if(show == "1")
            {
                this.setState({modalShow:true});
            }
		
	}
	loadData = async ()=>{
		this.setState({listloading:true})
		var results = await G.getData("sitegroup/query",[["filter","id=" + this.props.userLogin.site.groupid]]);
		results = await results.data;
        var item = results[0];
		this.setState({deleteID:item.id, listloading:false, fields: { uid:item.uid, name:item.name,fees:item.fees,transactionalfees:item.transactionalfees,contactPerson:item.contactPerson,contactPersonNo:item.contactPersonNo,email:item.email,senderEmail:item.senderEmail,country:item.country,city:item.city,address:item.address, id:item.id, active:item.active, country:{value:item.country, label:item.country}, city:item.city, address:item.address, postalcode:item.postalcode, vatnumber:item.vatnumber, invoiceemail:item.invoiceemail }});
		
	}
	
	handleChange(e) {
       let { fields, errors } = this.state;
        fields[e.target.name] = e.target.value;
        delete errors[e.target.name];

        this.setState({ fields });
    }
    handleSelectChange(name, e){
		let { fields, errors } = this.state;
		fields[name] = e;
		delete errors[name];
		console.log("fields",fields);
		console.log("field e",e);
		this.setState({ fields });
	 }
	render() {
		const { errors, fields } = this.state;
        var clientuid = this.props.userLogin.site && this.props.userLogin.site.uid;
        //if (this.props.userLogin.language)language=this.props.userLogin.language;
		return (
			<>
			
            { !this.state.listloading && <>
                    <div className='content-header'>
                    <div className='container-fluid'>
                        <div className='row mb-2'>
                            <div className='col-sm-6'>
                        <h1>{ts2('Group Info',this.props.userLogin.language)}</h1>
                        </div>
                        </div>
                        </div>
                        </div>
                        <section className='content'>
                        <div className="row">
                        <Form style={{width:"100%"}}>
                            <div className='col-sm-12 col-md-8 '>
                                {G.renderTextField("uid", 'text', "uid will be generated Automatically", this.handleChange, fields, errors, null, '', true,this.props.userLogin.language)}
                                {G.renderTextField("Name", 'text', "Add the Group Name here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true,"name")}
                                {G.renderTextField("Contact Person", 'text', "Add the contact person here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true,"contactPerson")}
                                {G.renderTextField("Phone", 'tel', "Add the Phone number here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true,"contactPersonNo")}
                                {G.renderTextField("Email", 'email', "Add the email here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true,"email")}
                                {G.renderTextField("Sender Email", 'email', "Add the Sender email here", this.handleChange, fields, errors, null, '',true, this.props.userLogin.language,true,"senderEmail")}
                                <Form.Group>
                                    <Col>
                                <Form.Label htmlFor={"country"} >{ts("Country",this.props.userLogin.language)}</Form.Label>
                                <div className=''>
                <div className=''><Select id="country" value={this.state.fields["country"]}
            onChange={value =>{console.log(value);this.handleSelectChange("country",value);}}
    closeMenuOnSelect={true}
    options={this.state.CountriesOptions}
  /></div>
                {this.state.errors['country'] && <div className='remarks notValidColor'>{this.state.errors['country']}</div>}
            </div></Col></Form.Group>
                                {G.renderTextField("City", 'text', "Add the City here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true,"city")}
                    {G.renderTextField("Address", 'text', "Add the Address here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true,"address")}
                    {G.renderTextField("Postal Code", 'text', "Add postal code here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true,"postalcode")}
                    {G.renderTextField("VAT number", 'text', "Enter VAT number", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true,"vatnumber")}
                    {G.renderTextField("Email address where to send invoices", 'email', "Enter email address", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true,"invoiceemail")}
                            </div>
                        </Form>
                       </div><div className='row'>
                           <div className='col-md-12'>
                        <Button variant='success ml-3 mt-5' onClick={() => {   fields.id == null ? this.updateGroup(-1) : this.updateGroup(fields.id) }}>{ts2('Save',this.props.userLogin.language)}</Button>
                        
                   </div> </div>
                <div className='row'>
                
                </div>
                </section>
                <MessageBox
                    show={this.state.modalShow}
                    title={ts("Changes are saved",this.props.userLogin.language)}
                    body={<div><center><Button variant="success" onClick={()=>{this.setState({ modalShow: false }); history.push("/GroupInfo")}}>OK</Button></center></div>}
                    onHide={() => { this.setState({ modalShow: false }) }}

                />
                </>}
                {this.state.listloading && <div className='flexcolumn verticalcenter  text-center col-12' style={{ marginTop: '25%' }}>
                    <div className='flexrow center fullWidth '>
                        <Spinner animation="grow" className='m-3' variant="warning" />
                        <Spinner animation="grow" className='m-3' variant="info" />
                        <Spinner animation="grow" className='m-3' variant="danger" />

                    </div>
                    <div className='hint remarks xlowalpha'>{ts2('Loading Data',this.props.userLogin.language)}</div>
                </div>}
			</>
		)
	}
	updateGroup = async (recordID) => {
        console.log("recordID=", recordID)
        const { name,contactPerson,contactPersonNo,email,senderEmail, fees, transactionalfees, active, country, city, address, postalcode, vatnumber, invoiceemail } = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!name) { formIsValid = false; errors["name"] = "company Name cannot be empty"; }
        if (!contactPerson) { formIsValid = false; errors["contactPerson"] = "contact Person cannot be empty"; }
        if (!contactPersonNo) { formIsValid = false; errors["contactPersonNo"] = "Phone cannot be empty"; }
        if (!email) { formIsValid = false; errors["email"] = "Email be empty"; }
        if (!senderEmail) { formIsValid = false; errors["senderEmail"] = "Sender Email be empty"; }
        if (formIsValid) {
                var parameters = [['name', name],['fees', fees],['transactionalfees', transactionalfees],['active', active], ['contactPerson', contactPerson], ['contactPersonNo', contactPersonNo], ['email', email],['country', country.value],['city', city],['address', address], ['senderEmail', senderEmail], ['postalcode', postalcode], ['vatnumber', vatnumber], ['invoiceemail', invoiceemail],['id', recordID]]
            
           //parameters.push(['clientID', this.props.userLogin.site.id])
            let result = await G.getData('sitegroup/update', parameters)
            console.log(result)
            let success = (result.status === 200)
            if (success) {
                var logtxt = this.props.userLogin.username + " has modified the group info of " + name;
                await G.SaveUserLog(logtxt, this.props.userLogin.id);
                window.location.href="/GroupInfo?show=1"
                //this.loadData();
                //this.setState({modalShow:true})
                //alert(ts('Changes are saved',this.props.userLogin.language));
                //window.location.reload();
            }
        }
    }
	
}


const mapStateToProps = state => {
    return {
        userLogin: state.SaveLogin.userData
    };
};

export default connect(
    mapStateToProps
)(GroupInfo);