import React, { Component } from 'react';
import { Container, Spinner, Badge, Button, Form } from 'react-bootstrap'
import MessageBox from '../components/MessageBox';
import * as G from '../components/global'
import '../styles/glob.css'
import history from '../components/history';
import { translateScreen as ts , translateScreen2 as ts2 , translateDate as td} from '../components/dictionary'
import { connect } from 'react-redux';
import {DatePicker} from 'rsuite';
import qs from 'qs'
import dateFormat from 'dateformat';
class Badges extends Component {

	constructor(props) {
		super(props);

		this.state = {
				list:[],
				listloading:false,
				updateMode:false,
				fields: {},
				modalShow: false,
				modalSubject: 'Delete badges',
				modalBody: 'Are you sure you want to delete this badges',
				justinfo: true,
				errors: {},
				deleteID: -1,
                languagesList:[],
                imagelink:'',
                selectedFile:null,
                rules:[],
                storecategories:[],
                giftcategories:[],
                fieldchanging:false,
                successmodalShow:false
		}
		this.handleChange = this.handleChange.bind(this);
        this.imagechangeHandler = this.imagechangeHandler.bind(this);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
	}
    getSnapshotBeforeUpdate(prevProps) {
        
        return { notifyRequired: prevProps.userLogin !== this.props.userLogin };
      }
      
      async componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot.notifyRequired) {
            await this.loadData();
        }
        
      }
      
	componentDidMount(){
        
        this.getlanguages();
        if(this.props.userLogin.site)
		    this.loadData();
		history.listen((location, action) => {
            if(location.pathname == "/Badges" || location.pathname == "/Badges/")
            {
            this.setState({ updateMode: false });
            if(this.props.userLogin.site)
		    this.loadData();
            }
            if(location.pathname == "/Badges/create" || location.pathname == "/Badges/create/")
            this.setState({ updateMode: true, fields: {} });
          });
          if(history.location.pathname == "/Badges/create" || history.location.pathname == "/Badges/create/")
            this.setState({ updateMode: true, fields: {} });
	}
    getlanguages = async()=>{
		let array = [];
		array = await G.getData("languages/query",[["filter"," 1=1 order by orderindex"]]);
		array = await array.data;
		this.setState({languagesList: array});
	}
	loadData = async ()=>{
        
        var stores = await G.getData('storecategory/query',[]);
                stores = await stores.data
                this.setState({storecategories: stores})
var gifts = await G.getData('giftcategory/query',[["filter","a.siteid=" + this.props.userLogin.site.id]]);
                gifts = await gifts.data
                this.setState({giftcategories: gifts})
		this.setState({listloading:true})
		var results = await G.getData("badges/query",[["filter","siteid=" + this.props.userLogin.site.id]]);
		results = await results.data;
		this.setState({list:results});
		this.loadDataTables();        
        var qid = qs.parse(history.location.search, { ignoreQueryPrefix: true }).id
        results.map((el)=>{
            if(qid == el.id)
            {
                this.openEdit(el, false);
            }
        })
	}
	loadDataTables = ()=>{
		
        if(this.state.list)
        {
            this.setState({ listloading: false })
        setTimeout(() => {
            
                const script = document.createElement("script");
            script.id = "dttablestats";
            script.src = '/dist/js/dttable.js';
            script.async = true;
            document.body.appendChild(script);
            
            
        }, 300);
    }
	}
	handleChange(e) {
       let { fields, errors } = this.state;
        fields[e.target.name] = e.target.value;
        delete errors[e.target.name];

        this.setState({ fields });
    }
    onBlur(e){
        this.setState({fieldchanging:false});
    }
    handleLanguageChange(e) {
		let { fields, errors,languagesList } = this.state;
		 fields[e.target.name] = e.target.value;
		 delete errors[e.target.name];

         for(var i=0;i<languagesList.length;i++)
         {
             var elem = languagesList[i];
             
             if(e.target.name.includes('_name'))
             {
                if(!fields[elem.id + "_name"] || fields[elem.id + "_name"]=='' || this.state.fieldchanging)
                {
                fields[elem.id + "_name"] = e.target.value;
                delete errors[elem.id + "_name"];
                this.setState({fieldchanging:true});
                }
             }
             if(e.target.name.includes('_description'))
             {
                if(!fields[elem.id + "_description"] || fields[elem.id + "_description"]=='' || this.state.fieldchanging)
                {
                    fields[elem.id + "_description"] = e.target.value;
                    document.getElementById(elem.id + "_description").value = e.target.value;
                    delete errors[elem.id + "_description"];
                    this.setState({fieldchanging:true});
                }
             }
         }
		 this.setState({ fields });
	 }
    imagechangeHandler(event) {
        var file = event.target.files[0];
        let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({imagelink: e.target.result});
    };
    reader.readAsDataURL(file);
        
        this.setState({selectedFile:file});
	}
    openEdit = async(item, redirect = true)=>{
        let record = { ...item }
        //record.creationdate = dateFormat(item.creationDate, "dddd, mmm dd yyyy h:MM tt")
        var fields = this.state.fields;
        var dres = await G.getData("badgetranslation/query",[["filter","badgeid="+item.id]])
            dres = await dres.data
        dres.map((elem)=>{
            
            fields[elem.languageid + "_name"] = elem.name;
            fields[elem.languageid + "_description"] = elem.description;
            fields[elem.languageid + "_descriptiontwo"] = elem.descriptiontwo;
        })
        fields["id"] = item.id;
        fields["appprimarycolor"] = item.appprimarycolor;
        fields["appsecondarycolor"] = item.appsecondarycolor;
        fields["appbuttoncolor"] = item.appbuttoncolor;
        var dbrules = await G.getData('badgerules/query',[["filter","badgeid=" + item.id]])
        dbrules = await dbrules.data;
        dbrules.map(r=>{
            var parentrule = this.state.rules.filter(x=>x.dbid == r.parentid);
            this.state.rules.push({id:this.state.rules.length + 1, parentid:(parentrule!=null && parentrule!=undefined && parentrule.length>0?parentrule[0].id:r.parentid), criteria:r.criteria, rule:r.rule, numberofpoints:r.numberofpoints!=null?r.numberofpoints.toString():'', fromdate:r.fromdate!=null?r.fromdate.toString():'', todate:r.todate!=null?r.todate.toString():'',days:r.days!=null?r.days.toString():'', operand:r.operand,parentoperand:r.parentoperand, storecategoryid:r.storecategoryid!=null?r.storecategoryid.toString():'', giftcategoryid:r.giftcategoryid!=null?r.giftcategoryid.toString():'', dbid:r.id})
            this.setState({rules:this.state.rules})
        })
        this.setState({ updateMode: true, imagelink:G.nodeServerSite+"mediaFiles/badgesicon/" + item.icon, fields:fields, rules:this.state.rules });
        if(redirect == true)
            history.push("/Badges/edit?id=" + item.id)
    }
	render() {
		const { errors, fields } = this.state;
        var clientslug = this.props.userLogin.site && this.props.userLogin.site.slug;
        //if (this.props.userLogin.language)language=this.props.userLogin.language;
		return (
			<>
			{!this.state.updateMode && <div>
			<section className='content-header'>
                    <div className='container-fluid'>
                    <div className='row mb-2'>
                <div className='col-sm-6'>
                    <h1 className=''>{ts('Badges',this.props.userLogin.language)} </h1>
                    </div>
                    </div>
                    </div>
                    </section>
					<section className='content'>
                        <div className='row'>
                    <Button variant='success mt-3 mb-5 ml-3' onClick={() => { this.setState({ updateMode: true, fields: {} });history.push("/Badges/create") }}>{ts2('Create New badge',this.props.userLogin.language)}</Button>
                    </div>
                    <div className='row'>
                    <div className='col-md-12 '>
                        <div className="">
                        {!this.state.loadList && <table id="example1" className="table table-bordered">
                                <thead>
                                    <tr>
                                    <th>{ts2('Action',this.props.userLogin.language)}</th>
                                        <th>{ts2('Image',this.props.userLogin.language)}</th>
                                        <th>{ts2('Name',this.props.userLogin.language)}</th>
                                        

                                    </tr>
                                </thead>


                                {!this.state.listloading && <tbody>
                                    {
                                        (this.state.list).map((item) => {
                                            
                                            /*this.state.clientsList.filter(val => val.key == item.clientID).map(filteredName => (
                                                clientslug = filteredName.clientslug 
                                            ))*/
                                            return (
                                                <tr key={item.id}>
                                                    <td className='xxxtitle whiteColor handCursor'
                                                        >
                                                        <Button onClick={async() => {
                                                            this.openEdit(item);
                                                            
                                                        }} className="btn btn-primary" style={{width:"150px"}}>{ts2('View',this.props.userLogin.language)}</Button><br/>
														<Button onClick={() => {
                                                                
                                                                
                                                                    this.setState({
                                                                        modalShow: true,
                                                                        modalSubject: ts2('Delete badge',this.props.userLogin.language),
                                                                        modalBody: ts2('Are you sure you want to delete this badge?',this.props.userLogin.language),
                                                                        justinfo: false,
                                                                        deleteID: item.id
                                                                    })
                                                                

                                                        }} className="btn btn-danger" style={{width:"150px", marginTop:"10px"}}>{ts2('Delete',this.props.userLogin.language)}</Button>
                                                    </td>
													<td><img src={G.nodeServerSite+"mediaFiles/badgesicon/" + item.icon} style={{width:"100px", maxWidth:"100%"}}/></td>
                                                    <td>{item.name}</td>
                                                    
                                                 

                                                </tr>)
                                        })
                                    }

                                </tbody>}
                                
                            </table>}
                            {this.state.loadList &&
                                <div className='flexcolumn center verticalcenter p-5 fullWidth hint '>
                                   {ts2('Loading Badges',this.props.userLogin.language)} 
                                    <Spinner animation="border" className='mt-3' variant="warning" />

                                </div>
                            }
                        </div>
                    </div>
                    </div>
                    </section>
					</div>}
					{
                    this.state.updateMode && <>
                    
                        <section className='content'>
                        <div className='row'>
							<div className='col-md-12'>
							<Button className='mb-4 mt-5' variant='warning' onClick={() => { this.setState({updateMode:false, fields:{}, rules:[],selectedFile:null, imagelink:''});history.go(-1) }} >{ts('Cancel / Return', this.props.userLogin.language)}</Button>
							</div>
							<div className='col-md-12'>
							<h3>{ts('badges details',this.props.userLogin.language)}
                            </h3>
							</div>
							
							<div className='col-md-12' style={{height:"20px"}}></div>
							<div class="col-12 col-sm-6">
<div class="card card-primary card-outline card-tabs">
<div class="card-header p-0 pt-1 border-bottom-0">
<ul class="nav nav-tabs" id="custom-tabs-two-tab" role="tablist">
<li class="pt-2 px-3"><h6 class="card-title" style={{margin:"0px"}}>Entry by language</h6></li>
{
	this.state.languagesList.map((elem)=>{
		return <li class="nav-item">
<a class={elem.code == "fr"?"nav-link active":"nav-link"} id={"custom-tabs-two-" + elem.code + "-tab"} data-toggle="pill" href={"#custom-tabs-two-" + elem.code} role="tab" aria-controls={"custom-tabs-two-" + elem.code} aria-selected="true">{elem.code}</a>
</li>
	})
}
</ul>
</div>
<div class="card-body">
<div class="tab-content" id="custom-tabs-two-tabContent">
	{
		this.state.languagesList.map((elem)=>{
			return <div class={elem.code == "fr"?"tab-pane fade show active":"tab-pane fade"} id={"custom-tabs-two-" + elem.code} role="tabpanel" aria-labelledby={"custom-tabs-two-"+elem.code+"-tab"}>
				{G.renderTextField("Title", 'text', "enter badge title here", (elem.code == "fr"?this.handleLanguageChange:this.handleChange), fields, errors, null, '',false, this.props.userLogin.language, true,elem.id + "_name",this.onBlur)}
				{G.renderTextArea("Description", 'textarea', "enter badge description here", (elem.code == "fr"?this.handleLanguageChange:this.handleChange), fields, errors, null, '',false, this.props.userLogin.language, true,elem.id + "_description",this.onBlur)}
                {G.renderTextArea("Second Description", 'textarea', "enter badge second description here", (elem.code == "fr"?this.handleLanguageChange:this.handleChange), fields, errors, null, '',false, this.props.userLogin.language, true,elem.id + "_descriptiontwo",this.onBlur)}
			</div>
		})
	}

</div>
</div>

</div>
</div>
<div className='col-md-12' style={{height:"20px"}}></div>
<div class='col-md-4'>
{G.renderTextField("App primary color", 'color', "App primary color", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language, true,"appprimarycolor",this.onBlur)}
</div>
<div className='col-md-12' style={{height:"20px"}}></div>
<div class='col-md-4'>
{G.renderTextField("App secondary color", 'color', "App secondary color", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language, true,"appsecondarycolor",this.onBlur)}
</div>
<div className='col-md-12' style={{height:"20px"}}></div>
<div class='col-md-4'>
{G.renderTextField("App button color", 'color', "App button color", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language, true,"appbuttoncolor",this.onBlur)}
</div>
<div className='col-md-12' style={{height:"20px"}}></div>
<div class='col-md-4'>
<div class="form-group">
 <label for="exampleInputFile">Image</label>
<div class="input-group">
<div class="custom-file">
<input type="file" onChange={this.imagechangeHandler} class="custom-file-input" id="icon" name="icon"/>
<label class="custom-file-label" for="exampleInputFile">Choose file</label>
</div>
</div>





</div>
{this.state.imagelink && <img src={this.state.imagelink} style={{width:"150px"}}/>}
</div>
<div className='col-md-12' style={{height:"20px"}}></div>
<div className='col-md-6'>
    <label>Rules</label><br/>
    <Button variant='outline-primary' onClick={() => {var rules = this.state.rules;rules.push({id:rules.length + 1, parentid:null, criteria:'', rule:'', numberofpoints:'', fromdate:'', todate:'',days:'', operand:'', storecategoryid:'', giftcategoryid:'', dbid:-1, parentoperand:''}); this.setState({rules})  }} style={{width:"150px"}}>{ts2('+ Add rule',this.props.userLogin.language)}</Button>
</div><div className='col-md-12' style={{marginTop:"20px"}}>
{this.state.rules.filter(e=>e.parentid == null).map((e)=>{
    return( 
        <>
        {
            e.id !=1 && e.parentid == null && <div style={{marginTop:"50px",width:"100%"}}></div>
        }
    <table border="0">
        
        <tr>
            <td style={{verticalAlign:"bottom"}} colspan="2">
             Where</td><td style={{width:"10px"}}></td><td style={{verticalAlign:"bottom"}}>
                 <select style={{marginTop:"10px", minWidth:"200px"}} id={e.id + "_criteria"} name={e.id + "_criteria"} onChange={(event)=>{
            let{rules} = this.state;
        e.criteria = event.target.value;
        rules.map(r=>{
            if(r.id == e.id)
            {
                r.criteria = event.target.value; 
            }
        })
        this.setState({rules})
        }} className='custom-select'>
        <option value=''>choose criteria</option>
        {e.criteria=='number of points' && <option selected>number of points</option>}
        {e.criteria!='number of points' && <option>number of points</option>}
        {e.criteria=='number of scanned tickets' && <option selected>number of scanned tickets</option>}
        {e.criteria!='number of scanned tickets' && <option>number of scanned tickets</option>}
        {e.criteria=='number of redeemed gifts' && <option selected>number of redeemed gifts</option>}
        {e.criteria!='number of redeemed gifts' && <option>number of redeemed gifts</option>}
        </select></td>
        <td style={{width:"10px"}}></td>
        <td style={{verticalAlign:"middle", textAlign:"center"}}>
        {
            e.criteria!='' &&
            <span>is</span>
        }</td>
        <td style={{width:"10px"}}></td>
        <td style={{verticalAlign:"bottom"}}>
        {
            e.criteria!='' &&
            <select style={{marginTop:"10px", minWidth:"200px"}} id={e.id + "_rule"} name={e.id + "_name"} onChange={(event)=>{
                let{rules} = this.state;
            e.rule = event.target.value;
            rules.map(r=>{
                if(r.id == e.id)
                {
                    r.rule = event.target.value; 
                }
            })
            this.setState({rules})
            }} className='custom-select'>
                <option value=''>choose rule</option>
                {e.rule=='less than' && <option selected>less than</option>}
                {e.rule!='less than' && <option>less than</option>}
                {e.rule=='equal to' && <option selected>equal to</option>}
                {e.rule!='equal to' && <option>equal to</option>}
                {e.rule=='higher than' && <option selected>higher than</option>}
                {e.rule!='higher than' && <option>higher than</option>}
                </select>
        }</td>
        <td style={{width:"10px"}}></td>
        <td style={{verticalAlign:"bottom"}}>
        {
            e.rule!='' &&
            <input type="number" style={{minWidth:"200px"}} name={e.id+"_numberofpoints"} value={e.numberofpoints} onChange={(event)=>{
                let{rules} = this.state;
        e.numberofpoints = event.target.value;
        rules.map(r=>{
            if(r.id == e.id)
            {
                r.numberofpoints = event.target.value; 
            }})
            this.setState({rules})
            }} id={e.id + "_numberofpoints"} class="form-control"/>
        }</td>
        <td style={{width:"10px"}}></td>
        <td style={{verticalAlign:"bottom"}}>
        {
            e.rule!='' &&
            <select name={e.id+"_ruleoperand"} id={e.id+"_ruleoperand"} className='custom-select' style={{border:"1px solid #007bff", color:"#007bff",minWidth:"200px"}} onChange={(event)=>{
                var rules = this.state.rules;
                if(e.operand == '')
                {
                    rules.push({id:rules.length + 1, parentid:e.id, criteria:'', rule:'', numberofpoints:'', fromdate:'', todate:'',days:'', operand:'', storecategoryid:'', giftcategoryid:'', dbid:-1}); 
                }
                e.operand = event.target.value;
        rules.map(r=>{
            if(r.id == e.id)
            {
                r.operand = event.target.value; 
            }
        })
        this.setState({rules})
                
            }}>
                <option value=''>Add sub rule</option>
                {e.operand=='AND' && <option selected>AND</option>}
                {e.operand!='AND' && <option>AND</option>}
                {e.operand=='OR' && <option selected>OR</option>}
                {e.operand!='OR' && <option>OR</option>}
                </select>
        }</td>
        <td style={{width:"10px"}}></td>
        
            <td style={{verticalAlign:"bottom"}}><Button variant="outline-danger" onClick={async()=>{
                this.state.rules = this.state.rules.filter(x=>x.id != e.id);
                this.setState({rules:this.state.rules})
                if(e.dbid!=-1)
                {
                    await G.getData("badgerules/delete",[["filter","id=" + e.dbid]])
                }
            }}>X</Button></td>
        
        </tr>
        {this.state.rules.filter(c=>c.parentid == e.id).map(c=>{
            return(
            <tr>
            
                <td>
                 <div style={{width:"30px",height:"49px", borderLeft:"1px solid gray", borderBottom:"1px solid gray"}}></div></td>
            
            <td style={{verticalAlign:"bottom"}}>
             Where</td><td style={{width:"10px"}}></td><td style={{verticalAlign:"bottom"}}><select style={{marginTop:"10px",minWidth:"200px"}} id={c.id + "_criteria"} name={c.id + "_criteria"} onChange={(event)=>{
            let{rules} = this.state;
        c.criteria = event.target.value;
        rules.map(async(r)=>{
            if(r.id == c.id)
            {
                r.criteria = event.target.value; 
            }
            
        })
        this.setState({rules})
        }} className='custom-select'>
        <option value=''>choose criteria</option>
        {(e.criteria == "number of points" || e.criteria == "number of scanned tickets" || e.criteria == "number of redeemed gifts") &&
        <>
        {c.criteria=='rule is met during a period' && <option selected>rule is met during a period</option>}
        {c.criteria!='rule is met during a period' && <option>rule is met during a period</option>}
        {c.criteria=='rule is met between date range' && <option selected>rule is met between date range</option>}
        {c.criteria!='rule is met between date range' && <option>rule is met between date range</option>}
        
        </>
        }
        {e.criteria == "number of scanned tickets" &&
        <>
        {c.criteria=='store category is' && <option selected>store category is</option>}
        {c.criteria!='store category is' && <option>store category is</option>}
        </>
        }
        {e.criteria == "number of redeemed gifts" &&
        <>
        {c.criteria=='gift category is' && <option selected>gift category is</option>}
        {c.criteria!='gift category is' && <option>gift category is</option>}
        </>
        }
        </select></td>
        <td style={{width:"10px"}}></td>
        <td style={{verticalAlign:"middle", textAlign:"center"}}>
        {
            c.criteria=='rule is met during a period' &&
            <span>&nbsp;of last&nbsp;</span>
        }
        {
            c.criteria=='rule is met between date range' &&
            <span>&nbsp;from&nbsp;</span>
        }
        
        
        </td>
        <td style={{width:"10px"}}></td>
        <td style={{verticalAlign:"bottom"}}>
        {
            c.criteria=='rule is met during a period' &&
            <input style={{minWidth:"200px"}} type="number" value={c.days} name={c.id+"_days"} id={c.id + "_days"} onChange={(event)=>{
                let{rules} = this.state;
            c.days = event.target.value;
            rules.map(async(r)=>{
                if(r.id == c.id)
                {
                    r.days = event.target.value; 
                }
                
            })
            this.setState({rules})
            }} class="form-control"/>
        }
        {
            c.criteria=='rule is met between date range' && c.fromdate!='' &&
            <DatePicker oneTap={true} name="fromdate" defaultValue={new Date(c.fromdate)} onChange={(value)=>{
                var d = new Date(value)
		var dates = td(dateFormat(d,"yyyy-mm-dd"));
                let{rules} = this.state;
            c.fromdate = dates;
            rules.map(async(r)=>{
                if(r.id == c.id)
                {
                    r.fromdate = dates; 
                }
                
            })
            this.setState({rules})
            }} style={{minWidth:"200px"}}/>
        }
        {
            c.criteria=='rule is met between date range' && c.fromdate=='' &&
            <DatePicker oneTap={true} name="fromdate" style={{minWidth:"200px"}} onChange={(value)=>{
                var d = new Date(value)
		var dates = td(dateFormat(d,"yyyy-mm-dd"));
                let{rules} = this.state;
            c.fromdate = dates;
            rules.map(async(r)=>{
                if(r.id == c.id)
                {
                    r.fromdate = dates; 
                }
                
            })
            this.setState({rules})
            }}/>
        }
        {
            c.criteria == 'store category is' &&
            <select id={c.id + "_storecategoryid"} name={c.id + "_storecategoryid"} onChange={(event)=>{
                let{rules} = this.state;
            c.storecategoryid = event.target.value;
            rules.map(async(r)=>{
                if(r.id == c.id)
                {
                    r.storecategoryid = event.target.value; 
                }
                
            })
            this.setState({rules})
            }}  className="custom-select" style={{minWidth:"200px"}}>
                <option value="">choose store category</option>
                {this.state.storecategories.map(sc=>{
                    return (
                        <>
                        {c.storecategoryid == sc.id && <option selected value={sc.id}>{sc.name}</option>}
                        {c.storecategoryid != sc.id && <option value={sc.id}>{sc.name}</option>}
                    </>
                    )
                })}
            </select>
        }
        {
            c.criteria == 'gift category is' &&
            <select id={c.id + "_gifcategoryid"} name={c.id + "_giftcategoryid"} onChange={(event)=>{
                let{rules} = this.state;
            c.giftcategoryid = event.target.value;
            rules.map(async(r)=>{
                if(r.id == c.id)
                {
                    r.giftcategoryid = event.target.value; 
                }
                
            })
            this.setState({rules})
            }} className="custom-select" style={{minWidth:"200px"}}>
                <option value="">choose gift category</option>
                {this.state.giftcategories.map(gc=>{
                    return (
                        <>
                        {c.giftcategoryid == gc.id && <option selected value={gc.id}>{gc.name}</option>}
                        {c.giftcategoryid != gc.id && <option value={gc.id}>{gc.name}</option>}
                    </>
                    )
                })}
            </select>
        }
        </td>
        <td style={{verticalAlign:"middle", textAlign:"center"}}>
        {
            c.criteria=='rule is met during a period' &&
            <span>&nbsp;days&nbsp;</span>
        }
        {
            c.criteria=='rule is met between date range' &&
            <span>&nbsp;until&nbsp;</span>
        }
        </td>
        {
            c.criteria=='rule is met between date range' && c.todate!='' && <td style={{verticalAlign:"bottom"}}>
            <DatePicker oneTap={true} name="todate" defaultValue={new Date(c.todate)} style={{minWidth:"200px"}} onChange={(value)=>{
                var d = new Date(value)
		var dates = td(dateFormat(d,"yyyy-mm-dd"));
                let{rules} = this.state;
            c.todate = dates;
            rules.map(async(r)=>{
                if(r.id == c.id)
                {
                    r.todate = dates; 
                }
                
            })
            this.setState({rules})
            }}/>
        </td>}
        {
            c.criteria=='rule is met between date range' && c.todate=='' && <td style={{verticalAlign:"bottom"}}>
            <DatePicker oneTap={true} name="todate" style={{minWidth:"200px"}} onChange={(value)=>{
                var d = new Date(value)
		var dates = td(dateFormat(d,"yyyy-mm-dd"));
                let{rules} = this.state;
            c.todate = dates;
            rules.map(async(r)=>{
                if(r.id == c.id)
                {
                    r.todate = dates; 
                }
                
            })
            this.setState({rules})
            }}/>
        </td>}
        {(c.criteria=='rule is met during a period' ||c.criteria == 'store category is' || c.criteria == 'gift category is') && <td style={{width:"10px"}}></td>}
        <td style={{width:"10px"}}></td>
        
        <td style={{verticalAlign:"bottom"}}>
        {
            c.criteria!='' &&
            <select name={c.id+"_ruleoperand"} id={c.id+"_ruleoperand"} className='custom-select' style={{border:"1px solid #007bff", color:"#007bff",minWidth:"200px"}} onChange={(event)=>{
                var rules = this.state.rules;
                c.operand = event.target.value;
        rules.map(r=>{
            if(r.id == c.id)
            {
                r.operand = event.target.value; 
            }});
                rules.push({id:rules.length + 1, parentid:e.id, criteria:'', rule:'', numberofpoints:'', fromdate:'', todate:'',days:'', operand:'', storecategoryid:'', giftcategoryid:'', dbid:-1}); this.setState({rules})
            }}>
                <option value=''>Add sub rule</option>
                {
                    c.operand == "AND" && <option selected>AND</option>
                }
                {
                    c.operand != "AND" && <option>AND</option>
                }
                {
                    c.operand == "OR" && <option selected>OR</option>
                }
                {
                    c.operand != "OR" && <option>OR</option>
                }
                </select>
        }</td>
        <td style={{width:"10px"}}></td>
        
            <td style={{verticalAlign:"bottom"}}><Button variant="outline-danger" onClick={async()=>{
                this.state.rules = this.state.rules.filter(x=>x.id != c.id);
                this.setState({rules:this.state.rules})
                if(c.dbid!=-1)
                {
                    await G.getData("badgerules/delete",[["filter","id=" + c.dbid]])
                }
            }}>X</Button></td>
        
        </tr>)
        })}
        </table>
        <select name={e.id+"_ruleoperand"} id={e.id+"_ruleoperand"} className='custom-select' style={{border:"1px solid #007bff", color:"#007bff",width:"200px", maxWidth:"100%", marginTop:"50px"}} onChange={(event)=>{
                var rules = this.state.rules;
                e.parentoperand = event.target.value;
        rules.map(r=>{
            if(r.id == e.id)
            {
                r.parentoperand = event.target.value; 
            }
        })
                //rules.push({id:rules.length + 1, parentid:e.id, criteria:'', rule:'', numberofpoints:'', fromdate:'', todate:'',days:'', operand:'', storecategoryid:'', giftcategoryid:'', dbid:-1}); this.setState({rules})
            }}>
                <option value=''>Choose operand</option>
                {e.parentoperand=='AND' ? <option selected>AND</option>:<option>AND</option>}
                {e.parentoperand=='OR' ? <option selected>OR</option>:<option>OR</option>}
                </select>
        </>
    )
    })}
    </div>
</div>
                        <div className='row' style={{marginTop:"20px"}}>
                           <div className='col-md-12'>
                        <Button variant='success' onClick={() => {   fields.id==undefined || fields.id == null ? this.updatebadges(-1) : this.updatebadges(fields.id) }}>{ts2('Save',this.props.userLogin.language)}</Button>&nbsp;&nbsp;
                        <Button variant='warning' onClick={() => { this.setState({ updateMode: false, rules:[], imagelink:'', selectedFile:null });history.go(-1);
                        
                        this.loadDataTables();
                    }}>{ts('Cancel',this.props.userLogin.language)}</Button>
                   </div> </div>
                <div className='row'>
                
                </div>
                </section>
                </>
    }
	<MessageBox
                    show={this.state.modalShow}
                    title={this.state.modalSubject}
                    body={this.state.modalBody}
                    onHide={() => { this.setState({ modalShow: false }) }}
                    middle={!this.state.justinfo && {
                        action: () => {
                            this.deletebadges()


                        }, text: ts2('Delete badge',this.props.userLogin.language), variant: 'danger'
                    }}
                    right={{ action: () => { this.setState({ modalShow: false }) }, text: this.state.justinfo ? 'OK' : ts('Cancel',this.props.userLogin.language), variant: 'success' }}


                />
			<MessageBox
                    show={this.state.successmodalShow}
                    title={ts("Changes are saved",this.props.userLogin.language)}
                    body={<div><center><Button variant="success" onClick={()=>{this.setState({ successmodalShow: false });}}>OK</Button></center></div>}
                    onHide={() => { this.setState({ modalShow: false }) }}

                />
            </>
		)
	}
    
	updatebadges = async (recordID) => {
        let errors = {};
        let formIsValid = true;
        console.log("recordID=", recordID)
        let parameters = [];
        var rules = this.state.rules;
        this.state.languagesList.map((elem)=>{
            var name = this.state.fields[elem.id + "_name"]
            var description = this.state.fields[elem.id + "_description"]
            var descriptiontwo = this.state.fields[elem.id + "_descriptiontwo"]
            if(name == undefined || name == '')
            {
                formIsValid = false;
                errors[elem.id + '_name'] = 'This field is required';
            }
            if(description == undefined || description == '')
            {
                formIsValid = false;
                errors[elem.id + '_description'] = 'This field is required';
            }
            if(descriptiontwo == undefined || descriptiontwo == '')
            {
                formIsValid = false;
                errors[elem.id + '_descriptiontwo'] = 'This field is required';
            }
            if(elem.code == "en")
            {
                this.state.fields["name"] = name;
                this.state.fields["description"] = description;
                this.state.fields["descriptiontwo"] = descriptiontwo;
            }
            parameters.push(["name_" + elem.code,name]);
            parameters.push(["description_" + elem.code,description]);
            parameters.push(["descriptiontwo_" + elem.code,descriptiontwo]);
        });
        var srules = JSON.stringify(rules);
        
        var {name, description,descriptiontwo, appprimarycolor, appsecondarycolor, appbuttoncolor} = this.state.fields; 
        if (!name) { formIsValid = false; errors["name"] = "badges Name cannot be empty"; }
        this.setState({errors});
        if (formIsValid) {
            parameters.push(["siteid" ,this.props.userLogin.site.id]);
            parameters.push(["name" ,name]);
            parameters.push(['description' ,description]);
            parameters.push(['icon' ,this.state.selectedFile]);
            parameters.push(['badgeid' , recordID]);
            parameters.push(['rules',srules]);
            parameters.push(['descriptiontwo' ,descriptiontwo]);
            parameters.push(['appprimarycolor' ,appprimarycolor]);
            parameters.push(['appsecondarycolor' ,appsecondarycolor]);
            parameters.push(['appbuttoncolor' ,appbuttoncolor]);
            
           //parameters.push(['clientID', this.props.userLogin.site.id])
            let result = await G.uploadData('badges/upload', parameters)
            console.log(result)
            let success = (result.status === 200)
            if (success) {
                if(recordID === -1)
                {
                    var logtxt = this.props.userLogin.username + " has created a badge " + name;
                    await G.SaveUserLog(logtxt, this.props.userLogin.id);
                }
                else{
                    var logtxt = this.props.userLogin.username + " has edited the badge info of " + name;
                    await G.SaveUserLog(logtxt, this.props.userLogin.id);
                }
                this.setState({ updateMode: false, fields: {}, rules:[], successmodalShow:recordID!==-1 })
                this.loadData()
                history.push("/Badges")
            }
        }
    }
	deletebadges =async () => {
		let parameters = [['filter', "id=" + this.state.deleteID]]
		var result = await G.getData('badges/delete', parameters, true)
		result = await result.status;
		if (result === 200) {
            
			this.setState({ deleteID: -1, modalShow:false })
			//this.loadData()
			window.location.reload();
		}else{
			this.setState({
				modalShow: true,
				modalSubject: 'Error Appeared',
				modalBody: 'Problem Occured while hiding Record. Please Try Again',
				justinfo: true
			})
		}
	}
}


const mapStateToProps = state => {
    return {
        userLogin: state.SaveLogin.userData
    };
};

export default connect(
    mapStateToProps
)(Badges);