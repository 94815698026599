import React, { Component } from "react";
import { Form } from 'react-bootstrap';
import * as G from '../global'

class Autocomplete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: this.props.value
        };
    }
    /*onfocusout = e =>{
        console.log('sss', e.currentTarget.value)
    }*/
    onChange = e => {
        const { suggestions } = this.props;
        const userInput = e.currentTarget.value;

        const filteredSuggestions = suggestions.filter(
            suggestion =>
                suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );

        this.setState({
            activeSuggestion: 0,
            filteredSuggestions,
            showSuggestions: true,
            userInput: e.currentTarget.value
        });
        this.props.onChange(e)
    };
    onClick = e => {
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: e.currentTarget.innerText
        });
        this.props.updateFromList(e,this.props.name,e.currentTarget.innerText)
        if(this.props.reset){this.setState({userInput:''})}
    };
    onKeyDown = e => {
        const { activeSuggestion, filteredSuggestions } = this.state;
        if (e.keyCode === 13) {
            this.setState({
                activeSuggestion: 0,
                showSuggestions: false,
                userInput: filteredSuggestions[activeSuggestion]
            });
            e.preventDefault();
            this.props.updateFromList(e,this.props.name,filteredSuggestions[activeSuggestion])

            if(this.props.reset){this.setState({userInput:''})}
        } else if (e.keyCode === 38) {
            
            if (activeSuggestion === 0) {
                return;
            }
            this.setState({ activeSuggestion: activeSuggestion - 1 });
        }
        // User pressed the down arrow, increment the index
        else if (e.keyCode === 40) {
            
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }
            this.setState({ activeSuggestion: activeSuggestion + 1 });
        }
    };
    render() {
        const {
            onChange,
            onClick,
            onKeyDown,
            //onfocusout,
            state: {
                activeSuggestion,
                filteredSuggestions,
                showSuggestions,
                userInput
            }
        } = this;

        let suggestionsListComponent;
        if (showSuggestions && userInput) {
            if (filteredSuggestions.length) {
                suggestionsListComponent = (
                    <ul className="suggestions">
                        {filteredSuggestions.map((suggestion, index) => {
                            let className = "suggestion-noneactive";

                            // Flag the active suggestion with a class
                            if (index === activeSuggestion) {
                                className = "suggestion-active";
                            }
                            return (
                                <div className={className} key={suggestion} onClick={onClick}>
                                    {suggestion}
                                </div>
                            );
                        })}
                    </ul>
                );
            } else {
                suggestionsListComponent = (
                    <div className='remarks notValidColor'>No Suggenstions </div>
                );
            }
            {}
            
        }
        return (
            <div className={this.props.classNames}>
                <Form.Control
                    type="text"
                    onChange={onChange}
                    /*onfocusout = {onfocusout}*/
                    onKeyDown={onKeyDown}
                    value={userInput}
                    name={this.props.name}
                    className={(this.props.showborders?'':'border-top-0 border-right-0 border-left-0 border-light rounded-5') + '  p-1 rounded-0  fullWidth '}
                    style={(!filteredSuggestions.length && this.state.userInput!=='' && activeSuggestion!==0) || 
                    (this.state.userInput!=='' && this.props.suggestions.filter(value=>value===this.state.userInput).length===0)
                    || (this.props.error)
                    ? { borderColor: G.errorColor } : {}} 
                    placeholder={this.props.placeholder}
                />
                <div className='remarks notValidColor'>{this.props.error} </div>
                {suggestionsListComponent}
            </div>
        );
    }
}

export default Autocomplete;