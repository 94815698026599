import React, { Component } from 'react';
import { Container, Spinner, Badge, Button, Form } from 'react-bootstrap'
import MessageBox from '../components/MessageBox';
import * as G from '../components/global'
import '../styles/glob.css'
import history from '../components/history';
import { translateScreen as ts , translateScreen2 as ts2 , translateDate as td} from '../components/dictionary'
import { connect } from 'react-redux';
import qs from 'qs'

class SiteInfo extends Component {

	constructor(props) {
		super(props);

		this.state = {
				list:[],
				listloading:true,
				fields: {},
				modalShow: false,
				modalSubject: 'Delete Site',
				modalBody: 'Are you sure you want to delete this Site?',
				justinfo: true,
				errors: {},
				deleteID: -1,
                siteaddresses:[],
                imagelink:'',
                selectedFile:null,
		}
		this.handleChange = this.handleChange.bind(this);
        this.handlecombinationChange = this.handlecombinationChange.bind(this);       
        this.imagechangeHandler = this.imagechangeHandler.bind(this);
	}
    imagechangeHandler(event) {
        var file = event.target.files[0];
        let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({imagelink: e.target.result});
    };
    reader.readAsDataURL(file);
        
        this.setState({selectedFile:file});
	}
    getSnapshotBeforeUpdate(prevProps) {
        
        return { notifyRequired: prevProps.userLogin !== this.props.userLogin };
      }
      
      async componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot.notifyRequired) {
            if(this.props.userLogin.site)
		        this.loadData();
        }
        
      }
	componentDidMount(){
        if(this.props.userLogin.site)
		    this.loadData();
            const show = qs.parse(history.location.search, { ignoreQueryPrefix: true }).show;
            if(show == "1")
            {
                this.setState({modalShow:true});
            }
	}
	loadData = async ()=>{
        var addresses = await G.getData("clientaddress/query",[["filter","clientID=" + this.props.userLogin.site.id]]);
        addresses = await addresses.data;
		this.setState({listloading:true})
		var results = await G.getData("client/query",[["filter","id=" + this.props.userLogin.site.id]]);
		results = await results.data;
        var item = results[0];
		this.setState({deleteID:item.id,imagelink:G.nodeServerSite+"mediaFiles/sitelogo/" + item.applogo, listloading:false, fields: { slug:item.slug, companyName:item.companyName,contactPerson:item.contactPerson,contactPersonNo:item.contactPersonNo,email:item.email,senderEmail:item.senderEmail,country:item.country,city:item.city,address:item.address, id:item.id,website:item.website,facebook:item.facebook,instagram:item.instagram,twitter:item.twitter,linkedin:item.linkedin,pinterest:item.pinterest,tiktok:item.tiktok }});
		addresses.map((el,index)=>{
            this.state.fields["address_" + index] = el.address;
        });
        this.setState({fields:this.state.fields, siteaddresses:addresses})
	}
	handlecombinationChange(e) {
        let { fields, errors, siteaddresses } = this.state;
         fields[e.target.name] = e.target.value;
         delete errors[e.target.name];
        siteaddresses.map((el,index)=>{
            el.address = fields["address_" + index];
        })
         this.setState({ fields, siteaddresses });
         console.log("Addresses", siteaddresses)
     }
	handleChange(e) {
       let { fields, errors } = this.state;
        fields[e.target.name] = e.target.value;
        delete errors[e.target.name];

        this.setState({ fields });
    }
	render() {
		const { errors, fields } = this.state;
        var clientslug = this.props.userLogin.site && this.props.userLogin.site.slug;
        //if (this.props.userLogin.language)language=this.props.userLogin.language;
		return (
            
			<>
			
            { !this.state.listloading && <>
                    <div className='content-header'>
                    <div className='container-fluid'>
                        <div className='row mb-2'>
                            <div className='col-sm-6'>
                        <h1>{ts2('Site Info',this.props.userLogin.language)}</h1>
                        </div>
                        </div>
                        </div>
                        </div>
                        <section className='content'>
                        <div className="row">
                        <Form style={{width:"100%"}}>
                            <div className='col-sm-12 col-md-8 '>
                                {G.renderTextField("UID", 'text', "UID will be generated Automatically", this.handleChange, fields, errors, null, '', true,this.props.userLogin.language,true,"slug")}
                                {G.renderTextField("Company name", 'text', "Add the Company Name here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true,"companyName")}
                                {G.renderTextField("Contact Person", 'text', "Add the contact person here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true,"contactPerson")}
                                {G.renderTextField("Phone", 'tel', "Add the Phone number here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true,"contactPersonNo")}
                                {G.renderTextField("Email", 'email', "Add the email here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true,"email")}
                                {G.renderTextField("Sender Email", 'email', "Add the Sender email here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true,"senderEmail")}
                                {G.renderTextField("Country", 'text', "Add the Country here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true,"country")}
                                {G.renderTextField("City", 'text', "Add the City here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true,"city")}
                    {G.renderTextField("Address", 'text', "Add the Address here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true,"address")}
                    {G.renderTextField("Website", 'text', "Add Wbsite here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true)}
                    {G.renderTextField("Facebook", 'text', "Add Facebook here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true)}
                    {G.renderTextField("Instagram", 'text', "Add Instagram here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true)}
                    {G.renderTextField("Twitter", 'text', "Add Twitter here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true)}
                    {G.renderTextField("LinkedIn", 'text', "Add LinkedIn here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true)}
                    {G.renderTextField("Pinterest", 'text', "Add Pinterest here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true)}
                    {G.renderTextField("TikTok", 'text', "Add TikTok here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true)}
                    <div class='col-md-12'>
<div class="form-group">
 <label for="exampleInputFile">App logo</label>
<div class="input-group">
<div class="custom-file">
<input type="file" onChange={this.imagechangeHandler} class="custom-file-input" id="logo" name="logo"/>
<label class="custom-file-label" for="exampleInputFile">Choose file</label>
</div>
</div>





</div>
{this.state.imagelink && <img src={this.state.imagelink} style={{width:"150px"}}/>}
</div>
                    {this.state.fields.id && <><h5>{ts("Address combinations", this.props.userLogin.language)} <a className="btn btn-primary" onClick={()=>{this.state.siteaddresses.push({id:-1,clientID:this.state.fields.id,address:''});this.setState({siteaddresses:this.state.siteaddresses});}}>{ts("Add", this.props.userLogin.language)}</a></h5>
                            {
                                
                            this.state.siteaddresses.map((el, index)=>{
                                return(
                                G.renderTextField("Address " + (index+1), 'text', "Add the Address here", this.handlecombinationChange, fields, errors, null, '',false, this.props.userLogin.language,true,"address_" + index)
                                );
                            }) }</>}
                            </div>
                        </Form>
                       </div><div className='row'>
                           <div className='col-md-12'>
                        <Button variant='success ml-3 mt-5' onClick={() => {   fields.id == null ? this.updateSite(-1) : this.updateSite(fields.id) }}>{ts2('Save',this.props.userLogin.language)}</Button>
                        
                   </div> </div>
                <div className='row'>
                
                </div>
                </section>
                <MessageBox
                    show={this.state.modalShow}
                    title={ts("Changes are saved",this.props.userLogin.language)}
                    body={<div><center><Button variant="success" onClick={()=>{this.setState({ modalShow: false }); history.push("/SiteInfo")}}>OK</Button></center></div>}
                    onHide={() => { this.setState({ modalShow: false }) }}

                />
               </>}
               {this.state.listloading && <div className='flexcolumn verticalcenter  text-center col-12' style={{ marginTop: '25%' }}>
                    <div className='flexrow center fullWidth '>
                        <Spinner animation="grow" className='m-3' variant="warning" />
                        <Spinner animation="grow" className='m-3' variant="info" />
                        <Spinner animation="grow" className='m-3' variant="danger" />

                    </div>
                    <div className='hint remarks xlowalpha'>{ts2('Loading Data',this.props.userLogin.language)}</div>
                </div>}
			</>
            
		)
	}
	updateSite = async (recordID) => {
        console.log("recordID=", recordID)
        const { companyName,contactPerson,contactPersonNo,email,senderEmail,country,city,address,website,facebook,instagram,twitter,linkedin,pinterest,tiktok } = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!companyName) { formIsValid = false; errors["companyName"] = "company Name cannot be empty"; }
        if (!contactPerson) { formIsValid = false; errors["contactPerson"] = "contact Person cannot be empty"; }
        if (!contactPersonNo) { formIsValid = false; errors["contactPersonNo"] = "Phone cannot be empty"; }
        if (!email) { formIsValid = false; errors["email"] = "Email be empty"; }
        if (!senderEmail) { formIsValid = false; errors["senderEmail"] = "Sender Email be empty"; }
        if (formIsValid) {
            let parameters = [['companyName', companyName], ['contactPerson', contactPerson], ['contactPersonNo', contactPersonNo], ['email', email], ['senderEmail', senderEmail],['addDate', "now()"],['groupid', this.props.userLogin.site.groupid],['country', country],['city', city],['address', address],['website', website],['facebook', facebook],['instagram', instagram],['twitter', twitter],['linkedin', linkedin],['pinterest', pinterest],['tiktok', tiktok],['applogo' ,this.state.selectedFile]]
            if (recordID !== -1){
                parameters = [['companyName', companyName], ['contactPerson', contactPerson], ['contactPersonNo', contactPersonNo], ['email', email], ['senderEmail', senderEmail],['groupid', this.props.userLogin.site.groupid],['country', country],['city', city],['address', address],['website', website],['facebook', facebook],['instagram', instagram],['twitter', twitter],['linkedin', linkedin],['pinterest', pinterest],['tiktok', tiktok],['applogo' ,this.state.selectedFile],['id', recordID]]
            }
           //parameters.push(['clientID', this.props.userLogin.site.id])
            let result = await G.getData('client/' + (recordID === -1 ? 'insert' : 'update'), parameters)
            console.log(result)
            let success = (result.status === 200)
            if (success) {
                var logtxt = this.props.userLogin.username + " has modified the site info of " + companyName;
                await G.SaveUserLog(logtxt, this.props.userLogin.id);
                this.setState({ updateMode: false, fields: {} })
                for(var i=0;i<this.state.siteaddresses.length;i++)
                    {
                        var siteaddress = this.state.siteaddresses[i];
                        if(siteaddress.id ===-1)
                        {
                            await G.uploadData("clientaddress/insert",[["clientID",recordID],["address", siteaddress.address]]);
                        }
                        else{
                            await G.uploadData("clientaddress/update",[["clientID",recordID],["address", siteaddress.address],["id", siteaddress.id]]);
                        }
                    }
                //this.loadData()
                //history.push("/SiteInfo")
                var sitestring = localStorage.getItem("site");  
        if(sitestring)
        {
          var site = JSON.parse(sitestring);
          if(site.id == recordID)
          {
            site.companyName = companyName;
            localStorage.setItem("site",JSON.stringify(site));
          }
        }
        window.location.href="/SiteInfo?show=1"
                //this.setState({modalShow:true})
            }
        }
    }
	disableSite =async () => {
		let parameters = [['id', this.state.deleteID]]
		var result = await G.getData('site/disable', parameters, true)
		result = await result.status;
		if (result === 200) {
			this.setState({ deleteID: -1, modalShow:false })
			//this.loadData()
			window.location.reload();
		}else{
			this.setState({
				modalShow: true,
				modalSubject: 'Error Appeared',
				modalBody: 'Problem Occured while hiding Record. Please Try Again',
				justinfo: true
			})
		}
	}
}


const mapStateToProps = state => {
    return {
        userLogin: state.SaveLogin.userData
    };
};

export default connect(
    mapStateToProps
)(SiteInfo);