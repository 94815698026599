import React, { Component } from 'react';
import { Container, Spinner, Badge, Button, Form } from 'react-bootstrap'
import MessageBox from '../components/MessageBox';
import * as G from '../components/global'
import '../styles/glob.css'
import history from '../components/history';
import { translateScreen as ts , translateScreen2 as ts2 , translateDate as td} from '../components/dictionary'
import { connect } from 'react-redux';
import dateFormat from 'dateformat';
class UserLogs extends Component {

	constructor(props) {
		super(props);

		this.state = {
				list:[],
				listloading:false
		}
	}
    
	componentDidMount(){
        if(this.props.userLogin.site)
		    this.loadData();
		
	}
    getSnapshotBeforeUpdate(prevProps) {
        
        return { notifyRequired: prevProps.userLogin !== this.props.userLogin };
      }
      
      async componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot.notifyRequired) {
            this.loadData();
        }
        
      }
	loadData = async ()=>{
		this.setState({listloading:true})
		var results = await G.getData("userlogs/query",[["filter","userid in (select id from user where clientID="+this.props.userLogin.site.id+" or accountType=0) order by datein desc"]]);
		results = await results.data;
		this.setState({list:results, listloading:false});
		this.loadDataTables();
	}
	loadDataTables = ()=>{
		
            try{
            if(document.getElementById("dttablestats")!=null)
            {
                document.getElementById("dttablestats").remove();
            }
        }
        catch(e){}
                const script = document.createElement("script");
            script.id = "dttablestats";
            script.src = '/dist/js/dttablewithoutsort.js';
            script.async = true;
            document.body.appendChild(script);
            
            this.setState({ listloading: false })
	}
	handleChange(e) {
       let { fields, errors } = this.state;
        fields[e.target.name] = e.target.value;
        delete errors[e.target.name];

        this.setState({ fields });
    }
	render() {
		const { errors, fields } = this.state;
        var clientslug = this.props.userLogin.site && this.props.userLogin.site.slug;
        //if (this.props.userLogin.language)language=this.props.userLogin.language;
		return (
			<>
			{!this.state.updateMode && <div>
			<section className='content-header'>
                    <div className='container-fluid'>
                    <div className='row mb-2'>
                <div className='col-sm-6'>
                    <h1 className=''>{ts('User Logs',this.props.userLogin.language)} </h1>
                    </div>
                    </div>
                    </div>
                    </section>
					<section className='content'>
                        <div className='row'>
                    
                    </div>
                    <div className='row'>
                    <div className='col-md-12 '>
                        <div className="">
                        {!this.state.loadList && <table id="example1" className="table table-bordered">
                                <thead>
                                    <tr>
                                    <th>{ts2('Date',this.props.userLogin.language)}</th>
                                        <th>{ts2('Logs',this.props.userLogin.language)}</th>
                                        
                                        

                                    </tr>
                                </thead>


                                <tbody>
                                    {
                                        (this.state.list).map((item) => {
                                            
                                            /*this.state.clientsList.filter(val => val.key == item.clientID).map(filteredName => (
                                                clientslug = filteredName.clientslug 
                                            ))*/
                                            return (
                                                <tr key={item.id}>
                                                    <td data-sort={item.datein}>{td(dateFormat(item.datein,"mmm dd, yyyy hh:mm tt"), this.props.userLogin.language)}</td>
													<td>{item.logtxt}</td>
                                                    
                                                    
                                                 

                                                </tr>)
                                        })
                                    }

                                </tbody>
                                
                            </table>}
                            {this.state.loadList &&
                                <div className='flexcolumn center verticalcenter p-5 fullWidth hint '>
                                   {ts2('Loading Logs',this.props.userLogin.language)} 
                                    <Spinner animation="border" className='mt-3' variant="warning" />

                                </div>
                            }
                        </div>
                    </div>
                    </div>
                    </section>
					</div>}
					
	
			</>
		)
	}
	
}


const mapStateToProps = state => {
    return {
        userLogin: state.SaveLogin.userData
    };
};

export default connect(
    mapStateToProps
)(UserLogs);