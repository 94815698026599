import React, { Component } from 'react';
import { Button, Container, Form, Col, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import '../styles/glob.css'
import * as G from '../components/global';
//import { country_list as CTRY } from '../components/constants';
import { connect } from 'react-redux';
import Select from 'react-select'
import { translateScreen as ts , translateScreen2 as ts2 , translateDate as td} from '../components/dictionary'
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

class regitrationRequest extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {},
            errors: {},
            result: [],
            filteredCountryList: [],            
            CountryList: [],
            sent: false,
            submitError: '',
            loadingSave: false,
            CountriesOptions:[],
            language: 0
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
    }
componentDidMount(){
    var countriesOptions = [];
     G.country_list.map(el=>{
        countriesOptions.push({value:el, label:el});
     });
     this.setState({CountriesOptions: countriesOptions})
    if (sessionStorage.getItem("language")=='1')this.setState({language:1})
}
    //#region Form Validation
    handleChange(e) {
        let { fields, errors } = this.state;
        fields[e.target.name] = e.target.value;
        delete errors[e.target.name];
        this.setState({ fields });
    }
    handleCheckboxChange(e) {
        let { fields, errors } = this.state;
        fields[e.target.name] = e.target.checked == true?"on":undefined;
        delete errors[e.target.name];
        this.setState({ fields });
    }
    handleSelectChange(name, e){
		let { fields, errors } = this.state;
		fields[name] = e;
		delete errors[name];
		console.log("fields",fields);
		console.log("field e",e);
		this.setState({ fields });
	 }
    handleValidation = async () => {
        const { email, companyname, firstname, lastname, phonenumber, accepted, countries, city, vatnumber, invoiceemail, postalcode, address } = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (!email) { formIsValid = false; errors["email"] =  ts("This field is required",this.state.language); } else {
            if (!G.isValideEmail(email)) { errors["email"] =  ts('This is not a valid Email',this.state.language); }
        }
        if (!companyname) { formIsValid = false; errors["companyname"] =  ts("This field is required",this.state.language); }
        if (!phonenumber) { formIsValid = false; errors["phonenumber"] =  ts("This field is required",this.state.language); }
        if (!firstname) { formIsValid = false; errors["firstname"] =  ts("This field is required",this.state.language); }
        if (!lastname) { formIsValid = false; errors["lastname"] =  ts("This field is required",this.state.language); }
        if (!accepted) { formIsValid = false; errors["accepted"] = ts('Please accept terms and conditions', this.state.language); }
        if (!countries) { formIsValid = false; errors["countries"] =  ts("This field is required",this.state.language); }
        if (!city) { formIsValid = false; errors["city"] =  ts("This field is required",this.state.language); }
        if (!vatnumber) { formIsValid = false; errors["vatnumber"] =  ts("This field is required",this.state.language); }
        if (!invoiceemail) { formIsValid = false; errors["invoiceemail"] =  ts("This field is required",this.state.language); }
        if (!postalcode) { formIsValid = false; errors["postalcode"] =  ts("This field is required",this.state.language); }
        if (!address) { formIsValid = false; errors["address"] =  ts("This field is required",this.state.language); }
        this.setState({ errors: errors });
        if (formIsValid) {
            G.log('Form is valid. pushing now values...')
            var result = await G.getData('registrationRequet',
                [
                    ['companyname', this.state.fields['companyname']],
                    ['contactperson', firstname + " " + lastname],
                    ['phonenumber', this.state.fields['phonenumber']],
                    ['email', this.state.fields['email']],
                    ['log', ''],
                    ['country',this.state.fields['countries'].value],
                    ['city',this.state.fields['city']],
                    ['address', this.state.fields['address']],
                    ['postalcode', this.state.fields['postalcode']],
                    ['vatnumber',this.state.fields['vatnumber']],
                    ['invoiceemail',this.state.fields['invoiceemail']]
                ]

            )
            return (result.status === 200)
        }
        else {
            return formIsValid;
        }
    }
    submitForm = async (e) => {
        e.preventDefault();

        this.setState({ submitError: '', loadingSave: true })
        var a = await this.handleValidation();
        if (a === true) {

            this.setState({ sent: true })
        } else { this.setState({ submitError: '' }) }
        this.setState({ loadingSave: false })
    }
    //#endregion

    render() {
        const { errors, fields } = this.state;
        return (
            <div className='fullWidth' style={{position:"absolute", left:0, top:0, margin:0, padding:0, width:"93vw"}}>
				<div className='row'>
					<div className='col-md-6'  style={{zIndex:"999999"}}>
					<div className="formcontainer">
							<img style={{ objectFit: 'cover', width: '160px' }} src="/dist/img/logo.png" />
							
							<div style={{width:'100%',  textAlign:'left', marginBottom: 10, marginTop:"30px"}}><p style={{color:"#24A2B6", fontSize:"30px", fontWeight:"700"}}>{ts2('Register',this.state.language)}</p></div>
						</div>
                        <div className='flexrow verticalcenter handCursor ' style={{marginTop:"50px", marginBottom:"50px", position:"absolute", right:"0px", top:"25px", width:"auto"}}>
							<img width={20} height={20} src={this.state.language  === 0 ? "/dist/img/france.png" : "/dist/img/english.png"} style={{marginTop:"-5px"}} />
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="button-tooltip" > {this.state.language === 0 ? 'Cliquez pour passer en français' : 'Click to switch to English'}</Tooltip>}
                            >
                                <div className=' ml-2 remarks hint' onClick={() => {
                                    this.setState({ language: this.state.language === 0 ? 1 : 0 },()=>{sessionStorage.setItem('language',this.state.language)});
                                    
                                }}>{this.state.language !== 0 ? 'English' : 'Francais'} </div>
                            </OverlayTrigger>
							</div>
					{!this.state.sent &&
                            
                            <Form name="form" className="formcontainer" style={{marginTop:"0px"}} onSubmit={this.submitForm.bind(this)} >
                                <Form.Group>
                                    <Col>
                                    <p style={{fontSize:"20px", color:"#24A2B6", marginTop:"20px", marginBottom:"20px"}}>{ts("Information on the company to be invoiced",this.state.language)}</p>
                                    </Col>
                                </Form.Group>
                                <Form.Group>
                                    <Col>
                                        {G.renderTextField("Company Name", 'text', ts2("Enter your company name",this.state.language), this.handleChange, fields, errors, '','',false,this.state.language)}
                                    </Col>

                                </Form.Group>
                                <Form.Group>
                                    <Col>
                                    <Form.Group className={'fullWidth '}>
            <Form.Label htmlFor={"countries"} >{ts("Countries",this.state.language)}</Form.Label>

            <div className=''>
                <div className=''><Select id="countries"
            onChange={value =>{this.handleSelectChange("countries",value)}}
    closeMenuOnSelect={true}
    options={this.state.CountriesOptions}
    menuPortalTarget={document.body} 
    styles={{ menuPortal: base => ({ ...base, zIndex: 9999999 }), control: provided=>({...provided, color:"#CFD5DE"}), option: provided => ({...provided, color: '#9399A1'}), singleValue: (provided) => ({...provided,color: '#9399A1'})}}/></div>
                {this.state.errors['countries'] && <div className='remarks notValidColor'>{this.state.errors['countries']}</div>}
            </div>
        </Form.Group>
                                    </Col>
                                </Form.Group>
                                <Form.Group>
                                    <Col>
                                        {G.renderTextField("City", 'text', ts2("Enter city",this.state.language), this.handleChange, fields, errors, '','',false,this.state.language)}
                                    </Col>

                                </Form.Group>
                                <Form.Group>
                                    <Col>
                                        {G.renderTextField("Street number and name", 'text', ts2("Enter street number and name",this.state.language), this.handleChange, fields, errors, '','',false,this.state.language, true, "address")}
                                    </Col>

                                </Form.Group>
                                <Form.Group>
                                    <Col>
                                        {G.renderTextField("Postal code", 'text', ts2("Enter postal code",this.state.language), this.handleChange, fields, errors, '','',false,this.state.language, true, "postalcode")}
                                    </Col>

                                </Form.Group>
                                <Form.Group>
                                    <Col>
                                    <Form.Group>
                                    <Form.Label htmlFor={"phonenumber"} >{ts("Company phone number",this.state.language)}</Form.Label>
                                    <IntlTelInput id="phonenumber" name="phonenumber" preferredCountries={['be']} separateDialCode={false} nationalMode={false}
      onPhoneNumberBlur={(e, value)=>{
        
        let { fields, errors } = this.state;
        fields["phonenumber"] = value;
        delete errors["phonenumber"];
        this.setState({ fields });
      }}
  containerClassName="intl-tel-input"
  inputClassName="form-control" style={{width:"100%"}}
/>
{this.state.errors['phonenumber'] && <div className='remarks notValidColor'>{this.state.errors['phonenumber']}</div>}
                                        {/*G.renderTextField("Phone Number", 'text', ts2("Enter your mobile/Phone number",this.state.language), this.handleChange, fields, errors, '','',false,this.state.language)*/}
                                        </Form.Group>
                                    </Col>
                                    </Form.Group>
                                    <Form.Group>
                                    <Col>
                                        {G.renderTextField("VAT number", 'text', ts2("Enter VAT number",this.state.language), this.handleChange, fields, errors, '','',false,this.state.language)}
                                    </Col>
                                </Form.Group>
                                <Form.Group>
                                    <Col>
                                        {G.renderTextField("Email address where to send invoices", 'text', ts2("Enter email address",this.state.language), this.handleChange, fields, errors, '','',false,this.state.language, true, "invoiceemail")}
                                    </Col>
                                </Form.Group>
                                <Form.Group>
                                    <Col>
                                    <p style={{fontSize:"20px", color:"#24A2B6", marginTop:"20px", marginBottom:"20px"}}>{ts("Information of contact person",this.state.language)}</p>
                                    </Col>
                                </Form.Group>
                                <Form.Group>
                                    <Col>
                                        {G.renderTextField("First Name", 'text', ts2("Enter first name",this.state.language), this.handleChange, fields, errors, '','',false,this.state.language)}
                                    </Col>
                                </Form.Group>
                                <Form.Group>
                                    <Col>
                                        {G.renderTextField("Last Name", 'text', ts2("Enter last name",this.state.language), this.handleChange, fields, errors, '','',false,this.state.language)}
                                    </Col>
                                </Form.Group>
                                <Form.Group>
                                    <Col>
                                        {G.renderTextField("Email address", 'email', ts2("Enter email address",this.state.language), this.handleChange, fields, errors, '','',false,this.state.language, true,"email")}
                                    </Col>
                                </Form.Group>
                                <Form.Group>
                                    <Col>
                                    <div class="form-check">
                                    
                                        <input type="checkbox" class="form-check-input" onChange={this.handleCheckboxChange} id="accepted" name="accepted"/>
                                    
                
                <label class="form-check-label" for="accepted">{ts("I've read and accepted the",this.state.language)} <a href={"/terms?language=" + (this.state.language==0?"en":"fr")} target="_blank">{ts("terms & conditions",this.state.language)}</a></label>
                </div>
                {this.state.errors['accepted'] && <div className='remarks notValidColor'>{this.state.errors['accepted']}</div>}
                                    </Col>
                                </Form.Group>

                                <div className='flexrow center fullWidth text-right mt-4 mb-3 wrap '>

                                    <Button variant='success' className='cprim ' type="submit" style={{width:"100%", backgroundColor:"#24A2B6", border:"1px solid #24A2B6"}}> {this.state.loadingSave && <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />}{ts2(this.state.submitError === '' ? 'Send Registration' : 'Retry', this.state.language)}</Button>
                                    <div style={{width:"100%", textAlign:"center", marginTop:"10px"}}>{ts("Already have an account?", this.state.language)} <a onClick={() => { this.props.history.push(G.websiteRoute.signIN) }} className='handCursor' style={{color:"#1A73F9"}}>{ts("Login now", this.state.language)}</a></div>
                                    
                                </div>
                                <div className='flexrow center warningColor remarks text-center mb-3'>{this.state.submitError}</div>

                                

                            </Form>
                        }
						{this.state.sent &&
                            <div className="formcontainer" style={{marginTop:"0px"}}>
                                <div>{ts2('Thank you for registering in our platform. The registration request has been sent.',this.state.language)}</div>
                                <div>{ts2('We will contact you soon. Usually within 4 hours',this.state.language)}</div>
                                <Button variant='success' style={{width:"100%", backgroundColor:"#24A2B6", border:"1px solid #24A2B6", marginTop:"20px"}} onClick={() => { this.props.history.push(G.websiteRoute.signIN); }}>{ts2('Quit',this.state.language)}</Button>
                            </div>}
					</div>
					<div className='col-md-6 d-none d-md-block' style={{position:"fixed",right:"0px",top:"0px",padding:"0px"}}><img src="/dist/img/loginback.png" style={{float:"right"}}/></div>
				</div>
			</div>
        )
    }
}
//#region Redux-Component Link
const mapDispachToProps = dispatch => {
    return {

        saveUserData: (data) => { G.log('dispatch', data); dispatch({ type: '', payLoad: data }) },

    };
};

const mapStateToProps = state => {
    return {
        userLogin: state.SaveLogin,
        siteid:state.SiteId.siteid
    };
};

//#endregion


export default connect(
    mapStateToProps,
    mapDispachToProps
)(regitrationRequest);
