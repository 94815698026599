import React, { Component } from 'react';
import './App.css';
import { Router, Route, Switch } from 'react-router-dom';

import './styles/glob.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import SignInForm from './pages/SignInForm';
import DashboardContent from './pages/dashboardContent';
import MainContent from './pages/mainContent';
import RegistrationRequest from './pages/regitrationRequest';
import ManageUserRegistration from './pages/ManageUserRegistration';
import StartPage from './pages/StartPage';
import CampaignsTemplates from './pages/CampaignsTemplate';
import UserManagement from './pages/userManagement';

import AdminUsersManagement from './pages/AdminUsersManagement';
import ProfileManagement from './pages/profile'
import CampaignEditor from './pages/campaignEditor';
import CustomerCampaigns from './pages/CustomerCampaigns';
import LogHistory from './pages/logHistory';

import NotFound from './pages/NotFound';
import ImportProfiles from './pages/importProfiles';
import Header from './components/adminLTE/header';
import Footer from './components/adminLTE/footer';
import Menu from './components/adminLTE/menu';
//import ExportProfiles from './pages/exportProfiles';
import CampaignStatistics from './pages/campaignStatistics';
import ResetPassword from './pages/resetPassword';
import HotspotCategories from './pages/HotspotCategories';

import TicketsScanning from './pages/TicketsScanning'

import * as G from './components/global'
import history from './components/history'
//loyalty pages
import Gifts from './pages/Gifts'
import GiftsCategories from './pages/GiftsCategories'
import PromoCode from './pages/PromoCode'
import PromoCodeCategories from './pages/PromoCodeCategories'
import Competitions from './pages/Competitions'
import CompetitionCategories from './pages/CompetitionCategories'
import PointsTokens from './pages/PointsTokens'
import Avatars from './pages/Avatars'
import Rankings from './pages/Rankings'
import Badges from './pages/Badges'
import Stores from './pages/Stores'
import Sites from './pages/Sites'
import SiteInfo from './pages/SiteInfo'
import GroupInfo from './pages/GroupInfo'
import SiteStores from './pages/SiteStores'
import StoresCategories from './pages/StoresCategories'
import UserLogs from './pages/UserLogs'
import UserDetails from './pages/UserDetails'
import ChangeUserPassword from './pages/ChangeUserPassword'
import ScannedTickets from './pages/ScannedTickets'
import Terms from './pages/Terms'
import ManageTerms from './pages/ManageTerms'
/*


import ScannedTickets from './pages/ScannedTickets'

import TicketsScanning from './pages/TicketsScanning'




*/
//end loyalty pages
//#region Redux
import { _reducersActions as _RA } from './components/_redux/constants';
import { connect } from 'react-redux';
import RightMenu from './components/adminLTE/Right_Menu';
import $ from "jquery";
//#endregion

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page:'/',
            groups:[],
            sites:[],
            listGroupSites:[],
        }
    }
    
    changePage = (newpage) =>{this.setState({page:newpage});window.scrollTo(0, 0)}
    //#region  componentDidMount,componentWillUnmount,listenToScroll
    async componentDidMount() {

        if (history.location.pathname.toLocaleLowerCase() === "/registrationrequest" || history.location.pathname.toLocaleLowerCase() === "/signin"|| history.location.pathname.toLocaleLowerCase() === "/terms") {
            this.props.saveUserData({})
            return;
        }
        const result = await G.checkUserAccess();
         
        if (!Array.isArray(result)) { this.logOut(); return }
        if (result.length === 0) { this.logOut(); return }
        this.props.saveUserData(result[0])
      if(this.props.userLogin.accountType ===5 && history.location.pathname == '/')
      {
            this.setState({page:"/CampaignsTemplates"})
            history.push("/CampaignsTemplates")
          //this.changePage("/CampaignsTemplates")
      }
      if(this.props.userLogin.accountType ===3 && history.location.pathname == '/')
      {
            this.setState({page:"/ScannedTickets"})
            history.push("/ScannedTickets")
          //this.changePage("/CampaignsTemplates")
      }
       
        history.listen((location, action) => {
          this.setState({page:location.pathname})
          })
          this.setState({page:history.location.pathname})
    }
    
    
    logOut = () => {
        
        this.props.saveUserData({})
        sessionStorage.removeItem(G.sessionStorageVariables.userData)
        localStorage.removeItem("site")
        history.push("/SignIn");
        
    }
  

    
    render() {
        let isLoggedIn = false;
        if (this.props.userLogin != null) { if (this.props.userLogin['id'] !== undefined)
         isLoggedIn = true;
        }     
        return (
/* changed class name by youssef to blank*/
            <div>
                <Router history={history}  >
                    {1 == 2 && <div style={{ textAlign: 'center' }}>{JSON.stringify(this.props.userLogin)}</div>}
                    {isLoggedIn && <Header language={this.props.userLogin.language} changePage={this.changePage} logOut={this.logOut} accountType={isLoggedIn ? this.props.userLogin.accountType : 10} groups={this.state.groups} sites={this.state.sites} siteselected={this.state.siteselected}/>}
                    {isLoggedIn && <Menu
                        userFullName={isLoggedIn ? this.props.userLogin.fullName : 'No user logged in'}
                        accountType={isLoggedIn ? this.props.userLogin.accountType : 10}
                        currentPage={this.state.page}
                        changePage={this.changePage}
                    />}

                    {/*<div className={isLoggedIn?'content-wrapper miniContentHeight whiteBg pl-5 primColor':"primColor cSignInBack fullHeight"} >
                        {1 === 2 && <div style={{ textAlign: "center", padding: 20 }}>
                            <div>Age:<span>{this.props.age}</span></div>

                            <div>
                                <button onClick={this.props.onAgeUp} >Age Up</button>
                                <button onClick={this.props.onAgeDown} >Age Down</button>
                            </div>
                            <div>Weight:<span>{this.props.weight}</span></div>
                            <div>
                                <button onClick={this.props.onWeightUp} >Weight Up</button>
                                <button onClick={this.props.onWeightDown} >Weight Down</button>
                            </div>
                        </div>
                        }*/}
                        <div className='content-wrapper'>
                                <Switch>
                                    {this.props.userLogin ? ([0,1,2].includes(this.props.userLogin.accountType) ) &&<Route path="/" exact render={()=> <MainContent isLoggedIn={isLoggedIn} />} />:''}
                                    <Route path="/marketing-dashboard" exact render={()=> <DashboardContent isLoggedIn={isLoggedIn} />} />
                                    <Route path="/SignIn" exact render={()=><SignInForm history={history} />} />
                                    <Route path="/startPage" exact render={()=> <StartPage history={history} />} />
                                    {this.props.userLogin ? ([0,1,2,4,5].includes(this.props.userLogin.accountType) ) && <Route path="/CampaignsTemplates" exact render={()=> <CampaignsTemplates  history={history} />} /> :''}
                                    <Route path="/RegistrationRequest" exact render={()=> <RegistrationRequest history={history} />} />
                                    {this.props.userLogin ? this.props.userLogin.accountType===0 && <Route path="/ManageUserRegistration" exact render={()=> <ManageUserRegistration />} /> :''}
                                    {this.props.userLogin ? this.props.userLogin.accountType===0 && <Route path="/ManageUserRegistration/edit" exact render={()=> <ManageUserRegistration  />} /> :''}
                                    {this.props.userLogin ? this.props.userLogin.accountType===0 && <Route path="/ManageUserRegistration/register" exact render={()=> <ManageUserRegistration  />} /> :''}
                                    {this.props.userLogin ? this.props.userLogin.accountType===0 && <Route path="/ManageTerms" exact render={()=> <ManageTerms />} /> :''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<3 && <Route path="/UserManagement" exact render={()=> <UserManagement />} /> :''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<3 && <Route path="/UserManagement/create" exact render={()=> <UserManagement />} /> :''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<3 && <Route path="/UserManagement/edit" exact render={()=> <UserManagement />} /> :''}

                                    {this.props.userLogin ? this.props.userLogin.accountType<1 && <Route path="/AdminUsersManagement" exact render={()=> <AdminUsersManagement />} /> :''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<1 && <Route path="/AdminUsersManagement/create" exact render={()=> <AdminUsersManagement />} /> :''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<1 && <Route path="/AdminUsersManagement/edit" exact render={()=> <AdminUsersManagement />} /> :''}
                                    
                                    {this.props.userLogin ? ([0,1,2,3,7].includes(this.props.userLogin.accountType) ) && <Route path="/ProfileManagement" exact render={()=> <ProfileManagement />} /> :''}
                                    {this.props.userLogin ? ([0,1,2,3,7].includes(this.props.userLogin.accountType) ) && <Route path="/ProfileManagement/customers" exact render={()=> <ProfileManagement />} /> :''}
                                    {this.props.userLogin ? ([0,1,2,3,7].includes(this.props.userLogin.accountType) ) && <Route path="/ProfileManagement/customers/create" exact render={()=> <ProfileManagement />} /> :''}
                                    {this.props.userLogin ? ([0,1,2,3,7].includes(this.props.userLogin.accountType) ) && <Route path="/ProfileManagement/customers/edit" exact render={()=> <ProfileManagement />} /> :''}
                                    
                                    
                                    {this.props.userLogin ? ([0,1,2,4,5].includes(this.props.userLogin.accountType) ) && <Route path="/CampaignEditor" exact render={()=> <CampaignEditor history={history} />} />:''}
                                    {this.props.userLogin ? ([0,1,2,4,6].includes(this.props.userLogin.accountType) ) &&<Route path="/CustomerCampaigns" exact render={()=> <CustomerCampaigns history={history} />} /> :''}
                                    {this.props.userLogin ? ([0,1,2,4,6].includes(this.props.userLogin.accountType)  ) &&<Route path="/CustomerCampaigns/list" exact render={()=> <CustomerCampaigns history={history} />} /> :''}
                                    {this.props.userLogin ? ([0,1,2,4,6].includes(this.props.userLogin.accountType)  ) &&<Route path="/CustomerCampaigns/list/send" exact render={()=> <CustomerCampaigns history={history} />} /> :''}
                                    {this.props.userLogin ? ([0,1,2,3,7].includes(this.props.userLogin.accountType) ) && <Route path="/ImportProfiles" exact render={()=> <ImportProfiles />} />:''}
                                    {/*this.props.userLogin ? (this.props.userLogin.accountType<3 || this.props.userLogin.accountType===4 ) && <Route path="/ExportProfiles" exact render={()=> <ExportProfiles />} />:''*/}
                                    {/*this.props.userLogin ? (this.props.userLogin.accountType<3 || this.props.userLogin.accountType===4 ) && <Route path="/ExportProfiles/list" exact render={()=> <ExportProfiles />} />:''*/}
                                    <Route path="/logHistory" exact render={()=> <LogHistory />} />
                                    <Route path="/ResetPassword" exact render={()=> <ResetPassword history={history} />} />
                                    {this.props.userLogin ? [0,1,2,4,6].includes(this.props.userLogin.accountType) && <Route path="/CampaignStatistics" exact render={()=> <CampaignStatistics/>} />:''}
                                    {this.props.userLogin ? [0,1,2,4,6].includes(this.props.userLogin.accountType) && <Route path="/CampaignStatistics/details" exact render={()=> <CampaignStatistics />} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<3 && <Route path="/HotspotCategories" exact render={()=> <HotspotCategories siteselected={this.state.siteselected} />} /> :''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<3 && <Route path="/HotspotCategories/create" exact render={()=> <HotspotCategories siteselected={this.state.siteselected} />} /> :''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<3 && <Route path="/HotspotCategories/edit" exact render={()=> <HotspotCategories siteselected={this.state.siteselected} />} /> :''}
                                    {/*loyalty routes*/}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/Gifts" exact render={()=> <Gifts/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/Gifts/create" exact render={()=> <Gifts/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/Gifts/edit" exact render={()=> <Gifts/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/Gifts/details" exact render={()=> <Gifts/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/GiftsCategories" exact render={()=> <GiftsCategories/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/GiftsCategories/create" exact render={()=> <GiftsCategories/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/GiftsCategories/edit" exact render={()=> <GiftsCategories/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/PromoCode" exact render={()=> <PromoCode/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/PromoCode/create" exact render={()=> <PromoCode/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/PromoCode/edit" exact render={()=> <PromoCode/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/PromoCode/details" exact render={()=> <PromoCode/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/PromoCodeCategories" exact render={()=> <PromoCodeCategories/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/PromoCodeCategories/create" exact render={()=> <PromoCodeCategories/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/PromoCodeCategories/edit" exact render={()=> <PromoCodeCategories/>} />:''}
                                    
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/Competitions" exact render={()=> <Competitions/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/Competitions/create" exact render={()=> <Competitions/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/Competitions/edit" exact render={()=> <Competitions/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/Competitions/details" exact render={()=> <Competitions/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/CompetitionCategories" exact render={()=> <CompetitionCategories/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/CompetitionCategories/create" exact render={()=> <CompetitionCategories/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/CompetitionCategories/edit" exact render={()=> <CompetitionCategories/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/PointsTokens" exact render={()=> <PointsTokens/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/TicketsScanning" exact render={()=> <TicketsScanning/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/Avatars" exact render={()=> <Avatars/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/Avatars/create" exact render={()=> <Avatars/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/Avatars/edit" exact render={()=> <Avatars/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<3 && <Route path="/Stores" exact render={()=> <Stores/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<3 && <Route path="/Stores/create" exact render={()=> <Stores/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<3 && <Route path="/Stores/edit" exact render={()=> <Stores/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<3 && <Route path="/StoresCategories" exact render={()=> <StoresCategories/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<3 && <Route path="/StoresCategories/create" exact render={()=> <StoresCategories/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<3 && <Route path="/StoresCategories/edit" exact render={()=> <StoresCategories/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/Rankings" exact render={()=> <Rankings/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/Rankings/create" exact render={()=> <Rankings/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/Rankings/edit" exact render={()=> <Rankings/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/Badges" exact render={()=> <Badges/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/Badges/create" exact render={()=> <Badges/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/Badges/edit" exact render={()=> <Badges/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/SiteStores" exact render={()=> <SiteStores/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/SiteStores/add" exact render={()=> <SiteStores/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/SiteStores/create" exact render={()=> <SiteStores/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/SiteStores/edit" exact render={()=> <SiteStores/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<3 && <Route path="/SiteInfo" exact render={()=> <SiteInfo/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<3 && <Route path="/GroupInfo" exact render={()=> <GroupInfo/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<3 && <Route path="/Sites" exact render={()=> <Sites/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<3 && <Route path="/Sites/create" exact render={()=> <Sites/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<3 && <Route path="/Sites/edit" exact render={()=> <Sites/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/ScannedTickets" exact render={()=> <ScannedTickets/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<=3 && <Route path="/ScannedTickets/details" exact render={()=> <ScannedTickets/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<3 && <Route path="/UserLogs" exact render={()=> <UserLogs/>} />:''}
                                    {this.props.userLogin ? <Route path="/UserDetails" exact render={()=> <UserDetails/>} />:''}
                                    {this.props.userLogin ? this.props.userLogin.accountType<3 && <Route path="/ChangeUserPassword" exact render={()=> <ChangeUserPassword/>} />:''}
                                    <Route path="/terms" exact render={()=><Terms />}/>
                                    <Route path='*' exact render={()=><NotFound />} />
                                  </Switch>  
                                
                                
                    </div>
                    {isLoggedIn &&<Footer />}
                    {/* right menu added by youssef */}
                    {isLoggedIn && <RightMenu language={this.props.userLogin.language} accountType={isLoggedIn ? this.props.userLogin.accountType : 10} changePage={this.changePage} />}
                </Router>
            </div >

        );
    }
}
//#region Redux-Component Link
const mapDispachToProps = dispatch => {
    return {
        onAgeUp: () => dispatch({ type: _RA.counter.onAgeUp, value: 1 }),
        onAgeDown: () => dispatch({ type: _RA.counter.onAgeDown, value: 1 }),
        onWeightUp: () => dispatch({ type: _RA.counterWeight.onWeightUp, value: 1 }),
        onWeightDown: () => dispatch({ type: _RA.counterWeight.onWeightDown, value: 1 }),
        saveUserData: (data) => { G.log('dispatch', data); dispatch({ type: '', payLoad: data }) },        
        saveSite: (sid) => dispatch({type:'', siteid:sid})
    };
};

const mapStateToProps = state => {
    return {
        age: state.counterAge.age,
        weight: state.counterWeight.weight,
        userLogin: state.SaveLogin.userData
    };
};

//#endregion
export default connect(
    mapStateToProps,
    mapDispachToProps
)(App);