import { Button, Badge, Form, Spinner } from 'react-bootstrap'
import React, { Component, createRef } from 'react'
import * as G from '../components/global'
import ReactPaginate from 'react-paginate';
import moment from "moment";
import { connect } from 'react-redux';
import template from '../components/assets/template.xlsx';
import history from '../components/history';
import Select from 'react-select'
import readXlsxFile from 'read-excel-file'
import { translateScreen as ts, translateScreen2 as ts2, translateDate as td } from '../components/dictionary'
import dateFormat from 'dateformat';
class importProfiles extends Component {
    
    
    constructor(props) {
        
        super(props);
        this.state = {
            selectedFile: null,
            isFilePicked: false,
            fileContent: '',
            linesError: [],
            lineAvailable:[],
            LinesAccepted: [],
            offset: 0,
            perPage: 9,
            currentPage: 0,
            pageCount: 0,
            loadprofiles: false,
            fields: {},
            errors: {},
            originalList: [],
            allList: [],
            displayedList: [],
            allCompanies: [],
            doneLoadingCmp: false,
            loadCompany: true,
            availableEmails: [],
            showSuccess: false,
            isExcel: true,
            excelRows: [],
            hotspotcategorymultiple:[]
        }
        this.handleChange = this.handleChange.bind(this);
        
        this.handlePageClick = this.handlePageClick.bind(this);
    };
    //#region Didmunt, Load Companies & disticnt functions

    componentDidMount() {
        this.selectInputRef = createRef();
        if(this.props.userLogin.site)
            this.getcategories(this.props.userLogin.site);        
        
    }
    getSnapshotBeforeUpdate(prevProps) {
        return { notifyRequired: prevProps.userLogin !== this.props.userLogin };
      }
      
      async componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot.notifyRequired && this.props.userLogin.site) {
            this.getcategories(this.props.userLogin.site);
        }
      }
    getcategories = async (company) => {
        if(this.selectInputRef.current!=null)
            this.selectInputRef.current.select.clearValue();
        let array = []
        array = await G.getData("hotspotCategory/query", [['filter','(hide is null or hide=0) and clientID=' + company.id]])
        array = await array.data
        this.setState({ hotspotCategory: array })
        let arrayNames = [];
        array.map(element => {
          arrayNames.push(element.name)
        })
        this.setState({ hotspotCategoryNames: arrayNames })
        console.log(this.state.hotspotCategory)
        const categoryOptions = [];
        array.map(element => {
          categoryOptions.push({value:element.id, label:element.name});
        })
        this.setState({ hotspotCategoryOptions: categoryOptions })
      }
    getCompanyUsers = (company) => {

        
        return (this.state.allCompanies.filter(record => record.id === company.id))
    }
    renderFormat(title, details) {
        return <p className='pl-4 bold themeColor ' ><Badge variant='warning' style={{ minWidth: 100 }}>{title}</Badge> <span className='normal hint ml-3'>{details}</span></p>
    }
    
    downloadCountryList() {
        let text = G.country_list.join("\r\n")
        G.downloadFile(text, 'CountryList')

    }
    //#endregion
    //#region Filter & Table
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.setdisplayList(selectedPage)
        });

    };
    handleChange(e) {
        let { fields, errors } = this.state;
        fields[e.target.name] = e.target.value;
        delete errors[e.target.name];
        this.setState({ fields });
        if (e.target.name === 'filter' && e.target.value === '') {
            this.filterGrid()
        }

    }
    onKeyDown = e => {

        if (e.keyCode === 13) {
            e.preventDefault();
            this.filterGrid()
        }
    }
    setdisplayList(startnumber) {

        let sliceArray = [...this.state.allList]

        if (this.state.LinesAccepted.length > this.state.perPage) {
            sliceArray = sliceArray.slice(startnumber * this.state.perPage, startnumber * this.state.perPage + this.state.perPage)

        }
        this.setState({ displayedList: sliceArray })


    }
    filterGrid = () => {

        let filter = this.state.fields['filter']

        if (filter === "" || filter == null) {

            this.setState({ allList: [...this.state.originalList] }, () => { this.setdisplayList(0) })


        } else {
            let arr = [...this.state.originalList.filter(element =>
                element[0].toLowerCase().includes(filter.toLowerCase())
                || element[1].toLowerCase().includes(filter.toLowerCase())
                || element[2].toLowerCase().includes(filter.toLowerCase())
                || element[6].toLowerCase().includes(filter.toLowerCase())
                || element[7].toLowerCase().includes(filter.toLowerCase())
                || element[8].toLowerCase().includes(filter.toLowerCase())
                || element[9].toLowerCase().includes(filter.toLowerCase())
                || element[10].toLowerCase().includes(filter.toLowerCase())
            )]

            this.setState({
                allList: arr
            }, () => { this.setdisplayList(0) })

        }
    }
    //#endregion
    //#region File UPload
    validateRecord = async (record, line) => {
        let recordError = false
        let recordAvailable = false;
        let recordErrorDescription = ''
        let recordAvailableDescription = ''
        let l = this.props.userLogin.language=== 0
        if (record.split(",").length !== 8) {
            if (record.trim() !== '') {
                recordError = true;
                recordErrorDescription = 'Wrong number of columns at ' + line + ". (" + G.countItems(record.split(",").length, 'Columns') + ", must be 8 Columns)"

            }
        } else {
            let column = 0
            record.split(",").map(val => {
                if (column === 0) {
                    if (val.trim().length === 0) { recordError = true; recordErrorDescription = l ? "First Name is not available\r\n" :"Le prénom n'est pas disponible\r\n" }
                    if (val.trim().length > 100) { recordError = true; recordErrorDescription = l ?"Les caractères du prénom sont plus de 100 caractères \r\n" :"Les caractères du prénom sont plus de 100 caractères \r\n" }
                }
                if (column === 1) {
                    if (val.trim().length === 0) { recordError = true; recordErrorDescription = l ?"Last Name is not available \r\n" :"Le nom de famille n'est pas disponible\r\n"}
                    if (val.trim().length > 100) { recordError = true; recordErrorDescription = l ?"Last Name characters is more then 100 characters \r\n" :"Les nom de famille du prénom sont plus de 100 caractères \r\n"}
                }
                if (column === 2) {
                    if (val.trim().length === 0) { recordError = true; recordErrorDescription = l ?"Email address is not available \r\n" :"Le E-mail n'est pas disponible\r\n"}
                    if (val.trim().length > 150) { recordError = true; recordErrorDescription = l ?"Email address characters is more then 150 characters \r\n":"Les caractères du E-mail sont plus de 150 caractères \r\n" }
                    if (this.state.availableEmails.filter(element => { return element.emailAddress.toLowerCase() === val.trim().toLowerCase() }).length > 0) { recordAvailable=true;  recordAvailableDescription =  l ?"E-mail " + val.trim() + " already available in the database \r\n":"E-mail " + val.trim() + " déjà disponible dans la base de données \r\n" }
                    if (this.state.LinesAccepted.filter(element => { return element[3].toLowerCase() === val.trim().toLowerCase() }).length > 0) { recordAvailable=true;  recordAvailableDescription = l ?"E-mail " + val.trim() + "  already available in list. \r\n":"E-mail " + val.trim() + " déjà disponible dans la base de données \r\n" }
                }
                if (column === 3) {
                    if (val.trim().length === 0) { recordError = true; recordErrorDescription = l ?"Mobile or phone number is not available \r\n":"Le Numéro de portable ou de téléphone n'est pas disponible\r\n"  }
                    if (val.trim().length > 150) { recordError = true; recordErrorDescription = l ?"Mobile or phone number characters is more then 150 characters \r\n" :"Les caractères du Numéro de portable ou de téléphone sont plus de 150 caractères \r\n"}
                }
                if (column === 4) {
                    //if (val.trim().length === 0) { recordError = true; recordErrorDescription = l ?"Gender is not available \r\n" :"Le genre n'est pas disponible\r\n"}
                   // if (val.trim() !== '0' && val.trim() !== '1') { recordError = true; recordErrorDescription = l ?"Gender Value is not valid" :"Le genre n'est pas disponible  \r\n"}
                }
                if (column === 5) {

                    if (val.trim().length === 0) { recordError = true; recordErrorDescription = l ?"Birthdate is not available \r\n" : "L'age n'est pas desponible \r\n"}
                    if (!moment(val.trim(), 'DD/MM/YYYY', true).isValid()) { recordError = true; recordErrorDescription = l ?"Invalid date format. Date Format must be DD/MM/yyyy (Day/Month/Year) \r\n":"Format de date non valide. Le format de la date doit être JJ/MM/aaaa (Jour/Mois/Année) \r\n" }
                }
                if (column === 6) {
                    if (val.trim().length === 0) { recordError = true; recordErrorDescription = l ? "Country is not available \r\n" :"Le pays n'est pas disponible\r\n" }
                    if (G.country_list.filter(element => val.trim() === element).length === 0) { recordError = true; recordErrorDescription = val.trim() +( l ?" is not a valid country \r\n":" n'est pas un pays valide \r\n" )}
                }
                if (column === 7) {
                    if (val.trim().length === 0) { recordError = true; recordErrorDescription = l ?"City is not available \r\n":"La ville n'est pas disponible\r\n" }
                    if (val.trim().length > 100) { recordError = true; recordErrorDescription = l ?"City characters is more then 100 characters \r\n":"Les caractères de la ville sont plus de 150 caractères\r\n" }
                }

                column += 1

            })
            if (recordAvailable){
                let availableAlready = this.state.lineAvailable;
                availableAlready.push(ts('Line',this.props.userLogin.language) +  '\r\n' + line + "  -  " + recordAvailableDescription)
            }
            if (recordError) {
                let errors = this.state.linesError;
                errors.push(ts2('Invalid data available at Line',this.props.userLogin.language) +  '\r\n' + line + "." + recordErrorDescription)
                this.setState({ linesError: errors })

            } else {
                let accept = this.state.LinesAccepted;
                let recordToUpload = record.split(",")

                recordToUpload[5] = moment(recordToUpload[5], 'DD/MM/YYYY', true).toDate().toISOString().slice(0, 19).split('T')[0]
                accept.push(recordToUpload)
                this.setState({ LinesAccepted: accept })


            }
            


        }
    }
    validateRecordExcel = async (record, line) => {
        let recordError = false
        let recordErrorDescription = ''
        let recordAvailable = false;
        let recordAvailableDescription=''
        console.log(record, line)
        let l = this.props.userLogin.language=== 0
        if (record.length !== 11) {
            recordError = true;
            recordErrorDescription = 'Wrong number of columns at line ' + line + ". (" + G.countItems(record.length, 'Column') + ", must be 8 Columns)"


        } else {
            let column = 0

            record.map(val => {
                if (val == null && (column==0 || column==1 || column==2 || column==6)) {
                    recordError = true;
                    recordErrorDescription += " - Column " + (column === 0 ? "First Name" : column === 1 ? "Last Name" : column === 2 ? "Email Address" : column === 6 ? "Country" : "") + " has no value.        "
                } else {
                    if (column === 0) {
                        if (val.trim().length === 0) { recordError = true; recordErrorDescription += l ? "First Name is not available\r\n" :"Le prénom n'est pas disponible\r\n" }
                        if (val.trim().length > 100) { recordError = true; recordErrorDescription +=  l ?"Les caractères du prénom sont plus de 100 caractères \r\n" :"Les caractères du prénom sont plus de 100 caractères \r\n" }
                    }
                    if (column === 1) {
                        if (val.trim().length === 0) { recordError = true; recordErrorDescription +=l? "Last Name is not available \r\n" :"Le nom de famille n'est pas disponible\r\n"}
                        if (val.trim().length > 100) { recordError = true; recordErrorDescription += l?"Last Name characters is more then 100 characters \r\n": "Les nom de famille du prénom sont plus de 100 caractères \r\n"}
                    }
                    if (column === 2) {
                        if (val.trim().length === 0) { recordError = true; recordErrorDescription +=l ?"Email address is not available \r\n" :"Le E-mail n'est pas disponible\r\n"}
                        if (val.trim().length > 150) { recordError = true; recordErrorDescription += l ?"Email address characters is more then 150 characters \r\n":"Les caractères du E-mail sont plus de 150 caractères \r\n" }
                        if (this.state.availableEmails.filter(element => { return element.emailAddress.toLowerCase() === val.trim().toLowerCase() }).length > 0) {recordAvailable=true;  recordAvailableDescription  = l?"E-mail " + val.trim() + " already available in the database \r\n":"E-mail " + val.trim() + " déjà disponible dans la base de données \r\n" }
                        if (this.state.LinesAccepted.filter(element => { return element[2].toLowerCase() === val.trim().toLowerCase() }).length > 0) {  recordError = true; recordErrorDescription = l?"E-mail " + val.trim() + "  already available in list. \r\n":"E-mail " + val.trim() + " déjà disponible dans la list \r\n" }
                    }
                    /*if (column === 3) {
                        if (val.length === 0) { recordError = true; recordErrorDescription += l?"Mobile or phone number is not available \r\n":"Le Numéro de portable ou de téléphone n'est pas disponible\r\n"  }
                        if (val.length > 150) { recordError = true; recordErrorDescription +=l?"Mobile or phone number characters is more then 150 characters \r\n" :"Les caractères du Numéro de portable ou de téléphone sont plus de 150 caractères \r\n"}
                    }*/
                    if (column === 4) {
                        if(val!="Male" && val!="Female")
                        {
                            val='';
                        }
                        //if (val.length === 0) { recordError = true; recordErrorDescription += l?"Gender is not available \r\n" :"Le genre n'est pas disponible\r\n"}
                        //if (val !== "Male" && val !== "Female") { recordError = true; recordErrorDescription =l ?"Gender Value is not valid" :"Le genre n'est pas disponible  \r\n"}
                    }
                   /* if (column === 5) {

                        if (val.length === 0) { recordError = true; recordErrorDescription +=  l ?"Birthdate is not available \r\n" : "L'age n'est pas desponible \r\n"}
                        if (!moment(val, 'DD/MM/YYYY', true).isValid()) { recordError = true; recordErrorDescription += l ?"Invalid date format. Date Format must be DD/MM/yyyy (Day/Month/Year) \r\n":"Format de date non valide. Le format de la date doit être JJ/MM/aaaa (Jour/Mois/Année) \r\n" }
                    }*/
                    if (column === 6) {
                        if (val.trim().length === 0) { recordError = true; recordErrorDescription += l? "Country is not available \r\n" :"Le pays n'est pas disponible\r\n" }
                        if (G.country_list.filter(element => val.trim() === element).length === 0) { recordError = true; recordErrorDescription += val.trim() + (l ?" is not a valid country \r\n":" n'est pas un pays valide \r\n" )}
                    }
                    /*if (column === 7) {
                        if (val.trim().length === 0) { recordError = true; recordErrorDescription += l? "City is not available \r\n":"La ville n'est pas disponible\r\n" }
                        if (val.trim().length > 100) { recordError = true; recordErrorDescription += l ?"City characters is more then 100 characters \r\n":"Les caractères de la ville sont plus de 150 caractères\r\n" }
                    }*/
    
                       
    
                }

                column += 1

            })

        }
        if (recordAvailable){
            let availableAlready = this.state.lineAvailable;
            availableAlready.push(ts('Line',this.props.userLogin.language) +  '\r\n' + line + "  -  " + recordAvailableDescription)
        }
        if (recordError) {
            let errors = this.state.linesError;
            errors.push(ts2('Invalid data available at Line',this.props.userLogin.language) + '\r\n' + line + ".  " + recordErrorDescription)
            this.setState({ linesError: errors })


        } else {
            let accept = this.state.LinesAccepted;
            let recordToUpload = record
            if(recordToUpload[4]!=null && recordToUpload[4]!=undefined && recordToUpload[4]!='')
                recordToUpload[4] = (recordToUpload[4].toLowerCase() === 'male' ? '0' : '1')
            else
                recordToUpload[4] = '2'
            if(recordToUpload[5]!=null && recordToUpload[5]!=undefined && recordToUpload[5]!='')   
                recordToUpload[5] = moment(recordToUpload[5], 'DD/MM/YYYY', true).toDate().toISOString().slice(0, 19).split('T')[0]
            accept.push(recordToUpload)

            this.setState({ LinesAccepted: accept })

        }
    }

    handleFileSubmission = async () => {
        const formData = new FormData();
        this.setState({ loadprofiles: true })
        formData.append('File', this.state.selectedFile);
        let errors = this.state.linesError;
        let availableAlready = this.state.lineAvailable;
        let availableEmails = this.state.availableEmails;
        let accepted = this.state.LinesAccepted;
        accepted.length = 0;
        errors.length = 0
        availableEmails.length = 0
        availableAlready.length=0
        this.setState({ linesError: errors, LinesAccepted: accepted, availableEmails, availableAlready:this.state.lineAvailable })

        let result = await G.getData('getRegisteredEmails', [['clientID', this.props.userLogin.site.id]])
        result = await result.data
        this.setState({ availableEmails: result })
        let Line = 1

        if (this.state.selectedFile.name.toLowerCase().endsWith("csv")) {
            this.setState({ isExcel: false, excelRows: [] })
            this.state.fileContent.split('\r\n').map(element => {
                this.validateRecord(element, Line);

                Line += 1
            })

            let pageCounts = this.state.allList.length / this.state.perPage
            this.setState({ originalList: this.state.LinesAccepted, pageCount: pageCounts, allList: this.state.LinesAccepted })

            this.setdisplayList(0)
            this.setState({ loadprofiles: false })


        } else {
            readXlsxFile(this.state.fileContent).then((rows) => {
                this.setState({ isExcel: true, excelRows: rows })
                rows.map(record => {
                    if (Line > 1) this.validateRecordExcel(record, Line);

                    Line += 1
                })
                let pageCounts = this.state.LinesAccepted.length / this.state.perPage

                this.setState({ originalList: this.state.LinesAccepted, pageCount: pageCounts, allList: this.state.LinesAccepted })

                this.setdisplayList(0)
                this.setState({ loadprofiles: false })



            }
            )
        }





    }
    uploadFile = async () => {


        console.log(this.state.LinesAccepted.join(";"));
        let result = await G.getData('uploadProfile', [
            ['clientID', this.props.userLogin.site.id],
            ['userID', this.props.userLogin.id],
            ['data', encodeURIComponent(this.state.LinesAccepted.join(";"))],
            ['categories',JSON.stringify(this.state.hotspotcategorymultiple)]
        ], true);
        if (result.status === 200) {
            this.setState({ linesError: [], LinesAccepted: [], selectedFile: null, showSuccess: true })

        }
    }
    //#endregion
    render() {
        const { fields, errors } = this.state;
        
        return (
            <div className='flexrow start mt-5'>
                
                <div className='miniContentHeight colSupLigGra pl-1 mt-5 mb-5 ml-5 mr-5'></div>
                <div>

                    <h4 className=' mt-5'>{ts2('Import Profiles', this.props.userLogin.language)}</h4>
                    <div className=' mb-5 hint'>{ts2('You can create manually customers in Customer Management Screen', this.props.userLogin.language)}<span onClick={() => { history.push('/ProfileManagement') }} className='link'> {ts2('Click to open', this.props.userLogin.language)} </span></div>

                    <h6><p className='mt-4'>{ts2('Uploading data to the your database will require this well defined format', this.props.userLogin.language)}</p></h6>
                    {this.renderFormat(ts2("First Name", this.props.userLogin.language), ts2('List Characters with maximum Length equal to ', this.props.userLogin.language) + '100')}
                    {this.renderFormat(ts2("Last Name", this.props.userLogin.language), ts2('List Characters with maximum Length equal to ', this.props.userLogin.language) + '100')}
                    {this.renderFormat(ts2("Email Address", this.props.userLogin.language), ts2('Well defined email ', this.props.userLogin.language))}
                    {this.renderFormat("Mobile", ts2('List Characters with maximum Length equal to ', this.props.userLogin.language) + '150')}
                    {this.renderFormat(ts2("Gender", this.props.userLogin.language), ts2('Only 2 values.', this.props.userLogin.language) + ' (Male,Female)')}
                    {this.renderFormat("Age", ts2('Date of birth of the person. Date format will be as Follow DD/MM/YYYY (Day/Month/Year)', this.props.userLogin.language))}
                    {this.renderFormat(ts2("Country", this.props.userLogin.language), <span>{ts2('List Characters with maximum Length equal to ', this.props.userLogin.language) + '100. ' + ts2('Country must be valid from a this country list', this.props.userLogin.language)}.<span className='link' onClick={this.downloadCountryList}>{ts2('Download List here', this.props.userLogin.language)}</span></span>)}
                    {this.renderFormat(ts2("City", this.props.userLogin.language), ts2('List Characters with maximum Length equal to ', this.props.userLogin.language) + '100')}
                    {this.renderFormat(ts2("Language", this.props.userLogin.language), ts2('Only 4 values.', this.props.userLogin.language) + ' (fr,en,nl,de)')}
                    {this.renderFormat(ts2("Subscription", this.props.userLogin.language), ts2('Only 2 values.', this.props.userLogin.language) + ' (subscribed,unsubscribed)')}
                    {this.renderFormat(ts2("Company", this.props.userLogin.language), ts2('List Characters with maximum Length equal to ', this.props.userLogin.language) + '500')}
                    <div className='ml-4 mt-3 mb-3'>{ts2('Download a CSV file template from', this.props.userLogin.language)} <span className='link bold'><a href={template} download="Template.xlsx">{ts2('Here', this.props.userLogin.language)}</a></span></div>

                    <p className='p-2'></p>
                    {/* <h6 ><p className='mt-5'>There are 2 types of files that you can  import data from.</p></h6> */}

                    <h6 ><p className='mt-5'>{ts2('', this.props.userLogin.language)}{ts2('Client Selection', this.props.userLogin.language)}</p></h6>

                    <h6 ><p className='mt-5'>{ts2('Categories', this.props.userLogin.language)}</p></h6>
                    <div className='col-5'>
                    <Select ref={this.selectInputRef} menuPortalTarget={document.body} isClearable={true}
    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            onChange={value =>{console.log(value); this.setState({ hotspotcategorymultiple: value })}}
    closeMenuOnSelect={false}
    isMulti
    options={this.state.hotspotCategoryOptions}
  />
                    </div>
                    <h6 ><p className='mt-5'>{ts2('Import Data From Files', this.props.userLogin.language)}</p></h6>

                    <div className='pl-5'>
                        <p className='p-0 pt-0 bold '> - {ts2('A csv file', this.props.userLogin.language)}
                            <p className='pl-3 hint normal'>
                                {ts2('A comma-separated values (CSV) file is a delimited text file that uses a comma to separate values. Each line of the file is a data record.', this.props.userLogin.language)}
                                <p> {ts2('You can save your Excel workbook as CSV file by switching to the File tab, and then click Save As and In the Save as type box, choose to save your Excel file as CSV (Comma delimited).', this.props.userLogin.language)}
                                </p>
                            </p>

                            {/* <input onChange={this.changeHandler} variant='success' type="file" name='file' className='successButton' /> */}
                            <Form.File
                                type="file"
                                className="col-5"
                                id="inputGroupFile01"
                                label={this.state.selectedFile ? this.state.selectedFile.name : ts2("Select your Excel or CSV file", this.props.userLogin.language)}
                                onChange={(e) => {
                                    this.setState({ showSuccess: false })
                                    const reader = new FileReader();
                                    if (e.target.files.length === 0) return;
                                    this.setState({ selectedFile: null })
                                    if (reader) {
                                        reader.onload = (evt) => {
                                            /* Parse data */
                                            const bstr = evt.target.result;
                                            this.setState({ fileContent: bstr })

                                        };
                                        reader.readAsBinaryString(e.target.files[0]);
                                        this.setState({ selectedFile: e.target.files[0] })
                                        this.setState({ linesError: [], LinesAccepted: [], lineAvailable:[] })
                                    }
                                }}
                                //onChange={this.changeHandler}
                                custom
                                accept=".xlsx,.csv,xls"
                            />
                            {/* {this.state.selectedFile && <Button variant='danger ml-3' onClick={()=>{this.setState({selectedFile:null, linesError:[],LinesAccepted:[],excelRows:[], fileContent:''})}}>Clear File</Button>} */}
                            {this.state.selectedFile &&
                                <div>
                                    <div className='pt-2'>
                                        <Badge variant='primary' className='mr-2 p-1'> {ts('Name', this.props.userLogin.language)} : {this.state.selectedFile.name} </Badge>
                                        <Badge variant='info' className='mr-2 p-1'> {ts2('Filetype', this.props.userLogin.language)}: {this.state.selectedFile.type} </Badge>
                                        <Badge variant='info' className='mr-2 p-1'> {ts2('Size', this.props.userLogin.language)}: {this.state.selectedFile.size < 1024 ? this.state.selectedFile.size + ' bytes' : this.state.selectedFile.size / 1000 + ' kb'} </Badge>
                                        <Badge variant='info' className='mr-2 p-1'>{ts2('Last Modified', this.props.userLogin.language)} : {td(dateFormat(this.state.selectedFile.lastModifiedDate, 'dddd , dd mmmm yyyy hh:MM tt'),this.props.userLogin.language)} </Badge>
                                    </div>
                                    <Button className='mt-5' variant='success' onClick={this.handleFileSubmission}>{ts2('Validate File', this.props.userLogin.language)}</Button>
                                </div>}
                        </p>
                        {this.state.linesError.length > 0 && <p className='remarks warningColor pl-5'>
                            <p className=' bold '>{ts2('Error Appeared While Trying to upload File',this.props.userLogin.language)} ({G.countItems(this.state.linesError.length, ts2('Error Available', this.props.userLogin.language))} )</p>
                            {this.state.linesError.length > 30 ? <p className='primColor bold themeColor'>{ts('Are you sure this is the correct file?', this.props.userLogin.language)}</p> : ''}
                            {this.state.linesError.slice(0, 30).map(element => { return <p className='normal'>{element}</p> })}
                            {this.state.linesError.length > 30 ? <p>...{G.countItems(this.state.linesError.length - 30, ts2('Additional Error', this.props.userLogin.language))}</p> : ''}
                        </p>}
                        {this.state.lineAvailable.length > 0 && <p className='remarks warningYellow pl-5'>
                            <p className=' bold '>{ts('Some emails already available. Data will be overwritten',this.props.userLogin.language)} ({G.countItems(this.state.lineAvailable.length, ts2('Warning', this.props.userLogin.language))} )</p>
                            {this.state.lineAvailable.length > 30 ? <p className='primColor bold themeColor'>{ts('Are you sure this is the correct file?', this.props.userLogin.language)}</p> : ''}
                            {this.state.lineAvailable.slice(0, 30).map(element => { return <p className='normal'>{element}</p> })}
                            {this.state.lineAvailable.length > 30 ? <p>...{G.countItems(this.state.lineAvailable.length - 30, ts2('Additional Warning', this.props.userLogin.language))}</p> : ''}
                        </p>}
                        {this.state.LinesAccepted.length > 0 && <p className=' pl-2'>

                            <h5 >
                                {this.state.isExcel ?
                                    <span className={'m-5 title bold ' + (this.state.excelRows.length - 1) > this.state.LinesAccepted.length ? 'warningColor' : 'successColor'}>
                                        {(this.state.excelRows.length - 1) > this.state.LinesAccepted.length && <i class="fas fa-exclamation-circle"></i>}
                                        {this.state.excelRows === this.state.LinesAccepted.length && <i class="far fa-check-circle"></i>}


                                        {(this.state.excelRows.length - 1) > this.state.LinesAccepted.length ?
                                            G.countItems(this.state.LinesAccepted.length, ts2('out of ', this.props.userLogin.language) + (this.state.excelRows.length - 1) + ts2(" record is ", this.props.userLogin.language), ts2('out of ', this.props.userLogin.language) + (this.state.excelRows.length - 1) + ts2(" records are ", this.props.userLogin.language)) + ts2(" available. You can check succeeded records in the grid below", this.props.userLogin.language)
                                            : ts2("All the records are available", this.props.userLogin.language)

                                        }

                                    </span>
                                    :
                                    <span className={'m-5 title bold ' + this.state.fileContent.split('\r\n').length - 1 > this.state.LinesAccepted.length ? 'warningColor' : 'successColor'}>
                                        {this.state.fileContent.split('\r\n').length - 1 > this.state.LinesAccepted.length && <i class="fas fa-exclamation-circle"></i>}
                                        {this.state.fileContent.split('\r\n').length - 1 === this.state.LinesAccepted.length && <i class="far fa-check-circle"></i>}


                                        {this.state.fileContent.split('\r\n').length > this.state.LinesAccepted.length - 1 ?
                                            G.countItems(this.state.LinesAccepted.length, ts2('out of ', this.props.userLogin.language) + (this.state.fileContent.split('\r\n').length - 1) + ts2(" record is ", this.props.userLogin.language), 'out of ' + (this.state.fileContent.split('\r\n').length - 1) + ts2(" records are ", this.props.userLogin.language)) + ts2(" available. You can check succeeded records in the grid below", this.props.userLogin.language)
                                            : ts2("All the records are available", this.props.userLogin.language)

                                        }

                                    </span>


                                }


                            </h5>
                            <Button className='mb-5 mt-3' variant='danger' onClick={this.uploadFile}>{ts2('Upload Succeeded Records', this.props.userLogin.language)}</Button>
                            {/* {this.state.LinesAccepted.map(element => { return <p className='normal'>{element[0]}</p> })} */}
                            {!this.state.loadprofiles && <div>

                                <div className='p-3 mr-3 '>
                                    <Form.Label  >{ts2('Filter Records', this.props.userLogin.language)}</Form.Label>
                                    <div className='flexrow col-8'>
                                        <Form.Control onKeyDown={this.onKeyDown} name='filter' type={'text'} placeholder={'Filter Result'} onChange={this.handleChange} value={fields['filter'] || ''} />
                                        {this.state.fields['filter'] && this.state.fields['filter'] !== '' && <Button variant='warning ml-2' onClick={this.filterGrid}> {ts2('Apply', this.props.userLogin.language)}</Button>}
                                        {this.state.fields['filter'] && this.state.fields['filter'] !== '' && <Button variant='info ml-2' onClick={() => {
                                            let { fields } = this.state;
                                            delete fields['filter'];
                                            this.setState({ fields });

                                            this.filterGrid()
                                        }}>{ts('Clear', this.props.userLogin.language)}</Button>}
                                    </div>
                                </div>
                                <div className='p-3 mr-3 '>
                                    <div className="table-responsive p-0" style={{ maxHeight: 900 }}>
                                        <table className="table table-head-fixed text-nowrap table-hover ">
                                            <thead>
                                                <tr>
                                                    <th>{ts2('First Name', this.props.userLogin.language)}</th>
                                                    <th>{ts2('Last Name', this.props.userLogin.language)}</th>
                                                    <th>{ts2('Email', this.props.userLogin.language)}</th>
                                                    <th>Mobile</th>
                                                    <th>{ts('Gender', this.props.userLogin.language)}</th>
                                                    <th>Age</th>
                                                    <th>{ts('Country', this.props.userLogin.language)}</th>
                                                    <th>{ts('City', this.props.userLogin.language)}</th>
                                                    <th>{ts('Language', this.props.userLogin.language)}</th>
                                                    <th>{ts('Subscription', this.props.userLogin.language)}</th>
                                                    <th>{ts('Company name', this.props.userLogin.language)}</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    (this.state.displayedList).map((item) => {

                                                        return (
                                                            <tr key={item.id}>
                                                                <td>{item[0]}</td>
                                                                <td>{item[1]}</td>
                                                                <td>{item[2]}</td>
                                                                <td>{item[3]}</td>
                                                                <td>{item[4] == 0 ? 'Male' : item[4] == 1 ? 'Female' : 'not Defined'}</td>
                                                                {/* <td>{G.countItems(G.getAge(item[0]), 'year') + ' old'} </td> */}
                                                                <td>{item[5]}</td>
                                                                <td>{item[6]}</td>
                                                                <td>{item[7]}</td>
                                                                <td>{item[8]}</td>
                                                                <td>{item[9]}</td>
                                                                <td>{item[10]}</td>

                                                            </tr>)
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>}

                            {!this.state.loadprofiles && this.state.allList.length > this.state.perPage && <ReactPaginate
                                previousLabel={ts("Previous", this.props.userLogin.language)}
                                nextLabel={ts("Next", this.props.userLogin.language)}
                                breakLabel={"..."}
                                pageCount={this.state.pageCount}
                                marginPagesDisplayed={this.state.perPage}
                                pageRangeDisplayed={2}
                                onPageChange={this.handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"subPagination"}
                                activeClassName={"activePage "}
                                breakClassName={'eachPage'}
                                previousClassName={'eachPagePrevNex'}
                                nextClassName={'eachPagePrevNex'}
                                pageClassName={'eachPage'}
                            />}
                            {this.state.loadprofiles &&
                                <div className='flexcolumn center verticalcenter p-5 fullWidth hint '>
                                    {ts2('Loading Available Profiles',this.props.userLogin.language)}
                                    <Spinner animation="border" className='mt-3' variant="warning" />

                                </div>
                            }

                        </p>}
                        {this.state.showSuccess &&
                            <div className='sstitle successColor mt-3'>
                                <i class="far fa-check-circle"></i>
                                {ts2('You have successfully uploaded the profiles.',this.props.userLogin.language)}
                                <p className='link ssstitle' onClick={() => { history.push("/ProfileManagement") }}> View these changes</p>
                            </div>
                        }






                        {/* <p className='p-0 pt-4 bold '> - A JSON file </p>
                    <p className='pl-3 hint normal'>
                        JSON stands for JavaScript Object Notation. It's  a text format for storing and transporting data.
                        <p> JSON Example:{'{"id": 1,"clientID": 1,"firstName": "Roucoz","lastName": "Karam","emailAddress": "rock.krm1@gmail.com",...}'}</p>
                    </p>
                    <Button variant='success'>Import from JSON File</Button> */}
                    </div>
                </div>
            </div>
        )
    }
}
//#region "Redux"
const mapStateToProps = state => {
    
    return {
        userLogin: state.SaveLogin.userData
    };
};
export default connect(
    mapStateToProps
)(importProfiles)
//#endregion
