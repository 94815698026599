import React, { Component } from 'react';
import { Container, Spinner, Badge, Button, Form, Col } from 'react-bootstrap'
import MessageBox from '../components/MessageBox';
import * as G from '../components/global'
import '../styles/glob.css'
import history from '../components/history';
import { translateScreen as ts , translateScreen2 as ts2 , translateDate as td} from '../components/dictionary'
import { connect } from 'react-redux';
import qs from 'qs'

import Select from 'react-select'
class ManageTerms extends Component {

	constructor(props) {
		super(props);

		this.state = {
				list:[],
				listloading:true,
				fields: {},
				modalShow: false,
				modalSubject: 'Delete Site',
				modalBody: 'Are you sure you want to delete this Site?',
				justinfo: true,
				errors: {},
				deleteID: -1,
                filteredCountryList: [],            
                CountryList: [],
                CountriesOptions:[],
		}
		this.handleChange = this.handleChange.bind(this);
	}
    
	componentDidMount(){
        
		    this.loadData();
            const show = qs.parse(history.location.search, { ignoreQueryPrefix: true }).show;
            if(show == "1")
            {
                this.setState({modalShow:true});
            }
	}
	loadData = async ()=>{
		this.setState({listloading:true})
		var englishresults = await G.getData("termscontent/query",[["filter"," language='en'"]]);
		englishresults = await englishresults.data;
        var frenchresults = await G.getData("termscontent/query",[["filter"," language='fr'"]]);
		frenchresults = await frenchresults.data;
        var eng = englishresults[0];
        var fr = frenchresults[0];
		this.setState({listloading:false, fields: { englishtext:eng.text, frenchtext:fr.text }});
		
	}
	
	handleChange(e) {
       let { fields, errors } = this.state;
        fields[e.target.name] = e.target.value;
        delete errors[e.target.name];

        this.setState({ fields });
    }
	render() {
		const { errors, fields } = this.state;
        var clientuid = this.props.userLogin.site && this.props.userLogin.site.uid;
        //if (this.props.userLogin.language)language=this.props.userLogin.language;
		return (
			<>
			
            { !this.state.listloading && <>
                    <div className='content-header'>
                    <div className='container-fluid'>
                        <div className='row mb-2'>
                            <div className='col-sm-6'>
                        <h1>{ts('Terms and conditions content',this.props.userLogin.language)}</h1>
                        </div>
                        </div>
                        </div>
                        </div>
                        <section className='content'>
                        <div className="row">
                        <Form style={{width:"100%"}}>
                            <div className='col-sm-12 col-md-8 '>
                                {G.renderTextArea("English Text", 'text', "Enter english text here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true,"englishtext")}
                                {G.renderTextArea("French Text", 'text', "Enter french text here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true,"frenchtext")}
                                </div>
                        </Form>
                       </div><div className='row'>
                           <div className='col-md-12'>
                        <Button variant='success ml-3 mt-5' onClick={() => {   this.updateContent() }}>{ts2('Save',this.props.userLogin.language)}</Button>
                        
                   </div> </div>
                <div className='row'>
                
                </div>
                </section>
                <MessageBox
                    show={this.state.modalShow}
                    title={ts("Changes are saved",this.props.userLogin.language)}
                    body={<div><center><Button variant="success" onClick={()=>{this.setState({ modalShow: false }); history.push("/ManageTerms")}}>OK</Button></center></div>}
                    onHide={() => { this.setState({ modalShow: false }) }}

                />
                </>}
                {this.state.listloading && <div className='flexcolumn verticalcenter  text-center col-12' style={{ marginTop: '25%' }}>
                    <div className='flexrow center fullWidth '>
                        <Spinner animation="grow" className='m-3' variant="warning" />
                        <Spinner animation="grow" className='m-3' variant="info" />
                        <Spinner animation="grow" className='m-3' variant="danger" />

                    </div>
                    <div className='hint remarks xlowalpha'>{ts2('Loading Data',this.props.userLogin.language)}</div>
                </div>}
			</>
		)
	}
	updateContent = async (recordID) => {
        const { englishtext, frenchtext } = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (formIsValid) {
            let result = await G.getData("termscontent/upload",[["englishtext",englishtext], ["frenchtext",frenchtext]])    
            
            let success = (result.status === 200)
            if (success) {
                window.location.href="/ManageTerms?show=1"
                //this.loadData();
                //this.setState({modalShow:true})
                //alert(ts('Changes are saved',this.props.userLogin.language));
                //window.location.reload();
            }
        }
    }
	
}


const mapStateToProps = state => {
    return {
        userLogin: state.SaveLogin.userData
    };
};

export default connect(
    mapStateToProps
)(ManageTerms);