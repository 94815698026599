import React, { Component } from 'react';
import { Container, Spinner, Badge, Button, Form } from 'react-bootstrap'
import MessageBox from '../components/MessageBox';
import * as G from '../components/global'
import '../styles/glob.css'
import history from '../components/history';
import { translateScreen as ts , translateScreen2 as ts2 , translateDate as td} from '../components/dictionary'
import { connect } from 'react-redux';
import qs from 'qs'

class Avatars extends Component {

	constructor(props) {
		super(props);

		this.state = {
				list:[],
				listloading:false,
				updateMode:false,
				fields: {},
				modalShow: false,
				modalSubject: 'Delete Avatar',
				modalBody: 'Are you sure you want to delete this Avatar',
				justinfo: true,
				errors: {},
				deleteID: -1,
                languagesList:[],
                imagelink:'',
                selectedFile:null,
                fieldchanging:false,
                successmodalShow:false
		}
		this.handleChange = this.handleChange.bind(this);
        this.imagechangeHandler = this.imagechangeHandler.bind(this);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        
	}
    getSnapshotBeforeUpdate(prevProps) {
        
        return { notifyRequired: prevProps.userLogin.site !== this.props.userLogin.site };
      }
      
      async componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot.notifyRequired) {
            if(this.props.userLogin.site)
            {
                await this.loadData();
            }
            
        }
        
      }
	async componentDidMount(){
        this.getlanguages();
        history.listen(async(location, action) => {
            if(location.pathname == "/Avatars" || location.pathname == "/Avatars/")
            {
            this.setState({ updateMode: false });
            if(this.props.userLogin.site)
                await this.loadData();
		    
            }
            if(location.pathname == "/Avatars/create" || location.pathname == "/Avatars/create/")
            this.setState({ updateMode: true, fields: {} });
          });
		
          if(history.location.pathname == "/Avatars/create" || history.location.pathname == "/Avatars/create/")
            this.setState({ updateMode: true, fields: {} });
            if(this.props.userLogin.site)
                await this.loadData();
	}
    getlanguages = async()=>{
		let array = [];
		array = await G.getData("languages/query",[["filter"," 1=1 order by orderindex"]]);
		array = await array.data;
		this.setState({languagesList: array});
	}
	loadData = async ()=>{
        var results = [];
		this.setState({listloading:true})
		var results = await G.getData("avatar/query",[["filter","siteid=" + this.props.userLogin.site.id]]);
		results = await results.data;
		this.setState({list:results});
        
            this.loadDataTables();
            
		
        var qid = qs.parse(history.location.search, { ignoreQueryPrefix: true }).id
        results.map((el)=>{
            if(qid == el.id)
            {
                this.openEdit(el, false);
            }
        })
	}
	loadDataTables = ()=>{
		if(this.state.list)
        {
            this.setState({ listloading: false })
        setTimeout(() => {
            
                const script = document.createElement("script");
            script.id = "dttablestats";
            script.src = '/dist/js/dttable.js';
            script.async = true;
            document.body.appendChild(script);
            
            
        }, 300);
    }
            
            
	}
	handleChange(e) {
       let { fields, errors } = this.state;
        fields[e.target.name] = e.target.value;
        delete errors[e.target.name];

        this.setState({ fields });
    }
    onBlur(e){
        this.setState({fieldchanging:false});
    }
    handleLanguageChange(e) {
		let { fields, errors,languagesList } = this.state;
		 fields[e.target.name] = e.target.value;
		 delete errors[e.target.name];

         for(var i=0;i<languagesList.length;i++)
         {
             var elem = languagesList[i];
             
             if(e.target.name.includes('_name'))
             {
                if(!fields[elem.id + "_name"] || fields[elem.id + "_name"]=='' || this.state.fieldchanging)
                {
                fields[elem.id + "_name"] = e.target.value;
                delete errors[elem.id + "_name"];
                this.setState({fieldchanging:true});
                }
             }
             if(e.target.name.includes('_description'))
             {
                if(!fields[elem.id + "_description"] || fields[elem.id + "_description"]=='' || this.state.fieldchanging)
                {
                    fields[elem.id + "_description"] = e.target.value;
                    document.getElementById(elem.id + "_description").value = e.target.value;
                    delete errors[elem.id + "_description"];
                    this.setState({fieldchanging:true});
                }
             }
         }
		 this.setState({ fields });
	 }
    imagechangeHandler(event) {
        var file = event.target.files[0];
        let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({imagelink: e.target.result});
    };
    reader.readAsDataURL(file);
        
        this.setState({selectedFile:file});
	}
    openEdit = async(item, redirect = true)=>{
        let record = { ...item }
        //record.creationdate = dateFormat(item.creationDate, "dddd, mmm dd yyyy h:MM tt")
        var fields = this.state.fields;
        var dres = await G.getData("avatartranslation/query",[["filter","avatarid="+item.id]])
            dres = await dres.data
        dres.map((elem)=>{
            
            fields[elem.languageid + "_name"] = elem.name;
            fields[elem.languageid + "_description"] = elem.description;
        })
        fields["id"] = item.id
        this.setState({ updateMode: true, imagelink:G.nodeServerSite+"mediaFiles/avatarpicture/" + item.picture, fields:fields });
        if(redirect == true)
            history.push("/Avatars/edit?id=" + item.id)
    }
	render() {
		const { errors, fields } = this.state;
        var clientslug = this.props.userLogin.site && this.props.userLogin.site.slug;
        //if (this.props.userLogin.language)language=this.props.userLogin.language;
		return (
			<>
			{!this.state.updateMode && <div>
			<section className='content-header'>
                    <div className='container-fluid'>
                    <div className='row mb-2'>
                <div className='col-sm-6'>
                    <h1 className=''>{ts('Avatars',this.props.userLogin.language)} </h1>
                    </div>
                    </div>
                    </div>
                    </section>
					<section className='content'>
                        <div className='row'>
                    <Button variant='success mt-3 mb-5 ml-3' onClick={() => { this.setState({ updateMode: true, fields: {} });history.push("/Avatars/create") }}>{ts2('Create New Avatar',this.props.userLogin.language)}</Button>
                    </div>
                    <div className='row'>
                    <div className='col-md-12 '>
                        <div className="">
                        {!this.state.loadList && <table id="example1" className="table table-bordered">
                                <thead>
                                    <tr>
                                    <th>{ts2('Action',this.props.userLogin.language)}</th>
                                        <th>{ts2('Image',this.props.userLogin.language)}</th>
                                        <th>{ts2('Name',this.props.userLogin.language)}</th>
                                        

                                    </tr>
                                </thead>

{!this.state.listloading &&
                                <tbody>
                                    {
                                        (this.state.list).map((item) => {
                                            
                                            /*this.state.clientsList.filter(val => val.key == item.clientID).map(filteredName => (
                                                clientslug = filteredName.clientslug 
                                            ))*/
                                            return (
                                                <tr key={item.id}>
                                                    <td className='xxxtitle whiteColor handCursor'
                                                        >
                                                        <Button onClick={async() => {
                                                            this.openEdit(item);
                                                        }} className="btn btn-primary" style={{width:"150px"}}>{ts2('View',this.props.userLogin.language)}</Button><br/>
														<Button onClick={() => {
                                                                
                                                                
                                                                    this.setState({
                                                                        modalShow: true,
                                                                        modalSubject: ts2('Delete Avatar',this.props.userLogin.language),
                                                                        modalBody: ts2('Are you sure you want to delete this Avatar?',this.props.userLogin.language),
                                                                        justinfo: false,
                                                                        deleteID: item.id
                                                                    })
                                                                

                                                        }} className="btn btn-danger" style={{width:"150px", marginTop:"10px"}}>{ts2('Delete',this.props.userLogin.language)}</Button>
                                                    </td>
													<td><img src={G.nodeServerSite+"mediaFiles/avatarpicture/" + item.picture} style={{width:"100px", maxWidth:"100%"}}/></td>
                                                    <td>{item.name}</td>
                                                    
                                                 

                                                </tr>)
                                        })
                                    }

                                </tbody>
    }
                            </table>}
                            {this.state.loadList &&
                                <div className='flexcolumn center verticalcenter p-5 fullWidth hint '>
                                   {ts2('Loading Avatars',this.props.userLogin.language)} 
                                    <Spinner animation="border" className='mt-3' variant="warning" />

                                </div>
                            }
                        </div>
                    </div>
                    </div>
                    </section>
					</div>}
					{
                    this.state.updateMode && <>
                    
                        <section className='content'>
                        <div className='row'>
							<div className='col-md-12'>
							<Button className='mb-4 mt-5' variant='warning' onClick={() => { this.setState({updateMode:false, fields:{}});history.go(-1) }} >{ts('Cancel / Return', this.props.userLogin.language)}</Button>
							</div>
							<div className='col-md-12'>
							<h3>{ts('Avatar details',this.props.userLogin.language)}
                            </h3>
							</div>
							
							<div className='col-md-12' style={{height:"20px"}}></div>
							<div class="col-12 col-sm-6">
<div class="card card-primary card-outline card-tabs">
<div class="card-header p-0 pt-1 border-bottom-0">
<ul class="nav nav-tabs" id="custom-tabs-two-tab" role="tablist">
<li class="pt-2 px-3"><h6 class="card-title" style={{margin:"0px"}}>Entry by language</h6></li>
{
	this.state.languagesList.map((elem)=>{
		return <li class="nav-item">
<a class={elem.code == "fr"?"nav-link active":"nav-link"} id={"custom-tabs-two-" + elem.code + "-tab"} data-toggle="pill" href={"#custom-tabs-two-" + elem.code} role="tab" aria-controls={"custom-tabs-two-" + elem.code} aria-selected="true">{elem.code}</a>
</li>
	})
}
</ul>
</div>
<div class="card-body">
<div class="tab-content" id="custom-tabs-two-tabContent">
	{
		this.state.languagesList.map((elem)=>{
			return <div class={elem.code == "fr"?"tab-pane fade show active":"tab-pane fade"} id={"custom-tabs-two-" + elem.code} role="tabpanel" aria-labelledby={"custom-tabs-two-"+elem.code+"-tab"}>
				{G.renderTextField("Title", 'text', "enter avatar title here", (elem.code == "fr"?this.handleLanguageChange:this.handleChange), fields, errors, null, '',false, this.props.userLogin.language, true,elem.id + "_name",this.onBlur)}
				{G.renderTextArea("Description", 'textarea', "enter avatar description here", (elem.code == "fr"?this.handleLanguageChange:this.handleChange), fields, errors, null, '',false, this.props.userLogin.language, true,elem.id + "_description",this.onBlur)}
			</div>
		})
	}

</div>
</div>

</div>
</div>
<div className='col-md-12' style={{height:"20px"}}></div>
<div class='col-md-4'>
<div class="form-group">
 <label for="exampleInputFile">Image</label>
<div class="input-group">
<div class="custom-file">
<input type="file" onChange={this.imagechangeHandler} class="custom-file-input" id="picture" name="picture"/>
<label class="custom-file-label" for="exampleInputFile">Choose file</label>
</div>
</div>





</div>
{this.state.imagelink && <img src={this.state.imagelink} style={{width:"150px"}}/>}
</div>
</div>
                        <div className='row'>
                           <div className='col-md-12'>
                        <Button variant='success ml-3 mt-5' onClick={() => {   fields.id==undefined || fields.id == null ? this.updateAvatar(-1) : this.updateAvatar(fields.id) }}>{ts2('Save',this.props.userLogin.language)}</Button>
                        <Button variant='warning ml-3 mt-5' onClick={() => { this.setState({ updateMode: false });history.go(-1);
                        
                        this.loadDataTables();
                    }}>{ts('Cancel',this.props.userLogin.language)}</Button>
                   </div> </div>
                <div className='row'>
                
                </div>
                </section>
                </>
    }
	<MessageBox
                    show={this.state.modalShow}
                    title={this.state.modalSubject}
                    body={this.state.modalBody}
                    onHide={() => { this.setState({ modalShow: false }) }}
                    middle={!this.state.justinfo && {
                        action: () => {
                            this.deleteAvatar()


                        }, text: ts2('Delete Avatar',this.props.userLogin.language), variant: 'danger'
                    }}
                    right={{ action: () => { this.setState({ modalShow: false }) }, text: this.state.justinfo ? 'OK' : ts('Cancel',this.props.userLogin.language), variant: 'success' }}


                />
			<MessageBox
                    show={this.state.successmodalShow}
                    title={ts("Changes are saved",this.props.userLogin.language)}
                    body={<div><center><Button variant="success" onClick={()=>{this.setState({ successmodalShow: false });}}>OK</Button></center></div>}
                    onHide={() => { this.setState({ modalShow: false }) }}

                />
            </>
		)
	}
    
	updateAvatar = async (recordID) => {
        let errors = {};
        let formIsValid = true;
        console.log("recordID=", recordID)
        let parameters = [];
        this.state.languagesList.map((elem)=>{
            var name = this.state.fields[elem.id + "_name"]
            var description = this.state.fields[elem.id + "_description"]
            if(name == undefined || name == '')
            {
                formIsValid = false;
                errors[elem.id + '_name'] = 'This field is required';
            }
            if(description == undefined || description == '')
            {
                formIsValid = false;
                errors[elem.id + '_description'] = 'This field is required';
            }
            if(elem.code == "en")
            {
                this.state.fields["name"] = name;
                this.state.fields["description"] = description;
            }
            parameters.push(["name_" + elem.code,name]);
            parameters.push(["description_" + elem.code,description]);
        });
        
        
        var {name, description} = this.state.fields; 
        if (!name) { formIsValid = false; errors["name"] = "Avatar Name cannot be empty"; }
        this.setState({errors});
        if (formIsValid) {
            parameters.push(["name" ,name]);
            parameters.push(['description' ,description]);
            parameters.push(['picture' ,this.state.selectedFile]);
            parameters.push(['siteid' ,this.props.userLogin.site.id]);
            parameters.push(['avatarId' , recordID]);

            
           //parameters.push(['clientID', this.props.userLogin.site.id])
            let result = await G.uploadData('avatar/upload', parameters)
            console.log(result)
            let success = (result.status === 200)
            if (success) {
                if(recordID === -1)
                {
                    var logtxt = this.props.userLogin.username + " has created an avatar " + name;
                    await G.SaveUserLog(logtxt, this.props.userLogin.id);
                }
                else{
                    var logtxt = this.props.userLogin.username + " has edited the avatar info of " + name;
                    await G.SaveUserLog(logtxt, this.props.userLogin.id);
                }
                this.setState({ updateMode: false, fields: {}, successmodalShow:recordID!==-1 })
                this.loadData()
                history.push("/Avatars")
            }
        }
    }
	deleteAvatar =async () => {
		let parameters = [['filter', "id=" + this.state.deleteID]]
		var result = await G.getData('avatar/delete', parameters, true)
		result = await result.status;
		if (result === 200) {
			this.setState({ deleteID: -1, modalShow:false })
			//this.loadData()
			window.location.reload();
		}else{
			this.setState({
				modalShow: true,
				modalSubject: 'Error Appeared',
				modalBody: 'Problem Occured while hiding Record. Please Try Again',
				justinfo: true
			})
		}
	}
}


const mapStateToProps = state => {
    return {
        userLogin: state.SaveLogin.userData
    };
};

export default connect(
    mapStateToProps
)(Avatars);