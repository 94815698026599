import React, { Component } from 'react'
import history from '../components/history';

import { Button, Container, Form, Spinner } from 'react-bootstrap';
import * as G from '../components/global';
import { connect } from 'react-redux';
import { translateScreen as ts , translateScreen2 as ts2 , translateDate as td} from '../components/dictionary'

class resetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
            result: [],
            load: false,
            errorMessage: '',
            successMessage: ''
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        let { fields, errors } = this.state;
        fields[e.target.name] = e.target.value;
        this.setState({ errorMessage: '', successMessage: '' })
        delete errors[e.target.name];
        this.setState({ loginFailed: false })
        this.setState({ fields });
    }
    signInSubmit = async (e) => {
        e.preventDefault();
        this.setState({ load: true })
        let formIsValid = true
        const { newpassword, confirmpassword } = this.state.fields;
        let errors = {};


        if (!newpassword) { formIsValid = false; errors["newpassword"] = ts2("New Password cannot be empty",this.props.userLogin.language); }
        if (!confirmpassword) { formIsValid = false; errors["confirmpassword"] = ts2("Confirm Password cannot be empty",this.props.userLogin.language); }
        if (!newpassword) { formIsValid = false; errors["newpassword"] = ts2("Password must be set",this.props.userLogin.language); } else {
            if (newpassword.length < 8) { formIsValid = false; errors["newpassword"] = ts2("Password is too short. Minumum 8 charcters",this.props.userLogin.language); }
            else {
                if (!confirmpassword) { formIsValid = false; errors["confirmpassword"] = "Passwords does not match"; } else {
                    if (confirmpassword !== newpassword) { formIsValid = false; errors["confirmpassword"] = "Passwords does not match"; };

                }
            }
        }


        this.setState({ errors });

        if (formIsValid) {
            
            var result = await G.getData('ChangePassword', [['username', this.props.userLogin.username], ['password', newpassword], ['log', '']])
            
            if (result.status)

                if (result.status === 200) {
                    this.setState({ successMessage: ts2('Your password has been successfully reset.',this.props.userLogin.language), fields: {}, errors: {} })


                } else {
                    this.setState({ errorMessage: ts2('Something hapened while reseting your email. Please try again',this.props.userLogin.language) })
                }
        }





        this.setState({ load: false })

    }


    render() {
        let language=0;
		if(this.props.userLogin!=null)language=this.props.userLogin.language
        const { errors, fields } = this.state;
        return (
            <Container fluid >

                <h4 className='mb-5 mt-5 '>{ts2('Reset Password',language)}</h4>
                {/* <h1>{JSON.stringify(this.props.history.location.state)}</h1> */}
                {this.props.history.location && this.props.history.location.state && this.props.history.location.state.rs && <h6 className='mb-4 warningColor'> <span className='warningYellow'> <i class="fas fa-exclamation-circle"></i> </span> You requested a reset password. Please change your password below or ignore this to leave your old password.</h6>}
                <Form name="form" onSubmit={this.signInSubmit.bind(this)} className='col-4'>

                    <Form.Group>
                        {G.renderTextField("New Password", 'password', ts2("Enter new password",language), this.handleChange, fields, errors, ts2('(Minimum 8 characters)',language),'',false,language)}
                    </Form.Group>
                    <Form.Group>
                        {G.renderTextField("Confirm Password", 'password', ts2("Confirm new password",language), this.handleChange, fields, errors,'','',false,language)}
                    </Form.Group>

                    {this.state.errorMessage !== '' && <div className='flexrow remarks warningYellow fullWidth mt-3 verticalcenter'>
                        <i class="fas fa-exclamation-circle"></i>
                        <span className='midremarks warningColor ml-2'>{this.state.errorMessage}</span>
                    </div>}

                    {this.state.successMessage !== '' && <div className='flexrow remarks successColor fullWidth mt-3 verticalcenter'>
                        <i class="far fa-check-circle"></i>
                        <span className='midremarks successColor ml-2'>{this.state.successMessage}</span>
                    </div>}

                    <div className='flexrow '>
                        <div className='flexrow  '>
                            {<Button variant='success' className='mt-3 pl-5 pr-5 text-center' type="submit">
                                {this.state.load && <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className='mr-2'
                                />}
                                {ts2('Change Password', language)}</Button>}
                            {this.props.history.location && this.props.history.location.state && this.props.history.location.state.rs && 
                                <Button onClick={() => { history.push("/") }} variant='warning' className='ml-3 mt-3 pl-5 pr-5 text-center' type="submit">{ts2('Ignore Change', language)}</Button>}
                        </div>
                    </div>
                </Form>
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
        userLogin: state.SaveLogin.userData
    };
};

export default connect(
    mapStateToProps
)(resetPassword);