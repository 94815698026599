import React, { Component } from 'react';
import { renderPageContainer,Container, Spinner, Badge, Button, Form } from 'react-bootstrap'
import MessageBox from '../components/MessageBox';
import * as G from '../components/global'
import '../styles/glob.css'
import history from '../components/history';
import { translateScreen as ts , translateScreen2 as ts2 , translateDate as td} from '../components/dictionary'
import { connect } from 'react-redux';

class TicketsScanning extends Component {

	constructor(props) {
		super(props);

		this.state = {
				ticketrule:{},
				updateMode:false,
				fields: {},
				modalShow: false,
				modalSubject: '',
				modalBody: '',
				justinfo: true,
				errors: {},
                show:'',
                ruleId:-1,
                loading:false
		}
		this.handleChange = this.handleChange.bind(this);
	}
	componentDidMount(){
        if(this.props.userLogin.site)
		    this.loadData();
	}
    getSnapshotBeforeUpdate(prevProps) {
        
        return { notifyRequired: prevProps.userLogin !== this.props.userLogin };
      }
      
      async componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot.notifyRequired) {
            await this.loadData();
        }
        
      }
	loadData = async ()=>{
		this.setState({listloading:true})
		var result = await G.getData("ticketscanningrules/query",[["filter","siteid=" + this.props.userLogin.site.id]]);
		result = await result.data;
        if(result.length>0)
        {
		    this.setState({ruleId:result[0].id, fields:{maxamountlimit:result[0].maxamountlimit,ticketscanlimitperday:result[0].ticketscanlimitperday, enabled:result[0].enabled}});
        }
	}
	
	handleChange(e) {
       let { fields, errors } = this.state;
        fields[e.target.name] = e.target.value;
        delete errors[e.target.name];

        this.setState({ fields });
    }
	render() {
		const { errors, fields } = this.state;
        //if (this.props.userLogin.language)language=this.props.userLogin.language;
		return (
			<>
            <section className='content'>
			<div className="row" style={{marginTop:"100px"}}>
                <div className="col-md-12">
                    <h3>{ts("Tickets scanning rules", this.props.userLogin.language)}</h3>
                    
                </div>
                <div className="col-md-6" style={{marginTop:"30px"}}>
                {G.renderTextField("Max amount that can be scanned per receipt (in EUR)", 'number', "enter max amount that can be scanned per receipt (in EUR)", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language, true,"maxamountlimit")}
                    {G.renderTextField("Max number of scans per customer per day", 'number', "enter max number of scans per customer per day", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language, true,"ticketscanlimitperday")}
                    <div class="form-group">
					<label for="enabled">Status</label>
				  <select name="enabled" id="enabled" onChange={this.handleChange} className="custom-select">
                      <option value="">Please select</option>
                      {fields["enabled"] == "1" && <option value="1" selected>Enabled</option>}
					  {fields["enabled"] != "1" && <option value="1">Enabled</option>}
					  {fields["enabled"] == "0" && <option value="0" selected>Disabled</option>}
                      {fields["enabled"] != "0" && <option value="0">Disabled</option>}
				  </select>
                  {errors['enabled'] && <div className='remarks notValidColor'>{errors['enabled']}</div>}
                </div>
                <Button variant='primary' onClick={async() => { 
                    
                    this.setState({ loading: true});
                    var isvalid = true;
                    if(fields["maxamountlimit"] ==null || fields["maxamountlimit"]=='')
                    {
                        this.state.errors["maxamountlimit"] = "This field is required.";
                        isvalid = false;
                    }
                    else if(isNaN(fields["maxamountlimit"])){
                        this.state.errors["maxamountlimit"] = "Please enter a valid number.";
                        isvalid = false;
                    }
                    if(fields["ticketscanlimitperday"] ==null || fields["ticketscanlimitperday"]=='')
                    {
                        this.state.errors["ticketscanlimitperday"] = "This field is required.";
                        isvalid = false;
                    }
                    else if(isNaN(fields["ticketscanlimitperday"])){
                        this.state.errors["ticketscanlimitperday"] = "Please enter a valid number.";
                        isvalid = false;
                    }
                    if(fields["enabled"] ==null || fields["enabled"]=='')
                    {
                        this.state.errors["enabled"] = "This field is required.";
                        isvalid = false;
                    }
                    if(isvalid)
                    {
                    if(this.state.ruleId == -1)
                        await G.getData("ticketscanningrules/insert",[["maxamountlimit",fields["maxamountlimit"]],["ticketscanlimitperday",fields["ticketscanlimitperday"]],["enabled",fields["enabled"]],["siteid",this.props.userLogin.site.id]])
                    else
                        await G.getData("ticketscanningrules/update",[["maxamountlimit",fields["maxamountlimit"]],["ticketscanlimitperday",fields["ticketscanlimitperday"]],["enabled",fields["enabled"]],["siteid",this.props.userLogin.site.id],["id",this.state.ruleId]])
                        this.setState({ loading: false});
                        var logtxt = this.props.userLogin.username + " updated ticket scanning settings ";
                    await G.SaveUserLog(logtxt, this.props.userLogin.id);
                        alert("Rules saved successfully");
                    }
                    else{
                        this.setState({loading: false, errors});
                        
                    }
                    }}>{ts2('Save',this.props.userLogin.language)}
                 {this.state.loading &&<Spinner animation='grow' style={{width:"10px", height:"10px", verticalAlign:"middle", marginLeft:"10px"}}></Spinner>}
                 </Button>
                </div>
            </div>
            
            </section>
	<MessageBox
                    show={this.state.modalShow}
                    
                    body={
                        <>
                        {this.state.show == "points" &&
                        <div className="container">
                            <div className="row" style={{marginTop:"20px"}}>
                                <div class="col-md-12">
                                    <h4 style={{textAlign:"center"}}>{this.state.modalSubject}</h4>
                                </div>
                            </div>
                            <div className="row" style={{marginTop:"20px"}}>
                                <table style={{margin:"auto"}}><tr><td>
                                    {G.renderTextField("Point", 'number', "Enter points here", this.handleChange, fields, errors, null, '', false,this.props.userLogin.language, true, "points")}
                                    </td><td style={{width:"10px"}}></td><th>=</th><td style={{width:"10px"}}></td><td>
                                    {G.renderTextField("Euro", 'number', "Enter rate here", this.handleChange, fields, errors, null, '', false,this.props.userLogin.language, true, "rateperpoint")}
                                        </td></tr></table>
                            </div>
                            <div className="row" style={{marginTop:"20px"}}>
                            <div className='col-md-12'>
                            <table style={{margin:"auto"}}>
                                    <tr><td><Button variant='success' style={{width:"150px"}} onClick={async() => { 
                                        await G.getData("pointrules/update",[["siteid",this.props.userLogin.site.id],["rateperpoint", this.state.fields["rateperpoint"]],["points", this.state.fields["points"]],["edituserid", this.props.userLogin.id],["id", this.state.fields["pointid"]]]);
                                        this.setState({ modalShow: false, show:'',modalSubject:'' })
                                        this.loadData()
                                     }}>{ts2('Save',this.props.userLogin.language)}</Button></td></tr>
                                    <tr><td style={{height:"10px"}}></td></tr>
                                    <tr><td><Button variant='warning' style={{width:"150px"}} onClick={() => { this.setState({ modalShow: false, show:'',modalSubject:'' })  }}>{ts2('Cancel',this.props.userLogin.language)}</Button></td></tr>
                                    </table>
                            </div>
                        </div>
                        </div>}
                        {this.state.show == "tokens" &&
                        <div className="container">
                            <div className="row" style={{marginTop:"20px"}}>
                                <div class="col-md-12">
                                    <h4 style={{textAlign:"center"}}>{this.state.modalSubject}</h4>
                                </div>
                            </div>
                        <div className="row" style={{marginTop:"20px"}}>
                            <table  style={{margin:"auto"}}><tr><td>
                                {G.renderTextField("Token", 'number', "Enter tokens here", this.handleChange, fields, errors, null, '', false,this.props.userLogin.language, true, "tokens")}
                                </td><td style={{width:"10px"}}></td><th>=</th><td style={{width:"10px"}}></td><td>
                                {G.renderTextField("Point", 'number', "Enter rate here", this.handleChange, fields, errors, null, '', false,this.props.userLogin.language, true, "ratepertoken")}
                                    </td></tr></table>
                        </div>
                        <div className="row" style={{marginTop:"20px"}}>
                            <div className='col-md-12'>
                                <table style={{margin:"auto"}}>
                                    <tr><td><Button variant='success' style={{width:"150px"}} onClick={async() => {
                                            await G.getData("tokenrules/update",[["siteid",this.props.userLogin.site.id],["ratepertoken", this.state.fields["ratepertoken"]],["tokens", this.state.fields["tokens"]],["edituserid", this.props.userLogin.id],["id", this.state.fields["tokenid"]]]);
                                            this.setState({ modalShow: false, show:'',modalSubject:'' })
                                        this.loadData()
                                      }}>{ts2('Save',this.props.userLogin.language)}</Button></td></tr>
                                    <tr><td style={{height:"10px"}}></td></tr>
                                    <tr><td><Button variant='warning' style={{width:"150px"}} onClick={() => { this.setState({ modalShow: false, show:'',modalSubject:'' })  }}>{ts2('Cancel',this.props.userLogin.language)}</Button></td></tr>
                                    </table>
                                
                                
                                
                            </div>
                        </div>
                    </div>
                        }
                        </>
                    }
                    onHide={() => { this.setState({ modalShow: false }) }}
                    /*middle={!this.state.justinfo && {
                        action: () => {
                            this.deleteCategory()


                        }, text: ts2('Delete category',this.props.userLogin.language), variant: 'danger'
                    }}
                    right={{ action: () => { this.setState({ modalShow: false }) }, text: this.state.justinfo ? 'OK' : ts('Cancel',this.props.userLogin.language), variant: 'success' }}*/


                />
			</>
            )
		
	}
	
}


const mapStateToProps = state => {
    return {
        userLogin: state.SaveLogin.userData
    };
};

export default connect(
    mapStateToProps
)(TicketsScanning);