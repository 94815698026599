import React, { Component } from 'react';
import { Container, Spinner, Badge, Button, Form } from 'react-bootstrap'
import MessageBox from '../components/MessageBox';
import * as G from '../components/global'
import '../styles/glob.css'
import history from '../components/history';
import { translateScreen as ts , translateScreen2 as ts2 , translateDate as td} from '../components/dictionary'
import { connect } from 'react-redux';
import qs from 'qs'

class Sites extends Component {

	constructor(props) {
		super(props);

		this.state = {
				list:[],
				listloading:false,
				updateMode:false,
				fields: {},
				modalShow: false,
				modalSubject: 'Delete Site',
				modalBody: 'Are you sure you want to delete this Site?',
				justinfo: true,
				errors: {},
				deleteID: -1,
                siteaddresses:[],
                successmodalShow:false,
                imagelink:'',
                selectedFile:null,
		}
		this.handleChange = this.handleChange.bind(this);
        this.handlecombinationChange = this.handlecombinationChange.bind(this);        
        this.imagechangeHandler = this.imagechangeHandler.bind(this);
	}
    imagechangeHandler(event) {
        var file = event.target.files[0];
        let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({imagelink: e.target.result});
    };
    reader.readAsDataURL(file);
        
        this.setState({selectedFile:file});
	}
    getSnapshotBeforeUpdate(prevProps) {
            
        return { notifyRequired: prevProps.userLogin !== this.props.userLogin };
      }
      componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot.notifyRequired) {
            this.loadData();
        }
      }
	componentDidMount(){
        if(this.props.userLogin.site)
		    this.loadData();
		history.listen((location, action) => {
            if(location.pathname == "/Sites" || location.pathname == "/Sites/")
            this.setState({ updateMode: false });
            if(location.pathname == "/Sites/create" || location.pathname == "/Sites/create/")
            this.setState({ updateMode: true, fields: {} });
          });
          if(history.location.pathname == "/Sites/create" || history.location.pathname == "/Sites/create/")
            this.setState({ updateMode: true, fields: {} });
	}
	loadData = async ()=>{
		this.setState({listloading:true})
		var results = await G.getData("client/query",[["filter","groupid=" + this.props.userLogin.site.groupid]]);
		results = await results.data;
		this.setState({list:results});
        console.log("CLIENT LIST", results);
		this.loadDataTables();
        var qid = qs.parse(history.location.search, { ignoreQueryPrefix: true }).id
        results.map((el)=>{
            if(qid == el.id)
            {
                this.openEdit(el, false);
            }
        })
	}
	loadDataTables = ()=>{
		
            try{
            if(document.getElementById("dttablestats")!=null)
            {
                document.getElementById("dttablestats").remove();
            }
        }
        catch(e){}
                const script = document.createElement("script");
            script.id = "dttablestats";
            script.src = '/dist/js/dttable.js';
            script.async = true;
            document.body.appendChild(script);
            
            this.setState({ listloading: false })
	}
	handleChange(e) {
       let { fields, errors } = this.state;
        fields[e.target.name] = e.target.value;
        delete errors[e.target.name];

        this.setState({ fields });
    }
    handlecombinationChange(e) {
        let { fields, errors, siteaddresses } = this.state;
         fields[e.target.name] = e.target.value;
         delete errors[e.target.name];
        siteaddresses.map((el,index)=>{
            el.address = fields["address_" + index];
        })
         this.setState({ fields, siteaddresses });
         console.log("Addresses", siteaddresses)
     }
    openEdit = async(item, redirect = true)=>{
        let record = { ...item }
        //record.creationdate = dateFormat(item.creationDate, "dddd, mmm dd yyyy h:MM tt")
        var addresses = await G.getData("clientaddress/query",[["filter","clientID=" + item.id]]);
        addresses = await addresses.data;
        
        this.setState({ updateMode: true,siteaddresses:addresses, fields: { slug:item.slug, companyName:item.companyName,contactPerson:item.contactPerson,contactPersonNo:item.contactPersonNo,email:item.email,senderEmail:item.senderEmail,country:item.country,city:item.city,address:item.address, id:item.id,website:item.website,facebook:item.facebook,instagram:item.instagram,twitter:item.twitter,linkedin:item.linkedin,pinterest:item.pinterest,tiktok:item.tiktok } });
        addresses.map((el,index)=>{
            this.state.fields["address_" + index] = el.address;
        });
        this.setState({imagelink:G.nodeServerSite+"mediaFiles/sitelogo/" + item.applogo,fields:this.state.fields})
        if(redirect == true)
            history.push("/Sites/edit?id=" + item.id)
    }
	render() {
		const { errors, fields } = this.state;
        var clientslug = this.props.userLogin.site && this.props.userLogin.site.slug;
        
        //if (this.props.userLogin.language)language=this.props.userLogin.language;
		return (
			<>
			{!this.state.updateMode && <div>
			<section className='content-header'>
                    <div className='container-fluid'>
                    <div className='row mb-2'>
                <div className='col-sm-6'>
                    <h1 className=''>{ts('Sites',this.props.userLogin.language)} </h1>
                    </div>
                    </div>
                    </div>
                    </section>
					<section className='content'>
                        <div className='row'>
                    <Button variant='success mt-3 mb-5 ml-3' onClick={() => { this.setState({ updateMode: true, fields: {} });history.push("/Sites/create") }}>{ts2('Create New Site',this.props.userLogin.language)}</Button>
                    </div>
                    <div className='row'>
                    <div className='col-md-12 '>
                        <div className="">
                         <table id="example1" className="table table-bordered">
                                <thead>
                                    <tr>
                                    <th>{ts2('Action',this.props.userLogin.language)}</th>
                                        <th>{ts2('Company Name',this.props.userLogin.language)}</th>
                                        <th>{ts2('Slug',this.props.userLogin.language)}</th>
                                        <th>{ts2('Contact Person',this.props.userLogin.language)}</th>
                                        <th>{ts2('Phone',this.props.userLogin.language)}</th>
                                        <th>{ts2('Email Address',this.props.userLogin.language)}</th>
                                        <th>{ts2('Status',this.props.userLogin.language)}</th>
                                        

                                    </tr>
                                </thead>

                                {!this.state.listloading &&
                                <tbody>
                                    {
                                        (this.state.list).map((item) => {
                                            
                                            /*this.state.clientsList.filter(val => val.key == item.clientID).map(filteredName => (
                                                clientslug = filteredName.clientslug 
                                            ))*/
                                            return (
                                                <tr key={item.id}>
                                                    <td className='xxxtitle whiteColor handCursor'
                                                        >
                                                        <Button onClick={() => {
                                                            this.openEdit(item)
                                                        }} className="btn btn-primary" style={{width:"150px"}}>{ts2('View',this.props.userLogin.language)}</Button><br/>
														{item.active == 1 &&<Button onClick={() => {
                                                                
                                                                
                                                                    this.setState({
                                                                        modalShow: true,
                                                                        modalSubject: ts2('Disable site',this.props.userLogin.language),
                                                                        modalBody: ts2('Are you sure you want to disable this Site?',this.props.userLogin.language),
                                                                        justinfo: false,
                                                                        deleteID: item.id
                                                                    })
                                                                

                                                        }} className="btn btn-danger" style={{width:"150px", marginTop:"10px"}}>{ts2('Disable',this.props.userLogin.language)}</Button>}
                                                        {item.active == 0 &&<Button onClick={async() => {
                                                                
                                                                
                                                                var result = await G.getData('site/enable',[["id",item.id]]);
                                                                result = await result.status;
                                                                if (result === 200) {
                                                                    this.setState({ deleteID: -1, modalShow:false })
                                                                    this.loadData()
                                                                    //window.location.reload();
                                                                }else{
                                                                    this.setState({
                                                                        modalShow: true,
                                                                        modalSubject: 'Error Appeared',
                                                                        modalBody: 'Problem Occured while Disabling Site. Please Try Again',
                                                                        justinfo: true
                                                                    })
                                                                }

                                                    }} className="btn btn-success" style={{width:"150px", marginTop:"10px"}}>{ts2('Activate',this.props.userLogin.language)}</Button>}
                                                    </td>
                                                    <td>{item.companyName}</td>
                                                    <td>{item.slug}</td>
                                                    <td>{item.contactPerson}</td>
                                                    <td>{item.contactPersonNo}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.active == 1?"Active":"Disabled"}</td>
                                                    
                                                 

                                                </tr>)
                                        })
                                    }

                                </tbody>
    }
                            </table>
                            {this.state.loadList &&
                                <div className='flexcolumn center verticalcenter p-5 fullWidth hint '>
                                   {ts2('Loading Sites',this.props.userLogin.language)} 
                                    <Spinner animation="border" className='mt-3' variant="warning" />

                                </div>
                            }
                        </div>
                    </div>
                    </div>
                    </section>
					</div>}
					{
                    this.state.updateMode && <>
                    <div className='content-header'>
                    <div className='container-fluid'>
                        <div className='row mb-2'>
                            <div className='col-sm-6'>
                        <h1>{ts2('Edit Site Name',this.props.userLogin.language)}</h1>
                        </div>
                        </div>
                        </div>
                        </div>
                        <section className='content'>
                        <div className="row">
                        <Form style={{width:"100%"}}>
                            <div className='col-sm-12 col-md-8 '>
                                {G.renderTextField("Slug", 'text', "Slug will be generated Automatically", this.handleChange, fields, errors, null, '', true,this.props.userLogin.language)}
                                {G.renderTextField("Company name", 'text', "Add the Company Name here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true,"companyName")}
                                {G.renderTextField("Contact Person", 'text', "Add the contact person here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true,"contactPerson")}
                                {G.renderTextField("Phone", 'tel', "Add the Phone number here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true,"contactPersonNo")}
                                {G.renderTextField("Email", 'email', "Add the email here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true,"email")}
                                {G.renderTextField("Sender Email", 'email', "Add the Sender email here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true,"senderEmail")}
                                {G.renderTextField("Country", 'text', "Add the Country here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true,"country")}
                                {G.renderTextField("City", 'text', "Add the City here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true,"city")}
                    {G.renderTextField("Address", 'text', "Add the Address here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true,"address")}
                    {G.renderTextField("Website", 'text', "Add Wbsite here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true)}
                    {G.renderTextField("Facebook", 'text', "Add Facebook here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true)}
                    {G.renderTextField("Instagram", 'text', "Add Instagram here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true)}
                    {G.renderTextField("Twitter", 'text', "Add Twitter here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true)}
                    {G.renderTextField("LinkedIn", 'text', "Add LinkedIn here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true)}
                    {G.renderTextField("Pinterest", 'text', "Add Pinterest here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true)}
                    {G.renderTextField("TikTok", 'text', "Add TikTok here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,true)}
                    <div class='col-md-12'>
<div class="form-group">
 <label for="exampleInputFile">App logo</label>
<div class="input-group">
<div class="custom-file">
<input type="file" onChange={this.imagechangeHandler} class="custom-file-input" id="logo" name="logo"/>
<label class="custom-file-label" for="exampleInputFile">Choose file</label>
</div>
</div>





</div>
{this.state.imagelink && <img src={this.state.imagelink} style={{width:"150px"}}/>}
</div>
                            {this.state.fields.id && <><h5>{ts("Address combinations", this.props.userLogin.language)} <a className="btn btn-primary" onClick={()=>{this.state.siteaddresses.push({id:-1,clientID:this.state.fields.id,address:''});this.setState({siteaddresses:this.state.siteaddresses});}}>{ts("Add", this.props.userLogin.language)}</a></h5>
                            {
                                
                            this.state.siteaddresses.map((el, index)=>{
                                return(
                                G.renderTextField("Address " + (index+1), 'text', "Add the Address here", this.handlecombinationChange, fields, errors, null, '',false, this.props.userLogin.language,true,"address_" + index)
                                );
                            }) }</>}
                            </div>
                        </Form>
                       </div><div className='row'>
                           <div className='col-md-12'>
                        <Button variant='success ml-3 mt-5' onClick={() => {   fields.id == null ? this.updateSite(-1) : this.updateSite(fields.id) }}>{ts2('Save',this.props.userLogin.language)}</Button>
                        <Button variant='warning ml-3 mt-5' onClick={() => { this.setState({ updateMode: false });history.go(-1);
                        
                        this.loadDataTables();
                    }}>{ts('Cancel',this.props.userLogin.language)}</Button>
                   </div> </div>
                <div className='row'>
                
                </div>
                </section>
                </>
    }
	<MessageBox
                    show={this.state.modalShow}
                    title={this.state.modalSubject}
                    body={this.state.modalBody}
                    onHide={() => { this.setState({ modalShow: false }) }}
                    middle={!this.state.justinfo && {
                        action: () => {
                            this.disableSite()


                        }, text: ts2('Disable site',this.props.userLogin.language), variant: 'danger'
                    }}
                    right={{ action: () => { this.setState({ modalShow: false }) }, text: this.state.justinfo ? 'OK' : ts('Cancel',this.props.userLogin.language), variant: 'success' }}


                />
                <MessageBox
                    show={this.state.successmodalShow}
                    title={ts("Changes are saved",this.props.userLogin.language)}
                    body={<div><center><Button variant="success" onClick={()=>{this.setState({ successmodalShow: false });}}>OK</Button></center></div>}
                    onHide={() => { this.setState({ modalShow: false }) }}

                />
			</>
		)
	}
	updateSite = async (recordID) => {
        console.log("recordID=", recordID)
        const { companyName,contactPerson,contactPersonNo,email,senderEmail,country,city,address,website,facebook,instagram,twitter,linkedin,pinterest,tiktok,applogo } = this.state.fields;
        
        let errors = {};
        let formIsValid = true;
        if (!companyName) { formIsValid = false; errors["companyName"] = "company Name cannot be empty"; }
        if (!contactPerson) { formIsValid = false; errors["contactPerson"] = "contact Person cannot be empty"; }
        if (!contactPersonNo) { formIsValid = false; errors["contactPersonNo"] = "Phone cannot be empty"; }
        if (!email) { formIsValid = false; errors["email"] = "Email be empty"; }
        if (!senderEmail) { formIsValid = false; errors["senderEmail"] = "Sender Email be empty"; }
        if (formIsValid) {
            let parameters = [['companyName', companyName], ['contactPerson', contactPerson], ['contactPersonNo', contactPersonNo], ['email', email], ['senderEmail', senderEmail],['groupid', this.props.userLogin.site.groupid],['country', country],['city', city],['address', address],['website', website],['facebook', facebook],['instagram', instagram],['twitter', twitter],['linkedin', linkedin],['pinterest', pinterest],['tiktok', tiktok],['applogo' ,this.state.selectedFile]]
            if (recordID !== -1){
                parameters = [['companyName', companyName], ['contactPerson', contactPerson], ['contactPersonNo', contactPersonNo], ['email', email], ['senderEmail', senderEmail],['groupid', this.props.userLogin.site.groupid],['country', country],['city', city],['address', address],['website', website],['facebook', facebook],['instagram', instagram],['twitter', twitter],['linkedin', linkedin],['pinterest', pinterest],['tiktok', tiktok],['applogo' ,this.state.selectedFile],['id', recordID]]
            }
           //parameters.push(['clientID', this.props.userLogin.site.id])
            let result = await G.uploadData('client/' + (recordID === -1 ? 'insert' : 'update'), parameters)
            
            console.log(result)
            let success = (result.status === 200)
            if (success) {
                if(recordID === -1)
                {
                    var logtxt = this.props.userLogin.username + " has created a site called " + companyName;
                    await G.SaveUserLog(logtxt, this.props.userLogin.id);
                }
                else{
                    var logtxt = this.props.userLogin.username + " has modified the site info of " + companyName;
                    await G.SaveUserLog(logtxt, this.props.userLogin.id);
                    for(var i=0;i<this.state.siteaddresses.length;i++)
                    {
                        var siteaddress = this.state.siteaddresses[i];
                        if(siteaddress.id ===-1)
                        {
                            await G.uploadData("clientaddress/insert",[["clientID",recordID],["address", siteaddress.address]]);
                        }
                        else{
                            await G.uploadData("clientaddress/update",[["clientID",recordID],["address", siteaddress.address],["id", siteaddress.id]]);
                        }
                    }
                }
                this.setState({ updateMode: false, fields: {}, successmodalShow:recordID!==-1 })
                this.loadData()
                history.push("/Sites")
            }
        }
    }
	disableSite =async () => {
		let parameters = [['id', this.state.deleteID]]
		var result = await G.getData('site/disable', parameters, true)
		result = await result.status;
		if (result === 200) {
			this.setState({ deleteID: -1, modalShow:false })
			//this.loadData()
			window.location.reload();
		}else{
			this.setState({
				modalShow: true,
				modalSubject: 'Error Appeared',
				modalBody: 'Problem Occured while hiding Record. Please Try Again',
				justinfo: true
			})
		}
	}
}


const mapStateToProps = state => {
    return {
        userLogin: state.SaveLogin.userData
    };
};

export default connect(
    mapStateToProps
)(Sites);