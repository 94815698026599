import React, { Component, createRef } from 'react'
import { connect } from 'react-redux';
import { Badge, Button, Container, Form, Col, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as G from '../components/global'
import { renderPageContainer, renderPageHeader, renderDivider, getData } from '../components/global'
import '../styles/glob.css';
import MessageBox from '../components/MessageBox';
import history from '../components/history';
import Autocomplete from '../components/otherComponents/Autocomplete';
import ReactPaginate from 'react-paginate';
import { translateScreen as ts, translateScreen2 as ts2, translateDate as td } from '../components/dictionary'
import Select, { StylesConfig } from 'react-select'
import 'rsuite/dist/rsuite.min.css';
import {DateRangePicker, DatePicker} from 'rsuite';
import chroma from 'chroma'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel, Thumbs } from 'react-responsive-carousel';
import qs from 'qs'
import dateFormat from 'dateformat';
class profileManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allList: [],
            originalList: [],
            displayedList: [],
            allCompanies: [],
            sitestores:[{key:'', value:'Please choose'}],
            fields: {},
            loadavailablegifts:false,
            loadavailablepromocodes:false,
            selectedgender:'',
            customergiftsloading:false,
            errors: {},
            ticketrule:null,
            result: [],
            activeScreen: 1,
            birthdate: null,
            offset: 0,
            perPage: 9,
            currentPage: 0,
            pageCount: 0,
            editProfile: -1,
            onlyProfile:true,
            deleteProfile: -1,
            modalShow: false,
            showPointsModal:false,
            showTokensModal:false,
            showRankingModal:false,            
            showAvatarModal:false,
            showBadgesModal:false,
            RankingsList:[],
            AvatarsList:[],
            modalGiftShow:false,
            modalGiftDetailsShow:false,
            modalPromoDetailsShow:false,
            modalAlertDetailsShow:false,            
            modalPromocodeShow:false,
            modalCreateAlertShow:false,
            modalCompetitionShow:false,
            modalBody: '',
            modalSubject: '',
            loadCmp: true,
            loadprofiles: false,
            loadSave: false,
            //filters
            CountryList: [],
            CityList: [],
            categoriesList:[],
            availableGender: [],
            availableSubs:[],
            filteredCountryList: [],
            CountriesOptions:[],
            CitiesOptions:[],
            filteredCategoriesList: [],
            filteredCityList: [],
            profileStats:{},
            hotspotcategoriesList: [],
            loadCatList: false,
            customergiftsList:[],
            filteredcustomergiftsList:[],
            filteredavailablegiftsList:[],
            loadCustomerGifts:false,            
			giftCategoryOptions:[],
			giftCategory:[],
            promoCategory:[],
            promocodeCategory:[],
            giftRedeems:[],
            promoRedeems:[],
            promocodeCategoryOptions:[],
            competitionCategoryOptions:[],
            selectedRedemption:{},
            customercollections:null,
            loadCustomerPromocodes: false,            
            customerpromocodesList:[],
            filteredcustomerpromocodesList:[],
            loadCustomerCompetitions:false,
            competitionsList:[],
            filteredcompetitionsList:[],
            questions:[],
            availablegifts:[],
            availablepromocodes:[],
            filteredavailablepromocodesList:[],
            giftsModalShow:false,
            promocodesModalShow:false,
            showalerts:null,            
            tickets: [],            
			searchfields:{},
            loadingTickets: true,
            showRedundancyModal:false,
            showAmountLimitModal:false,
            showScanningLimitModal:false,            
            showGlobalLimit:false,
            showFlagsModal:false,
            showTicketEditModal:false,
            reporter:{fullName:''},
            oldtickets:[],
            selectedTicket:{},
            scannedStore:null,
            alerts:[],
            ticketscount:0,
            storeticketscount:0,
            ticketactiveScreen:0,
            ticketfields: {},
            ticketerrors: {},
            giftResultModalShow:false,
            giftresult:"",
            successmodalShow:false
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleTicketChange = this.handleTicketChange.bind(this);
        this.handleUpdateAutoComplete = this.handleUpdateAutoComplete.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        
    }
    redeemPromo = async()=>{
        var result = await G.getData("redeempromobycode",[["code",this.state.fields.promoredeemcode], ["customerid",this.state.editProfile], ["siteid",this.props.userLogin.site.id]]);
                                            result = await result.data;
                                            if(result.response == "success")
                                            {
                                                var customercollections = await G.getData("customers/collected",[['profileId',this.state.editProfile]])
            customercollections = await customercollections.data
            this.setState({ customercollections:customercollections })
                                                this.setState({ promocodesModalShow: false });
                                    var logtxt = this.props.userLogin.username + " has redeemed " + result.promocode.name + " promocode for the customer " +  + this.state.fields["firstname"] + " " + this.state.fields["lastname"] + "(" + this.state.fields["email"] + ")";
                        await G.SaveUserLog(logtxt, this.props.userLogin.id);
                        this.setState({
                            modalPromocodeShow: true,
                            selectedRedemption: result.promocode,
                            modalSubject: "Details"
                        });
                        this.getPromoCodes();
                        setTimeout(function(){
                
                            const script = document.createElement("script");
                    
                        script.src = '/dist/js/redeemedgiftstable.js';
                        script.async = true;
                        document.body.appendChild(script);
                        },100);
                                    //this.getPromo();
                                          //      this.showRedeemedGift(result.gift)
                                                //show gift details
                                            }
                                            else{
                                                if(result.response == "notavailable")
                                                {
                                                    this.setState({promocodesModalShow:false, giftresult:ts2("this promocode is not available for this customer", this.props.userLogin.lanauge), giftResultModalShow:true});
                                                }
                                                else if(result.response == "notfound"){
                                                    this.setState({promocodesModalShow:false, giftresult:ts2("Code not found", this.props.userLogin.lanauge), giftResultModalShow:true})
                                                }
                                            }
    }
    //#region Didmount & Get Data & Filter
    componentDidMount() {
        
		this.selectInputRef = createRef();
        
        if(this.props.userLogin.site)
        {
            this.getDistinctValues();
            this.refreshData(this.props.userLogin.site.id);
            this.getcategories();
          this.getpromocategories();
          this.getcompetitioncategories();
        }
        let date = new Date();
        date.setFullYear(date.getFullYear - 18)
        history.listen((location, action) => {
            if(location.pathname == "/ProfileManagement" || location.pathname == "/ProfileManagement/")
            {
                this.setState({ activeScreen: 1 });
                this.getDistinctValues();
            this.refreshData(this.props.userLogin.site.id);
            }
            else if(location.pathname == "/ProfileManagement/customers" || location.pathname == "/ProfileManagement/customers/")
            {
                this.setState({ activeScreen: 1 });
                this.getDistinctValues();
            this.refreshData(this.props.userLogin.site.id);
            }
            
          });
          
          
    }
    getSnapshotBeforeUpdate(prevProps) {
        return { notifyRequired: prevProps.userLogin !== this.props.userLogin };
      }
      
      async componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot.notifyRequired && this.props.userLogin.site) {
            this.getDistinctValues();
            await this.refreshData(this.props.userLogin.site.id);
            this.getcategories();
          this.getpromocategories();
        }
      }
    languageArray = () => { let lang = G.getLanguageList(); return lang }
    
    addDays = (date, days) => {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }
    getcategories = async (company) => {
        if(this.selectInputRef.current!=null)
            this.selectInputRef.current.select.clearValue();
        let array = []
        array = await G.getData("giftcategory/query", [["filter","a.siteid=" + this.props.userLogin.site.id]])
        array = await array.data
        this.setState({ giftCategory: array })
        let arrayNames = [];
        array.map(element => {
          arrayNames.push(element.name)
        })
        this.setState({ giftCategoryNames: arrayNames })
        console.log(this.state.giftCategory)
        const categoryOptions = [];
        array.map(element => {
          categoryOptions.push({value:element.id, label:element.name});
        })
        this.setState({ giftCategoryOptions: categoryOptions })
      }
      getpromocategories = async (company) => {
        if(this.selectInputRef.current!=null)
            this.selectInputRef.current.select.clearValue();
        let array = []
        array = await G.getData("promocodecategory/query", [["filter","a.siteid=" + this.props.userLogin.site.id]])
        array = await array.data
        this.setState({ promocodeCategory: array })
        let arrayNames = [];
        array.map(element => {
          arrayNames.push(element.name)
        })
        this.setState({ promocodeCategoryNames: arrayNames })
        console.log(this.state.promocodeCategory)
        const categoryOptions = [];
        array.map(element => {
          categoryOptions.push({value:element.id, label:element.name});
        })
        this.setState({ promocodeCategoryOptions: categoryOptions })
      }
      getcompetitioncategories = async (company) => {
        if(this.selectInputRef.current!=null)
            this.selectInputRef.current.select.clearValue();
        let array = []
        array = await G.getData("competitioncategory/query", [["filter","a.siteid=" + this.props.userLogin.site.id]])
        array = await array.data
        this.setState({ competitionCategory: array })
        let arrayNames = [];
        array.map(element => {
          arrayNames.push(element.name)
        })
        this.setState({ competitionCategoryNames: arrayNames })
        console.log(this.state.competitionCategory)
        const categoryOptions = [];
        array.map(element => {
          categoryOptions.push({value:element.id, label:element.name});
        })
        this.setState({ competitionCategoryOptions: categoryOptions })
      }
      AddPoints = async(multiply)=>{
          let {fields, errors, editProfile} = this.state;
          var score = fields["points"];
          if(score == undefined || score=='' || isNaN(score))
          {
              errors["points"] = "This field is required";
              this.setState({fields, errors});
          }
          else{
          await G.getData("customerpoints/add",[["customerid", editProfile],["score",(score * multiply)], ["byadmin","1"], ["adminid",this.props.userLogin.id], ['source','cms']]);
          this.setState({showPointsModal:false})
          var customercollections = await G.getData("customers/collected",[['profileId',editProfile]])
        customercollections = await customercollections.data
        this.setState({ customercollections:customercollections })
        var logtxt = this.props.userLogin.username + " has edited the Points of customer " + fields["firstname"] + " " + fields["lastname"] + "(" + fields["email"] + ")";
            await G.SaveUserLog(logtxt, this.props.userLogin.id);
            window.location.reload();
          }
      }
      AddTokens = async(multiply)=>{
        let {fields, errors, editProfile} = this.state;
        var score = fields["tokens"];
        if(score == undefined || score=='' || isNaN(score))
        {
            errors["tokens"] = "This field is required";
            this.setState({fields, errors});
        }
        else{
        await G.getData("customertokens/insert",[["customerid", editProfile],["score",(score * multiply)], ["byadmin","1"], ["adminid",this.props.userLogin.id]]);
        this.setState({showTokensModal:false})
        var customercollections = await G.getData("customers/collected",[['profileId',editProfile]])
      customercollections = await customercollections.data
      this.setState({ customercollections:customercollections })
      var logtxt = this.props.userLogin.username + " has edited the Tokens of customer " + fields["firstname"] + " " + fields["lastname"] + "(" + fields["email"] + ")";
            await G.SaveUserLog(logtxt, this.props.userLogin.id);
            window.location.reload();
        }
    }
    EditRank = async()=>{
        let {fields, errors, editProfile} = this.state;
        var rankid = fields["rankid"];
        if(rankid == undefined || rankid=='' || isNaN(rankid))
        {
            errors["rankid"] = "This field is required";
            this.setState({fields, errors});
        }
        else{
        await G.getData("customerranking/insert",[["customerid", editProfile],["rankingid",rankid], ["byadmin","1"], ["adminid",this.props.userLogin.id]]);
        this.setState({showRankingModal:false})
        var customercollections = await G.getData("customers/collected",[['profileId',editProfile]])
      customercollections = await customercollections.data
      this.setState({ customercollections:customercollections })
      var logtxt = this.props.userLogin.username + " has edited the Rank of customer " + fields["firstname"] + " " + fields["lastname"] + "(" + fields["email"] + ")";
            await G.SaveUserLog(logtxt, this.props.userLogin.id);
            window.location.reload();
    }
}
EditAvatar = async()=>{
    let {fields, errors, editProfile} = this.state;
    var avatarid = fields["avatarid"];
    if(avatarid == undefined || avatarid=='' || isNaN(avatarid))
    {
        errors["avatarid"] = "This field is required";
        this.setState({fields, errors});
    }
    else{
    await G.getData("profile/editavatar",[["customerid", editProfile],["avatarid",avatarid]]);
    this.setState({showAvatarModal:false})
    var customercollections = await G.getData("customers/collected",[['profileId',editProfile]])
  customercollections = await customercollections.data
  this.setState({ customercollections:customercollections })
  var logtxt = this.props.userLogin.username + " has edited the Avatar of customer " + fields["firstname"] + " " + fields["lastname"] + "(" + fields["email"] + ")";
        await G.SaveUserLog(logtxt, this.props.userLogin.id);
        window.location.reload();
}
}
    renderGrid = (name, Data) => {
    
        
        return (
    
            <div className='fullWidth '>
                {/* {typeID == 0 && <p className='  ssstitle bold ml-3 mt-3 mb-2   '>{name} </p>} */}
                <div>
                <table id="example3" className="table table-bordered">
                        <thead>
                            <tr>
                                <th >{name}</th>
                                <th colSpan="2" className='cunlayer' >{ts('Reached',this.props.userLogin.language  )}</th>
    
                                <th colSpan="2" >{ts('Not Reached',this.props.userLogin.language  )}</th>
    
                                <th colSpan="2" className='cunlayer'>{ts('Opened',this.props.userLogin.language  )}</th>
    
                                <th colSpan="2">{ts('Not Opened',this.props.userLogin.language  )}</th>
    
    
                            </tr>
                        </thead>
                        {Data && <tbody>
                            
                                
                                    <tr>
    
                                        <td className='bold'>{Data.total}</td>
                                        <td className='cunlayer'>
                                        {Data.reached}
                                        </td >
                                        <OverlayTrigger overlay={<Tooltip >Percentage from Total (Reached/Unreached) <p className='sremarks'>{Math.floor(Data.reached / Data.total * 100)}</p></Tooltip>}>
                                            <td className='cunlayer'><Badge variant='info'>
                                                
                                            {Math.floor(Data.reached / Data.total * 100)} %</Badge></td>
                                        </OverlayTrigger>
    
    
                                        <td>
                                           {Data.total - Data.reached}
                                        </td>
                                        <OverlayTrigger overlay={<Tooltip >Percentage from Total (Reached/Unreached) <p className='sremarks'>{Math.ceil((Data.total - Data.reached) / Data.total * 100)}</p></Tooltip>}>
                                            <td className='cunlayer'><Badge variant='danger'>
                                                
                                            {Math.ceil((Data.total - Data.reached) / Data.total * 100)} %</Badge></td>
                                        </OverlayTrigger>
    
    
    
                                        <td className='cunlayer'>
                                            {Data.opened}
                                        </td >
                                        <OverlayTrigger overlay={<Tooltip >Percentage from Total (Opened/Unopened) <p className='sremarks'>{Math.floor(Data.opened / Data.total * 100)}</p></Tooltip>}>
                                            <td className='cunlayer'><Badge variant='success'>
                                                {Math.floor(Data.opened / Data.total * 100)}
                                                %</Badge></td>
                                        </OverlayTrigger>
    
    
    
                                        <td>
                                            {Data.total - Data.opened}
                                        </td>
                                        <OverlayTrigger overlay={<Tooltip >Percentage from Total (Opened/Unopened) <p className='sremarks'>{Math.ceil((Data.total - Data.opened) / Data.total * 100)}</p></Tooltip>}>
                                            <td className='cunlayer'><Badge variant='warning'>
                                                {Math.ceil((Data.total - Data.opened) / Data.total * 100)}
                                                %</Badge></td>
                                        </OverlayTrigger>
                                    </tr>
                                
                            
    
                        </tbody>}
                    </table>
                </div>
                {/* /.card-body */}
            </div>
    
    
        )
    }
    getDistinctValues = async () => {

        let result = await G.getData('GetDistinctProfilesTypes', [['clientID', this.props.userLogin.site.id]], true);
        result = await result.data
        const genderq = qs.parse(history.location.search, { ignoreQueryPrefix: true }).gender;
        if(genderq)
        {
            var lang = this.props.userLogin.language;
            if(genderq == '1')
                this.setState({selectedgender:ts2('Only Females',lang), fields:{'gender':ts2('Only Females',lang)}});
            else if(genderq == '0')
                this.setState({selectedgender:'Only Males', fields:{'gender':'Only Males'}});
            
        }
        let co = []; let ci = []; let ge = []; let subs = [];
        result.forEach(element => {
            if (element.type === 'co') { co.push(element.name) }
            if (element.type === 'ci') { ci.push(element.name) }
            if (element.type === 'ge') { ge.push(ts2(element.name == 0 ? 'Only Males' : (element.name == 1?'Only Females':'not Defined'), this.props.userLogin.language)) }
        })
        let array = []
        array = await G.getData("hotspotCategory/query", [['filter','(hide is null or hide=0) and clientID=' + this.props.userLogin.site.id]])
        array = await array.data
        this.setState({ hotspotCategory: array })
        let arrayNames = [];
        array.map(element => {
            arrayNames.push({value:element.name, label:element.name});
        })
        subs.push("subscribed");
        subs.push("unsubscribed");
        this.setState({ CountryList: co, CityList: ci, availableGender: ge,categoriesList:arrayNames, availableSubs:subs })
        var countriesOptions = [];
        co.map(element => {
            if(element!=null && element!='')
                countriesOptions.push({value:element, label:element});
        })
        var citiesOptions = [];
            ci.map(element => {
                if(element!=null && element!='')
                    citiesOptions.push({value:element, label:element});
            })
            this.setState({ CountriesOptions: countriesOptions, CitiesOptions: citiesOptions })
            if(genderq)
            {
                this.QueryProfiles();
            }
    }

    refreshData = async (company) => {
        this.setState({ loadprofiles: true })
        let result = await G.getData('getprofileswithsubscriptionstatus', [['filter', 'clientID = ' + company]]);
        result = await result.data;
        let pageCounts = result.length / this.state.perPage
        this.setState({ allList: result, pageCount: pageCounts, originalList: result })
        const p = qs.parse(history.location.search, { ignoreQueryPrefix: true }).p;
        const a = qs.parse(history.location.search, { ignoreQueryPrefix: true }).a;
        var qid = qs.parse(history.location.search, { ignoreQueryPrefix: true }).id;
        this.setdisplayList(0, (!p && !a && !qid))
        this.setState({ loadprofiles: false })
        if(qid)
        {
        result.map(item=>{

            if(item.id == qid)
            {
                //alert(qid)
                this.editProfile(item, false);
            }
        })
        }
        else{
            this.setState({onlyProfile:false})
        }
          if(this.state.allList.length>0)
          {
              var profile = this.state.allList.filter(x=>x.id == p);
              if(profile.length>0)
              {
                  this.setState({showalerts: a=="1"})
                  this.editProfile(profile[0])
              }
          }

    }
    
    setdisplayList(startnumber, loaddttables = true) {
        let sliceArray = [...this.state.allList]

        /*if (this.state.allList.length > this.state.perPage) {
            sliceArray = sliceArray.slice(startnumber * this.state.perPage, startnumber * this.state.perPage + this.state.perPage)

        }*/
        
        this.setState({ displayedList: this.state.allList })
        if(loaddttables == true)
        {
        const script = document.createElement("script");
                                        
                                                script.src = '/dist/js/dttable2wexport.js';
                                                script.async = true;
                                            
                                                document.body.appendChild(script);
        }
    }
    importFromCSV = () => {

        history.push('/ImportProfiles')
    }
    editProfile = async(item, redirect = true) => {
        if(redirect == true)
            history.push("/ProfileManagement/customers/edit?id=" + item.id)
        window.scrollTo(0, 0)
        this.setState({
            fields: {
                'firstname': item.firstName,
                'lastname': item.lastName,
                'email': item.emailAddress,
                'phone': item.mobile,
                'gender': ts(item.gender === 0 ? 'Male' : (item.gender===1?'Female':'not Defined'), this.props.userLogin.language),
                'language':item.language,
                'country': item.country,
                'city': item.city,
                'subscriptionstatus':item.subscriptionstatus,
                'companyname': item.companyname
            },
            errors:{},
            subscriptionstatus:item.subscriptionstatus,
            birthdate: item.age!=null?new Date(Date.parse(item.age)):null
            
        }

        )//Tue Jul 06 1993 15:54:17 GMT+0300
        
        var stats = await G.getData("profileStats",[['profileId',item.id]]);
        var results = await stats.data;
        this.setState({ editProfile: item.id, activeScreen: 2, profileStats:results })
        var customercollections = await G.getData("customers/collected",[['profileId',item.id]])
        customercollections = await customercollections.data
        this.setState({ customercollections:customercollections, onlyProfile:false })
    }

    deleteProfile = (item) => {
        this.setState({
            modalBody: ts("Are you sure you want to delete profile", this.props.userLogin.language) +" " + item.firstName + " " + item.lastName + ".",
            modalSubject: <span className='warningColor'>{ts2('Delete Profile', this.props.userLogin.language)} </span>,
            modalShow: true,
            deleteProfile: item.id
        })
    }
    confirmDeletePRofile = async () => {

        let parameters = [['filter', ' id = ' + this.state.deleteProfile]]
        var result = await G.getData('profile/delete', parameters, true)
        result = await result.status;
        if (result === 200) {
            this.setState({ modalShow: false });
            this.refreshData(this.props.userLogin.site.id)
        }

    }


    //#endregion

    //#region Form Validation
    handleChange(e) {
        let { fields, errors } = this.state;
        fields[e.target.name] = e.target.value;
        delete errors[e.target.name];
        this.setState({ fields });
        if (e.target.name === 'filter' && e.target.value === '') {
            this.filterGrid()
        }
    }
    handleTicketChange(e){
        let { ticketfields, ticketerrors } = this.state;
        ticketfields[e.target.name] = e.target.value;
        delete ticketerrors[e.target.name];
        this.setState({ ticketfields });
    }
    onKeyDown = e => {

        if (e.keyCode === 13) {
            e.preventDefault();
            this.filterGrid()
        }
    }
    filterGrid = () => {

        let filter = this.state.fields['filter']

        if (filter === "" || filter == null) {
            this.setState({ allList: [...this.state.originalList] }, () => { this.setdisplayList(0) })

        } else {
            this.setState({
                allList: [...this.state.originalList.filter(element =>{
                    var returnF = element.firstName.toLowerCase().includes(filter.trim().toLowerCase())
                    || element.lastName.toLowerCase().includes(filter.trim().toLowerCase())
                    || element.emailAddress.toLowerCase().includes(filter.trim().toLowerCase())
                    || element.city.toLowerCase().includes(filter.trim().toLowerCase())
                    || element.country.toLowerCase().includes(filter.trim().toLowerCase())
                    || element.mobile.toLowerCase().includes(filter.trim().toLowerCase())
                    if("male".includes(filter.trim().toLowerCase())||"mâle".includes(filter.trim().toLowerCase())||"female".includes(filter.trim().toLowerCase())||"femelle".includes(filter.trim().toLowerCase()))
                    {
                        var f = ("male".includes(filter.toLowerCase())||"mâle".includes(filter.toLowerCase())?0:1);
                        
                        returnF = returnF || element.gender == f;
                    }
                    return returnF;
                }
                    //|| element.gender.toLowerCase().includes(filter.toLowerCase()=="male"||filter.toLowerCase()=="mâle"?"0":(filter.toLowerCase()=="female"||filter.toLowerCase()=="femelle"?"1")))
                )]
            }, () => { this.setdisplayList(0) })

        }
    }

    handleUpdateAutoComplete(e, name, value) {
        let { fields } = this.state;
        fields[name] = value;
        if (name === 'searchcountry') {
            let countryArray = this.state.filteredCountryList;
            if (countryArray.filter(element => element === value).length === 0) countryArray.push(value)
            this.setState({ filteredCountryList: countryArray })
        }
        if (name === 'category') {
            let categoryArray = this.state.filteredCategoriesList;
            if (categoryArray.filter(element => element === value).length === 0) categoryArray.push(value)
            this.setState({ filteredCategoriesList: categoryArray })
        }
        if (name === 'searchcity') {
            let cityArray = this.state.filteredCityList;
            if (cityArray.filter(element => element === value).length === 0) cityArray.push(value)
            this.setState({ filteredCityList: cityArray })
        }
        if(name!=='country')
            fields[name] = ''
        this.setState({ fields })
    }
    handleValidation = async () => {


        const { firstname, lastname, email, phone, gender, country, city,language, subscriptionstatus, companyname } = this.state.fields;
        console.log("language",language)
        let errors = {};
        let formIsValid = true;

        if (!firstname) { formIsValid = false; errors["firstname"] = ts2("First name cannot be empty", this.props.userLogin.language); }
        if (!lastname) { formIsValid = false; errors["lastname"] = ts2("Last name cannot be empty", this.props.userLogin.language); }
        if (!email) { formIsValid = false; errors["email"] = ts2("Email cannot be empty", this.props.userLogin.language); }
        if (!language) { formIsValid = false; errors["language"] = ts2("Language cannot be empty", this.props.userLogin.language); }
        //if (!phone) { formIsValid = false; errors["phone"] = ts2("Phone cannot be empty", this.props.userLogin.language); }
        //if (!city) { formIsValid = false; errors["city"] = ts2("City cannot be empty", this.props.userLogin.language); }
        /*if (!gender) { formIsValid = false; errors["gender"] = ts2("Gender must be selected", this.props.userLogin.language); } else {
            if (gender.toLowerCase().startsWith("s")) {
                formIsValid = false;
                errors['Gender'] = ts2("Gender must be selected", this.props.userLogin.language)
            }
        }*/
        //if (!country) { formIsValid = false; errors["country"] = ts2("Country cannot be empty", this.props.userLogin.language); }
        //if (this.state.birthdate == null) { formIsValid = false; errors['birthdate'] = ts2("Birth date cannot be empty", this.props.userLogin.language); }


        if (this.state.editProfile < 0) {
            let checkEmail = await G.getData("CountEmails", [['email', email],['clientID', this.props.userLogin.site.id]])
            checkEmail = await checkEmail.data

            if (checkEmail[0].count > 0) { formIsValid = false; errors["email"] = ts2("Email already exist", this.props.userLogin.language); }
            
        }
        this.setState({ errors: errors });
        console.log("formIsValid:" , formIsValid)
        if (formIsValid) {
            let parameters = [];
            let postCommand = ''
            var genderparam = gender
            if(gender == undefined)
            {
                genderparam = "Male";
            }
            if (this.state.editProfile < 0) {
                parameters = [
                    ['clientID', this.props.userLogin.site.id],
                    ['firstName', firstname],
                    ['lastName', lastname],
                    ['emailAddress', email],
                    ['mobile', phone],
                    ['gender', genderparam?(genderparam.toLowerCase().startsWith("m") ? 0 : 1):2],
                    ['age', this.state.birthdate?(this.state.birthdate.toISOString().slice(0, 19).split('T')[0]):null],
                    ['country', country],
                    ['city', city],
                    ['Street', ''],
                    ['creationUser', this.props.userLogin.id],
                    ['language',language],
                    ['companyname', companyname],
                    ['avatarid', 0]
                ]
                postCommand = 'profile/insert'
            } else {
                parameters = [
                    ['firstName', firstname],
                    ['lastName', lastname],
                    ['emailAddress', email],
                    ['mobile', phone],
                    ['gender', gender?(gender.toLowerCase().startsWith("m") ? 0 : 1):2],
                    ['age', this.state.birthdate?(this.state.birthdate.toISOString().slice(0, 19).split('T')[0]):null],
                    ['country', country],
                    ['city', city],
                    ['Street', ''],
                    ['lastEditUser', this.props.userLogin.id],
                    ['language',language],                    
                    ['companyname', companyname],
                    ['avatarid', 0],
                    ['id', this.state.editProfile]

                ]
                postCommand = 'profile/update'
            }
            var substatus = subscriptionstatus;
            if(substatus == undefined || substatus == null || substatus == "" )
            {
                substatus = "subscribed"; 
            }
            console.log("Subscription Status", substatus)
            G.log(postCommand, parameters)
            console.log(postCommand, parameters)
            var result = await G.getData(postCommand, parameters, true)
            result = await result.status;
            await G.getData("updatesubscription",[['email',email],['subscriptionstatus',substatus]],true)
            var logtxt = this.props.userLogin.username + " has edited the personal details of customer " + firstname + " " + lastname + "(" + email + ")";
            await G.SaveUserLog(logtxt, this.props.userLogin.id);

            

            return result === 200

        }
        else {
            return formIsValid;
        }
    }
    submitForm = async (e) => {
        e.preventDefault();
        this.setState({ loadSave: true })
        var a = await this.handleValidation();

        if (a === true) {
            this.refreshData(this.props.userLogin.site.id)
            this.setState({ activeScreen: 1, successmodalShow:true })
            const script = document.createElement("script");
                                        
            script.src = '/dist/js/dttable2wexport.js';
            script.async = true;
        
            document.body.appendChild(script);
        } else { G.log('Form Is Not Valid') }
        this.setState({ loadSave: false })
    }
    //#endregion


    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.setdisplayList(selectedPage)
        });

    };
    QueryProfiles = async () => {
        this.setState({ loadprofiles: true })
        this.setState({ profileList: [] })
        let { fields, filteredCountryList, filteredCityList, filteredCategoriesList } = this.state
        console.log(fields);
        let { age } = this.state.fields
        let errors = {};
        let filter = "  clientID =  " + this.props.userLogin.site.id
        let filterDescr = ''
        let filterage = ''
        if (age != null) {
            if (age !== '') {
                let varAge = age.replace(/ /g, '')
                varAge = varAge.split(",")
                let Error = '';

                varAge.map(element => {
                    if (isNaN(element.replace(/-/g, ''))) { Error = "Invalid Age Fitler. Please Enter Age Or Age Ranges seperated by comma ','" }
                    else {
                        if (element !== '') {
                            if (element.includes("-")) {
                                element.split("-").map(subElement => {
                                    if (isNaN(subElement)) { Error = "Invalid Age Fitler. Please Enter Age Or Age Ranges seperated by comma ','" }
                                })
                            }


                            if (Error === '') {

                                if (element.includes("-")) {
                                    filterage += ' or TIMESTAMPDIFF(YEAR, age, CURDATE()) between ' + element.split("-")[0] + ' and ' + element.split("-")[1]
                                } else {
                                    filterage += ' or TIMESTAMPDIFF(YEAR, age, CURDATE()) = ' + element
                                }
                            }
                        }

                    }
                })

                if (Error !== '') {
                    errors['age'] = Error
                    this.setState({ errors })
                    return
                }
                filterDescr = ", Age Ranges (" + age + ") "
            }
        }
        if (filterage.trim() !== '') filter += ' and ( ' + filterage.trim().substring(2) + ')'

        if (fields['gender'] === (this.props.userLogin.language === 0 ? 'Only Males' : 'Seulement des mâles')) { filter += " and gender=0"; filterDescr += ', Only Males' }
        if (fields['gender'] === (this.props.userLogin.language === 0 ? 'Only Females' : "Seulement des femelle")) { filter += " and gender=1"; filterDescr += ', Only Females' }
        if (fields['gender'] === (this.props.userLogin.language === 0 ? 'not Defined' : "not Defined")) { filter += " and gender=2"; filterDescr += ', not Defined' }
        if (fields['subscription'] === "subscribed") { filter += " and emailAddress not in (select emailAddress from unsubscribe)"; filterDescr += ', Subscribed' }
        if (fields['subscription'] === "unsubscribed") { filter += " and emailAddress in (select emailAddress from unsubscribe)"; filterDescr += ', Unsubscribed' }
        filter += filteredCountryList.length === 0 ? '' : " and country in (" + filteredCountryList.map(element => "'" + element.replace("'","''") + "'") + ")"
        filterDescr += filteredCountryList.length === 0 ? '' : ", countries in (" + filteredCountryList.map(element => "'" + element.replace("'","''") + "'") + ")"
        filter += filteredCategoriesList.length === 0 ? '' : " and  id in (select customerID from campaigncustomerhotspot where hotspotID in(select hotspotId from hotspotcategoryrelation where categoryId in (select id from hotspotcategory where name in (" + filteredCategoriesList.map(element => "'" + element.value.replace("'","''") + "'") + "))))" 
        filter += filteredCityList.length === 0 ? '' : " and city in (" + filteredCityList.map(element => "'" + element.replace("'","''") + "'") + ")"
        filterDescr += filteredCityList.length === 0 ? '' : ", cities in (" + filteredCityList.map(element => "'" + element.replace("'","''") + "'") + ")"
        this.setState({ filterString: filter, filterDescr: filterDescr })
        //let result = await G.getData('getprofiles/profile', filter === '' ? [] : [['filter', filter + subscribedfilter]]);
        //let unsubscribedresult = await G.getData('getprofiles/profile', filter === '' ? [] : [['filter', filter + unsubscribedfilter]]);
        //result = await result.data;
        //unsubscribedresult = await unsubscribedresult.data;
        //let pageCounts = result.length / this.state.perPage
        //this.setState({ profileList: result, pageCount: pageCounts, originalList: result, unsubscribedprofileList: unsubscribedresult, originalsunsubscribedList: unsubscribedresult })
        //this.setdisplayList(0)
        //this.setdisplayUnsubscribedList(0);
        //this.setState({ loadProfiles: false, didMakeQuery: true })
        let result = await G.getData('getprofileswithsubscriptionstatus', filter === '' ? [] : [['filter', filter]]);
        result = await result.data;
        this.setState({allList:[], originalList: result})
        this.setState({ allList: result, originalList: result });
        const script = document.createElement("script");
                                        
                                                script.src = '/dist/js/dttable2wexport.js';
                                                script.async = true;
                                            
                                                document.body.appendChild(script);
        this.setState({ loadprofiles: false, didMakeQuery: true })
        
    }
    GetRelatedCategories = async ()=>{
        this.setState({ loadCatList:true })
    let result = await G.getData('getHotspotCategoriesWithCustomerRelation',[['clientID', this.props.userLogin.site.id], ["profileId",this.state.editProfile]]);
    result = await result.data;
    
    this.setState({ hotspotcategoriesList: result })
    
            const script = document.createElement("script");
        
        script.src = '/dist/js/dttable.js';
        script.async = true;
        document.body.appendChild(script);
        
        this.setState({ loadCatList: false })
    }
    getGiftsPerCustomer = async()=>{
        this.setState({ loadCustomerGifts: true })
    var gifts = await G.getData("customers/gifts/get",[["profileId",this.state.editProfile]]);
    gifts = await gifts.data;
    console.log("GIFT",gifts)
    gifts.map(async(g)=>{
        var result = await G.getData("giftcategory/query",[["filter"," id in (select categoryid from giftcategoryrelation where giftid ="+g.giftid+")"]]);
        result = await result.data;
        g.categories = result;
        this.setState({customergiftsList:gifts, filteredcustomergiftsList:gifts})
    })
    this.setState({customergiftsList:gifts, filteredcustomergiftsList:gifts})
    const script = document.createElement("script");
    
    script.src = '/dist/js/customergiftstable.js';
    script.async = true;
    document.body.appendChild(script);
    this.setState({customergiftsloading:false});
        
        this.setState({ loadCustomerGifts: false })
    }
    
    /*ticket functions*/
    loadDataTables = ()=>{
        var _this = this;
         setTimeout(() => {
             
                     const script = document.createElement("script");
                 script.src = '/dist/js/dttable.js';
                 script.async = true;
                 document.body.appendChild(script);
                 
                 _this.setState({ loadingTickets: false })
         }, 100);
         
 }
   
 OpenDetails = async(element)=>{
    if(element.sitestoreid)
    {
        var storesresult = await getData("sitestore/query",[["filter","siteid=" + this.props.userLogin.site.id]]);
            storesresult = await storesresult.data;
            this.state.sitestores = [{key:'', value:'Please choose'}];
            for(var i=0;i<storesresult.length;i++)
            {
                this.state.sitestores.push({key:storesresult[i].id, value:storesresult[i].name});
            } 
            this.setState({sitestores:this.state.sitestores});
        var result = await getData("ticketscanningrules/query",[["filter","siteid=" + this.props.userLogin.site.id + " and enabled=1"]]);
		result = await result.data;
        if(result.length>0)
            this.setState({ticketrule: result[0]});
        
        result = await G.getData("sitestore/query",[["filter","id=" + element.sitestoreid]]);
        result = await result.data;
        this.setState({scannedStore:result[0]});

        console.log("Store:",this.state.scannedStore);
        var flags = await G.getData("customeralerts/query",[["filter","customerid=" + element.customerid + " and status=1 order by id desc"]]);
        flags = await flags.data;
        this.setState({alerts:flags});
        /*var cresult = await G.getData('customerticket/count',[['customerid',element.customerid],['sitestoreid',element.sitestoreid],['ticketdate',dateFormat(element.datein,"yyyy-mm-dd")]]);
        cresult = await cresult.data;
        var ticketscount = cresult.count;
                var storeticketscount = cresult.storecount;
                
                this.setState({ticketscount:ticketscount, storeticketscount:storeticketscount});*/
    }    
this.setState({ticketactiveScreen:1, selectedTicket:element})

}
    handleDateRangeChange = async(range)=>{
        let { searchfields } = this.state;
        searchfields["searchrange"] = range;
		 this.setState({ searchfields });
         this.getCustomerTickets(this.state.editProfile);
        
    }
    getCustomerTickets = async (customerid) => {
		this.setState({ loadingTickets: true })
        let { searchfields, fields } = this.state;
        let filter = '';
        if(searchfields['searchrange']!= undefined && searchfields['searchrange']!=null)
        {
            var range = searchfields['searchrange'];
            var startdate = new Date(range[0])
            var enddate = new Date(range[1])
            var starts = dateFormat(startdate,"yyyy-mm-dd");
            var ends = dateFormat(enddate.setDate(enddate.getDate() + 1),"yyyy-mm-dd")
            filter += ` and datein between '${starts}' and '${ends}'`
        }
        
        
        var tickets = [];
        if(searchfields["ticketstatus"])
        {
            /*var status = searchfields["ticketstaus"];
            switch(status)
            {
                case "All":
                    tickets = await getData('gifts/get', [['filter', 'customerid in (select id from profile where clientID=' + company + ")" + filter]]);
                    tickets = await tickets.data;
                    break;
                case "Approved":
                    tickets = await getData('customerticket/get', [['filter', 'customerid in (select id from profile where clientID=' + company + ")" + " status='Validated'" + filter]]);
                    tickets = await tickets.data;
                    break;
                case "Rejected":
                    tickets = await getData('customerticket/get', [['filter', 'customerid in (select id from profile where clientID=' + company + ")" + " status='Rejected'" + filter]]);
                    tickets = await tickets.data;
                    break;
                case "Pending":
                    tickets = await getData('customerticket/get', [['filter', 'customerid in (select id from profile where clientID=' + company + ")" + " status='Pending'" + filter]]);
                    tickets = await tickets.data;
                    break;
                
                default:
                    tickets = await getData('gifts/get', [['filter', 'customerid in (select id from profile where clientID=' + company + ")" + filter]]);
                    tickets = await tickets.data;
                    break;
            }*/
            var statuses = searchfields["ticketstatus"];
            var s = "";
            console.log("Statuses",statuses);
            statuses.map(elem=>{
                s += ",'" + elem.value+"'"
            })
            if(s!="")
            {
                s = s.substring(1);
                filter += " and status in ("+s+")"
            }
        }
        console.log("Filter", filter)
            tickets = await G.getData('customerticket/get', [['filter', 'customerid =' + customerid + filter + " order by datein desc"]]);
            tickets = await tickets.data;
       
		
        this.setState({ tickets: tickets, loadingTickets: false })
        this.loadDataTables();
    }
    handleStatusSearch = (e)=>
    {
        let { searchfields } = this.state;
        searchfields["ticketstatus"] = e;
		 this.setState({ searchfields });
         this.getCustomerTickets(this.state.editProfile);
    }
    openRedundancyModal = async()=>{
        let {selectedTicket} = this.state;
        var oldtickets = await G.getData("customerticket/query",[["filter","(id=" + selectedTicket.previouscustomerticketid + " or (previouscustomerticketid=" + selectedTicket.previouscustomerticketid + " and previouscustomerticketid not in (select id from customerticket where status<>'Validated') and id <>" + selectedTicket.id+")) and status='Validated'"]])
        oldtickets = await oldtickets.data;
        this.setState({showRedundancyModal:true, oldtickets:oldtickets})
     }
     openFlagsModal = async()=>{
         var reporter = await G.getData("user/query",[["filter","id=" + this.state.alerts[0].creationuserid]])
         reporter = await reporter.data;
         if(reporter.length>0)
         {
            this.setState({reporter:reporter[0]});
         }
        this.setState({showFlagsModal:true});
     }
     openAmountLimitModal = (showglobal)=>{
        this.setState({showAmountLimitModal:true, showGlobalLimit:showglobal?true:false})
     }
     openScanningLimitModal = (showglobal)=>{
        this.setState({showScanningLimitModal:true, showGlobalLimit:showglobal?true:false})
     }
     ResolveTicket = ()=>{
         let {selectedTicket} = this.state;
         var rdate = new Date(selectedTicket.receiptdate)
         this.setState({ticketerrors:{}, ticketfields:{receiptdate:dateFormat(rdate,"yyyy-mm-dd'T'HH:MM"), sitename:selectedTicket.sitename,storename:selectedTicket.storename,amount:selectedTicket.amount,status:selectedTicket.status, sitestoreid:(this.state.scannedStore?this.state.scannedStore.id:null)}});
         this.setState({showScanningLimitModal:false, showAmountLimitModal:false, showFlagsModal:false,showRedundancyModal:false, showTicketEditModal:true});
     }
     SaveTicket = async()=>{
        let {ticketfields, ticketerrors} = this.state;
        var formValid = true;
        if(ticketfields["receiptdate"] == null || ticketfields["receiptdate"] == "")
        {
            ticketerrors["receiptdate"] = "This field is required";
           formValid = false;
        }
        if(ticketfields["sitename"] == null || ticketfields["sitename"] == "")
        {
            ticketerrors["sitename"] = "This field is required";
           formValid = false;
        }
        if(ticketfields["sitestoreid"] == null || ticketfields["sitestoreid"] == "")
        {
           ticketerrors["sitestoreid"] = "This field is required";
           formValid = false;
        }
        if(ticketfields["amount"] == null || ticketfields["amount"] == "")
        {
            ticketerrors["amount"] = "This field is required";
           formValid = false;
        }
        else if(isNaN(ticketfields["amount"]))
        {
            ticketerrors["amount"] = "Please enter a valid amount";
           formValid = false;
        }
        if(ticketfields["status"] == null || ticketfields["status"] == "")
        {
            ticketerrors["status"] = "This field is required";
           formValid = false;
        }
        if(formValid)
        {
           var logtxt = this.props.userLogin.username + " edited ticket details with id:" + this.state.selectedTicket.id + " from (Receipt Date: " + this.state.selectedTicket.receiptdate + " | Store name: " + this.state.selectedTicket.storename + " | Amount: " + this.state.selectedTicket.amount + " | Status: "  + this.state.selectedTicket.status + ") to (Receipt Date: " + ticketfields["receiptdate"] + " | Store name: " + this.state.sitestores.find(x=>x.key.toString() == ticketfields["sitestoreid"]).value + " | Amount: " + ticketfields["amount"] + " | Status: "  + ticketfields["status"] + ")";
           G.SaveUserLog(logtxt, this.props.userLogin.id)
           var result = await G.getData("ticket/edit",[["ticketId",this.state.selectedTicket.id], ["receiptdate",ticketfields["receiptdate"]], ["sitename",ticketfields["sitename"]], ["sitestoreid",ticketfields["sitestoreid"]], ["amount",ticketfields["amount"]], ["status",ticketfields["status"]]]);
           if(result.status ===200)
           {
               this.state.selectedTicket.receiptdate = ticketfields["receiptdate"];
               //this.state.selectedTicket.sitename = fields["sitename"];
               this.state.selectedTicket.storename = this.state.sitestores.find(x=>x.key.toString() == ticketfields["sitestoreid"]).value;
               this.state.selectedTicket.sitestoreid = ticketfields["sitestoreid"];
               this.state.selectedTicket.amount = ticketfields["amount"];
               this.state.selectedTicket.status = ticketfields["status"];
               this.setState({selectedTicket:this.state.selectedTicket, fields:{}, errors:{},showTicketEditModal:false});
               var result = await G.getData("sitestore/query",[["filter","id=" + this.state.selectedTicket.sitestoreid]]);
               result = await result.data;
               this.setState({scannedStore:result[0]});
               this.getCustomerTickets(this.state.editProfile);

               this.setState({fields:{}, errors:{},showTicketEditModal:false});
           }
        }
        else{
            this.setState({ticketerrors});
        }
    }
     /*SaveTicket = async()=>{
         let {ticketfields, ticketerrors} = this.state;
         var formValid = true;
         if(ticketfields["receiptdate"] == null || ticketfields["receiptdate"] == "")
         {
            ticketerrors["receiptdate"] = "This field is required";
            formValid = false;
         }
         if(ticketfields["sitename"] == null || ticketfields["sitename"] == "")
         {
            ticketerrors["sitename"] = "This field is required";
            formValid = false;
         }
         if(ticketfields["storename"] == null || ticketfields["storename"] == "")
         {
            ticketerrors["storename"] = "This field is required";
            formValid = false;
         }
         if(ticketfields["amount"] == null || ticketfields["amount"] == "")
         {
            ticketerrors["amount"] = "This field is required";
            formValid = false;
         }
         else if(isNaN(ticketfields["amount"]))
         {
            ticketerrors["amount"] = "Please enter a valid amount";
            formValid = false;
         }
         if(ticketfields["status"] == null || ticketfields["status"] == "")
         {
            ticketerrors["status"] = "This field is required";
            formValid = false;
         }
         if(formValid)
         {
            var result = await G.getData("ticket/edit",[["ticketId",this.state.selectedTicket.id], ["receiptdate",ticketfields["receiptdate"]], ["sitename",ticketfields["sitename"]], ["storename",ticketfields["storename"]], ["amount",ticketfields["amount"]], ["status",ticketfields["status"]]]);
            if(result.status ===200)
            {
                this.state.selectedTicket.receiptdate = ticketfields["receiptdate"];
                this.state.selectedTicket.sitename = ticketfields["sitename"];
                this.state.selectedTicket.storename = ticketfields["storename"];
                this.state.selectedTicket.amount = ticketfields["amount"];
                this.state.selectedTicket.status = ticketfields["status"];
                this.setState({selectedTicket:this.state.selectedTicket, ticketfields:{}, ticketerrors:{},showTicketEditModal:false});
            }
         }
         else{
             this.setState({ticketerrors});
         }
     }*/
    /*end ticket functions*/ 
    showGiftToRedeem = async(gift)=>{
        this.setState({
            selectedRedemption: gift,
            modalSubject: "Redeem Gift"
        })
        var item = this.state.selectedRedemption;
         var result = await G.getData("customertokens/query",[["filter","customerid=" + this.state.selectedRedemption.customerid]])
    result = await result.data;
    var customer = await G.getData("profile/query",[["filter","id=" + gift.customerid]]);
    customer = await customer.data;
    item.firstName = customer[0].firstName;
    item.lastName = customer[0].lastName;
    item.emailAddress = customer[0].emailAddress;
    var redeems = await G.getData("customergifts/get",[["filter","cg.giftid=" + customer[0].id]])
                redeems = await redeems.data;
    var score = 0;
    result.map(el=>score += el.score);
    var dbcats = [];
                var result = await G.getData("giftcategory/query",[["filter"," id in (select categoryid from giftcategoryrelation where giftid ="+item.id+")"]]);
                result = await result.data;
                result.map(elem=>{
                    dbcats.push(elem.name)
                })
    item.tokens = score;
        this.setState({
            giftCategory:dbcats,
            giftRedeems: redeems,
            modalGiftDetailsShow: true,
            selectedRedemption: item,
            showRedeemButton: true
        })
    }
    showPromoToRedeem = async(promo)=>{
        this.setState({
            selectedRedemption: promo,
            modalSubject: "Redeem Promo"
        })
        var item = this.state.selectedRedemption;
         var result = await G.getData("customertokens/query",[["filter","customerid=" + this.state.editProfile]])
    result = await result.data;
    var customer = await G.getData("profile/query",[["filter","id=" + this.state.editProfile]]);
    customer = await customer.data;
    item.firstName = customer[0].firstName;
    item.lastName = customer[0].lastName;
    item.emailAddress = customer[0].emailAddress;
    var redeems = await G.getData("customerpromocode/query",[["filter","promocodeid=" + promo.id + " and customerid=" + customer[0].id]])
                redeems = await redeems.data;
    var score = 0;
    result.map(el=>score += el.score);
    var dbcats = [];
                var result = await G.getData("promocodecategory/query",[["filter"," id in (select categoryid from promocodecategoryrelation where promocodeid ="+item.id+")"]]);
                result = await result.data;
                result.map(elem=>{
                    dbcats.push(elem.name)
                })
    item.tokens = score;
        this.setState({
            promocodeCategory:dbcats,
            promoRedeems:redeems,
            modalPromoDetailsShow: true,
            selectedRedemption: item,
            showRedeemButton: true
        })
    }
    redeemGift = async()=>{
        var result = await G.getData("redeemgiftbycode",[["code",this.state.selectedRedemption.code], ["customerid",this.state.selectedRedemption.customerid], ["siteid",this.props.userLogin.site.id], ["adminid",this.props.userLogin.id]]);
                                        result = await result.data;
                                        if(result.response == "success")
                                        {
                                            this.setState({ modalGiftDetailsShow: false });
                                var logtxt = this.props.userLogin.username + " has redeemed " + result.gift.name + " gift for the customer " +  + this.state.fields["firstname"] + " " + this.state.fields["lastname"] + "(" + this.state.fields["email"] + ")";
                    await G.SaveUserLog(logtxt, this.props.userLogin.id);
                                //this.getGiftsPerCustomer();
                                
                                this.getGiftsPerCustomer();
                                result.gift.customerid = this.state.editProfile
                                            this.showRedeemedGift(result.gift);
                                            var customercollections = await G.getData("customers/collected",[['profileId',this.state.editProfile]])
      customercollections = await customercollections.data
      this.setState({ customercollections:customercollections })
                                            //show gift details
                                        }
                                        else{
                                            if(result.response == "notavailable")
                                            {
                                                this.setState({modalGiftDetailsShow:false, giftresult:ts2("this gift is not available for this customer", this.props.userLogin.lanauge), giftResultModalShow:true});
                                            }
                                            else if(result.response == "notfound"){
                                                this.setState({modalGiftDetailsShow:false, giftresult:ts2("Code not found", this.props.userLogin.lanauge), giftResultModalShow:true})
                                            }
                                        }
     }
    showRedeemedGift = async(gift)=>{
        this.setState({
            modalGiftShow: true,
            selectedRedemption: gift,
            modalSubject: "Details"
        })
        
        var result = await G.getData("customertokens/query",[["filter","customerid=" + gift.customerid]])
result = await result.data;
var score = 0;
result.map(el=>score += el.score);
gift.tokens = score;
this.setState({
selectedRedemption: gift
})
    }
    showAvailablePromos = async()=>{
        //var filter = `(((value <= (select sum(score) from customertokens where customerid=`+this.state.editProfile+`)) and redemptiontype='Token') OR (value <= (select sum(score) from customerpoints where customerid=`+this.state.editProfile+`) and redemptiontype='Points')) and (customersallowedlimit is null or customersallowedlimit>(select count(*) from customerpromocode where promocodeid=a.id)) and ((DATEDIFF(a.enddate,now()) >= 0 or a.enddate is null) and (DATEDIFF(a.startdate,now()) <= 0 or a.startdate is null)) and a.id not in (select promocodeid from customerpromocode where customerid=`+this.state.editProfile+`) and a.enabled=1 and siteid= ${this.props.userLogin.site.id}`;
        var filter = `(customersallowedlimit is null or customersallowedlimit>(select count(*) from customerpromocode where promocodeid=a.id)) and ((DATEDIFF(a.enddate,now()) >= 0 or a.enddate is null) and (DATEDIFF(a.startdate,now()) <= 0 or a.startdate is null)) and a.id not in (select promocodeid from customerpromocode where customerid=`+this.state.editProfile+`) and a.enabled=1 and siteid= ${this.props.userLogin.site.id}`;
        var promocodes = await G.getData("promocodes/query",[['filter',filter]]);
        promocodes = await promocodes.data;
        this.setState({availablepromocodes: promocodes,filteredavailablepromocodesList:promocodes, promocodesModalShow:true});
        setTimeout(function(){
            
            const script = document.createElement("script");
    
        script.src = '/dist/js/redeemedgiftstable.js';
        script.async = true;
        document.body.appendChild(script);
        },100);
    }
    getPromoCodes = async()=>{
        this.setState({loadCustomerPromocodes:true})
var promocodes = await G.getData("customers/promocodes/get",[["profileId",this.state.editProfile]]);
promocodes = await promocodes.data;
for(var i=0;i<promocodes.length;i++)
{
    var g = promocodes[i];
//promocodes.map(async(g)=>{
    var result = await G.getData("promocodecategory/query",[["filter"," id in (select categoryid from promocodecategoryrelation where promocodeid ="+g.promocodeid+")"]]);
    result = await result.data;
    g.categories = result;
    this.setState({customerpromocodesList:promocodes, filteredcustomerpromocodesList:promocodes});
    this.setState({showalerts:null})
}
const script = document.createElement("script");
this.setState({loadCustomerPromocodes:false})           
            script.src = '/dist/js/dttable2.js';
            script.async = true;
            document.body.appendChild(script);
    }
    render() {
        const { errors, fields } = this.state;
        return (

            <Container fluid className='fullWidth '>
                {this.state.onlyProfile && <div className='flexcolumn center verticalcenter p-5 fullWidth hint '>
                                {ts2('Loading Profile', this.props.userLogin.language)}
                                <div className='flexrow center fullWidth '>
              <Spinner animation="grow" className='m-3' variant="warning" />
              <Spinner animation="grow" className='m-3' variant="info" />
              <Spinner animation="grow" className='m-3' variant="danger" />

          </div>
                            </div>}
                {this.state.activeScreen === 1 && !this.state.onlyProfile &&
                    <div className='container-fluid ' style={{marginTop:"50px"}}>
                        {/*<h4 className='mt-5'>{ts2('Customers of ', this.props.userLogin.language) + (this.props.userLogin.site ? this.props.userLogin.site.companyName : 'No Company Selected')}</h4>*/}
                         
                            {/*<h6 className='mb-3'>{G.countItems(this.state.allList.length, 'Profile') + ' ' + ts2('created', this.props.userLogin.language)}</h6>*/}

                            <div className='flexrow'>
                                <Button variant='success' onClick={() => { this.setState({ activeScreen: 2, fields: {}, errors: {}, editProfile: -1 });history.push("/ProfileManagement/customers/create") }} className='mr-3 '>{ts2('Create New Profile', this.props.userLogin.language)}</Button>
                                <Button variant='primary' onClick={this.importFromCSV} className='mr-3 '>{ts2('Import', this.props.userLogin.language)}</Button>
                            </div>

                            <div className='mt-5 '>
                                {/*<Form.Label  >{ts2('Search for a customer', this.props.userLogin.language)}</Form.Label>
                                <div className='flexrow col-8'>
                                    <Form.Control onKeyDown={this.onKeyDown} name='filter' type={'text'} placeholder={ts2('Search for a customer', this.props.userLogin.language)} onChange={this.handleChange} value={fields['filter'] || ''} />
                                    {this.state.fields['filter'] && this.state.fields['filter'] !== '' && <Button variant='warning ml-2' onClick={this.filterGrid}>{ts2('Apply', this.props.userLogin.language)}</Button>}
                                    {this.state.fields['filter'] && this.state.fields['filter'] !== '' && <Button variant='info ml-2' onClick={() => {
                                        let { fields } = this.state;
                                        delete fields['filter'];
                                        this.setState({ fields });

                                        this.filterGrid()
                                    }}>{ts2('Clear', this.props.userLogin.language)}</Button>}
                                </div>*/}
                                <h4 className='bold'> {ts('Filter based on customer demographics & behavior', this.props.userLogin.language)}</h4>
                                <div className='row mt-5'>
                                    <div className='col-md-6' >
                                    {G.renderHotspotDropDown("Gender", this.state.availableGender, this.handleChange, this.state.fields, this.state.errors, this.state.selectedgender, false, this.props.userLogin.language)}
                                    </div>
                                    <div className='col-md-6' >
                                    {G.renderTextField("Age", 'text', "All Ages", this.handleChange, this.state.fields, this.state.errors, ts('Filter Example 18-24,35, 50-54', this.props.userLogin.language), '', false, this.props.userLogin.language,true)}
                                                <p className='sremarks hint  ml-3'>{ts('Filter Example 18-24,35,50-54 is equal for (all ages between 18 and 24) or (age equal 35) or (age between 50 and 54)', this.props.userLogin.language)}    </p>
                                    </div>
                                    <div className='col-md-6' >
                                    {/*{G.renderMultiSelectionBox('Countries', this.state.filteredCountryList, ts('All Countries Selected', this.props.userLogin.language),
                                                (removedItem) => { this.setState({ filteredCountryList: [...this.state.filteredCountryList].filter(element => element !== removedItem) }) }
                                                , <Autocomplete showborders={true} reset={true} value={this.state.fields['searchcountry'] || ''} error={this.state.errors['searchcountry']} name='searchcountry' updateFromList={this.handleUpdateAutoComplete} suggestions={this.state.CountryList}
                                                    placeholder={ts('Add Countries Filter Here', this.props.userLogin.language) + ' (' + (this.props.userLogin.language === 0 ? G.countItems(this.state.CountryList.length, "Country", 'Countries') : this.state.CountryList.length + ' Pays') + ' ' + ts('Available', this.props.userLogin.language) + ')'} classNames='fullWidth' onChange={this.handleChange} />
                                                , ''


                            , this.props.userLogin.language)}*/}
                            <Form.Group className={'fullWidth '}>
            <Form.Label htmlFor={"Countries"} >{ts("Countries",this.props.userLogin.language)}</Form.Label>

            <div className=''>
                <div className=''><Select id="Countries"
            onChange={value =>{/*this.handleSelectChange("countries",value)*/this.setState({ filteredCountryList: [...this.state.CountryList].filter(element => value.filter(c=>c.value==element).length>0) })}}
    closeMenuOnSelect={false}
    isMulti
    styles={colourStyles}
    options={this.state.CountriesOptions}
  /></div>
                
            </div>
        </Form.Group>
                                    </div>
                                    <div className='col-md-6' >
                                    {/*G.renderMultiSelectionBox('Cities', this.state.filteredCityList, ts('All Cities Selected', this.props.userLogin.language),
                                                (removedItem) => { this.setState({ filteredCityList: [...this.state.filteredCityList].filter(element => element !== removedItem) }) }
                                                , <Autocomplete showborders={true} reset={true} value={this.state.fields['searchcity'] || ''} error={this.state.errors['searchcity']} name='searchcity' updateFromList={this.handleUpdateAutoComplete} suggestions={this.state.CityList}
                                                    placeholder={ts('Add Cities Filter Here', this.props.userLogin.language) + ' (' + (this.props.userLogin.language === 0 ? G.countItems(this.state.CityList.length, "City", 'Cities') : G.countItems(this.state.CityList.length, 'Ville')) + ' ' + ts('Available', this.props.userLogin.language) + ')'} classNames='fullWidth' onChange={this.handleChange} />
                                                , ''
                        , this.props.userLogin.language)*/}
                         <Form.Group className={'fullWidth '}>
            <Form.Label htmlFor={"cities"} >{ts("Cities",this.props.userLogin.language)}</Form.Label>

            <div className=''>
                <div className=''><Select id="cities"
            onChange={value =>{/*this.handleSelectChange("cities",value)*/this.setState({ filteredCityList: [...this.state.CityList].filter(element => value.filter(c=>c.value==element).length>0) })}}
    closeMenuOnSelect={false}
    isMulti
    styles={colourStyles}
    options={this.state.CitiesOptions}
  /></div>
                
            </div>
        </Form.Group>
                                    </div>
                                    <div className='col-md-6'>
                                        {/*G.renderMultiSelectionBox('Interactions categories', this.state.filteredCategoriesList, ts('All Categories Selected', this.props.userLogin.language),
                                                (removedItem) => { this.setState({ filteredCategoriesList: [...this.state.filteredCategoriesList].filter(element => element !== removedItem) }) }
                                                , <Autocomplete showborders={true} reset={true} value={this.state.fields['category'] || ''} error={this.state.errors['category']} name='category' updateFromList={this.handleUpdateAutoComplete} suggestions={this.state.categoriesList}
                                                    placeholder={ts('Add Categories Filter Here', this.props.userLogin.language) + ' (' + (this.props.userLogin.language === 0 ? G.countItems(this.state.categoriesList.length, "Category", 'Categories') : this.state.categoriesList.length + ' Categories') + ' ' + ts('Available', this.props.userLogin.language) + ')'} classNames='fullWidth' onChange={this.handleChange} />
                                                , ''


                    , this.props.userLogin.language)*/}
                    <Form.Group className={'fullWidth '}>
            <Form.Label htmlFor={"cities"} >{ts("Interactions categories",this.props.userLogin.language)}</Form.Label>

            <div className=''>
                <div className=''><Select id="category"
            onChange={value =>{/*this.handleSelectChange("category",value)*/this.setState({ filteredCategoriesList: [...this.state.categoriesList].filter(element => value.filter(c=>c.value==element.value).length>0) });}}
    closeMenuOnSelect={false}
    isMulti
    styles={colourStyles}
    options={this.state.categoriesList}
  /></div>
                
            </div>
        </Form.Group>
                                                </div>
                                                <div className='col-md-6'>
                                                {G.renderDropDown("Subscription", this.state.availableSubs, this.handleChange, this.state.fields, this.state.errors, ts2("Subscription status", this.props.userLogin.language), false, this.props.userLogin.language)}
                                                </div>
                                       
                                    
                                    
                                </div>
<div className='row'>
<div className='col-md-12'>
                                    <Button variant='primary' className=' ' onClick={this.QueryProfiles}>
            {this.state.loadProfiles && <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                disabled={this.state.successfullySentCampaign}
            />}
            {ts('Query Profiles', this.props.userLogin.language)}</Button>
                                        </div>

</div>
{this.state.allList.length > 0 && <h6 className='mt-4 pl-2'>{G.countItems(this.state.allList.length, ts('Profile', this.props.userLogin.language)) + '  ' + ts('Found', this.props.userLogin.language)} </h6>}
                            </div>
                            {!this.state.loadprofiles &&
                            <div className='p-3 mr-3 '>
                                <table id="example2" className="table table-bordered">
                                        <thead>
                                            <tr>
                                            <th>Action</th>
                                                <th>{ts2('First Name', this.props.userLogin.language)}</th>
                                                <th>{ts2('Last Name', this.props.userLogin.language)}</th>
                                                <th>{ts('Email', this.props.userLogin.language)}</th>
                                                <th>{ts('Mobile', this.props.userLogin.language)}</th>
                                                <th>{ts('Gender', this.props.userLogin.language)}</th>
                                                <th>{ts('Subscription status', this.props.userLogin.language)}</th>
                                                <th>{ts('Birthdate', this.props.userLogin.language)}</th>
                                                <th>{ts('Country', this.props.userLogin.language)}</th>
                                                <th>{ts('City', this.props.userLogin.language)}</th>
                                                <th>{ts('Language', this.props.userLogin.language)}</th>
                                                <th>{ts('Company name', this.props.userLogin.language)}</th>
                                                <th>{ts('Points', this.props.userLogin.language)}</th>
                                                <th>{ts('Tokens', this.props.userLogin.language)}</th>
                                            </tr>
                                        </thead>
                                        {(this.state.allList.length>0) && <tbody>
                                            {
                                                (this.state.allList).map((item) => {
                                                    
                                                    return (
                                                        <tr key={item.id}>
                                                            <td>
                                                            <Badge onClick={() => { this.editProfile(item) }} variant='info' style={{width:"70px", cursor:"pointer"}}>{ts2('View', this.props.userLogin.language)}</Badge><br/>
                                                            <Badge onClick={() => { this.deleteProfile(item) }} variant='danger' style={{width:"70px", cursor:"pointer"}}>{ts2('Delete', this.props.userLogin.language)}</Badge>
                                                            </td>
                                                            <td>{item.firstName}</td>
                                                            <td>{item.lastName}</td>
                                                            
                                                            <td>{item.emailAddress}</td>
                                                            <td>{item.mobile}</td>
                                                            <td>{ts(item.gender === 0 ? 'Male' : item.gender === 1 ? 'Female' : 'not Defined', this.props.userLogin.language)}</td>
                                                            <td>{item.subscriptionstatus}</td>
                                                            <td>{item.age}</td>
                                                            <td>{item.country}</td>
                                                            <td>{item.city}</td>
                                                            <td>{item.language}</td>
                                                            <td>{item.companyname}</td>
                                                            <td>{item.points}</td>
                                                            <td>{item.tokens}</td>

                                                        </tr>)
                                                })
                                            }

                                        </tbody>}
                                    </table>
                            </div>}
                        {/*!this.state.loadprofiles && this.state.allList.length > this.state.perPage && <ReactPaginate
                            previousLabel={ts("Previous", this.props.userLogin.language)}
                            nextLabel={ts("Next", this.props.userLogin.language)}
                            breakLabel={"..."}
                            pageCount={this.state.pageCount}
                            marginPagesDisplayed={this.state.perPage}
                            pageRangeDisplayed={2}
                            onPageChange={this.handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"subPagination"}
                            activeClassName={"activePage "}
                            breakClassName={'eachPage'}
                            previousClassName={'eachPagePrevNex'}
                            nextClassName={'eachPagePrevNex'}
                            pageClassName={'eachPage'}

                            breakClassName={"break-me"}
                        />*/}
                        {this.state.loadprofiles &&
                            <div className='flexcolumn center verticalcenter p-5 fullWidth hint '>
                                {ts2('Loading Available Profiles', this.props.userLogin.language)}
                                <Spinner animation="border" className='mt-3' variant="warning" />

                            </div>
                        }
                        {!this.state.loadprofiles && this.state.allList.length === 0 &&
                            <h4 className='text-center hint pt-5 mt-5 lowalpha'>{ts2('No Profiles Found', this.props.userLogin.language)}
                                <p onClick={() => { this.setState({ activeScreen: 2, fields: {}, errors: {} }) }} >{this.state.originalList.length === 0 && <Badge variant='info' className='mt-2 handCursor'> {ts2('Click Here to Create your first Profile', this.props.userLogin.language)}</Badge>}</p>
                            </h4>}


                    </div>

                }
                {this.state.activeScreen === 2 &&
                    <div className='container-fluid' style={{marginTop:"50px"}}>
                        <div className='row' >
                            <div className="col-md-12"><Button variant='warning' onClick={() => {
                                    this.setState({ activeScreen: 1, errors:{},showalerts:null  })
                                    history.push("/ProfileManagement/customers")
                                    const script = document.createElement("script");
                                        
                                                script.src = '/dist/js/dttable2wexport.js';
                                                script.async = true;
                                            
                                                document.body.appendChild(script);
                                }}>{ts('Cancel / Return', this.props.userLogin.language)}</Button></div>
                        <div className='col-md-6' style={{marginTop:"20px"}}>

<div className="card card-widget widget-user-2">

<div className="widget-user-header" style={{backgroundColor:"#00B8D7"}}>
<div className="widget-user-image">
<img className="img-circle elevation-2" src="/dist/img/profile.png" alt="User Avatar"/>
</div>

<h3 className="widget-user-username" style={{color:"white", marginBottom:"0px", marginTop:"0px"}}>{this.state.fields["firstname"]} {this.state.fields["lastname"]}</h3>
<p className="widget-user-desc" style={{color:"white"}}>{this.state.fields["email"]}</p>
<p className="widget-user-desc" style={{color:"white"}}>{this.state.fields["phone"]}</p>
</div>
<div className="card-footer p-0" style={{backgroundColor:"white"}}>
<ul className="nav flex-column">
<li className="nav-item" style={{borderBottom:"0px"}}>
<a onClick={()=>{this.setState({showPointsModal:true})}} className="nav-link handCursor" style={{color:"#000000"}}>
<i className="fa fa-pen" style={{color:"#00B8D7"}}></i>&nbsp;&nbsp;
Points:&nbsp;<span style={{color:"#00B8D7"}}>{this.state.customercollections && this.state.customercollections.points}</span>
</a>
</li>
<li className="nav-item" style={{borderBottom:"0px"}}>
<a onClick={()=>{this.setState({showTokensModal:true})}} className="nav-link handCursor" style={{color:"#000000"}}>
<i className="fa fa-pen" style={{color:"#00B8D7"}}></i>&nbsp;&nbsp;
Tokens:&nbsp; <span style={{color:"#00B8D7"}}>{this.state.customercollections && this.state.customercollections.tokens}</span>
</a>
</li>
<li className="nav-item" style={{borderBottom:"0px"}}>
{/*<a onClick={async()=>{
    var result = await G.getData("ranking/query",[["filter","siteid=" + this.props.userLogin.site.id]]);
    result = await result.data;
    var options = [];
    result.map(el=>{
        options.push({value:el.id, label:<div><img src={G.nodeServerSite+"mediaFiles/rankingicon/" +el.icon} height="30px" width="30px"/>{el.name} </div>})
    });
    this.setState({showRankingModal:true, RankingsList: options});
    }} className="nav-link handCursor" style={{color:"#000000"}}>*/}
    <a className="nav-link handCursor" style={{color:"#000000"}}>
        <span className="float-left">{/*<i className="fa fa-pen" style={{color:"#00B8D7"}}></i>*/}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
Rank:&nbsp; </span><span className="float-left" style={{color:"#00B8D7"}}>{this.state.customercollections && this.state.customercollections.ranking && <div><table>
    <tr><td><img src={G.nodeServerSite+"mediaFiles/rankingicon/" + this.state.customercollections.ranking.icon} style={{height:"30px"}}/></td><td style={{width:"10px"}}></td><td>{this.state.customercollections.ranking.name}</td></tr></table></div>}</span>
    <div className='clearfix'></div>
</a>
</li>
<li className="nav-item" style={{borderBottom:"0px"}}>
<a onClick={async()=>{
    var result = await G.getData("avatar/query",[["filter","siteid=" + this.props.userLogin.site.id]]);
    result = await result.data;
    var options = [];
    result.map(el=>{
        options.push({value:el.id, label:<div><img src={G.nodeServerSite+"mediaFiles/avatarpicture/" +el.picture} height="30px" width="30px"/>{el.name} </div>})
    });
    console.log(result);
    this.setState({showAvatarModal:true, AvatarsList: options});
    }} className="nav-link handCursor" style={{color:"#000000"}}>
        
<span className="float-left"><i className="fa fa-pen" style={{color:"#00B8D7"}}></i>&nbsp;&nbsp;Avatar:&nbsp;</span> <span className="float-left" style={{color:"#00B8D7"}}>{this.state.customercollections && this.state.customercollections.avatar && <div><table>
    <tr><td><img src={G.nodeServerSite+"mediaFiles/avatarpicture/" + this.state.customercollections.avatar.picture} style={{width:"25px"}}/></td><td style={{width:"10px"}}></td><td>{this.state.customercollections.avatar.name}</td></tr></table></div>}</span>
    <div className="clearfix"></div>
</a>
</li>
<li className="nav-item" style={{borderBottom:"0px"}}>
<a onClick={()=>this.setState({showBadgesModal:true})} className="nav-link handCursor" style={{color:"#000000"}}>
<span className="float-left"><i className="fa fa-eye" style={{color:"#00B8D7"}}></i>&nbsp;&nbsp;
{ts2("Collected Badges",this.props.userLogin.language)}: &nbsp;</span><span className="float-left" style={{color:"#00B8D7"}}>{this.state.customercollections && this.state.customercollections.collectedbadges && <div>
    <table><tr>
        {this.state.customercollections.collectedbadges.map(b=>{
            return(
                <>
            <td><img src={G.nodeServerSite+"mediaFiles/badgesicon/" + b.icon} style={{width:"25px", marginRight:"5px"}}/></td>
            </>
            )
        })}
        <td>{this.state.customercollections.collectedbadges.length}</td>
        </tr>
        </table>
    </div>}</span><div className="clearfix"></div>
</a>
</li>
<li className="nav-item" style={{borderBottom:"0px"}}>
<a onClick={()=>{this.setState({showalerts:true});

document.getElementById("vert-tabs-info").classList.remove("active");
document.getElementById("vert-tabs-info-tab").classList.remove("active");
document.getElementById("vert-tabs-stats").classList.remove("active");
document.getElementById("vert-tabs-stats-tab").classList.remove("active");
document.getElementById("vert-tabs-tickets").classList.remove("active");
document.getElementById("vert-tabs-tickets-tab").classList.remove("active");
document.getElementById("vert-tabs-gifts").classList.remove("active");
document.getElementById("vert-tabs-gifts-tab").classList.remove("active");
document.getElementById("vert-tabs-promo").classList.remove("active");
document.getElementById("vert-tabs-promo-tab").classList.remove("active");
document.getElementById("vert-tabs-competitions").classList.remove("active");
document.getElementById("vert-tabs-competitions-tab").classList.remove("active");

}} className="nav-link handCursor" style={{color:"#000000"}}>
<span className="float-left"><i className="fa fa-eye" style={{color:"#00B8D7"}}></i>&nbsp;&nbsp;
Alerts:&nbsp;</span> <span className="float-left" style={{color:"#00B8D7"}}>{this.state.customercollections && this.state.customercollections.customeralerts &&<div>
{this.state.customercollections.customeralerts.length>0 && <div style={{color:"red"}}>{this.state.customercollections.customeralerts.length} alerts</div>}
{this.state.customercollections.customeralerts.length==0 && <div style={{color:"black"}}>no alerts</div>}
</div>}</span><div className="clearfix"></div>
</a>
</li>
</ul>
</div>
</div>

</div>
                        </div>
                        <div className="row">
<div className="col-5 col-sm-3">
<div className="nav flex-column nav-tabs h-100" id="vert-tabs-tab" role="tablist" aria-orientation="vertical">
<a onClick={()=>{this.setState({showalerts:null})}} className={this.state.showalerts?"nav-link":"nav-link active"} id="vert-tabs-info-tab" data-toggle="pill" href="#vert-tabs-info" role="tab" aria-controls="vert-tabs-info" aria-selected="true">Info</a>
{this.state.editProfile>=0 &&<>
<a  className="nav-link" id="vert-tabs-stats-tab" data-toggle="pill" href="#vert-tabs-stats" role="tab" aria-controls="vert-tabs-stats" aria-selected="false" onClick={async()=>{
    this.GetRelatedCategories();
    this.setState({showalerts:null})
}}>Marketing Stats</a>
<a className="nav-link" id="vert-tabs-tickets-tab" data-toggle="pill" href="#vert-tabs-tickets" role="tab" aria-controls="vert-tabs-tickets" aria-selected="false" onClick={()=>{
this.getCustomerTickets(this.state.editProfile);
this.setState({showalerts:null})
}}>Scanned tickets</a>
<a className="nav-link" id="vert-tabs-gifts-tab" data-toggle="pill" href="#vert-tabs-gifts" role="tab" aria-controls="vert-tabs-gifts" aria-selected="false" onClick={async()=>{
    this.setState({customergiftsloading:true});
    this.getGiftsPerCustomer();
    this.setState({showalerts:null})
   
    
}}>Gifts</a>
<a className="nav-link" id="vert-tabs-promo-tab" data-toggle="pill" href="#vert-tabs-promo" role="tab" aria-controls="vert-tabs-promo" aria-selected="false" onClick={async()=>{

     this.getPromoCodes()       
}}>Promo codes</a>
<a className="nav-link" id="vert-tabs-competitions-tab" data-toggle="pill" href="#vert-tabs-competitions" role="tab" aria-controls="vert-tabs-competitions" aria-selected="false" onClick={async()=>{
    this.setState({loadCustomerCompetitions:true})
    //alert("Load Customer Competitions")
    var competitions = await G.getData("customers/competitions/get",[["profileId",this.state.editProfile]]);
    competitions = await competitions.data
    //competitions.map(async(item)=>{
        for(var i=0;i<competitions.length;i++)
        {
            var item = competitions[i];
            var categories = await G.getData("competitioncategory/query",[["filter"," id in (select categoryid from competitioncategoryrelation where competitionid ="+item.competitionid+")"]])
            categories = await categories.data;
            console.log("Categories",categories);
            item.categories = categories;
        var questions = await G.getData("competitionquestions/query",[["filter","competitionid=" + item.competitionid]])
                questions = await questions.data
        var correctresult = await G.getData("competitionanswerslist/query",[["filter","competitionanswersid in (select id from competitionanswers where competitionid=" + item.competitionid+" and customerid="+item.customerid + ") and answerid in (select id from competitionquestionoptions where iscorrect=1)"]])
         correctresult = await correctresult.data;
            item.totalquestions = questions.length;
            item.correctanswers = correctresult.length;
            item.questions = questions;
            item.correctresult = correctresult;
            //this.setState({competitionsList:competitions, filteredcompetitionsList:competitions})
    //</>})
        }
    this.setState({competitionsList:competitions, filteredcompetitionsList:competitions})
    this.setState({showalerts:null});
    var _this = this;
    _this.setState({loadCustomerCompetitions:false})

                setTimeout(function(){
                    
                    const script = document.createElement("script");
            
            script.src = '/dist/js/customercompetitionstable.js';
            script.async = true;
            document.body.appendChild(script);
            
                },500)
}}>Competitions</a>
<a className={this.state.showalerts?"nav-link active":"nav-link"} id="vert-tabs-alerts-tab" data-toggle="pill" href="#vert-tabs-alerts" role="tab" aria-controls="vert-tabs-alerts" aria-selected="false">Alerts</a>
{/*<a className="nav-link" onClick={()=>{
    this.setState({showalerts:null})}} id="vert-tabs-logs-tab" data-toggle="pill" href="#vert-tabs-logs" role="tab" aria-controls="vert-tabs-logs" aria-selected="false">Logs</a>*/}
</>
    }
</div>
</div>
<div className="col-7 col-sm-9">
<div className="tab-content" id="vert-tabs-tabContent">
<div className={this.state.showalerts?"tab-pane text-left fade":"tab-pane text-left fade show active"} id="vert-tabs-info" role="tabpanel" aria-labelledby="vert-tabs-info-tab">
<Form name="form" onSubmit={this.submitForm.bind(this)} >

                            <Form.Group>
                                <Col>
                                    {G.renderTextField("First Name", 'text', ts2("Enter first name", this.props.userLogin.language), this.handleChange, fields, errors, '', '', false, this.props.userLogin.language)}
                                </Col>
                                <Col>
                                    {G.renderTextField("Last Name", 'text', ts2("Enter last name", this.props.userLogin.language), this.handleChange, fields, errors, '', '', false, this.props.userLogin.language)}
                                </Col>

                            </Form.Group>
                            <Form.Group>
                                <Col>
                                    {G.renderTextField("Email", 'email', ts2("Enter email address", this.props.userLogin.language), this.handleChange, fields, errors, '', '', /*this.state.editProfile > 0*/false , this.props.userLogin.language)}
                                </Col>
                                <Col>
                                    {G.renderTextField("Phone", 'text', ts2("Enter profile primary phone", this.props.userLogin.language), this.handleChange, fields, errors, '', '', false, this.props.userLogin.language)}
                                </Col>

                            </Form.Group>

                            <Form.Group>
                                <Col>
                                    {G.renderDropDown('Gender', [ts('Male', this.props.userLogin.language), ts('Female', this.props.userLogin.language), ts('not Defined', this.props.userLogin.language)], this.handleChange, fields, errors, ts2('Select Gender', this.props.userLogin.language), '', false, this.props.userLogin.language)}
                                </Col>

                                <Col>
                                    {G.renderDatePicker('Birthdate', this.state.birthdate, (date) => { this.setState({ birthdate: date }) }, errors, ts2("Enter the birthdate", this.props.userLogin.language), "dd MMMM yyyy", false, null, this.props.userLogin.language)}
                                </Col>
                            </Form.Group>


                            <Form.Group>
                                <Col>
                                    <Form.Group>
                                        <Form.Label htmlFor={'country'} >{ts('Country', this.props.userLogin.language)}</Form.Label>
                                        <Autocomplete showborders="true" value={this.state.fields['country'] || ''} error={this.state.errors['country']} name='country' updateFromList={this.handleUpdateAutoComplete} suggestions={G.country_list} placeholder={ts2('Enter the profile country', this.props.userLogin.language)} classNames='fullWidth' onChange={this.handleChange} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    {G.renderTextField("City", 'text', ts2("Enter profile city", this.props.userLogin.language), this.handleChange, fields, errors, '', '', false, this.props.userLogin.language)}
                                </Col>
                            </Form.Group>
                            <Form.Group>
                                <Col>
                                    {G.renderDropDown('Language', this.languageArray()/*[ts('fr', this.props.userLogin.language), ts('en', this.props.userLogin.language)]*/, this.handleChange, fields, errors, '', '', false, this.props.userLogin.language)}
                                </Col>
                                <Col>
                                {G.renderDropDown('Subscription Status', [ts('subscribed', this.props.userLogin.language), ts('unsubscribed', this.props.userLogin.language)], this.handleChange, fields, errors, '', '', false, this.props.userLogin.language)}
                                {/*<Form.Label  >{ts('Subscription', this.props.userLogin.language)}</Form.Label>
                                <Form.Control disabled="true" className={' p-1 '}  name="subscriptionstatus" type="text"  value={this.state.subscriptionstatus} />*/}
                                </Col>
                                </Form.Group>
                                <Form.Group>
                                <Col>
                                    {G.renderTextField("Company name", 'text', ts2("Enter company name", this.props.userLogin.language), this.handleChange, fields, errors, '', '', false, this.props.userLogin.language)}
                                </Col>
                                <Col></Col>
                                    </Form.Group>
                            <div className='flexrow pt-5 '>
                                <Button variant='success' type="submit" className='mr-3'>
                                    {this.state.loadSave && <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />}
                                    {ts2(this.state.editProfile < 0 ? 'Register Client' : 'Save', this.props.userLogin.language)}</Button>

                                <Button variant='warning' onClick={() => {
                                    this.setState({ activeScreen: 1, errors:[] })
                                    history.push("/ProfileManagement/customers")
                                    const script = document.createElement("script");
                                        
                                                script.src = '/dist/js/dttable2wexport.js';
                                                script.async = true;
                                            
                                                document.body.appendChild(script);
                                }}>{ts('Return', this.props.userLogin.language)}</Button>
                            </div>
                            
                        </Form>
</div>
{this.state.editProfile>=0 &&
<>
<div className="tab-pane fade" id="vert-tabs-stats" role="tabpanel" aria-labelledby="vert-tabs-stats-tab">
 
                            <div className='flexrow  spacebetween fullWidth col-12  '  >
                            <div className=' mr-2 fullWidth ' >
                                {/*<p className='  ssstitle bold boxTopRadius5 cunlayer   p-3   '> {ts('Statistics',this.props.userLogin.language)} </p>*/}
                                <div style={{ maxHeight: 500, overflow: 'auto' }}>
                                    {this.renderGrid(ts2('Total sent campaigns',this.props.userLogin.language), this.state.profileStats)}

                                </div>
                                <p className='  ssstitle boxTopRadius5 cunlayer   p-3   '> {ts2('Number of categories customer interacted with',this.props.userLogin.language)}: <span style={{fontSize:'30px',fontWeight:'bold' }}>{this.state.profileStats.categoriesNbr}</span></p>
                                
                                <p className='  ssstitle boxTopRadius5 cunlayer   p-3   '> {ts2('List of categories customer interacted with',this.props.userLogin.language)}: </p>
                                <ul>
                                {this.state.profileStats.categories.map(element=>{
                                    return(<li>{element.name}</li>)
                                })}
                                </ul>
                            </div>
                            

                        </div>
                        <div className='flexrow  spacebetween fullWidth col-12  '  >
                            <h4>{ts("Edit hotspot category interactions for this customer", this.props.userLogin.language)}</h4>
                            </div>
                            <div className='fullWidth  '  style={{marginTop:"20px"}}>
                            {!this.state.loadCatList && <div><table id="example1" className="table table-bordered" style={{width:"100%"}}>
                                <thead>
                                    <tr>
                                    <th>Action</th>
                                        <th>{ts2('Category Name',this.props.userLogin.language)}</th>
                                        

                                    </tr>
                                </thead>


                                <tbody>
                                    {
                                        (this.state.hotspotcategoriesList).map((item) => {
                                            
                                            /*this.state.clientsList.filter(val => val.key == item.clientID).map(filteredName => (
                                                clientslug = filteredName.clientslug 
                                            ))*/
                                            return (
                                                <tr key={item.id}>
                                                    
                                                    <td>
                                                        {
                                                            item.Added >0 && <Badge variant="danger" style={{width:"70px", cursor:"pointer"}} onClick={async()=>{
                                                                    await G.getData("removecategorycustomerrelation",[["profileId",this.state.editProfile], ["categoryId", item.id]]);
                                                                    item.Added = 0;
                                                                    this.setState({hotspotcategoriesList:this.state.hotspotcategoriesList})
                                                                    var stats = await G.getData("profileStats",[['profileId',this.state.editProfile]]);
                                                                    var results = await stats.data;
                                                                    this.setState({ profileStats:results })
                                                                    var logtxt = this.props.userLogin.username + " removed hotspot category from customer  Hotspot category:" + item.name + ", Customer Email:" + this.state.fields["email"];
                    await G.SaveUserLog(logtxt, this.props.userLogin.id);
                                                            }}>REMOVE</Badge>
                                                        }
                                                        {
                                                            item.Added == 0 && <Badge style={{backgroundColor:"#1A98AC", color:"white", width:"70px", cursor:"pointer"}} onClick={async()=>{
                                                                await G.getData("addcategorycustomerrelation",[["profileId",this.state.editProfile], ["categoryId", item.id], ["categoryname", item.name], ["clientID", this.props.userLogin.site.id], ["userID", this.props.userLogin.id]]);
                                                                    item.Added = 1;
                                                                    this.setState({hotspotcategoriesList:this.state.hotspotcategoriesList})
                                                                    var stats = await G.getData("profileStats",[['profileId',this.state.editProfile]]);
                                                                    var results = await stats.data;
                                                                    this.setState({ profileStats:results })
                                                                    var logtxt = this.props.userLogin.username + " added hotspot category from customer  Hotspot category:" + item.name + ", Customer Email:" + this.state.fields["email"];
                    await G.SaveUserLog(logtxt, this.props.userLogin.id);
                                                            }}>+ ADD</Badge>
                                                        }
                                                    </td>
                                                    <td>{item.name}</td>
                                                    


                                                </tr>)
                                        })
                                    }

                                </tbody>
                                
                            </table></div>}
                            {this.state.loadCatList &&
                                <div className='flexcolumn center verticalcenter p-5 fullWidth hint '>
                                   {ts2('Loading Categories',this.props.userLogin.language)} 
                                    <Spinner animation="border" className='mt-3' variant="warning" />

                                </div>
                            }
                        </div>
                            
</div>
<div className="tab-pane fade" id="vert-tabs-tickets" role="tabpanel" aria-labelledby="vert-tabs-tickets-tab">
{this.state.ticketactiveScreen === 0 &&

renderPageContainer(
    <>
    <section className='content' >                
        
        
        
        <h3 style={{marginTop:"50px"}}>{ts('Scanned Tickets',this.props.userLogin.language)}&nbsp;&nbsp;
         <DateRangePicker onChange={(value)=>{this.handleDateRangeChange(value)}}  placeholder={ts("Filter by date range", this.props.userLogin.language)} />
        </h3><div className="col-md-12" style={{height:"20px"}}></div>
        <div className="row">
        <div className="col-md-12"><h5>{ts('List of Scanned Tickets',this.props.userLogin.language)} </h5></div>
            <div className='form-group col-md-3' style={{marginTop:"20px"}}>
            <label for="searchfield">Filter table by ticket status</label>
            {/*<select className='custom-select' onChange={this.handleStatusSearch}>
                <option>All</option>
                <option>Approved</option>
                <option>Rejected</option>
                <option>Pending</option>
</select>*/}
<Select ref={this.selectInputRef} menuPortalTarget={document.body} isClearable={true}
value={this.state.searchfields["ticketstatus"]}    
closeMenuOnSelect={false}
isMulti
styles={colourStyles}
options={[{value:'Validated', label:'Validated'},{value:'Rejected', label:'Rejected'},{value:'Pending', label:'Pending'}]}
id="ticketstatus" name="ticketstatus" onChange={(e)=>this.handleStatusSearch(e)}
/>
            </div>
            </div>
        <div className='row'>
            <div className="col-md-12">
            <table className='table table-bordered' id="example1">
                <thead><tr>
                    <th>{ts("Action", this.props.userLogin.language.id)}</th>
                    <th>{ts("Date", this.props.userLogin.language.id)}</th>
                    <th>{ts("Time", this.props.userLogin.language.id)}</th>
                    <th>{ts("Store name", this.props.userLogin.language.id)}</th>
                    <th>{ts("Customer name", this.props.userLogin.language.id)}</th>
                    <th>{ts("Customer email", this.props.userLogin.language.id)}</th>
                    <th>{ts("Amount (EUR)", this.props.userLogin.language.id)}</th>
                    <th>{ts("Status", this.props.userLogin.language.id)}</th>
                    </tr></thead>
{this.state.tickets && !this.state.loadingTickets && <tbody>
{this.state.tickets.map(el=>{
return (
<tr>
<td><Badge style={{backgroundColor:"#1A98AC", color:"white", cursor:"pointer"}} onClick={()=>{
this.OpenDetails(el);
}}>View Details</Badge></td>
<td>{dateFormat(el.datein,"yyyy-mm-dd")}</td>
<td>{dateFormat(el.datein,"hh:MM tt")}</td>
{el.storename?<td>{el.storename}</td>:<td style={{color:"red"}}>Not found</td>}
<td>{el.firstName} {el.lastName}</td>
<td>{el.emailAddress}</td>
<td>{el.amount}</td>
<td>
{el.status=='Validated' &&<Badge style={{backgroundColor:"#37A64A", color:"white"}}>{ts("validated", this.props.userLogin.language.id)}</Badge>}
{el.status=='Pending' &&<Badge style={{backgroundColor:"#FDB83B", color:"black"}}>{ts("pending", this.props.userLogin.language.id)}</Badge>}
{el.status=='Rejected' &&<Badge style={{backgroundColor:"#DA3849", color:"white"}}>{ts("rejected", this.props.userLogin.language.id)}</Badge>}
</td>
</tr>)
})}

</tbody>}
        </table>
        </div>
        {this.state.loadingTickets &&
            <div className='flexcolumn center verticalcenter p-5 fullWidth hint mt-5 mb-4'>
                {/*changed by youssef to meet the selected site requirment*/}
                {/*{ts('Loading templates for',this.props.userLogin.language)} {this.state.activeCompany ? this.state.activeCompany.companyName : ''}*/}
                {ts('Loading data for',this.props.userLogin.language)} {this.props.userLogin.site ? this.props.userLogin.site.companyName : ''}
                <Spinner animation="border" className='mt-3' variant="warning" />

            </div>
        }
        </div>
        {!this.state.loadingTickets &&<div className='row'> <div className='flexrow start wrap mt-2   pt-3 '>
            
            {this.state.tickets.length === 0&&
                <div className='col-md-12'>
                <div className='flexcolumn center verticalcenter p-5 fullWidth cSignInBack boxRadius5'>
                   {ts(' No Tickets scanned yet for',this.props.userLogin.language)} {this.props.userLogin.site ? this.props.userLogin.site.companyName : ''}
                    
                </div>
                </div>
            }

        </div>
        </div>}
        
        
        
        
        </section>
            
        
        
    
    </>
)

}
{

this.state.ticketactiveScreen === 1 &&
renderPageContainer(
    
    <section className='content'>
        {this.state.customersloading &&
            <div className='flexcolumn center verticalcenter p-5 fullWidth hint mt-5 mb-4'>
                {/*changed by youssef to meet the selected site requirment*/}
                {/*{ts('Loading templates for',this.props.userLogin.language)} {this.state.activeCompany ? this.state.activeCompany.companyName : ''}*/}
                {ts('Loading',this.props.userLogin.language)}
                <Spinner animation="border" className='mt-3' variant="warning" />

            </div>
        }
        
        <div className='row'>
        <div className='col-md-12'>
        <Button className='mb-4 mt-5' variant='warning' onClick={() => { this.setState({ticketactiveScreen:0, ticketfields:{}}); }} >{ts('Cancel / Return', this.props.userLogin.language)}</Button>
        </div>
        <div className='col-md-6'>
            <div className='row'>
            <div className='col-md-12'><h4>{ts("Ticket Details",this.props.userLogin.language)}</h4></div>
        <div className='col-md-12'>
        <p className="  ssstitle bold  boxTopRadius5 cunlayer  p-2  ">{ts("Info",this.props.userLogin.language )} </p>
        </div>
        <div className='col-md-12' style={{height:"20px"}}></div>
        <div className='col-md-12'>
            <ul className='ticketscanninglist'><li>{ts("Scanning date & time",this.props.userLogin.language)}: <b>{td(dateFormat(this.state.selectedTicket.datein,"dddd, mmm dd, yyyy hh:MM tt"),this.props.userLogin.language)}</b></li>
            {this.state.scannedStore && this.state.selectedTicket.previouscustomerticketid==null &&<li style={{color:"#33A02C"}}><span style={{color:"#33A02C"}}>✓</span> New ticket</li>}
                                {this.state.scannedStore && this.state.selectedTicket.previouscustomerticketid!=null &&<li style={{color:"#DA3849"}}><span style={{color:"#DA3849"}}>×</span> Ticket already used <a onClick={()=>{this.openRedundancyModal()}} style={{color:"#0070FA", cursor:"pointer"}}>view details</a></li>}
                                
                                <li>{this.state.ticketrule && parseFloat(this.state.selectedTicket.amount)> this.state.ticketrule.maxamountlimit?<span style={{color:"#DA3849"}}>× Customer exceeded amount limit <a onClick={()=>{this.openAmountLimitModal(true)}} style={{color:"#0070FA", cursor:"pointer"}}>view details</a></span>:<span style={{color:"#33A02C"}}>✓ Customer did not exceed amount limit</span> }</li>
                                {this.state.scannedStore &&
                                    <li>{this.state.scannedStore.maxamountperreceipt == null || (!isNaN(this.state.selectedTicket.amount) && this.state.scannedStore.maxamountperreceipt>=parseFloat(this.state.selectedTicket.amount))?<span style={{color:"#33A02C"}}>✓ Customer did not exceed amount limit in {this.state.scannedStore.name}</span>:<span style={{color:"#DA3849"}}>× Customer exceeded amount limit in {this.state.scannedStore.name} <a onClick={()=>{this.openAmountLimitModal(false)}} style={{color:"#0070FA", cursor:"pointer"}}>view details</a></span> }</li>
                                }
                                {this.state.alerts.length>0?<li style={{color:"#DA3849"}}><span style={{color:"#DA3849"}}>×</span> Customer is flagged with alerts <a onClick={()=>{this.openFlagsModal()}} style={{color:"#0070FA", cursor:"pointer"}}>view details</a></li>:<li style={{color:"#33A02C"}}><span style={{color:"#33A02C"}}>✓</span> Customer is not flagged with alerts</li>}
                                <li>
                                    {this.state.ticketrule && this.state.selectedTicket.scannedtickets - 1>this.state.ticketrule.ticketscanlimitperday?<span style={{color:"#DA3849"}}>× Customer exceeded scanning limit <a onClick={()=>{this.openScanningLimitModal(true)}} style={{color:"#0070FA", cursor:"pointer"}}>view details</a></span>:<span style={{color:"#33A02C"}}>✓ Customer did not exceed scanning limit</span>}
                                </li>
                                
                                {
                                    this.state.scannedStore &&
                                    <li>
                                        {this.state.scannedStore.maxnumberofscanspercustomerperday && this.state.selectedTicket.storescannedtickets - 1>this.state.scannedStore.maxnumberofscanspercustomerperday?<span style={{color:"#DA3849"}}>× Customer exceeded scanning limit in {this.state.scannedStore.name} <a onClick={()=>{this.openScanningLimitModal(false)}} style={{color:"#0070FA", cursor:"pointer"}}>view details</a></span>:<span style={{color:"#33A02C"}}>✓ Customer did not exceed scanning limit in {this.state.scannedStore.name}</span>}
                                    </li>
                                }
            </ul>
        </div>
        <div className='col-md-12' style={{height:"20px"}}></div>
        <div className='col-md-12'>
        <p className="  ssstitle bold  boxTopRadius5 cunlayer  p-2  ">{ts("Scanned details",this.props.userLogin.language )} </p>
        </div>
        <div className='col-md-12' style={{height:"20px"}}></div>
        
        <div className='col-md-12'>
            <ul className='ticketscanninglist'>
            {this.state.selectedTicket.receiptdate?<li><span style={{color:"#33A02C"}}>✓</span> {ts("Date & time",this.props.userLogin.language)}: <b>{this.state.selectedTicket.receiptdate}</b></li>:<li><span style={{color:"#DA3849"}}>×</span>{ts("Date & time",this.props.userLogin.language)}: <b>Not detected</b></li>} 
                                {/*<li>{this.state.selectedTicket.sitenamescore>=0.85?<span><span style={{color:"#33A02C"}}>✓</span> {ts("Site name",this.props.userLogin.language)}</span>:<span style={{color:"#DA3849"}}><span >×</span> {ts("Site name",this.props.userLogin.language)}</span>}: <b>{this.state.selectedTicket.sitename}</b></li>*/}
                                <li>{this.state.selectedTicket.storenamescore>=0.6?<span><span style={{color:"#33A02C"}}>✓</span> {ts("Detected store name",this.props.userLogin.language)}: {this.state.selectedTicket.storename?<b>{this.state.selectedTicket.storename}</b>:<b>Not found</b>}  {this.state.selectedTicket.storenamescore>=0.6 && this.state.scannedStore && <span style={{color:"#33A02C"}}>- matched with store {this.state.scannedStore.name}</span>}</span>:<span><span style={{color:"#DA3849"}}>×</span> {ts("Detected store name",this.props.userLogin.language)}: {this.state.selectedTicket.storename?<b>{this.state.selectedTicket.storename}</b>:<b>Not found</b>} <span style={{color:"#DA3849"}}>- did not match with any store</span></span>}</li>
                                {/*<li>{this.state.selectedTicket.storecodescore>=0.85?<span><span style={{color:"#33A02C"}}>✓</span> {ts("Store code",this.props.userLogin.language)}</span>:<span style={{color:"#DA3849"}}><span >×</span> {ts("Store code",this.props.userLogin.language)}</span>}: <b>{this.state.selectedTicket.storecode}</b></li>*/}
                                <li>{this.state.selectedTicket.siteaddresscore>=0.5?<span><span style={{color:"#33A02C"}}>✓</span> {ts("Address",this.props.userLogin.language)}</span>:<span style={{color:"#DA3849"}}><span >×</span> {ts("Address",this.props.userLogin.language)}</span>}: {this.state.selectedTicket.siteaddress?<b>{this.state.selectedTicket.siteaddress}</b>:<b>Not found</b>}</li>
                                {isNaN(this.state.selectedTicket.amount)?<li><span style={{color:"#DA3849"}}>×</span> {ts("Amount",this.props.userLogin.language)}: <b>{this.state.selectedTicket.amount} EUR</b></li>:(this.state.scannedStore.maxamountperreceipt == null || (!isNaN(this.state.selectedTicket.amount) && this.state.scannedStore!=null && this.state.scannedStore.maxamountperreceipt>=parseFloat(this.state.selectedTicket.amount))?<li><span style={{color:"#33A02C"}}>✓</span> {ts("Amount",this.props.userLogin.language)}: <b>{this.state.selectedTicket.amount} EUR</b></li>:(this.state.scannedStore!=null?<li style={{color:"#DA3849"}}><span style={{color:"#DA3849"}}>×</span> {ts("Amount",this.props.userLogin.language)}: <b>{this.state.selectedTicket.amount} EUR</b> <a onClick={()=>{this.openAmountLimitModal()}} style={{color:"#0070FA", cursor:"pointer"}}>view details</a></li>:<li><span style={{color:"#33A02C"}}>✓</span> {ts("Amount",this.props.userLogin.language)}: <b>{this.state.selectedTicket.amount} EUR</b></li>))}
            
            </ul>
            <p><b>Status:</b>&nbsp;
            {this.state.selectedTicket.status=='Validated' &&<Badge style={{backgroundColor:"#37A64A", color:"white"}}>{ts("validated", this.props.userLogin.language.id)}</Badge>}
{this.state.selectedTicket.status=='Pending' &&<Badge style={{backgroundColor:"#FDB83B", color:"black"}}>{ts("pending", this.props.userLogin.language.id)}</Badge>}
{this.state.selectedTicket.status=='Rejected' &&<Badge style={{backgroundColor:"#DA3849", color:"white"}}>{ts("rejected", this.props.userLogin.language.id)}</Badge>}
            </p>
        </div>
            </div>
        </div>
        <div className='col-md-6'>
        <a target="_blank" href={G.nodeServerSite + "mediaFiles/ocrfiles/" + this.state.selectedTicket.ticketimage} style={{position:'absolute', left:'240px', width:"50px", padding:"3px", top:"10px", color:"white", backgroundColor:"#0070FA", textAlign:"center", borderRadius:"5px"}}>View</a>
            {/*<h5>Ticket image will be placed here</h5>*/}
            {this.state.selectedTicket.ticketimage?<img src={G.nodeServerSite + "mediaFiles/ocrfiles/" + this.state.selectedTicket.ticketimage} style={{width:300}}/>:<h5>Image not available</h5>}
        </div>
        <div className='col-md-12' style={{height:"20px"}}></div>
        <div className='col-md-12'>
        <Button className='mb-4 mt-5' variant='primary' onClick={() => { this.ResolveTicket() }} >{ts('Edit details', this.props.userLogin.language)}</Button>&nbsp;
        <Button className='mb-4 mt-5' variant='warning' onClick={() => { this.setState({ticketactiveScreen:0, ticketfields:{}, selectedTicket:null}); }} >{ts('Cancel / Return', this.props.userLogin.language)}</Button>
        </div>
        </div>
<MessageBox
show={this.state.showRedundancyModal}
body={
<div ><center><img src="/dist/img/alerthead.png" style={{width:"50px", margin:"auto"}}/></center><br/>
<h4 style={{textAlign:"center"}}>This ticket is conflicting with another ticket</h4>
<Carousel showArrows={true} showStatus={false} centerMode={true} centerSlidePercentage="33" showIndicators={false} renderArrowPrev={(onClickHandler, hasPrev, label) =>
hasPrev && (
<button type="button"  onClick={onClickHandler} title={label} style={{ position: 'absolute',
zIndex: 2,
top: 'calc(50% - 15px)',
width: 35,
height: 35,
backgroundColor:"transparent",
cursor: 'pointer', left: 15 }}>
    <img src="/dist/img/prev.png" style={{height:35}}/>
</button>
)
}
renderArrowNext={(onClickHandler, hasNext, label) =>
hasNext && (
<button type="button" onClick={onClickHandler} title={label} style={{ position: 'absolute',
zIndex: 2,
top: 'calc(50% - 15px)',
width: 35,
height: 35,
backgroundColor:"transparent",
cursor: 'pointer', right: 15 }}>
    <img src="/dist/img/next.png" style={{height:35}}/>
</button>
)
}>
<div>
<span style={{color:"#DA3849"}}>Current ticket</span><br/><span>ID:{this.state.selectedTicket.id}</span><br/>
{this.state.selectedTicket.ticketimage?<img src={G.nodeServerSite + "mediaFiles/ocrfiles/" + this.state.selectedTicket.ticketimage} style={{width:300}}/>:<div style={{width:"200px",height:"300px", backgroundColor:"lightgray"}}>Image no available</div>}
</div>
{this.state.oldtickets.map(el=>{
return (
<div>
<div>
<span style={{color:"#33A02C"}}>Previously validated ticket</span><br/><span>ID:{el.id}</span><br/>
{el.ticketimage?<img src={G.nodeServerSite + "mediaFiles/ocrfiles/" + el.ticketimage} style={{width:200}}/>:<div style={{width:"200px",height:"300px", backgroundColor:"lightgray"}}>Image no available</div>}
</div>
</div>
);
})}
</Carousel>
<center>
{/*<Button variant="success" style={{marginTop:"50px", width:"150px"}} onClick={()=>{this.ResolveTicket()}}>Resolve</Button>
<br/>*/}
<Button variant="warning" style={{marginTop:"10px", width:"150px"}} onClick={()=>{this.setState({ showRedundancyModal: false })}}>Cancel</Button></center>
</div>
}
onHide={() => { this.setState({ showRedundancyModal: false }) }}
/>
<MessageBox
show={this.state.showTicketEditModal}
body={
<div className="row">
<div className="col-md-7">
<div className="row">
<div className="col-md-12"><h4 style={{textAlign:"left"}}>{ts("Edit ticket details",this.props.userLogin.language)}</h4></div>
                     <div className="col-md-12">
  {G.renderTextField("Date & time","datetime-local","",this.handleTicketChange,this.state.ticketfields,this.state.ticketerrors, "","",false,this.props.userLogin.language,true,"receiptdate")}
  </div>
                     
  {/*<div className="col-md-12">
  {G.renderTextField("Site name","text","Enter site name",this.handleChange,this.state.fields,this.state.errors, "","",false,this.props.userLogin.language,true,"sitename")}
     </div>*/}
     <div className="col-md-12">
     <b className='fullWidth '>
            {ts("Detected store name",this.props.userLogin.language)}</b><br/>
            
            <div >{this.state.ticketfields["storename"]}</div><br/>
        
     </div>
  <div className="col-md-12">
  {G.renderDropDownWithIdAndName("Matched shop name","sitestoreid",this.state.sitestores,this.handleTicketChange,this.state.ticketfields,this.state.ticketerrors, (this.state.scannedStore?this.state.scannedStore.id.toString():''),false,this.props.userLogin.language)}
  </div>
  <div className="col-md-12">
  {G.renderTextField("Amount","text","Enter shop name",this.handleTicketChange,this.state.ticketfields,this.state.ticketerrors, "","",false,this.props.userLogin.language,true,"amount")}
  </div>
  <div className="col-md-12">
  {G.renderHotspotDropDown("Status",["Validated","Pending","Rejected"],this.handleTicketChange,this.state.ticketfields,this.state.ticketerrors, "",false,this.props.userLogin.language,true)}
  </div>
 <div className="col-md-12">
 <Button variant="success" style={{width:"100px"}} onClick={()=>{this.SaveTicket()}}>Save</Button>&nbsp;
 <Button variant="warning" style={{width:"100px"}} onClick={()=>{this.setState({ showTicketEditModal: false })}}>Cancel</Button>
 </div>
</div>


</div>
<div className="col-md-5">
{this.state.selectedTicket.ticketimage?<img src={G.nodeServerSite + "mediaFiles/ocrfiles/" + this.state.selectedTicket.ticketimage} style={{width:"100%"}}/>:<h5>Image not available</h5>}
</div>


<center><br/><br/>


<br/>
</center>
</div>
}
onHide={() => { this.setState({ showTicketEditModal: false }) }}
/>
<MessageBox
     show={this.state.showAmountLimitModal}
     body={
         <div ><center><img src="/dist/img/alerthead.png" style={{width:"50px", margin:"auto"}}/></center><br/>
         <h4 style={{textAlign:"center"}}>This customer has exceeded the amount scanning limit</h4>
         
         <center><br/><br/>
             <p>{ts("Scanning amount limit", this.props.userLogin.language)}: <b>EUR {this.state.showGlobalLimit && this.state.ticketrule && this.state.ticketrule.maxamountlimit?this.state.ticketrule.maxamountlimit:(this.state.scannedStore?this.state.scannedStore.maxamountperreceipt:0)}</b></p>
             {!this.state.showGlobalLimit && this.state.scannedStore?<p>{ts("Limit store", this.props.userLogin.language)}: <b>{this.state.scannedStore.name}</b></p>:<p></p>}
             <p style={{color:"#DA3849"}}>{ts("Customer amount", this.props.userLogin.language)}: <b>EUR {this.state.selectedTicket.amount}</b></p>
         {/*<Button variant="success" style={{marginTop:"50px", width:"150px"}} onClick={()=>{this.ResolveTicket()}}>Resolve</Button>*/}
         <br/>
             <Button variant="warning" style={{marginTop:"10px", width:"150px"}} onClick={()=>{this.setState({ showAmountLimitModal: false })}}>{ts("Close",this.props.userLogin.language)}</Button></center>
         </div>
     }
     onHide={() => { this.setState({ showAmountLimitModal: false }) }}
     />
     <MessageBox
     show={this.state.showScanningLimitModal}
     body={
         <div ><center><img src="/dist/img/alerthead.png" style={{width:"50px", margin:"auto"}}/></center><br/>
         <h4 style={{textAlign:"center"}}>This customer has exceeded the scanning limit</h4>
         
         <center><br/><br/>
             <p>{ts("Scanning limit per customer", this.props.userLogin.language)}: <b>{this.state.showGlobalLimit && this.state.ticketrule && this.state.ticketrule.ticketscanlimitperday?this.state.ticketrule.ticketscanlimitperday:(this.state.scannedStore?this.state.scannedStore.maxnumberofscanspercustomerperday:"unlimited")}</b></p>
             {!this.state.showGlobalLimit && this.state.scannedStore?<p>{ts("Limit store", this.props.userLogin.language)}: <b>{this.state.scannedStore.name}</b></p>:<p></p>}
             <p>{ts("Limit period", this.props.userLogin.language)}: <b>{ts("1 day", this.props.userLogin.language)}</b></p>
             <p style={{color:"#DA3849"}}>{ts("Customer scanning", this.props.userLogin.language)}: <b>{!this.state.showGlobalLimit && this.state.scannedStore?this.state.selectedTicket.storescannedtickets:this.state.selectedTicket.scannedtickets}</b></p>
         {/*<Button variant="success" style={{marginTop:"50px", width:"150px"}} onClick={()=>{this.ResolveTicket()}}>Resolve</Button>*/}
         <br/>
             <Button variant="warning" style={{marginTop:"10px", width:"150px"}} onClick={()=>{this.setState({ showScanningLimitModal: false })}}>{ts("Close",this.props.userLogin.language)}</Button></center>
         </div>
     }
     onHide={() => { this.setState({ showScanningLimitModal: false }) }}
     />
     <MessageBox
     show={this.state.showFlagsModal}
     body={
         <div ><center><img src="/dist/img/alerthead.png" style={{width:"50px", margin:"auto"}}/></center><br/>
         <h4 style={{textAlign:"center"}}>This customer is flagged with an alert</h4>
         
         <center><br/><br/>
             <p>{ts("Flagged by user", this.props.userLogin.language)}: <b>{this.state.reporter.fullName}</b></p>
             {this.state.alerts.length>0 && <p>{ts("Date of flagging", this.props.userLogin.language)}: <b>{td(dateFormat(this.state.alerts[0].datein,"dddd, mmm dd, yyyy hh:MM TT"),this.props.userLogin.language)}</b></p>}
             {this.state.alerts.length>0 && <p>{ts("Reason", this.props.userLogin.language)}: <b><i>"{this.state.alerts[0].description}"</i></b></p>}
             <p><a style={{color:"#0070FA", cursor:"pointer"}} onClick={()=>{window.location.href='/ProfileManagement?p=' + this.state.selectedTicket.customerid + '&a=1'}}>{ts("view alerts history", this.props.userLogin.language)}</a></p>
         {/*<Button variant="success" style={{marginTop:"50px", width:"150px"}} onClick={()=>{this.ResolveTicket()}}>Resolve</Button>*/}
         <br/>
             <Button variant="warning" style={{marginTop:"10px", width:"150px"}} onClick={()=>{this.setState({ showFlagsModal: false })}}>{ts("Close",this.props.userLogin.language)}</Button></center>
         </div>
     }
     onHide={() => { this.setState({ showFlagsModal: false }) }}
     />
<MessageBox
show={this.state.modalShow}
body={
    <div>
    <h4 style={{textAlign:"center"}}>Details</h4>
    
            <center><Button variant="warning" style={{marginTop:"50px", width:"150px"}} onClick={()=>{this.setState({ modalShow: false })}}>Close</Button></center>
            </div>
}
onHide={() => { this.setState({ modalShow: false }) }}
/>
    </section>
    
)
}
</div>
<div className="tab-pane fade" id="vert-tabs-gifts" role="tabpanel" aria-labelledby="vert-tabs-gifts-tab">
<div className='fullWidth  '  style={{marginTop:"20px"}}>
    <a className="btn btn-success" onClick={async()=>{
        var filter = ' a.siteid='+this.props.userLogin.site.id+' and (tokensvalue <= (select sum(score) from customertokens where customerid='+this.state.editProfile+') or tokensvalue is null) and (limitpergift>(select count(*) from customergifts where giftid=a.id) or limitpergift is null) and (DATEDIFF(a.enddate,now()) >= 0 or a.enddate is null) and (DATEDIFF(a.startdate,now()) <= 0 or a.startdate is null) and a.enabled=1';
        var gifts = await G.getData("gifts/query",[['filter',filter]]);
        gifts = await gifts.data;
        this.setState({giftsModalShow:true});
        gifts.map(async(g)=>{
            var result = await G.getData("giftcategory/query",[["filter"," id in (select categoryid from giftcategoryrelation where giftid ="+g.id+")"]]);
            result = await result.data;
            g.categories = result;
            this.setState({availablegifts:gifts, filteredavailablegiftsList:gifts})
        })
        this.setState({availablegifts: gifts, filteredavailablegiftsList:gifts});
        var _this = this;
        setTimeout(function(){
            
            const script = document.createElement("script");
    
        script.src = '/dist/js/redeemedgiftstable.js';
        script.async = true;
        document.body.appendChild(script);
        },100);
        
    }}>{ts("Redeem Gift", this.props.userLogin.language)}</a><br/>
    <h3>{ts2("List of redeemed gifts", this.props.userLogin.language)}</h3>
<label>Filter table by gift category</label>
<div style={{width:"200px", maxWidth:"100%"}}>
								<Select ref={this.selectInputRef} menuPortalTarget={document.body} isClearable={true}
    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            onChange={(e)=>{
                if(e.length>0)
                {
                this.state.filteredcustomergiftsList = this.state.customergiftsList.filter(x=>x.categories!=undefined && x.categories.filter(z=>e.filter(n=>n.value == z.id).length>0).length>0)
                }
                else{
                    this.state.filteredcustomergiftsList = this.state.customergiftsList;
                }
                
                this.setState({filteredcustomergiftsList: this.state.filteredcustomergiftsList})
            }}
    closeMenuOnSelect={false}
    isMulti
    options={this.state.giftCategoryOptions}
  /></div>
                            {!this.state.loadCustomerGifts && <div style={{width:"100%", marginTop:"20px"}}><table id="customergifts" className="table table-bordered" style={{width:"100%", marginTop:"20px"}}>
                                <thead>
                                    <tr>
                                        <th>Action</th>
                                        <th>{ts2('Date',this.props.userLogin.language)}</th>
                                        <th>{ts2('Time',this.props.userLogin.language)}</th>
                                        <th>{ts2('Gift name',this.props.userLogin.language)}</th>
                                        <th>{ts2('Category',this.props.userLogin.language)}</th>
                                    </tr>
                                </thead>

{!this.state.customergiftsloading && 
                                <tbody>
                                    {
                                        (this.state.filteredcustomergiftsList).map((item) => {
                                            
                                            /*this.state.clientsList.filter(val => val.key == item.clientID).map(filteredName => (
                                                clientslug = filteredName.clientslug 
                                            ))*/
                                            return (
                                                <tr key={item.id}>
                                                    
                                                    <td>
                                                    <Badge variant="success" style={{backgroundColor:"#1a98ac", fontSize:"10.5px"}}  className='handCursor remarks' onClick={async()=>{
                                                        this.showRedeemedGift(item)
                                                        
                                                    }}>
View details
</Badge>
                                                    </td>
                                                    <td>{td(dateFormat(item.datein,"mmm dd, yyyy"), this.props.userLogin.language)}</td>
                                                    <td>{td(dateFormat(item.datein,"hh:MM tt"), this.props.userLogin.language)}</td>
                                                    <td>{item.name}</td>
                                                    <td>
                                                    { item.categories && item.categories.map(el=>
                                                    {
                                                       return( <Badge variant="primary" style={{backgroundColor:"rgba(63, 202, 224, 0.19)", marginLeft:"10px", color:"black"}} className=' remarks'>
{el.name}
</Badge>)
                                                    }
                                                    )}
                                                    </td>


                                                </tr>)
                                        })
                                    }

                                </tbody>
    }
                            </table></div>}
                            {this.state.loadCustomerGifts &&
                                <div className='flexcolumn center verticalcenter p-5 fullWidth hint '>
                                   {ts2('Loading Gifts',this.props.userLogin.language)} 
                                    <Spinner animation="border" className='mt-3' variant="warning" />

                                </div>
                            }
                        </div>
</div>
<div className="tab-pane fade" id="vert-tabs-promo" role="tabpanel" aria-labelledby="vert-tabs-promo-tab">
<div className='fullWidth  '  style={{marginTop:"20px"}}>
<a className="btn btn-success" onClick={async()=>{
    this.showAvailablePromos();
        
        
    }}>{ts("Redeem Promo", this.props.userLogin.language)}</a><br/>
    <h3>{ts2("List of promo codes", this.props.userLogin.language)}</h3> 
<label>Filter table by promocode category</label>

<div style={{width:"200px", maxWidth:"100%"}}>
								<Select ref={this.selectInputRef} menuPortalTarget={document.body} isClearable={true}
    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            onChange={(e)=>{
                this.setState({loadCustomerPromocodes:true})
                if(e.length>0)
                {
                var filteredcustomerpromocodesList = this.state.customerpromocodesList.filter(x=>x.categories!=undefined && x.categories.filter(z=>e.filter(n=>n.value == z.id).length>0).length>0)
                this.setState({filteredcustomerpromocodesList: filteredcustomerpromocodesList});
            }
                else{
                    var filteredcustomerpromocodesList = this.state.customerpromocodesList;
                    this.setState({filteredcustomerpromocodesList: filteredcustomerpromocodesList});
                }
                
                var _this = this;
                

                setTimeout(function(){
                    _this.setState({loadCustomerPromocodes:false})
                    const script = document.createElement("script");
            
            script.src = '/dist/js/dttable2.js';
            script.async = true;
            document.body.appendChild(script);
            
                },500)
                
            }}
    closeMenuOnSelect={false}
    isMulti
    options={this.state.promocodeCategoryOptions}
  /></div>
                            
                            <div style={{width:"100%", marginTop:"20px"}}><table id="example2" className="table table-bordered" style={{width:"100%", marginTop:"20px"}}>
                                <thead>
                                    <tr>
                                        <th>Action</th>
                                        <th>{ts2('Date',this.props.userLogin.language)}</th>
                                        <th>{ts2('Time',this.props.userLogin.language)}</th>
                                        <th>{ts2('Promo code name',this.props.userLogin.language)}</th>
                                        <th>{ts2('Category',this.props.userLogin.language)}</th>
                                    </tr>
                                </thead>

                                {!this.state.loadCustomerPromocodes && 
                                <tbody>
                                    {
                                        (this.state.filteredcustomerpromocodesList).map((item) => {
                                            
                                            /*this.state.clientsList.filter(val => val.key == item.clientID).map(filteredName => (
                                                clientslug = filteredName.clientslug 
                                            ))*/
                                            return (
                                                <tr key={item.id}>
                                                    
                                                    <td>
                                                    <Badge variant="success" style={{backgroundColor:"#1a98ac", fontSize:"10.5px"}}  className='handCursor remarks' onClick={async()=>{
                                                        this.setState({
                                                            modalPromocodeShow: true,
                                                            selectedRedemption: item,
                                                            modalSubject: "Details"
                                                        })
                                                        
                                                    }}>
View details
</Badge>
                                                    </td>
                                                    <td>{td(dateFormat(item.datein,"mmm dd, yyyy"), this.props.userLogin.language)}</td>
                                                    <td>{td(dateFormat(item.datein,"hh:MM tt"), this.props.userLogin.language)}</td>
                                                    <td>{item.name}</td>
                                                    <td>
                                                    { item.categories && item.categories.map(el=>
                                                    {
                                                       return( <Badge variant="primary" style={{backgroundColor:"rgba(63, 202, 224, 0.19)", marginLeft:"10px", color:"black"}} className=' remarks'>
{el.name}
</Badge>)
                                                    }
                                                    )}
                                                    </td>


                                                </tr>)
                                        })
                                    }

                                </tbody>
    }
                            </table></div>
                            {this.state.loadCustomerPromocodes &&
                                <div className='flexcolumn center verticalcenter p-5 fullWidth hint '>
                                   {ts2('Loading Promo codes',this.props.userLogin.language)} 
                                    <Spinner animation="border" className='mt-3' variant="warning" />

                                </div>
                            }
                        </div>
</div>
<div className="tab-pane fade" id="vert-tabs-competitions" role="tabpanel" aria-labelledby="vert-tabs-competitions-tab">
<label>Filter table by competition category</label>

<div style={{width:"200px", maxWidth:"100%"}}>
								<Select ref={this.selectInputRef} menuPortalTarget={document.body} isClearable={true}
    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            onChange={(e)=>{
                this.setState({loadCustomerCompetitions:true})
                if(e.length>0)
                {
                    console.log(this.state.competitionsList)
                var filteredcompetitionsList = this.state.competitionsList.filter(x=>x.categories!=undefined && x.categories.filter(z=>e.filter(n=>n.value == z.id).length>0).length>0)
                this.setState({filteredcompetitionsList: filteredcompetitionsList});
            }
                else{
                    var filteredcompetitionsList = this.state.competitionsList;
                    this.setState({filteredcompetitionsList: filteredcompetitionsList});
                }
                
                var _this = this;
                

                setTimeout(function(){
                    _this.setState({loadCustomerCompetitions:false})
                    const script = document.createElement("script");
            
            script.src = '/dist/js/customercompetitionstable.js';
            script.async = true;
            document.body.appendChild(script);
            
                },500)
                
            }}
    closeMenuOnSelect={false}
    isMulti
    options={this.state.competitionCategoryOptions}
  /></div>
 <div style={{width:"100%", marginTop:"20px"}}><table id="customercompetitionstable" className="table table-bordered" style={{width:"100%", marginTop:"20px"}}>
                                <thead>
                                    <tr>
                                        <th>Action</th>
                                        <th>{ts2('Date',this.props.userLogin.language)}</th>
                                        <th>{ts2('Time',this.props.userLogin.language)}</th>
                                        <th>{ts2('Correct answers',this.props.userLogin.language)}</th>
                                    </tr>
                                </thead>

                                {!this.state.loadCustomerCompetitions &&
                                <tbody>
                                    {
                                        (this.state.filteredcompetitionsList).map((item) => {
                                            
                                            /*this.state.clientsList.filter(val => val.key == item.clientID).map(filteredName => (
                                                clientslug = filteredName.clientslug 
                                            ))*/
                                            return (
                                                <tr key={item.id}>
                                                    
                                                    <td>
                                                    <Badge variant="success" style={{backgroundColor:"#1a98ac", fontSize:"10.5px"}}  className='handCursor remarks' onClick={async()=>{
                                                        var correctresult = await G.getData("competitionanswerslist/query",[["filter","competitionanswersid in (select id from competitionanswers where competitionid=" + item.id+" and customerid="+item.customerid + ") and answerid in (select id from competitionquestionoptions where iscorrect=1)"]])
                                                        correctresult = await correctresult.data;
                                                        var questions = item.questions
                                                        questions.map(async(q)=>{
                                                           var answers = await G.getData("questioncustomeranswers/get",[["questionid",q.id], ["customerid",item.customerid]])
                                                           q.answers = await answers.data;
                                                           var correct = await G.getData("competitionquestionoptions/query",[["filter","questionid=" + q.id + " and iscorrect=1"]])
                                                           correct = await correct.data
                                                           q.correctanswer = correct.length>0?correct[0].description:""
                                                           this.setState({
                                                               questions:questions
                                                           })
                                                       })
                                                        this.setState({
                                                            modalCompetitionShow: true,
                                                            selectedRedemption: item,
                                                            questions:questions
                                                        })
                                                        
                                                    }}>
View details
</Badge>
                                                    </td>
                                                    <td>{td(dateFormat(item.datein,"mmm dd, yyyy"), this.props.userLogin.language)}</td>
                                                    <td>{td(dateFormat(item.datein,"hh:MM tt"), this.props.userLogin.language)}</td>
                                                <td>{item.correctanswers}/{item.totalquestions}</td>
              


                                                </tr>)
                                        })
                                    }

                                </tbody>
    }
                            </table></div>
                            {this.state.loadCustomerCompetitions &&
                                <div className='flexcolumn center verticalcenter p-5 fullWidth hint '>
                                   {ts2('Loading Competitions',this.props.userLogin.language)} 
                                    <Spinner animation="border" className='mt-3' variant="warning" />

                                </div>
                            }
</div>
<div className={this.state.showalerts?"tab-pane text-left fade show active":"tab-pane text-left fade"} id="vert-tabs-alerts" role="tabpanel" aria-labelledby="vert-tabs-alerts-tab">
    <div className="row">
        <div className="col-md-12"><Button onClick={()=>{
            this.setState({modalCreateAlertShow:true})
        }} variant="danger">{ts2("Create New Alert",this.props.userLogin.language)}</Button></div>
    </div>
    {this.state.customercollections && this.state.customercollections.customeralerts &&
        <div className='row' style={{marginTop:"20px"}}>
        <div className='col-md-12'>
        <table id="example2" className="table table-bordered" style={{width:"100%"}}><tr><th>Action</th><th>Date</th><th>{ts2("Time",this.props.userLogin.language)}</th><th>User</th><th>Reasons</th></tr>
{this.state.customercollections.customeralerts.map(item=>{
    return(
        <>
{item.status == 1 && <tr>
    <td style={{lineHeight:"39px"}}><Badge variant="success"  className='handCursor remarks' style={{color:"white", width:"69.5px", backgroundColor:"#1a98ac", fontSize:"10.5px"}} onClick={async()=>{
                                                        this.setState({
                                                            modalAlertDetailsShow: true,
                                                            selectedRedemption: item,
                                                            modalSubject: "Details"
                                                        })
                                                        
                                                    }}>{ts("View Details",this.props.userLogin.language)}</Badge><br/>
                                                    <Badge variant="alert" style={{backgroundColor:"#DC3545", color:"white", width:"69.5px", fontSize:"10.5px"}}  className='handCursor remarks' onClick={async()=>{
                                                        await G.getData("hidecustomeralert",[["id",item.id]]);
                                                        var logtxt = this.props.userLogin.username + " removed alert from customer (Alert:"+item.description + ", Customer:"+item.fullName+")";
                    await G.SaveUserLog(logtxt, this.props.userLogin.id);
                                                        var customercollections = await G.getData("customers/collected",[['profileId',this.state.editProfile]])
        customercollections = await customercollections.data
        this.setState({ customercollections:customercollections })
                                                        
                                                    }}>{ts("Remove",this.props.userLogin.language)}</Badge>
                                                    </td>
    <td>{td(dateFormat(item.datein,"mmm dd, yyyy"), this.props.userLogin.language)}</td>
    <td>{td(dateFormat(item.datein,"hh:MM tt"), this.props.userLogin.language)}</td>
    <td>{item.fullName}</td>
    <td><div style={{width:"200px", overflow:'hidden', textOverflow:"ellipsis", whiteSpace:"nowrap"}}>{item.description}</div></td>
</tr>}</>)
})}
</table>
        </div>
    </div>
    }
                            
</div>
{/*<div className="tab-pane fade" id="vert-tabs-logs" role="tabpanel" aria-labelledby="vert-tabs-logs-tab">
    
    {this.state.customercollections && this.state.customercollections.customerlogs &&
        <div className='row' style={{marginTop:"20px"}}>
        <div className='col-md-12'>
        <table id="example2" className="table table-bordered" style={{width:"100%"}}><tr><th>Logs</th><th>Date</th></tr>
{this.state.customercollections.customerlogs.map(item=>{
    return(
<tr>
    <td>{item.logtxt}</td>
    <td>{td(dateFormat(item.datein,"mmm dd, yyyy hh:MM tt"), this.props.userLogin.language)}</td>
</tr>)
})}
</table>
        </div>
    </div>
    }
                        
</div>*/}
</>
    }
</div>
</div>
</div>
                        




                    </div>}
                <MessageBox
                    show={this.state.modalShow}
                    title={this.state.modalSubject}
                    body={this.state.modalBody}
                    middle={{ action: () => { this.confirmDeletePRofile() }, text: ts2('Delete Profile',this.props.userLogin.language), variant: 'danger' }}

                    right={{ action: () => { }, text: ts('Cancel',this.props.userLogin.language), variant: 'success' }}
                    onHide={() => { this.setState({ modalShow: false }) }}
                />
                <MessageBox
                    show={this.state.giftsModalShow}
                    title="Redeem Gifts"
                    body={
                        <div className="container-fluid">
                            <div className='row'>
                            <div className="col-md-8">
                            {G.renderTextField("Enter gift code here:","text", "enter gift code here", this.handleChange, fields, errors, null,'',false,this.props.userLogin.language, true, "giftredeemcode")}
                            </div>
                            <div className="col-md-4" style={{position:"relative"}}>
                                <Button variant='success' onClick={async()=>{
                                    if(this.state.fields.giftredeemcode)
                                    {
                                        /*var result = await G.getData("redeemgiftbycode",[["code",this.state.fields.giftredeemcode], ["customerid",this.state.editProfile], ["siteid",this.props.userLogin.site.id], ["adminid",this.props.userLogin.id]]);
                                        result = await result.data;
                                        if(result.response == "success")
                                        {
                                            this.setState({ giftsModalShow: false });

                                var logtxt = this.props.userLogin.username + " has redeemed " + result.gift.name + " gift for the customer " +  + this.state.fields["firstname"] + " " + this.state.fields["lastname"] + "(" + this.state.fields["email"] + ")";
                    await G.SaveUserLog(logtxt, this.props.userLogin.id);
                                this.getGiftsPerCustomer();
                                result.gift.customerid = this.state.editProfile
                                            this.showRedeemedGift(result.gift);
                                            var customercollections = await G.getData("customers/collected",[['profileId',this.state.editProfile]])
      customercollections = await customercollections.data
      this.setState({ customercollections:customercollections })
                                            //show gift details
                                        }
                                        else{
                                            if(result.response == "notavailable")
                                            {
                                                this.setState({giftsModalShow:false, giftresult:ts2("this gift is not available for this customer", this.props.userLogin.lanauge), giftResultModalShow:true});
                                            }
                                            else if(result.response == "notfound"){
                                                this.setState({giftsModalShow:false, giftresult:ts2("Code not found", this.props.userLogin.lanauge), giftResultModalShow:true})
                                            }
                                        }*/
                                        var result = await G.getData("customergiftcode/query",[["filter","code='"+this.state.fields.giftredeemcode+"'"], ["siteid",this.props.userLogin.site.id]]);
                                        result = await result.data;
                                        if(result.length>0)
                                        {
                                            this.setState({ giftsModalShow: false });
                                            var gift = await G.getData("gifts/query",[["filter","id=" + result[0].giftid]])
                                            gift = await gift.data
                                            gift[0].customerid = result[0].customerid
                                            this.showGiftToRedeem(gift[0])
                                            //show gift details
                                        }
                                        else{
                                            
                                                this.setState({redeemModalShow:false, giftresult:ts2("Gift code not found", this.props.userLogin.lanauge), giftResultModalShow:true})
                                        }
                                    }
                                    else{
                                        this.state.errors["giftredeemcode"] = "This field is required";
                                        this.setState({errors:this.state.errors});
                                    }
                                }}  style={{position:"absolute", bottom:"17px"}}>{ts("Search", this.props.userLogin.language)}</Button>
                            </div>
                            </div>
                            <div className="row" style={{marginTop:"40px", marginBottom:"40px"}}>
                                <div className="col-md-12">
                                Or search for an available gift for this customer to redeem
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                <label>Filter table by gift category</label>
<div style={{width:"200px", maxWidth:"100%"}}>
								<Select ref={this.selectInputRef} menuPortalTarget={document.body} isClearable={true}
    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            onChange={(e)=>{
                this.setState({loadavailablegifts:true});
                if(e.length>0)
                {
                this.state.filteredavailablegiftsList = this.state.availablegifts.filter(x=>x.categories!=undefined && x.categories.filter(z=>e.filter(n=>n.value == z.id).length>0).length>0)
                }
                else{
                    this.state.filteredavailablegiftsList = this.state.availablegifts;
                }
                this.setState({filteredavailablegiftsList: this.state.filteredavailablegiftsList});
                var _this = this;
                setTimeout(function(){
                    _this.setState({loadavailablegifts:false});
                    const script = document.createElement("script");
            
                script.src = '/dist/js/redeemedgiftstable.js';
                script.async = true;
                document.body.appendChild(script);
                },100);
            }}
    closeMenuOnSelect={false}
    isMulti
    options={this.state.giftCategoryOptions}
  /></div><br/><br/>
                                <table className="table table-bordered" id="redeemedgifts">
                                    <thead>
                                    <tr><th>Action</th><th>Name</th></tr>
                                    </thead>
                                    {!this.state.loadavailablegifts &&
                                    <tbody>
                        {this.state.filteredavailablegiftsList.map(gift=>{
                            return(<tr><td><Badge variant="success" style={{padding:"10px", cursor:"pointer"}} onClick={async()=>{
                                await G.getData("customergifts/insert",[["customerid",this.state.editProfile],["giftid",gift.id]]);
                                await G.getData("customertokens/insert",[["customerid", this.state.editProfile],["score",(gift.tokensvalue * -1)], ["byadmin","1"], ["adminid",this.props.userLogin.id]]);
                                this.setState({ giftsModalShow: false });
                                var logtxt = this.props.userLogin.username + " has redeemed " + gift.name + " gift for the customer " +  this.state.fields["firstname"] + " " + this.state.fields["lastname"] + "(" + this.state.fields["email"] + ")";
                    await G.SaveUserLog(logtxt, this.props.userLogin.id);
                                this.getGiftsPerCustomer();
                                gift.customerid = this.state.editProfile
                                this.showRedeemedGift(gift);
                                var customercollections = await G.getData("customers/collected",[['profileId',this.state.editProfile]])
      customercollections = await customercollections.data
      this.setState({ customercollections:customercollections })
                            }}>{ts("Redeem", this.props.userLogin.language)}</Badge></td><td>{gift.name}</td></tr>);
                        })}
                        </tbody>}
                        </table>
                                </div>
                            </div>
                        </div>
                    }
                    onHide={() => { this.setState({ giftsModalShow: false }) }}
                />
                <MessageBox
                    show={this.state.giftResultModalShow}
                    body={<div style={{width:"90%", margin:"auto"}}>
                        <h3 style={{width:"100%", textAlign:"center"}}>{this.state.giftresult}</h3><br/><br/>
                        <center><Button variant="warning" onClick={()=>{this.setState({giftResultModalShow:false})}}>OK</Button></center>
                    </div>}
                    onHide={() => { this.setState({ giftResultModalShow: false }) }}
                />
                <MessageBox
                    show={this.state.promocodesModalShow}
                    title="Redeem Promo Code"
                    body={
                        <div className="container-fluid">
                            <div className='row'>
                            <div className="col-md-8">
                            {G.renderTextField("Enter promo code here:","text", "enter promo code here", this.handleChange, fields, errors, null,'',false,this.props.userLogin.language, true, "promoredeemcode")}
                            </div>
                            <div className="col-md-4" style={{position:"relative"}}>
                                <Button variant='success' onClick={async()=>{
                                    if(this.state.fields.promoredeemcode)
                                    {
                                        var result = await G.getData("promocodes/query",[["filter","code='"+this.state.fields.promoredeemcode+"' and siteid=" + this.props.userLogin.site.id]]);
                                        result = await result.data;
                                        if(result.length>0)
                                        {
                                            this.setState({ promocodesModalShow: false });
                                            this.showPromoToRedeem(result[0])
                                            //show gift details
                                        }
                                        else{
                                            
                                            this.setState({promocodesModalShow:false, giftresult:ts2("Code not found", this.props.userLogin.lanauge), giftResultModalShow:true})
                                        }
                                        /*var result = await G.getData("redeempromobycode",[["code",this.state.fields.promoredeemcode], ["customerid",this.state.editProfile], ["siteid",this.props.userLogin.site.id]]);
                                        result = await result.data;
                                        if(result.response == "success")
                                        {
                                            var customercollections = await G.getData("customers/collected",[['profileId',this.state.editProfile]])
        customercollections = await customercollections.data
        this.setState({ customercollections:customercollections })
                                            this.setState({ promocodesModalShow: false });
                                var logtxt = this.props.userLogin.username + " has redeemed " + result.promocode.name + " promocode for the customer " +  + this.state.fields["firstname"] + " " + this.state.fields["lastname"] + "(" + this.state.fields["email"] + ")";
                    await G.SaveUserLog(logtxt, this.props.userLogin.id);
                    this.setState({
                        modalPromocodeShow: true,
                        selectedRedemption: result.promocode,
                        modalSubject: "Details"
                    });
                    this.getPromoCodes();
                    setTimeout(function(){
            
                        const script = document.createElement("script");
                
                    script.src = '/dist/js/redeemedgiftstable.js';
                    script.async = true;
                    document.body.appendChild(script);
                    },100);
                                //this.getPromo();
                                      //      this.showRedeemedGift(result.gift)
                                            //show gift details
                                        }
                                        else{
                                            if(result.response == "notavailable")
                                            {
                                                this.setState({promocodesModalShow:false, giftresult:ts2("this promocode is not available for this customer", this.props.userLogin.lanauge), giftResultModalShow:true});
                                            }
                                            else if(result.response == "notfound"){
                                                this.setState({promocodesModalShow:false, giftresult:ts2("Code not found", this.props.userLogin.lanauge), giftResultModalShow:true})
                                            }
                                        }*/
                                    }
                                    else{
                                        this.state.errors["promoredeemcode"] = "This field is required";
                                        this.setState({errors:this.state.errors});
                                    }
                                }}  style={{position:"absolute", bottom:"17px"}}>{ts("Search", this.props.userLogin.language)}</Button>
                            </div>
                            </div>
                            <div className="row" style={{marginTop:"40px", marginBottom:"40px"}}>
                                <div className="col-md-12">
                                Or search for an available promocode for this customer to redeem
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                <label>Filter table by promocode category</label>

<div style={{width:"200px", maxWidth:"100%"}}>
								<Select ref={this.selectInputRef} menuPortalTarget={document.body} isClearable={true}
    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            onChange={(e)=>{
                this.setState({loadavailablepromocodes:true})
                if(e.length>0)
                {
                var filteredavailablepromocodesList = this.state.availablepromocodes.filter(x=>x.categories!=undefined && x.categories.filter(z=>e.filter(n=>n.value == z.id).length>0).length>0)
                this.setState({filteredavailablepromocodesList: filteredavailablepromocodesList});
            }
                else{
                    var filteredavailablepromocodesList = this.state.availablepromocodes;
                    this.setState({filteredavailablepromocodesList: filteredavailablepromocodesList});
                }
                
                var _this = this;
                
                _this.setState({loadavailablepromocodes:false})
                setTimeout(function(){
            
                    const script = document.createElement("script");
            
                script.src = '/dist/js/redeemedgiftstable.js';
                script.async = true;
                document.body.appendChild(script);
                },100);
                
            }}
    closeMenuOnSelect={false}
    isMulti
    options={this.state.promocodeCategoryOptions}
  /></div><br/><br/>
                        <table className="table table-bordered" id="redeemedgifts"><thead><tr><th>Action</th><th>Name</th></tr></thead>
                        {!this.state.loadavailablepromocodes &&
                        <tbody>
                        {this.state.filteredavailablepromocodesList.map(promo=>{
                            return(<tr><td><Badge variant="success" style={{padding:"10px", cursor:"pointer"}} onClick={async()=>{
                                
            this.setState({loadCustomerPromocodes:true})
                                await G.getData("customerpromocode/upload",[["customerid",this.state.editProfile],["promocodeid",promo.id],["used",1]]);
                                var customercollections = await G.getData("customers/collected",[['profileId',this.state.editProfile]])
        customercollections = await customercollections.data
        this.setState({ customercollections:customercollections })
                                var logtxt = this.props.userLogin.username + " has redeemed " + promo.name + " promocode for the customer " + this.state.fields["firstname"] + " " + this.state.fields["lastname"] + "(" + this.state.fields["email"] + ")";
                    await G.SaveUserLog(logtxt, this.props.userLogin.id);
                    this.setState({ promocodesModalShow: false });
this.setState({
                        modalPromocodeShow: true,
                        selectedRedemption: promo,
                        modalSubject: "Details"
                    });
                    this.getPromoCodes();
                    
                            }}>Redeem</Badge></td><td>{promo.name}</td></tr>);
                        })}
                        </tbody>}
                        </table></div>
                        </div>
                        </div>
                    }
                    onHide={() => { this.setState({ promocodesModalShow: false }) }}
                />
                <MessageBox
                    show={this.state.showPointsModal}
                    body={<div style={{width:"90%", margin:"auto"}}>
                        <h3 style={{width:"100%", textAlign:"center"}}>Adjust number of points</h3>
                        {G.renderTextField("", "number", "Adjust number of points", this.handleChange, this.state.fields, this.state.errors, "","",false,this.props.userLogin.language, true, "points")}
                        <center><Button onClick={()=>{this.AddPoints(1)}} variant="success" style={{width:"150px"}}>Add</Button>&nbsp;
                        <Button onClick={()=>{this.AddPoints(-1)}} variant="danger" style={{width:"150px"}}>Deduct</Button><br/>
                        <br/><br/>
                        <Button onClick={()=>{this.setState({ showPointsModal: false })}} variant="warning" style={{width:"150px"}}>Close</Button></center>
                    </div>}
                    onHide={() => { this.setState({ showPointsModal: false }) }}
                />
                <MessageBox
                    show={this.state.showTokensModal}
                    body={<div style={{width:"90%", margin:"auto"}}>
                        <h3 style={{width:"100%", textAlign:"center"}}>Adjust number of tokens</h3>
                        {G.renderTextField("", "number", "Adjust number of tokens", this.handleChange, this.state.fields, this.state.errors, "","",false,this.props.userLogin.language, true, "tokens")}
                        <center><Button onClick={()=>{this.AddTokens(1)}} variant="success" style={{width:"150px"}}>Add</Button>&nbsp;<Button onClick={()=>{this.AddTokens(-1)}} variant="danger" style={{width:"150px"}}>Deduct</Button><br/><br/>
                        <Button onClick={()=>{this.setState({ showTokensModal: false })}} variant="warning" style={{width:"150px"}}>Close</Button></center>
                    </div>}
                    onHide={() => { this.setState({ showTokensModal: false }) }}
                />
                <MessageBox
                    show={this.state.showRankingModal}
                    body={<div style={{width:"90%", margin:"auto"}}>
                        <h3 style={{width:"100%", textAlign:"center"}}>Edit rank</h3><br/>
                        {this.state.RankingsList.length>0 && <Select ref={this.selectInputRef} menuPortalTarget={document.body} isClearable={true}
    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            onChange={(e)=>{
                this.state.fields["rankid"] = e.value;
                
                this.setState({fields: this.state.fields})
            }}
    closeMenuOnSelect={true}
    options={this.state.RankingsList}
  />}<br/>{this.state.errors["rankid"] &&<div className="remarks notValidColor">{this.state.errors["rankid"]}</div>}
                            <br/><br/>
                        <center><Button onClick={()=>{this.EditRank()}} variant="success" style={{width:"150px"}}>Save</Button><br/><br/>
                        <Button onClick={()=>{this.setState({ showRankingModal: false })}} variant="warning" style={{width:"150px"}}>Close</Button></center>
                    </div>}
                    onHide={() => { this.setState({ showRankingModal: false }) }}
                />
                <MessageBox
                    show={this.state.showAvatarModal}
                    body={<div style={{width:"90%", margin:"auto"}}>
                        <h3 style={{width:"100%", textAlign:"center"}}>Edit avatar</h3><br/>
                        {this.state.AvatarsList.length>0 && <Select ref={this.selectInputRef} menuPortalTarget={document.body} isClearable={true}
    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            onChange={(e)=>{
                this.state.fields["avatarid"] = e.value;
                
                this.setState({fields: this.state.fields})
            }}
    closeMenuOnSelect={true}
    options={this.state.AvatarsList}
  />}<br/>{this.state.errors["avatarid"] &&<div className="remarks notValidColor">{this.state.errors["avatarid"]}</div>}
                            <br/><br/>
                        <center><Button onClick={()=>{this.EditAvatar()}} variant="success" style={{width:"150px"}}>Save</Button><br/><br/>
                        <Button onClick={()=>{this.setState({ showAvatarModal: false })}} variant="warning" style={{width:"150px"}}>Close</Button></center>
                    </div>}
                    onHide={() => { this.setState({ showAvatarModal: false }) }}
                />
                <MessageBox
                    show={this.state.showBadgesModal}
                    body={<div style={{width:"90%", margin:"auto"}}>
                        <h3 style={{width:"100%", textAlign:"center"}}>{ts2("Collected Badges",this.props.userLogin.language)}</h3><br/>
                        <div className="container"><div className="row">
                        {this.state.customercollections && this.state.customercollections.collectedbadges.map(b=>{
            return(
                <>
            <div className="col-md-4" style={{textAlign:"center", marginTop:"10px"}}><img src={G.nodeServerSite+"mediaFiles/badgesicon/" + b.icon}/><br/>{b.name}</div>
            </>
            )
        })}
        {!this.state.customercollections || this.state.customercollections.collectedbadges.length == 0 && <div className="col-md-12" style={{textAlign:"center", marginTop:"10px"}}>{ts2("No badges collected yet", this.props.userLogin.language)}</div>}
                        </div></div>
                            <br/><br/>
                        <center>
                        <Button onClick={()=>{this.setState({ showBadgesModal: false })}} variant="warning" style={{width:"150px"}}>Close</Button></center>
                    </div>}
                    onHide={() => { this.setState({ showBadgesModal: false }) }}
                />
                <MessageBox
                    show={this.state.modalCompetitionShow}
                    
                    body={this.state.selectedRedemption &&
                        <>
                        <h3>Customer answers</h3><br/>
                        <h5>Customer details:</h5><br/>
                        <ul>
                                <li>Customer name: {this.state.fields["firstname"]} {this.state.fields["lastname"]}</li>
<li>Customer email: {this.state.fields["email"]}</li>
<li>Correct answers: {this.state.selectedRedemption.correctanswers}/{this.state.selectedRedemption.totalquestions}</li>
<li>Participation date: {td(dateFormat(this.state.selectedRedemption.datein,"mmm dd, yyyy"), this.props.userLogin.language)}</li>
                                <li>Participation time: {td(dateFormat(this.state.selectedRedemption.datein,"hh:MM tt"), this.props.userLogin.language)}</li>
                            
                                </ul>
{
    
    this.state.questions.map((q, index)=>{
        return(
        <>
        <div className='col-md-12'>
							<p className="  ssstitle bold  boxTopRadius5 cunlayer  p-2  ">{ts("Competition question " + (index + 1).toString(),this.props.userLogin.language )} </p>
							</div>
							<div className='col-md-12' style={{height:"20px"}}></div>
                            <div className="row" style={{marginLeft:"10px"}}>
                                <div className="col-md-12">
                                    <b>Question</b>
                                </div>
                                <div className="col-md-12">
                                    {q.description}
                                </div>
                                <div className='col-md-12' style={{height:"10px"}}></div>
                                <div className="col-md-12">
                                    <b>Customer answer</b>
                                </div>
                                {
                                    q.answers && q.answers.map(a=>{
                                        return(
                                            <>
                                            
                                <div className="col-md-12">
                                    {a.freetext!=null && a.freetext!=''?a.freetext:a.answer}<br/>
                                    {(a.freetext==null || a.freetext=='') && a.iscorrect && <span style={{color:"#33A02C"}}>correct answer</span>}
                                    {(a.freetext==null || a.freetext=='') && !a.iscorrect && <span style={{color:"#DA3849"}}>wrong answer</span>}
                                    {(a.freetext==null || a.freetext=='') && !a.iscorrect && q.answertype=='single choice' && 
                                    <span style={{color:"#0046FA"}}>Correct answer: {q.correctanswer}</span>
                                    }
                                </div>
                                            </>
                                        )
                                    })
                                }
                                {
                                    q.answers && q.answers.length == 0 && <div className="col-md-12"><span style={{color:"#DA3849"}}>not answered</span></div>
                                    
                                }
                                {
                                    q.answers && q.answers.length == 0 && q.answertype=='single choice' && 
                                    <div className="col-md-12"><span style={{color:"#0046FA"}}>Correct answer: {q.correctanswer}</span></div>
                                }
                            </div>
                            </>)
    })
}
                                </>
                    }
                    left={{ action: () => { this.setState({ modalCompetitionShow: false, questions:[], questionscount:0,selectedRedemption:{} }) }, text: ts2('Cancel / Return',this.props.userLogin.language), variant: 'warning' }}
                    onHide={() => { this.setState({ modalCompetitionShow: false, questions:[], questionscount:0,selectedRedemption:{} }) }}
                />
                <MessageBox
                    show={this.state.modalCreateAlertShow}
                    title=''
                    body={<div>
                        <center>
                        <img src="/dist/img/alerthead.png" style={{width:"50px", margin:"auto"}}/>
                        <p>Describe the reason for this alert</p>
                    </center>
                        <div className="form-group" style={{marginTop:"20px"}}>
                    <textarea className="form-control" id="alertDescription" name="alertDescription" placeholder='type reason here' rows="3"></textarea>
                    <span id="textareavalidation" className="text-danger"></span>
                  </div>
                <center>
                    <Button variant="success" style={{width:"150px"}} onClick={async()=>{
                        var description = document.getElementById("alertDescription").value;
                        if(description=='')
                        {
                            document.getElementById("textareavalidation").innerText = "Please insert reason first";
                        }
                        else{
                            await G.getData("customeralerts/insert",[["customerid",this.state.editProfile],["creationuserid", this.props.userLogin.id],["description", description]]);
                            var customercollections = await G.getData("customers/collected",[['profileId',this.state.editProfile]])
        customercollections = await customercollections.data
        this.setState({ customercollections:customercollections })
        this.setState({ modalCreateAlertShow: false })
        var logtxt = this.props.userLogin.username + " has added a note ("+description+") for the customer " + this.state.fields["firstname"] + " " + this.state.fields["lastname"] + "(" + this.state.fields["email"] + ")";
                    await G.SaveUserLog(logtxt, this.props.userLogin.id);
                        }
                    }}>Save</Button><br/><br/>
                    <Button variant="warning" style={{width:"150px"}} onClick={()=>{this.setState({ modalCreateAlertShow: false })}}>Close</Button>
                </center>  </div>
                }
                    
                    onHide={() => { this.setState({ modalCreateAlertShow: false }) }}
                /> 
                <MessageBox
                    show={this.state.modalAlertDetailsShow}
                    title=""
                    body={this.state.selectedRedemption &&
                        <center>
                        <img src="/dist/img/alerthead.png" style={{width:"50px", margin:"auto"}}/>
                        <h4>This customer is flagged with an alert</h4><br/>
                        <p>Flagged by user: <b>{this.state.selectedRedemption.fullName}</b></p><br/>
                        <p>Date of flagging <b>{td(dateFormat(this.state.selectedRedemption.datein,"dddd, mmm dd, yyyy hh:MM tt"), this.props.userLogin.language)}</b></p><br/><br/>
                        <p>Reason: <i>"{this.state.selectedRedemption.description}"</i></p><br/><br/>
                        <Button variant="warning" style={{width:"150px"}} onClick={()=>{this.setState({ modalAlertDetailsShow: false })}}>Close</Button>
                    </center>
                    }
                    
                    onHide={() => { this.setState({ modalAlertDetailsShow: false }) }}
                />
                <MessageBox
                    show={this.state.modalGiftDetailsShow}
                    title={this.state.modalSubject?this.state.modalSubject:""}
                    body={
                        <div>
                        <h4 style={{textAlign:"left"}}>Gift info</h4>
                        <ul style={{marginTop:"30px"}}><li>Title: <b>{this.state.selectedRedemption.name}</b></li>
                        <li>Category: <b>{ this.state.giftCategory.map(el=>el+" | ")}</b></li>                        
                        <li>Description: <b>{this.state.selectedRedemption.description}</b></li>
                                
                                {this.state.selectedRedemption.startdate && <li>Start date: <b>{td(dateFormat(this.state.selectedRedemption.startdate,"mmm dd, yyyy"), this.props.userLogin.language)}</b></li>}
                                {this.state.selectedRedemption.enddate && <li>End date: <b>{td(dateFormat(this.state.selectedRedemption.enddate,"mmm dd, yyyy"), this.props.userLogin.language)}</b></li>}
                                <li>Status: <Badge variant={this.state.selectedRedemption.enabled==1?'primary':'danger'} style={{backgroundColor:this.state.selectedRedemption.enabled==1?'#3FCAE0':'lightgray'}} className=' remarks'>
{this.state.selectedRedemption.enabled==1?'Active':'Disabled'}
</Badge></li>
<li>Number of required tokens: <b>{this.state.selectedRedemption.tokensvalue?this.state.selectedRedemption.tokensvalue:ts2("No limit", this.props.userLogin.language)}</b></li>
<li>Usage limit: <b>{this.state.selectedRedemption.limitpergift?this.state.selectedRedemption.limitpergift:ts2("No limit", this.props.userLogin.language)}</b></li>
<li>Remaining usage: <b>{this.state.selectedRedemption.limitpergift?(this.state.selectedRedemption.limitpergift - this.state.giftRedeems.length>=0?this.state.selectedRedemption.limitpergift - this.state.giftRedeems.length:0):ts2("No limit", this.props.userLogin.language)}</b></li>
<li>Number of customers who redeemed: <b>{this.state.giftRedeems.length}</b></li>
                                </ul>
                                
                                <Button variant="success" style={{marginTop:"50px", width:"150px"}} onClick={()=>{this.redeemGift()}}>{ts("Redeem Gift", this.props.userLogin.language)}</Button>
                                <Button variant="warning" style={{marginTop:"50px", width:"150px", marginLeft:"10px"}} onClick={()=>{this.setState({ modalGiftDetailsShow: false })}}>{ts("Cancel", this.props.userLogin.language)}</Button>
                                </div>
                    }
                    onHide={() => { this.setState({ modalGiftDetailsShow: false }) }}
                />
                <MessageBox
                    show={this.state.modalPromoDetailsShow}
                    title={this.state.modalSubject?this.state.modalSubject:""}
                    body={
                        <div>
                        <h4 style={{textAlign:"left"}}>Promo info</h4>
                        <ul style={{marginTop:"30px"}}><li>Title: <b>{this.state.selectedRedemption.name}</b></li>
                        <li>Category: <b>{ this.state.promoCategory.map(el=>el+" | ")}</b></li>                        
                        <li>Description: <b>{this.state.selectedRedemption.description}</b></li>
                                
                                <li>Start date: <b>{td(dateFormat(this.state.selectedRedemption.startdate,"mmm dd, yyyy"), this.props.userLogin.language)}</b></li>
                                <li>End date: <b>{td(dateFormat(this.state.selectedRedemption.enddate,"hh:mm tt"), this.props.userLogin.language)}</b></li>
                                <li>Status: <Badge variant={this.state.selectedRedemption.enabled==1?'primary':'danger'} style={{backgroundColor:this.state.selectedRedemption.enabled==1?'#3FCAE0':'lightgray'}} className=' remarks'>
{this.state.selectedRedemption.enabled==1?'Active':'Disabled'}
</Badge></li>
<li>Number of gained {this.state.selectedRedemption.redemptiontype == "Token"?"tokens":"points"}: <b>{this.state.selectedRedemption.value?this.state.selectedRedemption.value:ts2("No limit", this.props.userLogin.language)}</b></li>
<li>Usage limit: <b>{this.state.selectedRedemption.customersallowedlimit?this.state.selectedRedemption.customersallowedlimit:ts2("No limit", this.props.userLogin.language)}</b></li>
<li>Remaining usage: <b>{this.state.selectedRedemption.customersallowedlimit?(this.state.selectedRedemption.customersallowedlimit - this.state.promoRedeems.length>=0?this.state.selectedRedemption.customersallowedlimit - this.state.promoRedeems.length:0):ts2("No limit", this.props.userLogin.language)}</b></li>
<li>Number of customers who redeemed: <b>{this.state.promoRedeems.length}</b></li>
                                </ul>
                                
                                <Button variant="success" style={{marginTop:"50px", width:"auto"}} onClick={()=>{this.redeemPromo()}}>{ts("Redeem Promo code", this.props.userLogin.language)}</Button>
                                <Button variant="warning" style={{marginTop:"50px", width:"150px", marginLeft:"10px"}} onClick={()=>{this.setState({ modalPromoDetailsShow: false })}}>{ts("Cancel", this.props.userLogin.language)}</Button>
                                </div>
                    }
                    onHide={() => { this.setState({ modalPromoDetailsShow: false }) }}
                />
                <MessageBox
                    show={this.state.modalGiftShow}
                    title={this.state.modalSubject}
                    body={this.state.selectedRedemption &&
                        <ul><li>Title: <b>{this.state.selectedRedemption.name}</b></li>                        
                        <li>Description: <b>{this.state.selectedRedemption.description}</b></li>
                                <li>Category: <b>{ this.state.selectedRedemption.categories && this.state.selectedRedemption.categories.map(el=>el.name+" | ")}</b></li>
                                <li>Redemption date: <b>{td(dateFormat(this.state.selectedRedemption.datein,"mmm dd, yyyy"), this.props.userLogin.language)}</b></li>
                                <li>Redemption time: <b>{td(dateFormat(this.state.selectedRedemption.datein,"hh:mm tt"), this.props.userLogin.language)}</b></li>
                                <li>Customer name: <b>{this.state.fields["firstname"]} {this.state.fields["lastname"]}</b></li>
<li>Customer email: <b>{this.state.fields["email"]}</b></li>
<li>Paid tokens: {this.state.selectedRedemption.tokensvalue ? <b>{this.state.selectedRedemption.tokensvalue}</b>:<b>0</b>}</li>
                                </ul>
                    }
                    
                    right={{ action: () => {this.setState({ modalGiftShow: false }) }, text: ts('Close',this.props.userLogin.language), variant: 'warning' }}
                    onHide={() => { this.setState({ modalGiftShow: false }) }}
                />
                <MessageBox
                    show={this.state.modalPromocodeShow}
                    title={this.state.modalSubject}
                    body={this.state.selectedRedemption &&
                        <ul><li>Title: <b>{this.state.selectedRedemption.name}</b></li>                        
                        <li>Description: <b>{this.state.selectedRedemption.description}</b></li>
                                <li>Category: <b>{ this.state.selectedRedemption.categories && this.state.selectedRedemption.categories.map(el=>el.name+" | ")}</b></li>
                                <li>Usage date: <b>{td(dateFormat(this.state.selectedRedemption.datein,"mmm dd, yyyy"), this.props.userLogin.language)}</b></li>
                                <li>Usage time: <b>{td(dateFormat(this.state.selectedRedemption.datein,"hh:MM tt"), this.props.userLogin.language)}</b></li>
                                <li>Customer name: <b>{this.state.fields["firstname"]} {this.state.fields["lastname"]}</b></li>
<li>Customer email: <b>{this.state.fields["email"]}</b></li>
                                </ul>
                    }
                    
                    right={{ action: () => {this.setState({ modalPromocodeShow: false }) }, text: ts('Close',this.props.userLogin.language), variant: 'warning' }}
                    onHide={() => { this.setState({ modalPromocodeShow: false }) }}
                />
                <MessageBox
                    show={this.state.successmodalShow}
                    title={ts("Changes are saved",this.props.userLogin.language)}
                    body={<div><center><Button variant="success" onClick={()=>{this.setState({ successmodalShow: false });}}>OK</Button></center></div>}
                    onHide={() => { this.setState({ modalShow: false }) }}

                />
            </Container>
        )
    }
}

//#region Redux & Export
const mapStateToProps = state => {

    return {
        userLogin: state.SaveLogin.userData
    };
};
const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma("#643FB2");
      const disabledcolor = chroma("gray")
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#643FB2"
          : isFocused
          ? disabledcolor
          : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : "#643FB2",
        cursor: isDisabled ? 'not-allowed' : 'default',
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#643FB2"
              : "#643FB230"
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma("#643FB2");
      return {
        ...styles,
        backgroundColor: "#643FB2",
        color:"#ffffff"
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#ffffff",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "#ffffff",
      ':hover': {
        backgroundColor: "#643FB2",
        color: 'white',
      },
    }),
  };
export default connect(
    mapStateToProps
)(profileManagement);
//#endregion