import React, { useEffect, useState } from 'react';


export const Flash = (props) => {
    let [visibility, setVisibility] = useState(false);
    let [message, setMessage] = useState('');
    let [type, setType] = useState('');

    useEffect(() => {
        // Update the document title using the browser API
        setVisibility(props.visibility)
        setMessage(props.message)
    });


    return (
        visibility &&
            <div className='fullWidth flexrow center toastBackground'>
                <div className={''} className='toastMessage'>
                    {message}
                </div>
            </div> 

           

    )
}