import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Badge, Button, Container, Form, Col, Spinner } from 'react-bootstrap';
import * as G from '../components/global'
import '../styles/glob.css';
import history from '../components/history'
import { translateScreen as ts, translateScreen2 as ts2, translateDate as td } from '../components/dictionary'
import dateFormat from 'dateformat';
class UserDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userdetails:null,
            editUser: -1,
            loadCompany: true,
            loadUsers: true,
            loadSave: false,
            activeScreen:0,
            fields:{},
            errors:{},
            changeUserPassword:false

        }
        this.handleChange = this.handleChange.bind(this);
    }
    //#region Didmount & Get Data & Filter
    componentDidMount() {
        this.refreshData()
        
        

    }
    getSnapshotBeforeUpdate(prevProps) {
        
        return { notifyRequired: prevProps.userLogin !== this.props.userLogin };
      }
      
      async componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot.notifyRequired) {
            await this.refreshData();
        }
        
      }
    
    getCompanies = (records) => {
        let companies = [];
        records.map(element => {
            let { clientID, companyName } = element;
            if (companies.filter(values => values.clientID === element.clientID).length === 0) companies.push({ clientID, companyName })
        })
        return companies
    }
    getCompanyUsers = (company) => {
        this.setState({ activeCompany: company })
        return (this.state.allList.filter(record => record.clientID === company.id))
    }
    getUserType = () => {
        let result = []

        if (this.props.userLogin != null) { if (this.props.userLogin.accountType === 0) { result = G.userType.filter(value=>value!==G.userType[0]) } else { result = G.userType.filter(value => value !== G.userType[0]) } }
        return result
    }
    refreshData = async () => {
        this.setState({ loadUsers: true })
        var result = await G.getData('getuserdetails', [['userid', this.props.userLogin.id]]);
        result = await result.data;
        this.editUser(result[0]);
        this.setState({ userdetails: result[0] })
        
        //this.setState({ companyList: this.getCompanies(this.state.allList) })
        //if (this.state.companyList) { if (this.state.companyList.length > 0) { this.setState({ companyUsersList: this.getCompanyUsers(this.state.companyList[0]) }) } }
        //this.setState({ companyUsersList: this.getCompanyUsers(this.props.userLogin.site) })
        //this.setState({ loadUsers: false })
        //this.setState({ loadCompany: false })
    }
    editUser = (user) => {

        this.setState(
            {
                editUser: user.id,
                fields: {
                    usertype: G.getUserType(user.accountType),
                    email: user.username,
                    fullname: user.fullName
                },
                activeScreen: 1
            }
        )

    }

    //#endregion

    //#region Form Validation
    handleChange(e) {
        let { fields, errors } = this.state;
        fields[e.target.name] = e.target.value;
        delete errors[e.target.name];

        this.setState({ fields });
    }
    handleValidation = async () => {

        const { usertype, password, confirmpassword, email, fullname, changepassword } = this.state.fields;
        const { editUser } = this.state;
        const confirmchangepassword = this.state.fields["confirmchange password"]

        let errors = {};
        let formIsValid = true;

        if (!email) { formIsValid = false; errors["email"] = ts2("Username cannot be empty", this.props.userLogin.language); } else {
            if (!G.isValideEmail(email)) { formIsValid = false; errors["email"] = 'This is not a valid Email'; }
        }
        //if (!email) { formIsValid = false; errors["email"] = ts2("Username cannot be empty", this.props.userLogin.language); }
        if (!fullname) { formIsValid = false; errors["fullname"] = ts2("Full name cannot be empty", this.props.userLogin.language); }
        if (!usertype) { formIsValid = false; errors["usertype"] = ts2("User Type must be selected", this.props.userLogin.language); } else {
            if (G.getUserTypeID(usertype) < 0) {
                formIsValid = false;
                errors['usertype'] = ts2("User Type must be selected", this.props.userLogin.language)
            }
        }
        if (editUser < 0) {
            if (!password) { formIsValid = false; errors["password"] = ts2("Password must be set", this.props.userLogin.language); } else {
                if (password.length < 8) { formIsValid = false; errors["password"] = ts2("Password is too short", this.props.userLogin.language); }
                else {
                    if (!confirmpassword) { formIsValid = false; errors["confirmpassword"] = ts2("Passwords does not match", this.props.userLogin.language); } else {
                        if (confirmpassword !== password) { formIsValid = false; errors["confirmpassword"] = ts2("Passwords does not match", this.props.userLogin.language); };

                    }
                }
            }
        }
        if (editUser >= 0 && this.state.changeUserPassword) {
            
            if (!changepassword) {
                formIsValid = false; errors["changepassword"] = ts2("New Password must be set", this.props.userLogin.language);
                console.log(ts2("New Password must be set", this.props.userLogin.language))
            } else {
                if (changepassword.length < 8) { formIsValid = false; errors["changepassword"] = ts2("New Password is too short", this.props.userLogin.language); }
                else {
                    if (!confirmchangepassword) { formIsValid = false; errors["confirmchange password"] = ts2("New Passwords does not match", this.props.userLogin.language); } else {
                        if (confirmchangepassword !== changepassword) {console.log(ts2("New Password must be set", this.props.userLogin.language)); formIsValid = false; errors["confirmchange password"] = ts2("New Passwords does not match", this.props.userLogin.language); };

                    }
                }
            }
        }
        console.log(this.state.fields)
        this.setState({ errors: errors });
        //const { registrationRequestID, companyName, contactPerson, contactPersonNo, email, addDate } = req.body
        //const { username, password, fullName, accountType, creationUser } = req.body

        let parameters = []

        if (editUser < 0) {
            parameters = [
                ['clientID', this.props.userLogin.site.id],
                ['username', email],
                ['password', password],
                ['fullName', fullname],
                ['accountType', G.getUserTypeID(usertype)],
                ['creationUser', this.props.userLogin.id],
                ['groupid',this.props.userLogin.site.groupid]
            ]
        } else {
            parameters = [
                ['id', editUser],
                ['username', email],
                ['fullName', fullname],
                ['accountType', G.getUserTypeID(usertype)],
                ['lastEditUser', this.props.userLogin.id],
                ['clientID', this.props.userLogin.site.id],
                ['groupid',this.props.userLogin.site.groupid]
            ]
            if (changepassword != null) {
                parameters.push(['changepassword', changepassword])
            }
        }
        console.log('isbusy', formIsValid);
        console.log("errors",this.state.errors);
        if (formIsValid) {

            G.log('Form is valid. pushing now values...')
            var result = await G.getData('registerUser', parameters)
            result = await result.status;
            console.log('result', result)
            if (result === 601) {

                errors['email'] = "Username already available!"
                this.setState({ errors: errors });
            }
            if(result ===200)
            {
                if(editUser<0)
                {
                    var logtxt = this.props.userLogin.username + " has created a new "+G.getUserType(usertype)+" (" + email + ")";
                    await G.SaveUserLog(logtxt, this.props.userLogin.id)
                }
            }
            return (result === 200)
        }
        else {
            return formIsValid;
        }
    }
    submitForm = async (e) => {
        e.preventDefault();
        this.setState({ loadingSave: true })
        G.log('Try to submit form')
        var a = await this.handleValidation();

        G.log('submit form is', a)
        if (a === true) {
            this.refreshData()
            this.setState({ activeScreen: 1, changeUserPassword: false })
            //history.push("/userManagement")
        } else { G.log('Form Is Not Valid') }
        this.setState({ loadingSave: false });
        
    }

    //#endregion


    render() {
        const { errors, fields } = this.state;
        return (

            <Container fluid  >

                <div>
                    <div className='flexrow start mt-5'>
                        
    
                        {!this.state.userdetails &&
                            <div className='flexrow center verticalcenter p-5 fullWidth hint '>
                                <Spinner animation="grow" className='m-3' variant="warning" />
                                <Spinner animation="grow" className='m-3' variant="warning" />
                                <Spinner animation="grow" className='m-3' variant="warning" />
                            </div>
                        }
                        
                        {this.state.activeScreen == 1 && <div className='fullWidth p-3'>

<Form name="form" onSubmit={this.submitForm.bind(this)} >

    <h5 className='mb-5 '>{ts2(this.state.editUser < 0 ? 'Create New User' : 'Change User Details', this.props.userLogin.language)}  {this.props.userLogin.accountType === 0 && this.state.companyUsersList ? (this.state.companyUsersList.length > 0 ? ts(' in ', this.props.userLogin.language) + this.state.companyUsersList[0].companyName : '') : ''}</h5>
    <Form.Group>
        <Col>
            {G.renderTextField("Email", 'email', ts2("Enter user email", this.props.userLogin.language), this.handleChange, fields, errors, '', '', false, this.props.userLogin.language)}
        </Col>

    </Form.Group>
    <Form.Group>
        <Col>
            {G.renderTextField("Full Name", 'text', ts2("Enter user full name", this.props.userLogin.language), this.handleChange, fields, errors, '', '', false, this.props.userLogin.language)}
        </Col>

    </Form.Group>


    {/*{G.renderDropDown('User Type', this.getUserType(), this.handleChange, fields, errors, ts2('Select from list', this.props.userLogin.language), false, this.props.userLogin.language)}*/}


    {this.state.editUser >= 0 && <Button className={'mt-2 mb-3'} onClick={() => { this.setState({ changeUserPassword: !this.state.changeUserPassword }); }} variant={'dark'} >{this.state.changeUserPassword ? ts2('Cancel Change User Password', this.props.userLogin.language) : ts2('Change user password', this.props.userLogin.language)}</Button>}

    {

        (this.state.editUser < 0 || (this.state.changeUserPassword && this.state.editUser >= 0)) &&
        <Form.Group className={this.state.changeUserPassword && this.state.editUser >= 0 ? 'cunlayer pt-5 pb-5' : ''}>
            <Col>
                {G.renderTextField(this.state.editUser < 0 ? "Password" : "Change Password", 'password', ts2("Enter default username Password", this.props.userLogin.language), this.handleChange, fields, errors, ts2('(Minimum 8 characters)', this.props.userLogin.language), '', false, this.props.userLogin.language)}
            </Col>
            <Col>
                {G.renderTextField(this.state.editUser < 0 ? "Confirm Password" : 'Confirm Change Password', 'password', ts2("Confirm default Password", this.props.userLogin.language), this.handleChange, fields, errors, '', '', false, this.props.userLogin.language)}
            </Col>
        </Form.Group>
    }



    <div className='flexrow pt-5 '>
        <Button varant='success' type="submit" className='mr-3'>
            {this.state.loadSave && <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
            />}
            {ts2(this.state.editUser < 0 ? 'Register User' : 'Save Changes', this.props.userLogin.language)}</Button>
        <Button variant='warning' onClick={() => { this.setState({ activeScreen: 0 });history.go(-1) }}>{ts('Cancel', this.props.userLogin.language)}</Button>
    </div>

</Form>
</div>}
                            
                    </div>

                </div>


            </Container>
        )
    }
}

//#region Redux & Export
const mapStateToProps = state => {
    return {
        userLogin: state.SaveLogin.userData
    };
};
export default connect(
    mapStateToProps
)(UserDetails);
//#endregion