import React, { Component } from 'react'
//import logo from '../assets/logo.png'
import { connect } from 'react-redux'
import { translateScreen as ts, generateFrenchLanguage as gn } from '../dictionary'
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import * as G from '../global';
import $ from "jquery"
import history from '../history';
class Header extends Component {
  
constructor(props) {
  super(props);
  this.state = {
    groups:[],
    sites:[],
    siteselected:[],
    selectedgroup:[]
}
}
    logOut = () => { 
      if(G.checkifCreate(history,this.props.language)){
      this.props.logOut();
      }
    }
    getSite = ()=>{
      
    }
    async componentDidMount(){
        //added by youssef to get groups and sites//
        // if user is a super admin fetch groups and sites// 
        console.log("account type is ",this.props.accountType);
      if(this.props.accountType===0){
        console.log("user is a super admin");
        let groups= await G.getData('sitegroup/query',[]);
        groups=await groups.data;
        let sites =await G.getData('client/query',[]);
        sites=await sites.data;
        this.setState({groups:groups});
        this.setState({sites:sites});
        var sitestring = localStorage.getItem("site");  
        if(sitestring)
        {
          
          var site = JSON.parse(sitestring);
          if(sites.find(x=>x.id == site.id))
          {
            let a = { ...this.props.userLogin };a.site = site;this.props.saveUserData(a);
            this.setState({siteselected:site, selectedgroup:groups.find(x=>x.id == site.groupid)})
          }
          else{
            this.setState({siteselected:sites.find((site)=>site.groupid==groups[0].id),selectedgroup:groups[0]})  
          }
        }
        else{     
            
          this.setState({siteselected:sites.find((site)=>site.groupid==groups[0].id),selectedgroup:groups[0]})
        }
        
    }
    else if(this.props.accountType===1){
      console.log("user is a group admin");
      let groups= await G.getData('sitegroup/query',[["filter",'id in (select groupid from client where id='+this.props.userLogin.clientID+')']]);
      groups=await groups.data;
      let sites =await G.getData('client/query',[["filter",'groupid in (select groupid from client where id='+this.props.userLogin.clientID+')']]);
      sites=await sites.data;
      this.setState({groups:groups});
      this.setState({sites:sites});
      var sitestring = localStorage.getItem("site");  
      if(sitestring)
      {
        var site = JSON.parse(sitestring);
        if(sites.find(x=>x.id == site.id))
        {
          let a = { ...this.props.userLogin };a.site = site;this.props.saveUserData(a);
          this.setState({siteselected:site, selectedgroup:groups.find(x=>x.id == site.groupid)})
        }
        else{
          this.setState({siteselected:sites.find((site)=>site.groupid==groups[0].id),selectedgroup:groups[0]})  
        }
      }
      else{        
        this.setState({siteselected:sites.find((site)=>site.groupid==groups[0].id),selectedgroup:groups[0]})
      } 
  }
    else{
      
    //fecth only the client/site of the user//
    let userid=this.props.userLogin.id;
    console.log("here it is the non admin user id"+userid);
    let sid=this.props.userLogin.clientID;
    let groups= await G.getData('sitegroup/query',['filter','id in (select groupid from client where id='+this.props.userLogin.clientID+')']);
        groups=await groups.data;
    let usersite= await G.getData('client/query',[['filter', 'id = ' + sid]]);
    usersite=await usersite.data;
    console.log(usersite);
    console.log(groups);
    this.setState({siteselected:usersite[0], selectedgroup:groups.find(x=>x.id == usersite[0].groupid)});  
    }
    let a = { ...this.props.userLogin };a.site = this.state.siteselected;this.props.saveUserData(a);
    //this.props.saveSite(this.state.siteselected.id)
    }
    render() {
        const type = this.props.accountType;
        const name = this.props.userLogin.fullName;
        const groups=this.state.groups;
        const sites=this.state.sites;
        const siteselected=this.state.siteselected;

        //console.log("in header ");
        //console.log(groups);
        return (
            <nav className="main-header navbar navbar-expand navbar-white navbar-light" id="mainheader" style={{marginLeft:"0px !important"}}>
                {/* Left navbar links */}
                <ul className="navbar-nav">
                    <div className='flexrow '>
                       
                        <div className='flexrow   verticalcenter  '>{<img src="/dist/img/logo.png" alt="Givenmine" className="   ml-2 responsiveheader" width={130} />}</div>
                        <li className="nav-item navpushmenu">
                        <a className="nav-link" id="pushmenubtn" data-widget="pushmenu"  role="button"><i className="fas fa-bars" ></i></a>
                        </li>
                         {/*added by youssef for site selection if super admin */}
                         
                         {type>1 &&<li className="nav-item SiteSelector"><a className='nav-link siteselectora' style={{width:300}}><b style={{color:'black'}}>{ this.state.selectedgroup.name}</b>{" >> " + this.state.siteselected.companyName}</a></li>}
                         {type==1 &&<li className="nav-item dropdown SiteSelector" >
            <a data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle siteselectora"><b style={{color:'black'}}>{ this.state.selectedgroup.name}</b>{ " >> " + this.state.siteselected.companyName}</a>
            <ul className="dropdown-menu border-0 shadow custom-dropdown">
              {groups.map((group)=>(
                <>
              <li className="dropdown-submenu ">
                <a id="dropdownSubMenu2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="dropdown-item dropdown-toggle">{group.name}</a>
                <ul aria-labelledby="dropdownSubMenu2" className="dropdown-menu border-0 shadow">
                
                  {sites.filter((site)=>site.groupid==group.id).map((site)=>(
                  <li>
                    <a className="dropdown-item" onClick={()=>{let a = { ...this.props.userLogin };a.site = site;this.props.saveUserData(a); this.setState({siteselected:site, selectedgroup:group});localStorage.setItem("site",JSON.stringify(site));  }}>{site.companyName}</a>
                  </li>
                  ))}
                </ul>
              </li></>))}
  
            </ul>
          </li>}
                         {type==0 &&<li className="nav-item dropdown SiteSelector">
            <a  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle siteselectora"><b style={{color:'black'}}>{ this.state.selectedgroup.name}</b>{ " >> " + this.state.siteselected.companyName}</a>
            <div style={{position:"absolute", width:"85vw", maxHeight:"95vh", overflowY:"auto", paddingBottom:"10px"}}>
            <ul className="dropdown-menu border-0 shadow custom-dropdown scrollable-menu " style={{position:"relative", width:"500px", maxWidth:(window.innerWidth - 100)}}>
              {groups.map((group)=>(
                <>
              <li className="dropdown-submenu dropdown-hover">
                <a id="dropdownSubMenu2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{overflow:"hidden", textOverflow:"ellipsis"}} className="dropdown-item dropdown-toggle">{group.name}</a>
                <ul aria-labelledby="dropdownSubMenu2" className="dropdown-menu border-0 shadow">
                
                  {sites.filter((site)=>site.groupid==group.id).map((site)=>(
                  <li>
                    <a className="dropdown-item" onClick={()=>{let a = { ...this.props.userLogin };a.site = site;this.props.saveUserData(a); this.setState({siteselected:site, selectedgroup:group});localStorage.setItem("site",JSON.stringify(site));}}>{site.companyName}</a>
                  </li>
                  ))}
                </ul>
              </li>
              
              </>))}
  
            </ul>
            </div>
          </li>}
                    </div>
                </ul>
                {/* <Button onClick={()=>{gn()}}>generate dictionary</Button> */}
                {/* Right navbar links */}
                <ul className="navbar-nav ml-auto">
                    <div className='flexrow flexrowheader verticalcenter pr-2'><img width={16} height={16} src={this.props.userLogin.language === 0 ? "/dist/img/english.png" : "/dist/img/france.png"} /></div>
                    <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip id="button-tooltip" > {this.props.userLogin.language === 0 ? 'Cliquez pour passer en français' : 'Click to switch to English'}</Tooltip>}
                    >
                        <div className='flexrow flexrowheader verticalcenter hint handCursor bold langselector' onClick={() => {
                            let a = { ...this.props.userLogin }
                            if (a.language === 0) { a.language = 1 } else { a.language = 0 };
                            var result = G.getData('switchLanguage', [['username', this.props.userLogin.username], ['language', a.language]])
                            a.siteid = a.siteid!=null && a.siteid!=undefined?a.siteid:1
                            this.props.saveUserData(a)
                        }}> {this.props.userLogin.language !== 0 ? 'Français' : 'English'} </div>
                    </OverlayTrigger>
                    {/*<li className="nav-item responsiveheader">
                        <a className="nav-link" data-widget="fullscreen" role="button">
                            <i className="fas fa-expand-arrows-alt" /> <span className=' bold showRightHeaderText'> {ts('Full Screen', this.props.userLogin.language)} </span>
                        </a>
                      </li>*/}

                    {/*<div onClick={this.logOut} className="nav-link handCursor whiteColor ml-3">
                        <i className="nav-icon fas fa-sign-out-alt " /> <span className='bold showRightHeaderText'>{ts('Log Out', this.props.userLogin.language)}</span>
                    </div>*/}
                    <li className="nav-item dropdown">
        <a className="nav-link" data-toggle="dropdown" aria-expanded="false">
          <div id="profileImage">{name[0]}</div>
        </a>
        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <a href="#" onClick={()=>{
            if(G.checkifCreate(history,this.props.language)){
              window.location.href = "/UserDetails";
            }
          }} className="dropdown-item handCursor">
            {/*<i className="fas fa-envelope mr-2"></i>*/} View Details
          </a>
          <div className="dropdown-divider"></div>
          <a href="#" onClick={()=>{
            if(G.checkifCreate(history,this.props.language)){
              window.location.href = "/ResetPassword";
            }
          }} className="dropdown-item">
             Change Password
          </a>
          <div className="dropdown-divider"></div>
          <a onClick={this.logOut} className="dropdown-item handCursor">
          {ts('Log Out', this.props.userLogin.language)}
          </a>
          
        </div>
      </li>
                    <li className="nav-item">
                    {type==0 &&<a className="nav-link" data-widget="control-sidebar" data-slide="true" role="button">
          <i className="fas fa-th-large"></i>
        </a>}
      </li>
                </ul>
            </nav>

        )
    }
}

const mapDispatchToProps = dispatch => {
    return {

        saveUserData: (data) => dispatch({ type: '', payLoad: data }),
        saveSite: (sid) => dispatch({type:'', siteid:sid}),
    };
};
const mapStateToProps = state => {

    return {
        userLogin: state.SaveLogin.userData
    };
};
export default connect(
    mapStateToProps, mapDispatchToProps
)(Header)