
import React, { Component } from 'react'
import { Button, Modal, Form, Col, Spinner, OverlayTrigger, Tooltip, Alert } from 'react-bootstrap';
import EmailEditor from 'react-email-editor';
import MessageBox from '../MessageBox';
import * as G from '../global';
import SaveImage from '../assets/save.png'
import LoadImage from '../assets/load.png'
import HtmlImage from '../assets/html.png'
import dropDown from '../assets/download.png'
import upload from '../assets/upload.png'
import hotspotImage from '../assets/research.png'
import copy16 from '../assets/copy16.png'
import edit16 from '../assets/edit16.png'
import { Flash } from '../flash';
import history from '../history';
import Select from 'react-select'
import { translateScreen as ts, translateScreen2 as ts2, translateDate as td } from '../dictionary'
export default class editor extends Component {
  constructor(props) {
    super(props);
    this.editorRef = React.createRef();
    this.state = {
      data: {},
      showHotspots: true,
      showHotspotList: true,
      showParamsList: false,
      showPreferences: true,
      showSave: true,
      modalShow: false,
      hotspots: [],
      copiedLink: '',
      showToast: false,
      fields: { 'templatelanguage': G.getLanguageList()[1] },
      errors: {},
      result: [],
      hotspotID: -1,
      hotspotFields: {},
      loadSave: false,
      languageload:false,
      modalShow1: false,
      modalBody: '',
      modalSubject: '',
      modalShow2: false,
      hotspotname:'',
      hotspotlink:'',
      modalShow3:false,
      hotspotCategory: [],
      hotspotCategoryNames: [],
      savedhotspotCategories:[]
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeWLanguage = this.handleChangeWLanguage.bind(this);
  }
  getcategories = async () => {
    let array = []
    array = await G.getData("hotspotCategory/query", [['filter','(hide is null or hide=0) and clientID=' + this.props.data.userLogin.site.id]])
    array = await array.data
    this.setState({ hotspotCategory: array })
    let arrayNames = [];
    array.map(element => {
      arrayNames.push(element.name)
    })
    this.setState({ hotspotCategoryNames: arrayNames })
    console.log(this.state.hotspotCategory)
    const categoryOptions = [];
    array.map(element => {
      categoryOptions.push({value:element.id, label:element.name});
    })
    this.setState({ hotspotCategoryOptions: categoryOptions })
  }
  async componentDidMount() {
    
  }
  //#region Form Validation
  handleChange(e) {
    
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.value;
    delete errors[e.target.name];

    this.setState({ fields });
  }
  handleChangeWLanguage = async (e)=> {
    /**/
    
    let { fields, errors } = this.state;
    this.setState({languageload:true})
    //e.target.value = e.target.getAttribute('data-value');
    
    this.saveForm(e);
    fields[e.target.name] = e.target.value;
    delete errors[e.target.name];
    
    /**/
    

    //this.setState({ fields });
    var langfields = "fields" + e.target.value; 
    try{
    let langData = await G.getData('getTemplateLanguage',[['templateId',this.props.data.element.id],['language',e.target.value]]);
    var data = await langData.data;
    if(data.length>0)
    {
    fields['templatename'] = data[0].name;
    fields['templatesubject'] = data[0].subject;
    }
  }
  catch(e){}
    this.setState({ fields });
    try{
    let designFile = await G.getData('getTemplateFile', [['fileName', 'Template_' + this.props.data.element.id +"_"+ e.target.value + '.txt']])
      designFile = await designFile.data
      designFile = JSON.parse(designFile.text)
      if (designFile)
        this.editorRef.current.editor.loadDesign(designFile);

    }
    catch(e){}
        this.setState({languageload:false}) 
  }
  saveForm = async (e) => {
    let { fields } = this.state;
    this.setState({ loadSave: true })
    const { templatesubject, templatelanguage, templatename } = this.state.fields;
    const clientID = this.props.data.userLogin.site.id;
    const creationUser = this.props.data.userLogin.id;
    let errors = {};
    let formIsValid = true;
    
    if (!templatesubject) { formIsValid = false; errors["templatesubject"] = ts2("Template subject cannot be empty", this.props.data.userLogin.language); }
    if (!templatename) { formIsValid = false; errors["templatename"] = ts2("Template Name cannot be empty", this.props.data.userLogin.language); }
    if (G.getLanguageList().filter(element => element === templatelanguage).length === 0) {
      formIsValid = false;
      errors['templatelanguage'] = ts2("Template Language must be selected", this.props.data.userLogin.language)
    }

    this.setState({ errors: errors });
    if (!formIsValid) {
      this.setState({ loadSave: false })
      this.setState({languageload:false})
      alert(ts('Please provide template name & template subject',this.props.data.userLogin.language));
      if(e)
      {
        e.target.value = e.target.getAttribute('data-value');
        
        fields[e.target.name] = e.target.value;
        this.setState({ fields });
       }

      return formIsValid;
    }
    else{
      if(e)
      {
        e.target.setAttribute('data-value', e.target.value);
        
      }
    }
    this.editorRef.current.editor.saveDesign(async (design) => {

      let fileContent = JSON.stringify(design)
      let updatedLink = this.props.data.templateID!==-1 ? this.props.data.element.id:undefined;
      this.editorRef.current.editor.exportHtml(async (data) => {
        const { design, html } = data;
        let parameters = []
        if (updatedLink) {
          parameters = [
            ['id', updatedLink],
            ['subject', templatesubject],
            ['name', templatename],
            ['language', templatelanguage],
            ['lastEditUser', creationUser],
            ['fileContents', encodeURIComponent(fileContent)],
            ['htmlFormat', encodeURIComponent(html)]
          ]
        } else {
          parameters = [
            ['clientID', clientID],
            ['subject', templatesubject],
            ['name', templatename],
            ['language', (templatelanguage!=''?templatelanguage:"fr")],
            ['creationUser', creationUser],
            ['fileContents', encodeURIComponent(fileContent)],
            ['htmlFormat', encodeURIComponent(html)]
          ]
        }

        //  return 
        if (formIsValid) {
          console.log('form is valid try to getdata', parameters)
          var result = await G.getDataWEncode('SaveTemplate', parameters, true)

          G.log('Try to submit form')
          var a = result.status == 200;
          if (a === true) {
            var d = await result.data
            this.props.data.element = d[0];
            this.props.data.templateID = d[0].id;
            //history.goBack();
          } else {
            G.log('Form Is Not Valid')
          }
        }
        else {
          return formIsValid;
        }


      });

    });
    this.setState({ loadSave: false, fields })
    return formIsValid;
  }
    submitForm = async () => {
    this.setState({ loadSave: true })
    const { templatesubject, templatelanguage, templatename } = this.state.fields;
    const clientID = this.props.data.userLogin.site.id;
    const creationUser = this.props.data.userLogin.id;
    let errors = {};
    let formIsValid = true;

    if (!templatesubject) { formIsValid = false; errors["templatesubject"] = ts2("Template subject cannot be empty", this.props.data.userLogin.language); }
    if (!templatename) { formIsValid = false; errors["templatename"] = ts2("Template Name cannot be empty", this.props.data.userLogin.language); }
    if (G.getLanguageList().filter(element => element === templatelanguage).length === 0) {
      formIsValid = false;
      errors['templatelanguage'] = ts2("Template Language must be selected", this.props.data.userLogin.language)
    }

    this.setState({ errors: errors });
    if (!formIsValid) {
      this.setState({ loadSave: false })
      return formIsValid;
    }
    this.editorRef.current.editor.saveDesign(async (design) => {

      let fileContent = JSON.stringify(design)
      let updatedLink = this.props.data.templateID!==-1 ? this.props.data.element.id:undefined;

      this.editorRef.current.editor.exportHtml(async (data) => {
        const { design, html } = data;
        let parameters = [];
        if (updatedLink) {
          parameters = [
            ['id', updatedLink],
            ['subject', templatesubject],
            ['name', templatename],
            ['language', templatelanguage],
            ['lastEditUser', creationUser],
            ['fileContents', encodeURIComponent(fileContent)],
            ['htmlFormat', encodeURIComponent(html)]
          ]
        } else {
          parameters = [
            ['clientID', clientID],
            ['subject', templatesubject],
            ['name', templatename],
            ['language', templatelanguage],
            ['creationUser', creationUser],
            ['fileContents', encodeURIComponent(fileContent)],
            ['htmlFormat', encodeURIComponent(html)]
          ]
        }

        //  return 
        if (formIsValid) {
          console.log('form is valid try to getdata', parameters)
          var result = await G.getDataWEncode('SaveTemplate', parameters, true)

          G.log('Try to submit form')
          var a = result.status == 200;
          if (a === true) {
            
          var logtxt = this.props.data.userLogin.username + (updatedLink?"edited template":"created a new template") + " (subject:" + templatesubject + ", name:" + templatename + ")";
          await G.SaveUserLog(logtxt, this.props.data.userLogin.id);
            history.goBack();
          } else {
            G.log('Form Is Not Valid')
          }
        }
        else {
          return formIsValid;
        }
        this.setState({ loadSave: false })

      });

    });
    
  }
  submitDesignForm = async () => {
    this.setState({ loadSave: true })
    const { templatename } = this.state.fields;
    const clientID = this.props.data.userLogin.site.id;
    const creationUser = this.props.data.userLogin.id;
    let errors = {};
    let formIsValid = true;

    if (!templatename) { formIsValid = false; errors["templatename"] = ts2("Template Name cannot be empty", this.props.data.userLogin.language); }
    

    this.setState({ errors: errors });
    if (!formIsValid) {
      this.setState({ loadSave: false })
      return formIsValid;
    }
    this.editorRef.current.editor.saveDesign(async (design) => {

      let fileContent = JSON.stringify(design)
    
      this.editorRef.current.editor.exportHtml(async (data) => {
        const { design, html } = data;
        let parameters = [];

          parameters = [
            ['clientID', clientID],
            ['name', templatename],
            ['creationUser', creationUser],
            ['fileContents', encodeURIComponent(fileContent)],
            ['htmlFormat', encodeURIComponent(html)]
          ]
        

        //  return 
        if (formIsValid) {
          console.log('form is valid try to getdata', parameters)
          var result = await G.getDataWEncode('SaveDesignTemplate', parameters, true)

          G.log('Try to submit form')
          var a = result.status == 200;
          if (a === true) {
            
          var logtxt = this.props.data.userLogin.username + " created a new designed template (name:" + templatename + ")";
          await G.SaveUserLog(logtxt, this.props.data.userLogin.id);
            history.goBack();
          } else {
            G.log('Form Is Not Valid')
          }
        }
        else {
          return formIsValid;
        }
        this.setState({ loadSave: false })

      });

    });
    
  }
  //#endregion

  //#region Function Export,Save,onLoad,LoadDesign

  exportHtml = () => {
    this.editorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      G.downloadFile(html, 'GivenmineDesign', 'html')
    });
  };

  saveDesign = () => {
    this.editorRef.current.editor.saveDesign(async (design) => {
      alert(ts2('Design file has been saved. You can Load it in all screens by pressing on Load Last Workspace button.', this.props.data.userLogin.language));
      this.setState({ data: design });
      const a = await G.getData("createUserFile",
        [['fileName', 'design.txt'], ['fileContents', encodeURIComponent(JSON.stringify(design))], ['userID', this.props.data.userLogin.id]])
    });


  };
  onDesignLoad = (data) => {

  };
  loadDesign = async () => {

    
    this.editorRef.current.editor.addEventListener(
      'onDesignLoad',
      this.onDesignLoad
    );
    let designFile = await G.getData('getUserFile', [['fileName', 'design.txt'], ['userID', this.props.data.userLogin.id]])
    designFile = await designFile.data

    designFile = JSON.parse(designFile.data)
    this.editorRef.current.editor.loadDesign(designFile);
  }
  onLoad = async() => {
    // you can load your template here;
    // const templateJson = {};
    // emailEditorRef.current.editor.loadDesign(templateJson);

    //if (this.state.data.count>1) this.editorRef.current.editor.loadDesign(JSON.parse(JSON.stringify(this.state.data).replaceAll('hotspot','rouoz')));
    if(this.props.data.templateID!=-1)
    this.refreshHotSpots()
  await this.getcategories();
  if (this.props.data.element.id) {

    const { fields } = this.state
    fields['templatesubject'] = this.props.data.element.subject
    fields['templatelanguage'] = this.props.data.element.language
    fields['templatename'] = this.props.data.element.name

    this.setState({ fields })
    console.log('Template_' + this.props.data.element.id + '.txt')
    let designFile = await G.getData('getTemplateFile', [['fileName', 'Template_' + this.props.data.element.id +"_"+ this.props.data.element.language + '.txt']])
    designFile = await designFile.data
    designFile = JSON.parse(designFile.text)
    if (!designFile) { alert(ts2('This template design file was not found. Please try again', this.props.data.userLogin.language)); return }
    try{
    this.editorRef.current.editor.loadDesign(designFile);
    }
    catch{
      history.go(-1);
    }
  }
  else{
    if(this.props.data.htmlId!="")
    {
    var result = await G.getData("clienttemplates/query",[["filter","id=" + this.props.data.htmlId]]);
      result = await result.data;
      if(result.length>0)
      {
        let designFile = await G.getData('getCustomTemplateFile', [['fileName', result[0].jsonfile]])
        var data = await designFile.data
    //var data = JSON.parse(designFile.text)
        //var data = JSON.parse(result[0].jsondata);
        try{
        this.editorRef.current.editor.loadDesign(data);
        }
        catch{
          history.go(-1);
        }
      }
    }
  }
  };
  //#endregion
  //#region Left Panel
  renderButton = (Name, image, action, includeDropDown = false) => {
    return (
      <div className='unlayerButton' onClick={action}>
        <img src={image} width="24" height="24" className='unlayerSVG fullWidth mb-1' style={{ objectFit: 'contain' }} />
        <div>{Name}</div>
        {includeDropDown ? <img src={dropDown} width="16" height="16" className='unlayerSVG fullWidth mb-1 mt-2' style={{ objectFit: 'contain' }} /> : ''}
      </div>)
  }
  renderHotspotButton = () => {
    return (
      <div className='unlayerButton unlayerHotspotbutton' >
        <img src={hotspotImage} width="24" height="24" className='unlayerSVG fullWidth mb-1' style={{ objectFit: 'contain' }} />
        <div>{ts2('My Created Hotspots', this.props.data.userLogin.language)}</div>
        <img onClick={() => this.setState({ showHotspotList: !this.state.showHotspotList })} src={!this.state.showHotspotList ? dropDown : upload} width="16" height="16" className='unlayerSVG fullWidth mb-3 mt-4 handCursor' style={{ objectFit: 'contain' }} />
        {this.state.showHotspotList && this.state.hotspots.map(element => {

          return (
            
            <div
              placement="bottom"
              delay={{ show: 100, hide: 500 }}
              
            >
              <p className='unlayerHotspot ' key={element.hotspotID}>
              <i className="fas fa-info-circle rightInfoIcon" onClick={async()=>{
                var data = await G.getData("GetHotspotCategoriesByHotspotId", [['hotspotId',element.hotspotID],['clientId',this.props.data.userLogin.site.id]]);
                var cats = await data.data;
                this.setState({ hotspotID:element.hotspotID,hotspotname:element.name,hotspotlink:element.redirectionLink, modalShow3:true, savedhotspotCategories:cats })}}></i>
                {console.log(element,'1')}
                <span className='bold'>{element.name}</span>
                <div className='flexrow spacearround pt-4'>
                  {/* <span className='flexrow verticalcenter handCursor editCopyHover' onClick={() => {
                      this.editCurrentHotspot({ hotspotname: element.name, hotspottype: element.type, hotspotID: element.hotspotID, hyperlink: element.redirectionLink })
                      // {CampaignID: -1
                      //   clientID: 1
                      //   creationDate: "2021-07-01T12:16:45.000Z"
                      //   creationUser: 0
                      //   hotspotID: -1
                      //   lastEditDate: null
                      //   lastEditUser: 0
                      //   name: "Facebook Link"
                      //   redirectionLink: "www.facebook.com"
                      //   type: "0"}

                    }}>Edit <img src={edit16} width="16" height="16" className='whitesvg pl-1' style={{ objectFit: 'contain' }} /></span> */}

                  <span onClick={() => {
                    this.setState({ showToast: true });
                    navigator.clipboard.writeText(G.nodeServerSite + "ProfileClickonHotSpot/" + 'profileid/' + (element.hotspotID > 0 ? '' : 'hotspotID:') + element.hotspotID + '/:campaignID?redirect=' + encodeURIComponent(element.redirectionLink));
                    setTimeout(() => { this.setState({ showToast: false }) }, 1500)
                  }} className='flexrow verticalcenter handCursor editCopyHover'>{ts('Click to Copy Link', this.props.data.userLogin.language)} <img src={copy16} width="25" height="20" className='whitesvg pl-1 pr-1' style={{ objectFit: 'contain' }} /></span>
                </div>
              </p>
            </div>

          )
        })}

      </div>)
  }
  renderUserParams = () => {
    return (
      <div className='unlayerButton unlayerHotspotbutton' >
        <img src={hotspotImage} width="24" height="24" className='unlayerSVG fullWidth mb-1' style={{ objectFit: 'contain' }} />
        <div>{ts2('User Parameters', this.props.data.userLogin.language)}</div>
        <img onClick={() => this.setState({ showParamsList: !this.state.showParamsList })} src={!this.state.showParamsList ? dropDown : upload} width="16" height="16" className='unlayerSVG fullWidth mb-3 mt-4 handCursor' style={{ objectFit: 'contain' }} />
        {this.state.showParamsList && ['First Name', 'Last Name', 'Email Address', 'Mobile', 'Gender', 'Birthdate', 'Age', 'Country', 'City', 'Registration Date'].map(element => {

          return <p className='unlayerHotspot '>
            <span className='bold'>{ts2(element, this.props.data.userLogin.language) === element ? ts(element, this.props.data.userLogin.language) : ts2(element, this.props.data.userLogin.language)}</span>
            <div className='flexrow spacearround pt-4'>
              <span onClick={() => {
                this.setState({ showToast: true });
                navigator.clipboard.writeText('{' + element + '}');
                setTimeout(() => { this.setState({ showToast: false }) }, 1500)
              }} className='flexrow verticalcenter handCursor editCopyHover'> {ts('Click to Copy Parameter', this.props.data.userLogin.language)} <img src={copy16} width="16" height="16" className='whitesvg pl-1' style={{ objectFit: 'contain' }} /></span>
            </div>
          </p>
        })}

      </div>)
  }

  renderHeader = (text, show, onClick) => {
    return (
      <div onClick={onClick} className='unlayerHeader flexrow spacebetween verticalcenter handCursor'> {text} <img src={!show ? dropDown : upload} width="16" height="16" className='whitesvg ml-2 mr-1' style={{ objectFit: 'contain' }} />   </div>)
  }
  createNewHotspot = () => {

    this.setState({ hotspotID: -1, modalShow: true })
  }
  
  editCurrentHotspot = (hotpsotData) => {
    const { hotspotname, hotspottype, hyperlink } = hotpsotData;
    this.setState({ hotspotFields: { hotspotname, hotspottype, hyperlink }, hotspotID: hotpsotData.hotspotID }, () => { this.setState({ modalShow: true }) })


  }
  refreshHotSpots = async () => {
    let hotspotfitler = ' clientID = ' + this.props.data.userLogin.site.id 
    if (this.props.data.element.id && this.props.data.templateID!=-1) {
      ////if we want to filter user is here
      console.log('data imported from parentsss', this.props)
      hotspotfitler += " and CampaignID = " + this.props.data.element.id

      let result = await G.getData('hotspot/query', [['filter', hotspotfitler]]);
      result = await result.data;
      this.setState({ hotspots: result })
    } else {
      hotspotfitler += ' and hotspotid <0'
      ////if we want to filter user is here
      let result = await G.getData('hotspot/query', [['filter', hotspotfitler]]);
      result = await result.data;

      this.setState({ hotspots: result })
    }

  }
  //#endregion
  languageArray = () => { let lang = G.getLanguageList(); lang.shift(); return lang }
  render() {
    const { fields, errors } = this.state;
    return (
      <>
      <div className='fullWidth'>
        <Form name="form" className='pt-4 col-9 mb-5' >
          <div className='flexrow mb-5' >
            <Button className='mr-3' disabled={this.state.loadSave} variant='success' onClick={() => {if(this.props.data.templateID!==-2){this.submitForm()}else{this.submitDesignForm()}}}>
              {this.state.loadSave && <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className='mr-2'
              />}
              {this.props.data.templateID!==-2?ts2('Save Campaign Template', this.props.data.userLogin.language):ts2('Save Designed Template', this.props.data.userLogin.language)}</Button>
            <Button variant='warning' onClick={() => {
              console.log("Data",this.props.data)
              this.setState({ modalSubject: ts('Close Wihout Save', this.props.data.userLogin.language), modalBody: ts('Are you sure you want to close and return to the previous page?', this.props.data.userLogin.language), modalShow1: true })
            }
            }>{ts('Cancel / Return', this.props.data.userLogin.language)}</Button>
          </div>
          <Form.Group>
            <Col sm={12} md={4} >
              {G.renderTextField("Template Name", 'text', ts2("Enter the Template name here", this.props.data.userLogin.language), this.handleChange, fields, errors, '', '', false, this.props.data.userLogin.language)}
            </Col>
            {this.props.data.templateID!==-2 && <Col sm={6} md={4}>
              {G.renderTextField("Template Subject", 'text', ts2("Enter the email subject here", this.props.data.userLogin.language), this.handleChange, fields, errors, '', '', false, this.props.data.userLogin.language)}
            </Col>}
            {this.props.data.templateID!==-2 && <Col sm={6} md={4}>{G.renderDropDown('Template Language', this.languageArray(), this.handleChangeWLanguage, fields, errors, this.languageArray()[0], false, this.props.data.userLogin.language)}
            </Col>}
          </Form.Group>
        </Form>
        <div className='flexrow '>
          <div className='flexcolumn unlayerStatusBackground' >
            {/* {this.renderHeader('Save Record', this.state.showSave, () => this.setState({ showSave: !this.state.showSave }))}
            {this.state.showSave && this.renderButton('Save Template', SaveImage, this.submitForm)} */}
{this.props.data.templateID!==-2 && <>
            {this.renderHeader('Hotspots', this.state.showHotspots, () => this.setState({ showHotspots: !this.state.showHotspots }))}
            {this.state.showHotspots && this.renderButton(ts2('Create New Hotspot', this.props.data.userLogin.language), SaveImage, this.createNewHotspot)}
            {this.state.showHotspots && this.renderHotspotButton()}
            </>}
            {this.renderHeader(ts2('User Information', this.props.data.userLogin.language), this.state.showSave, () => this.setState({ showSave: !this.state.showSave }))}
            {this.state.showSave && this.renderUserParams()}
            
            {this.renderHeader(ts2('Save/Load Design', this.props.data.userLogin.language), this.state.showPreferences, () => this.setState({ showPreferences: !this.state.showPreferences }))}
            {this.state.showPreferences && this.renderButton(ts2('Save Current Workspace', this.props.data.userLogin.language), SaveImage, this.saveDesign)}
            {this.state.showPreferences && this.renderButton(ts2('Load Last Workspace', this.props.data.userLogin.language), LoadImage, () => { this.setState({ modalShow2: true, modalSubject: 'Load Previous Saved Design', modalBody: 'Are you sure you want to load previous saved design if available?' }) })}
            {this.state.showPreferences && this.renderButton(ts2('Export HTML', this.props.data.userLogin.language), HtmlImage, this.exportHtml)}
          </div>
          <div className='pr-2 ' style={{ width: '100%', height: '100vh' }}>


            {<EmailEditor ref={this.editorRef} onLoad={this.onLoad} style={{ height: '100vh' }} />}

            <MyVerticallyCenteredModal
              show={this.state.modalShow}
              campaignTemplateID={this.props.data.templateID}
              onHide={() => { this.setState({ modalShow: false }) }}
              refreshHotspot={() => { this.refreshHotSpots(); this.setState({ modalShow: false }) }}
              loginUser={this.props.data.userLogin.id}
              companyID={this.props.data.userLogin.site.id}
              hotspotID={this.state.hotspotID}
              hotspotFields={this.state.hotspotFields}
              hotspotCategory={this.state.hotspotCategory}
              hotspotCategoryNames={this.state.hotspotCategoryNames}
              language={this.props.data.userLogin.language}
              hotspotCategoryOptions = {this.state.hotspotCategoryOptions}
            />
            <MyVerticallyCenteredReadOnlyModal
            show={this.state.modalShow3}
            onHide={() => { this.setState({ modalShow3: false }) }}
            hotspotId={this.state.hotspotID}
            hotspotname={this.state.hotspotname}
            language={this.props.data.userLogin.language}
            hotspotlink={this.state.hotspotlink}
            categories={this.state.savedhotspotCategories}
            />
            <MessageBox
              show={this.state.modalShow1}
              title={this.state.modalSubject}
              body={this.state.modalBody}
              onHide={() => { this.setState({ modalShow1: false }) }}
              middle={{ action: () => history.push({
                pathname: '/CampaignsTemplates'
            }), text: ts('Yes', this.props.data.userLogin.language), variant: 'warning' }}
              right={{ action: () => { }, text: ts('Stay', this.props.data.userLogin.language), variant: 'success' }}


            />
            <MessageBox
              show={this.state.modalShow2}
              title={this.state.modalSubject}
              body={this.state.modalBody}
              onHide={() => { this.setState({ modalShow2: false }) }}
              middle={{ action: () => { this.loadDesign(); this.setState({ modalShow2: false }) }, text: 'Load Design', variant: 'warning' }}
              right={{ action: () => { }, text: 'Cancel', variant: 'success' }}


            />
          </div>
          <Flash message='Successfully Copied To Clipboard!' visibility={this.state.showToast} />
        </div>
        
      </div>
      {
          this.state.languageload && <div className='flexcolumn verticalcenter  text-center col-12' style={{ position:'fixed', top:'0',zIndex:9999, left:0, paddingTop:'20%',width:'100vw', height:'100vh', backgroundColor:'rgba(255,255,255,0.8)'  }}>
          <div className='flexrow center fullWidth '>
              <Spinner animation="grow" className='m-3' variant="warning" />
              <Spinner animation="grow" className='m-3' variant="info" />
              <Spinner animation="grow" className='m-3' variant="danger" />

          </div>
          <div className='hint remarks xlowalpha'>Loading Data</div>
      </div>
        }
      </>
    )
  }
}



class MyVerticallyCenteredModal extends Component {
  constructor(props) {
    super(props);
    this.editorRef = React.createRef();
    this.state = {
      data: {},
      fields: {},
      errors: {},
      hotspotCategory: [],
      hotspotCategoryNames: [],
      hotspotCategoryOptions:[],
      hotspotcategorymultiple:[]
    }
    this.handleChange = this.handleChange.bind(this);
  }

  //#region Form Validation
  handleChange(e) {
    let { fields, errors } = this.state;
    fields[e.target.name] = e.target.value;
    delete errors[e.target.name];
    this.setState({ fields });
  }
  getCategoryID = (name) => {
    let category = 0;

    this.props.hotspotCategory.map(element => {

      if (element.name === name) { category = element.id; return }
    })

    return category
  }
  // getcategories = async () =>{
  //   let array = []
  //   array = await G.getData("hotspotCategory/query",[])
  //   array = await array.data
  //   this.setState({hotspotCategory:array})
  //   let arrayNames=[];
  //   array.map(element=>{
  //     arrayNames.push(element.name)
  //   })
  //   this.setState({hotspotCategoryNames:arrayNames})
  // }
  componentDidMount() {
    // this.getcategories()
  }
  handleValidation = async () => {

    const { hotspotname, hotspottype, hyperlink } = this.state.fields;
    var hotspotcategorymultiple = this.state.hotspotcategorymultiple;
    let errors = {};
    let formIsValid = true;


    if (!hotspotname) { formIsValid = false; errors["hotspotname"] = ts2("Hotspot name cannot be empty", this.props.language); }
    if (!hotspottype) { formIsValid = false; errors["hotspottype"] = ts2("Hotspot Type must be selected", this.props.language); } else {
      if (G.getHotspotType(hotspottype) < 0) {
        formIsValid = false;
        errors['hotspottype'] = ts2("Hotspot Type must be selected", this.props.language)
      }
    }
    /*let categoryid = this.getCategoryID(hotspotcategory);
    if (!hotspotcategory) { formIsValid = false; errors["hotspotcategory"] = ts2("Hotspot category must be selected", this.props.language); } else {
      if (categoryid === 0) {
        formIsValid = false;
        errors['hotspotcategory'] = ts2("Hotspot Category Type must be selected", this.props.language)
      }
    }*/
    if(hotspotcategorymultiple.length==0)
    {
      formIsValid = false; errors["hotspotcategory"] = ts2("Hotspot category must be selected", this.props.language);
    }

    if (!hyperlink) { formIsValid = false; errors["hyperlink"] = "Hyperlink name cannot be empty"; }
    this.setState({ errors: errors });


    if (formIsValid) {
      let parameters = [
        //////campaignTemplateID
        ['hotspotID', this.props.hotspotID],
        ['campaignID', this.props.campaignTemplateID],
        ['name', hotspotname],
        ['type', G.getHotspotTypeID(hotspottype)],
        ['redirectionLink', hyperlink.toLowerCase().replace("https://", '').replace("http://", '')],
        ['creationUser', this.props.loginUser],
        ['clientID', this.props.companyID],
        ['category', JSON.stringify(hotspotcategorymultiple)]
      ]
      G.log('Form is valid. pushing now values...')
      var result = await G.getData('SaveHotspot', parameters)
      
      return (result.status === 200)
    }
    else {
      return formIsValid;
    }
  }
  submitForm = async (e) => {
    e.preventDefault();
    G.log('Try to submit form')
    var a = await this.handleValidation();

    G.log('submit form is', a)
    if (a === true) {
      this.props.refreshHotspot()
      this.setState({ activeList: 0 })
      
    } else { G.log('Form Is Not Valid') }

  }

  //#endregion
  render() {
    const { errors, fields } = this.state;
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered

      //animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Create New Hotspot
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ width: '100%' }}>
            <Form name="form" className='pt-3' onSubmit={this.submitForm.bind(this)} >

              <Form.Group>
                <Col>
                  {G.renderTextField("Hotspot Name", 'text', ts("Enter hotspot Name", this.props.language), this.handleChange, fields, errors, '', '', false, this.props.language)}
                </Col>

              </Form.Group>
              {G.renderHotspotDropDown('Hotspot Type', G.hotspotTypes, this.handleChange, fields, errors, ts2('Select from list', this.props.language), false, this.props.language)}
              <Form.Group>
              </Form.Group>
              {/*G.renderDropDown('Hotspot Category', this.props.hotspotCategoryNames, this.handleChange, fields, errors, ts2('Select from list', this.props.language), false, this.props.language)*/}
              <Form.Group className='fullWidth '>
            <Form.Label htmlFor={'hotspotcategory'} >{ts('Hotspot Category',this.props.language)}{errors['hotspotcategory'] && <span className='warningColor bold'> *</span>}</Form.Label>
            <Select
            onChange={value =>{console.log(value); this.setState({ hotspotcategorymultiple: value })}}
    closeMenuOnSelect={false}
    isMulti
    options={this.props.hotspotCategoryOptions}
  />
            
            {errors['hotspotcategory'] && <div className='remarks notValidColor'>{errors['hotspotcategory']}</div>}
        </Form.Group>
              
              <Form.Group>
                <Col>
                  {G.renderTextField("Hyperlink", 'text', ts('Enter Hyperlink', this.props.language), this.handleChange, fields, errors, '', '', false, this.props.language)}
                </Col>
              </Form.Group>
            </Form>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='flexrow end  '>
            <Button onClick={this.submitForm} className='mr-3'>{ts('Create Hotspot', this.props.language)}</Button>
            <Button onClick={this.props.onHide}>{ts('Cancel', this.props.language)}</Button>
          </div>

        </Modal.Footer>
      </Modal>
    )
  }
}

class MyVerticallyCenteredReadOnlyModal extends Component {
  constructor(props) {
    super(props);
    this.editorRef = React.createRef();
    this.state = {
      data: {},
      fields: {},
      errors: {},
      categories:[]
    }
    this.cats = [];
  }
  
  componentDidMount = async()=> {
    
  }
  
  getCategories = async(hotspotId) => {
    let array = await G.getData("GetHotspotCategoriesByHotspotId", [[hotspotId,hotspotId]]);
    return array;
  }

  //#endregion
  render() {
    const { errors, fields } = this.state;
    
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered

      //animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {ts2("Hotspot details", this.props.language)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ width: '100%' }}>
            <Form name="form" className='pt-3'>

              <Form.Group>
                <Col>
                <Form.Group className='fullWidth '>
            <Form.Label >{ts("Hotspot Name",this.props.language)}</Form.Label>
            <div>{this.props.hotspotname}</div>
        </Form.Group>
                </Col>

              </Form.Group>
              
              
              <Form.Group className='fullWidth '>
            <Form.Label >{ts('Hotspot Category',this.props.language)}</Form.Label>
            { 
              
            this.props.categories.map(element=>{
              return(
              <div>{element.name}</div>
              )
             })
            }
            
            
        </Form.Group>
              
              <Form.Group>
                <Col>
                <Form.Group className='fullWidth '>
            <Form.Label >{ts("Hyperlink",this.props.language)}</Form.Label>
            <div>{this.props.hotspotlink}</div>
        </Form.Group>
                  
                </Col>
              </Form.Group>
            </Form>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='flexrow end  '>
            
          </div>

        </Modal.Footer>
      </Modal>
    )
  }
}



