import { Button, Badge, Form, Spinner, Image, ProgressBar, OverlayTrigger, Tooltip } from 'react-bootstrap'
import React, { Component } from 'react'
import * as G from '../components/global'
import ReactPaginate from 'react-paginate';
import moment from "moment";
import { connect } from 'react-redux';
import Chart from 'react-apexcharts'
import { translateScreen as ts,translateScreen2 as ts2, translateDate as td } from '../components/dictionary'
import history from '../components/history';
import { NavLink } from 'react-router-dom';
import $ from "jquery";
import 'rsuite/dist/rsuite.min.css';
import {DateRangePicker} from 'rsuite';
import dateFormat from 'dateformat';
import qs from 'qs'
//import "datatables.net/js/jquery.dataTables"
//import "datatables.net-dt/css/jquery.dataTables.css"
const defaultDonuOptions = {
    dataLabels: {
        style: {
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'bold',
            color: '#333'
        },
    },
    series: [0],
    labels: [],
    colors: ['#007bff', '#ff7424', '#f7d240', '#86ee28', '#07a3af'],
    chart: {
        type: 'donut',
    },
    responsive: [{
        breakpoint: 200,
        options: {
            chart: {
                width: 100
            },
            legend: {
                position: 'bottom'
            }
        }
    }],
}

class campaignStatistics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadprofiles: false,
            fields: [],
            errors: [],
            allCompanies: [],
            activeCompany: {},
            doneLoadingCmp: false,
            loadCompany: true,
            loadTemplates: true,
            ClientTemplates: [],
            activeScreen: 0,
            selectedCampaign: {},
            selectedCampaignData: {},
            totalclicks:0,
            optionHistory: {
                series: [{
                    name: 'Loading',
                    type: 'column',
                    data: []
                }, {
                    name: 'Loading',
                    type: 'line',
                    data: []
                }],
                chart: {
                    height: 350,
                    type: 'line',

                },
                stroke: {
                    width: [0, 4]
                },

                title: {
                    text: 'Traffic Sources'
                },
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: [1, 0]
                },
                labels: [],
                xaxis: {
                    type: 'date'
                },
                yaxis: [{
                    title: {
                        text: ts('Number of Opens',this.props.userLogin.language),
                    },

                }, {
                    opposite: true,
                    title: {
                        text: ts('Number of Hotspots Clicks',this.props.userLogin.language)
                    }
                }]
            },
            optionsReach: { series: [] },
            optionsOpen: { series: [] },
            distinctAges: [],
            AgesReachTotal: 0,
            AgesOpenTotal: 0,
            distinctCountry: [],
            CountryReachTotal: 0,
            CountryOpenTotal: 0,
            distinctCity: [],
            CityReachTotal: 0,
            CityOpenTotal: 0,
            loadingStatisticsSpinner: false,

        }
        this.onChange = this.onChange.bind(this);
    };

    getSnapshotBeforeUpdate(prevProps) {
        return { notifyRequired: prevProps.userLogin !== this.props.userLogin };
      }
      
      async componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot.notifyRequired && this.props.userLogin.site && (this.state.activeCompany.id != this.props.userLogin.site.id)) {
            this.setState({activeCompany:this.props.userLogin.site})
            await this.refreshData(this.props.userLogin.site.id,'','');
            
        }
        

      }

    //#region Didmunt, Load Companies & disticnt functions
    renderTemplateButton = (id, subject, creationDate = '') => {
        return (
            <div className='m-3 handCursor text-center' style={{ borderRadius: 8 }} onClick={() => { this.openCampaignEditor({ id, subject, creationDate }) }}>
                <Image style={{ borderRadius: 8 }} width={120} src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRaOc-TwahmfvG4VCoTK1PGHfxLXUV44mcxatoK6B9h2lST0nw4HKHRT5PEg-G-v2Vz8Xw&usqp=CAU' />
                <div className='text-center pt-3'>{subject}

                </div>
                <Badge variant='light' className=' remarks'>

                    {creationDate !== '' ? dateFormat(creationDate, 'dddd , dd mmmm yyyy') : ''}
                </Badge>
            </div>
        )
    }

    componentDidMount() {
        //console.log("here it is the id of the site"+this.props.userLogin.site.id);
        if (this.props.userLogin.site){
            this.refreshData(this.props.userLogin.site.id,'','')
        }
        //this.loadCompanies()
        
        history.listen((location, action) => {
            if(location.pathname == "/CampaignStatistics" || location.pathname == "/CampaignStatistics/")
                this.setState({ activeScreen: 0 });
                this.refreshData(this.props.userLogin.site.id,'','')
          });
          
        //   $(document).ready(function () {
        //     $('#example1').DataTable();
        // });
             
    }
   
    loadCompanies = async () => {
        try {
            if (this.props.userLogin) {
                this.setState({ doneLoadingCmp: true })
                let paramArr = []
                if (this.props.userLogin.site) paramArr.push(['filter', " a.id = " + this.props.userLogin.site.id])

                let result = await G.getData('client/query', paramArr);
                result = await result.data;
              
                this.setState({ allCompanies: result })
                if (result.length > 0) {
                    this.setState({ activeCompany: result[0] })
                    this.listTemplates(result[0].id, result[0])
                }
                this.setState({ loadCompany: false })
            }
        }
        catch (error) {

        }
    }
    getCompanyUsers = (company) => {

        this.setState({ activeCompany: company })
        return (this.state.allCompanies.filter(record => record.id === company.id))
    }
    listTemplates = (companyId, companyDetails) => {

        this.setState({ ClientTemplates: [], displayedList: [], activeCompany: companyDetails })
        this.refreshData(companyId,'','');
        this.setState({ activeScreen: 0 })


    }
    refreshData = async (company, startdate, enddate) => {
        this.setState({ loadTemplates: true })
       //changed by youssef to meet the site selected// 
        //let result = await G.getData('getCampaignsList', [['clientID', company]]);
        let result = await G.getData('getCampaignsList', [['clientID', company], ['startdate', startdate], ['enddate', enddate]]);
        result = await result.data;
        
        
        
        //if (result.length > 0) { this.loadStatitics(result[0]) }
        if(this.state.campaigntype)
        {
            var now = Date.now();
            if(this.state.campaigntype=="1")
            {
                console.log(result)
                var array = result.filter(element=>element.toBeSchedule==='1' && Date.parse(element.scheduleDate) - now>0)
                console.log(array)
                this.setState({ClientTemplates:array})
            }
            else if(this.state.campaigntype == "0")
                this.setState({ClientTemplates:result.filter(element=>element.toBeSchedule==='0' || (element.toBeSchedule === '1' && Date.parse(element.scheduleDate) - now<=0))})
            else
                this.setState({ ClientTemplates: result })
        }
        else
            this.setState({ ClientTemplates: result })
        var _this = this;
        var qid = qs.parse(history.location.search, { ignoreQueryPrefix: true }).id;
        this.state.ClientTemplates.map((item)=>{
                    
            if(qid == item.id)
            {
                this.loadStatitics(item);
            }
        })
        this.setState({loadTemplates: false})
       setTimeout(function(){
        
            if(document.getElementById("dttablestats")!=null)
            {
                document.getElementById("dttablestats").remove();
            }
            const script = document.createElement("script");
            script.id = "dttablestats";
            script.src = '/dist/js/dttablestats.js';
            script.async = true;
            document.body.appendChild(script);
            
            
        },100)
        
    }
    
    renderButton = (title, value, image, action, additional) => {
        return (
            <div className="col-md-3 col-sm-6 col-12">
                <div className="info-box handCursor" onClick={action}>
                    {image}
                    <div className="info-box-content">
                        <span className="info-box-text">{title}</span>
                        <span className="info-box-number ssstitle">{value.toLocaleString('en')}</span>
                        {additional}
                    </div>
                    <span className='flexcolumn center fullVertical handCursor'></span>
                </div>
                {/* /.info-box */}
            </div>
        )
    }
    renderGenderRow = (title, cmpData) => {


        let newValue = undefined;

        if (cmpData != null) {
            if (title.startsWith('Reached')) { newValue = cmpData[0].filter(element => { return element.Reached === 'Reached' }) }
            if (title.startsWith('Unreached')) { newValue = cmpData[0].filter(element => { return element.Reached === 'Not Reached' }) }
            if (title.startsWith('Opened')) { newValue = cmpData[0].filter(element => { return element.Reached === 'Opened' }) }
            if (title.startsWith('Unopened')) { newValue = cmpData[0].filter(element => { return element.Reached === 'Not Opened' }) }
        }
        let valueMale = 0; let valueFemale = 0; let valueNotDefined = 0;



        if (newValue) {
            if (newValue.length > 0) {
                let newValueCopy = [...newValue].filter(element => { return element.genderName === 'Male' })
                if (newValueCopy.length > 0) { valueMale = newValueCopy[0].ProfileCount }
                newValueCopy = [...newValue].filter(element => { return element.genderName === 'Female' })

                if (newValueCopy.length > 0) { valueFemale = newValueCopy[0].ProfileCount }
                newValueCopy = [...newValue].filter(element => { return element.genderName === 'Not defined' })

                if (newValueCopy.length > 0) { valueNotDefined = newValueCopy[0].ProfileCount }
            }
        }

        let total = valueMale + valueFemale + valueNotDefined
        valueMale = Math.round((valueMale / total) * 100)
        valueFemale = Math.round((valueFemale / total) * 100)
        valueNotDefined = Math.round((valueNotDefined / total) * 100)
        return (<div className='fullWidth'>
            <Badge variant='light' className='p-2 fullWidth'>{ts(title,this.props.userLogin.language)} <span className='sremarks themColor'>({G.countItems(total, ts('Customer',this.props.userLogin.language))})</span></Badge>
            {
                newValue && newValue.length == 0 && <div className='text-center hint sremarks p-3'> {ts('No records',this.props.userLogin.language)}</div>
            }
            {newValue && newValue.length > 0 && <ProgressBar variant='dark' className='ml-3 mr-3 mt-2'>
                <ProgressBar variant={valueMale > 50 ? 'success' : (valueMale < 10 ? 'danger' : 'warning')} now={valueMale} key={1} />
                <ProgressBar variant={valueFemale > 50 ? 'success' : (valueFemale < 10 ? 'danger' : 'warning')} now={valueFemale} key={2} />
                <ProgressBar variant={valueNotDefined > 50 ? 'success' : (valueNotDefined < 10 ? 'danger' : 'warning')} now={valueNotDefined} key={3} />
            </ProgressBar>}
            {newValue && newValue.length > 0 && <div className='flexrow spacebetween  ml-3 mr-5   ' style={{width:"95%"}}>
                <div className='flexcolumn   text-center  '   >
                    <div className='bold remarks primColor'>{valueMale}% </div>
                    <div className='sremarks'>{ts('Males',this.props.userLogin.language)}</div>
                </div>
                <div className='flexcolumn   text-center '   >
                    <div className='bold remarks primColor'>{valueFemale}% </div>
                    <div className='sremarks'>{ts('Females',this.props.userLogin.language)}</div>
                </div>
                <div className='flexcolumn   text-center '   >
                    <div className='bold remarks primColor'>{valueNotDefined}% </div>
                    <div className='sremarks'>{ts('Not defined',this.props.userLogin.language)}</div>
                </div>
            </div>}
        </div>)
    }
    getValueFromArrayAge = (type, Age) => {
        let result = this.state.selectedCampaignData;
        if (!result) return 0
        result = result.statisticDetailsAge
        if (!result) return 0
        if (result.length == 0) return (0)

        result = result[0].filter(element => { return (element.Reached === type && element.Ages === Age) })

        if (result.length === 0) return 0
        return result[0].ProfileCount
    }
    getValueFromArrayCountry = (type, Country) => {
        let result = this.state.selectedCampaignData;
        if (!result) return 0
        result = result.statisticDetailsCountry
        if (!result) return 0
        if (result.length == 0) return (0)

        result = result[0].filter(element => { return (element.Reached === type && element.country === Country) })

        if (result.length === 0) return 0
        return result[0].ProfileCount
    }
    getValueFromArrayCity = (type, City) => {
        let result = this.state.selectedCampaignData;
        if (!result) return 0
        result = result.statisticDetailsCity
        if (!result) return 0
        if (result.length == 0) return (0)

        result = result[0].filter(element => { return (element.Reached === type && element.City === City) })

        if (result.length === 0) return 0
        return result[0].ProfileCount
    }

    renderGrid = (name, Data, typeID =0) => {
        //let typeID = 0 // 0 is age, 1 is country , 2 is city
        //if (name.toLowerCase().includes('countr')) { typeID = 1 }
        //if (name.toLowerCase().includes('cit')) { typeID = 2 }

        return (

            <div className='fullWidth '>
                {/* {typeID == 0 && <p className='  ssstitle bold ml-3 mt-3 mb-2   '>{name} </p>} */}
                <div className={typeID == 0 ? "card-body p-0" : ''}>
                    <table className="table table-hover ">
                        <thead>
                            <tr>
                                <th >{name}</th>
                                <th colspan="2" className='cunlayer' >{ts('Reached',this.props.userLogin.language  )}</th>

                                <th colspan="2" >{ts('Not Reached',this.props.userLogin.language  )}</th>

                                <th colspan="2" className='cunlayer'>{ts('Opened',this.props.userLogin.language  )}</th>

                                <th colspan="2">{ts('Not Opened',this.props.userLogin.language  )}</th>


                            </tr>
                        </thead>
                        <tbody>
                            {
                                Data && Data.map(element => {
                                    return (<tr>

                                        <td className='bold'>{element}</td>
                                        <td className='cunlayer'>
                                            {typeID == 0 && this.getValueFromArrayAge('Reached', element)}
                                            {typeID == 1 && this.getValueFromArrayCountry('Reached', element)}
                                            {typeID == 2 && this.getValueFromArrayCity('Reached', element)}
                                        </td >
                                        <OverlayTrigger overlay={<Tooltip >Percentage from Total (Reached/Unreached) <p className='sremarks'>{G.countItems(typeID == 0 ? this.state.AgesReachTotal : (typeID == 1 ? this.state.CountryReachTotal : this.state.CityReachTotal), 'Profile')}</p></Tooltip>}>
                                            <td className='cunlayer'><Badge variant='info'>
                                                {typeID == 0 && (this.state.AgesOpenTotal === 0 ? '100' : Math.round((this.getValueFromArrayAge('Reached', element) / this.state.AgesReachTotal) * 100))}
                                                {typeID == 1 && (this.state.CountryOpenTotal === 0 ? '100' : Math.round((this.getValueFromArrayCountry('Reached', element) / this.state.CountryReachTotal) * 100))}
                                                {typeID == 2 && (this.state.CityOpenTotal === 0 ? '100' : Math.round((this.getValueFromArrayCity('Reached', element) / this.state.CityReachTotal) * 100))}
                                                %</Badge></td>
                                        </OverlayTrigger>


                                        <td>
                                            {typeID == 0 && this.getValueFromArrayAge('Not Reached', element)}
                                            {typeID == 1 && this.getValueFromArrayCountry('Not Reached', element)}
                                            {typeID == 2 && this.getValueFromArrayCity('Not Reached', element)}
                                        </td>
                                        <OverlayTrigger overlay={<Tooltip >Percentage from Total (Reached/Unreached) <p className='sremarks'>{G.countItems(typeID == 0 ? this.state.AgesReachTotal : (typeID == 1 ? this.state.CountryReachTotal : this.state.CityReachTotal), 'Profile')}</p></Tooltip>}>
                                            <td className='cunlayer'><Badge variant='danger'>
                                                {typeID == 0 && (this.state.AgesOpenTotal === 0 ? '100' : Math.round((this.getValueFromArrayAge('Not Reached', element) / this.state.AgesReachTotal) * 100))}
                                                {typeID == 1 && (this.state.CountryOpenTotal === 0 ? '100' : Math.round((this.getValueFromArrayCountry('Not Reached', element) / this.state.CountryReachTotal) * 100))}
                                                {typeID == 2 && (this.state.CityOpenTotal === 0 ? '100' : Math.round((this.getValueFromArrayCity('Not Reached', element) / this.state.CityReachTotal) * 100))}
                                                %</Badge></td>
                                        </OverlayTrigger>



                                        <td className='cunlayer'>
                                            {typeID == 0 && this.getValueFromArrayAge('Opened', element)}
                                            {typeID == 1 && this.getValueFromArrayCountry('Opened', element)}
                                            {typeID == 2 && this.getValueFromArrayCity('Opened', element)}
                                        </td >
                                        <OverlayTrigger overlay={<Tooltip >Percentage from Total (Opened/Unopened) <p className='sremarks'>{G.countItems(typeID == 0 ? this.state.AgesOpenTotal : (typeID == 1 ? this.state.CountryOpenTotal : this.state.CityOpenTotal), 'Profile')}</p></Tooltip>}>
                                            <td className='cunlayer'><Badge variant='success'>
                                                {typeID == 0 && (this.state.AgesOpenTotal === 0 ? '100' : Math.round((this.getValueFromArrayAge('Opened', element) / this.state.AgesReachTotal) * 100))}
                                                {typeID == 1 && (this.state.CountryOpenTotal === 0 ? '100' : Math.round((this.getValueFromArrayCountry('Opened', element) / this.state.CountryReachTotal) * 100))}
                                                {typeID == 2 && (this.state.CityOpenTotal === 0 ? '100' : Math.round((this.getValueFromArrayCity('Opened', element) / this.state.CityReachTotal) * 100))}
                                                %</Badge></td>
                                        </OverlayTrigger>



                                        <td>
                                            {typeID == 0 && this.getValueFromArrayAge('Not Opened', element)}
                                            {typeID == 1 && this.getValueFromArrayCountry('Not Opened', element)}
                                            {typeID == 2 && this.getValueFromArrayCity('Not Opened', element)}
                                        </td>
                                        <OverlayTrigger overlay={<Tooltip >Percentage from Total (Opened/Unopened) <p className='sremarks'>{G.countItems(typeID == 0 ? this.state.AgesOpenTotal : (typeID == 1 ? this.state.CountryOpenTotal : this.state.CityOpenTotal), 'Profile')}</p></Tooltip>}>
                                            <td className='cunlayer'><Badge variant='warning'>
                                                {typeID == 0 && (this.state.AgesOpenTotal === 0 ? '100' : Math.round((this.getValueFromArrayAge('Not Opened', element) / this.state.AgesReachTotal) * 100))}
                                                {typeID == 1 && (this.state.CountryOpenTotal === 0 ? '100' : Math.round((this.getValueFromArrayCountry('Not Opened', element) / this.state.CountryReachTotal) * 100))}
                                                {typeID == 2 && (this.state.CityOpenTotal === 0 ? '100' : Math.round((this.getValueFromArrayCity('Not Opened', element) / this.state.CityReachTotal) * 100))}
                                                %</Badge></td>
                                        </OverlayTrigger>
                                    </tr>)
                                })
                            }

                        </tbody>
                    </table>
                </div>
                {/* /.card-body */}
            </div>


        )
    }

    //#endregion
    loadStatitics = async (item) => {
        this.setState({ activeScreen: 1, loadingStatisticsSpinner: true })

        this.setState({ selectedCampaign: item })
        let result = await G.getData('getCampaignStatistics/Summary', [['campaignID', item.id], ['templateID', item.campaignTemplateID]]);
        console.log( [['campaignID', item.id], ['templateID', item.campaignTemplateID]])
        result = await result.data
        console.log(result);
        this.setState({ selectedCampaignData: result })
        var totalclicks = 0;
        for(var k=0;k<result.hotspotsDetails.length;k++)
        {
            var el = result.hotspotsDetails[k];
            if(el.filter)
            {
            var filtered = el.filter(element => element.Gender === 'Gender');//.map(element => { totalclicks += element.counts })
            for(var f=0; f<filtered.length;f++)
            {
                totalclicks += filtered[f].counts;
            }
        }
        }
        this.setState({totalclicks:totalclicks});
        try {
            //#region Reached Not Reached opened Fonuts
            let optionsReach = { ...defaultDonuOptions }
            optionsReach.series = [(result.actions[0].Reach / result.actions[0].total) * 100, ((result.actions[0].total - result.actions[0].Reach) / result.actions[0].total) * 100]
            optionsReach.labels = ['Reached Emails', 'Not Reached']
            optionsReach.colors = ['#8fca09', '#fd5239']


            let optionsOpen = { ...defaultDonuOptions }
            optionsOpen.series = [(result.actions[0].Open / result.actions[0].total) * 100, ((result.actions[0].total - result.actions[0].Open) / result.actions[0].total) * 100]
            optionsOpen.labels = ['Opened Emails', 'Emails Not Opened']
            optionsOpen.colors = ['#8fca09', '#fd5239']

            this.setState({ optionsOpen })
            this.setState({ optionsReach: optionsReach })
            //#endregion
            //#region History Grid
            let optionHistory = { ...this.state.optionHistory }
            let { history } = result
            let unikeDates = []
            let openDatesCounts = [];
            let openDatesLabels = []
            let hotspotsDatesCounts = []
            
            history.map(element => unikeDates.push(element.Date))

            unikeDates = [...new Set(unikeDates)];

            unikeDates.map(element => {
                openDatesLabels.push(dateFormat(element, 'mmm dd'))
                let filter = history.filter(subElement => subElement.Date === element)
                let filterHotspot = filter.filter(element => element.type === 'Hotspot')
                let filterOpen = filter.filter(element => element.type === 'open')
                openDatesCounts.push(filterOpen.length > 0 ? filterOpen[0].counts : 0)
                hotspotsDatesCounts.push(filterHotspot.length > 0 ? filterHotspot[0].counts : 0)
            })
            
            optionHistory.series = [{
                name: 'Open Email',
                type: 'column',
                data: openDatesCounts
            }, {
                name: 'Hotspot Clicks',
                type: 'line',
                data: hotspotsDatesCounts
            }]

            optionHistory.labels = openDatesLabels

            this.setState({ optionHistory })
            //#endregion
            //#region Ages
            this.setState({ distinctAges: result.statisticDetailsAge ? (result.statisticDetailsAge.length > 0 ? G.getDistinctRecords(result.statisticDetailsAge[0], 'Ages', true) : []) : [] })
             let totalsAgeReach = 0; let totalsAgeOpen = 0

            if (result.statisticDetailsAge) {
                if (result.statisticDetailsAge.length > 0) {
                    result.statisticDetailsAge[0].map(element => {
                        if (element.Reached.includes('Reached')) { totalsAgeReach += element.ProfileCount }
                        if (element.Reached.includes('Open')) { totalsAgeOpen += element.ProfileCount }
                    })

                }
            }
            this.setState({ AgesReachTotal: totalsAgeReach, AgesOpenTotal: totalsAgeOpen })




             //#endregion
            //#region Country
            this.setState({ distinctCountry: result.statisticDetailsCountry ? (result.statisticDetailsCountry.length > 0 ? G.getDistinctRecords(result.statisticDetailsCountry[0], 'country', true) : []) : [] })
            let totalsCountryReach = 0; let totalsCountryOpen = 0
            if (result.statisticDetailsCountry) {
                if (result.statisticDetailsCountry.length > 0) {
                    result.statisticDetailsCountry[0].map(element => {
                        if (element.Reached.includes('Reached')) { totalsCountryReach += element.ProfileCount }
                        if (element.Reached.includes('Open')) { totalsCountryOpen += element.ProfileCount }
                    })

                }
            }
            this.setState({ CountryReachTotal: totalsCountryReach, CountryOpenTotal: totalsCountryOpen })




            
            //#endregion
            //#region City
            this.setState({ distinctCity: result.statisticDetailsCity ? (result.statisticDetailsCity.length > 0 ? G.getDistinctRecords(result.statisticDetailsCity[0], 'City', true) : []) : [] })
            let totalsCityReach = 0; let totalsCityOpen = 0
            if (result.statisticDetailsCity) {
                if (result.statisticDetailsCity.length > 0) {
                    result.statisticDetailsCity[0].map(element => {
                        if (element.Reached.includes('Reached')) { totalsCityReach += element.ProfileCount }
                        if (element.Reached.includes('Open')) { totalsCityOpen += element.ProfileCount }
                    })

                }
            }
            this.setState({ CityReachTotal: totalsCityReach, CityOpenTotal: totalsCityOpen })
            

             //#endregion

            //#region Reached Not Reached
            //#endregion
            //#region Reached Not Reached
            //#endregion

            this.setState({ loadingStatisticsSpinner: false })
        } catch (error) {
            alert(error)
        }


    }


    loadhotSpots = (record,campaignID) => {
        let hsData = this.state.selectedCampaignData.hotspotsDetails
        let genders = []; let countries = []; let cities = []; let ageRanges = [];
        if (hsData && hsData.length > 0) {
             
            hsData = hsData[0].filter(element => element.hotspotID === record.hotspotID)
            genders = hsData.filter(element => element.Gender === 'Gender')
            countries = hsData.filter(element => element.Gender === 'Country')
            cities = hsData.filter(element => element.Gender === 'City')
            ageRanges = hsData.filter(element => element.Gender === 'Age Range')
        }
        let sum = 0;
        genders.map(element => { sum += element.counts })

       
        return (<div className='mb-3' >
            <h5 className=' bold boxTopRadius5 cunlayer p-3 pt-1 '>{record.name}
                <p className='bold remarks'> {record.type==="0"?ts("Hyperlink",this.props.userLogin.language  ):"Email"}: <span className='normal '>{record.redirectionLink}</span></p>
                <h2 className='totalvisits'>{ts('Total visits',this.props.userLogin.language)}:{" "+sum.toLocaleString('en')}</h2>
                <a href={G.nodeServerSite + "exportHotspotCustomers?campaignID=" +campaignID+"&hotspotID="+record.hotspotID+"&hotspotName="+record.name+"&a=" + G.getUserToken()} target="_blank"  style={{color:"black"}} title={ts('Download customers', this.props.userLogin.language)}><i class="fas fa-file-download"></i></a>
            </h5>
            {hsData && hsData.length > 0 &&
                <div className='flexrow spacebetween col-12 '  >

                    <div>
                        <Badge variant='info' className='bold mb-2 sssttitle'> {ts("Countries",this.props.userLogin.language  )}</Badge>
                        {this.renderHotspotTable("Country", countries, sum)}
                    </div>
                    <div>
                        <Badge variant='danger' className='bold mb-2 sssttitle'>{ts("Cities",this.props.userLogin.language  )} </Badge>
                        {this.renderHotspotTable("City", cities, sum)}
                    </div>
                    <div>
                        <Badge variant='warning' className='bold mb-2 sssttitle'> {ts("Age Range",this.props.userLogin.language  )}</Badge>
                        {this.renderHotspotTable("Range", ageRanges, sum)}
                    </div>
                    <div >
                        <Badge variant='success' className='bold mb-2 sssttitle'> {ts("Gender",this.props.userLogin.language  )}</Badge>
                        {this.renderHotspotTable("Gender", genders, sum)}
                    </div>


                </div>}
            {hsData && hsData.length === 0 &&
                <div className='fullWidth flexcolumn hint p-3 text-center sstitle ' >

                    <i class="far fa-frown-open"></i>
                    <h6 className=' sssttile mt-2  '>{ts('No Visits Registered',this.props.userLogin.language)}</h6>
                    <h5 className=' sssttile  '>{record.name}</h5>

                </div>
            }

        </div>)
    }
    renderHotspotTable = (title, data, sum) => {
        return (<table className="table table-hover ml-3 remarks">
            <thead>
                <tr>
                    <th >{ts(title,this.props.userLogin.language)}</th>
                    <th colspan="2" className='cunlayer' >{ts('Visits',this.props.userLogin.language)}</th>

                </tr>
            </thead>
            <tbody>
                {
                    data && data.map(element =>
                        <tr>
                            <td className='bold'>{element.Name}</td>
                            <td className='cunlayer'>
                                {element.counts}
                            </td >
                            <OverlayTrigger overlay={<Tooltip>{ts('Percentage of Total Clicks',this.props.userLogin.language)}<p className='sremarks'>({G.countItems(sum, 'Click')})</p></Tooltip>}>
                                <td className='cunlayer'>{/*<Badge variant={Math.round(element.counts / sum * 100) > 75 ? 'success' : (Math.round(element.counts / sum * 100) > 20 ? 'warning' : 'danger')}>
                                    {Math.round(element.counts / sum * 100)}
                    %</Badge>*/}</td>
                            </OverlayTrigger>
                        </tr>
                    )
                }

            </tbody>
        </table>)
    }
    onChange = (e)=>{
        this.setState({campaigntype: e.target.value})
        
 let{ClientTemplates} = this.state;
 this.refreshData(this.props.userLogin.site.id,'','');
        
        
    }
    handleDateRangeChange = (range)=>{
        if(range!=undefined && range!=null && range[0]!=undefined && range[0]!="" && range[1]!=undefined && range[1]!="")
        {
            var startdate = new Date(range[0])
            var enddate = new Date(range[1])
            var starts = td(dateFormat(startdate,"yyyy-mm-dd"));
            var ends = td(dateFormat(enddate,"yyyy-mm-dd"))
             this.refreshData(this.props.userLogin.site.id, starts, ends)
        }
        else{
             this.refreshData(this.props.userLogin.site.id,'','')
        }
        
    }
    render() {
        const { fields, errors, selectedCampaign } = this.state;
        var _this = this;
        var now = Date.now();
        var filteredList = this.state.ClientTemplates.filter(element=>element.toBeSchedule==='1' && Date.parse(element.scheduleDate) - now>0);
        /*if (_this.props.userLogin && !this.state.doneLoadingCmp) {
            _this.loadCompanies()
        }*/
        const cmpData = this.state.selectedCampaignData;
            
            
        
        
        return (
            <>
            {/* added by youssef for section header */}
            <section className='content-header'>
        <div className='container-fluid'>
        <div className='row mb-2'>
            <div className='col-sm-6'>
                <NavLink to={{
        pathname:"/CustomerCampaigns",
        siteselected:this.props.siteselected
        }}> <Button  variant='success' className='mt-5 mb-4' >{ts('Create new campaign',this.props.userLogin.language)}</Button></NavLink>
            </div>
            </div>
            <div className='row'>
                <div className='col-sm-6'>
                    <h3>{ts("Campaigns History",this.props.userLogin.language)}&nbsp;&nbsp;
                    <DateRangePicker onChange={async (value)=>{await this.handleDateRangeChange(value)}}  placeholder={ts("Filter by date range", this.props.userLogin.language)} />
                    </h3>
                </div>
            </div>
        </div>
    </section>
     {/* added by youssef for content*/}
    <section className='content'>                        
{this.state.activeScreen === 0 &&
    <div className='row'>
        {/*<h5 className='mb-3 '>{ts("Available Campaigns List",this.props.userLogin.language  )}</h5>*/}
        {/*div className='col-md-6'>
        <Form.Group className='fullWidth '>
<Form.Label htmlFor='campaigntype' >{ts("Campaign type",this.props.userLogin.language)}</Form.Label>
<select className='border border-light"' className='custom-select' id='campaigntype'  name="campaigntype" onChange={this.onChange}>
<option value="">{ts2("View all",this.props.userLogin.language)}</option>
<option value="0">{ts2("Sent campaigns", this.props.userLogin.language)}</option>
<option value="1">{ts2("Upcoming campaigns", this.props.userLogin.language)}</option>
</select>
</Form.Group>

</div>*/}
    <div className='col-md-12'></div>   
    <div class="col-md-12">
            <div class="card card-primary card-outline card-outline-tabs">
              <div class="card-header p-0 border-bottom-0">
                <ul class="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" id="custom-tabs-three-home-tab" data-toggle="pill" href="#custom-tabs-three-home" role="tab" aria-controls="custom-tabs-three-home" aria-selected="false">Sent campaigns</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="custom-tabs-three-profile-tab" data-toggle="pill" href="#custom-tabs-three-profile" role="tab" aria-controls="custom-tabs-three-profile" aria-selected="false">Upcoming campaigns</a>
                  </li>
                  
                </ul>
              </div>
              <div class="card-body">
                <div class="tab-content" id="custom-tabs-three-tabContent">
                  <div class="tab-pane fade active show" id="custom-tabs-three-home" role="tabpanel" aria-labelledby="custom-tabs-three-home-tab">
                  <table id="examplestats" className="table table-bordered">  
<thead>
                  <tr>
                  <th>Action</th>
                                <th>{ts("Created In",this.props.userLogin.language  )}</th>
                                <th>{ts("Subject",_this.props.userLogin.language  )}</th>
                                {/*<th>Language</th>*/}
                                
                                 
                                {/* <th>{ts("Status",this.props.userLogin.language  )}</th> */}
                                <th>{ts("Created By",_this.props.userLogin.language  )}</th>

                                
                  </tr></thead>{!_this.state.loadTemplates && <tbody>
                            {_this.state.ClientTemplates &&
                                (_this.state.ClientTemplates).filter(element=>element.toBeSchedule==='0').map((item) => {

                                    return (
                                        <tr key={item.id}>

                                            <td className='xxxtitle shiteColor handCursor'
                                                onClick={() => { this.loadStatitics(item);history.push("/CampaignStatistics/details?id=" + item.id) }}>
                                                <Badge variant='info'>{ts("View Details",_this.props.userLogin.language  )}</Badge>
                                            </td>
                                            <td>{item.creationDate ? td(dateFormat(item.creationDate, 'dddd, mmm dd, yyyy hh:MM tt'),this.props.userLogin.language  ) : ' - '}</td>
                                            <td>{item.subject}</td>
                                            {/*<td>fr</td>*/}
                                             
                                             
                                            {/* <td>{item.status === 0 ? ts('Not Closed',this.props.userLogin.language  ) : ts('Closed',this.props.userLogin.language  )}</td> */}
                                            <td>{item.creationUser} </td>





                                        </tr>)
                                })
                            }

                        </tbody>}
                </table>
                  </div>
                  <div class="tab-pane fade" id="custom-tabs-three-profile" role="tabpanel" aria-labelledby="custom-tabs-three-profile-tab">
                  <table id="example2" className="table table-bordered">
<thead>
                  <tr>
                  <th>Action</th>
                                <th>{ts("Created In",this.props.userLogin.language  )}</th>
                                <th>{ts2("Date & time", _this.props.userLogin.language)}</th>
                                <th>{ts("Subject",_this.props.userLogin.language  )}</th>
                                {/*<th>Language</th>*/}
                                
                                 
                                {/* <th>{ts("Status",this.props.userLogin.language  )}</th> */}
                                <th>{ts("Created By",_this.props.userLogin.language  )}</th>

                                
                  </tr></thead>{!_this.state.loadTemplates && <tbody>
                            {filteredList &&
                                (filteredList).map((item) => {

                                    return (
                                        <tr key={item.id}>

                                            <td className='xxxtitle shiteColor handCursor'
                                                onClick={() => { this.loadStatitics(item);history.push("/CampaignStatistics/details") }}>
                                                <Badge variant='info'>{ts("View Details",_this.props.userLogin.language  )}</Badge>
                                            </td>
                                            <td>{item.creationDate ? td(dateFormat(item.creationDate, 'dddd, mmm dd, yyyy hh:MM tt'),this.props.userLogin.language  ) : ' - '}</td>
                                            <td>{item.scheduleDate ? td(dateFormat(item.scheduleDate, 'dddd, mmm dd, yyyy hh:MM tt'),_this.props.userLogin.language  ) : ' - '}</td>
                                            <td>{item.subject}</td>
                                            {/*<td>fr</td>*/}
                                             
                                             
                                            {/* <td>{item.status === 0 ? ts('Not Closed',this.props.userLogin.language  ) : ts('Closed',this.props.userLogin.language  )}</td> */}
                                            <td>{item.creationUser} </td>


                                            



                                        </tr>)
                                })
                            }

</tbody>}</table>
                
                  </div>
                  
                </div>
              </div>
            </div>
          </div>


<div className="card-body">

              </div>
              

        

        {this.state.loadTemplates &&
            <div className='flexcolumn center verticalcenter p-5 fullWidth hint col-12 '>
                {/*changed by youssef to meet the site selected */}
                {/*{ts("Loading Statistics List",this.props.userLogin.language  )} {this.state.activeCompany ? ts("for ",this.props.userLogin.language  ) + this.state.activeCompany.companyName : ''}*/}
                {ts("Loading Statistics List",this.props.userLogin.language  )} {this.props.userLogin.site ? ts("for ",this.props.userLogin.language  ) + this.props.userLogin.site.companyName : ''}
                <Spinner animation="border" className='m-3' variant="warning" />

            </div>
        }

    </div>
}

{this.state.activeScreen === 1 && (
    this.state.loadingStatisticsSpinner ?
        <div className='flexcolumn verticalcenter  text-center col-12' style={{ marginTop: '25%' }}>
            <div className='flexrow center fullWidth '>
                <Spinner animation="grow" className='m-3' variant="warning" />
                <Spinner animation="grow" className='m-3' variant="info" />
                <Spinner animation="grow" className='m-3' variant="danger" />

            </div>
            <div className='hint remarks xlowalpha'>Loading Data</div>
        </div>

        :


        <div className='col-12'>

            <Button className='mb-5' variant='warning' onClick={() => {this.setState({loadTemplates: true});this.setState({activeScreen:0});history.go(-1);
        setTimeout(function(){
            if(document.getElementById("dttablestats")!=null)
            {
                document.getElementById("dttablestats").remove();
            }
            const script = document.createElement("script");
            script.id = "dttablestats";
            script.src = '/dist/js/dttablestats.js';
            script.async = true;
            document.body.appendChild(script);
            
            _this.setState({loadTemplates: false})
        },100)
        }}>{ts('Cancel / Return',this.props.userLogin.language  )}</Button>
            <h5 className=' p-0'>{selectedCampaign.subject}</h5>
            <p style={{ marginTop: -3 }} className=' hint p-0 mb-5'><Badge variant='info'>{ts('Campaign ID',this.props.userLogin.language  ) + ' - ' + selectedCampaign.id}</Badge> 
            <Badge variant='dark ml-2'>{ts('Applied By',this.props.userLogin.language  ) + ' ' + selectedCampaign.creationUser + ts(" in ",this.props.userLogin.language) + td(dateFormat(selectedCampaign.creationDate, "dddd, mmmm dd, yyyy hh:MM tt"),this.props.userLogin.language  )}</Badge></p>

            <div className='row'>

                {this.renderButton(ts('Hotspots Found',this.props.userLogin.language  ), cmpData.hotspots !== undefined ? cmpData.hotspots.length : ' - ', <span className="info-box-icon bg-info"><i class="fas fa-mouse" /></span>, () => {  })}
                {this.renderButton(ts('Target Customers',this.props.userLogin.language  ), cmpData.actions !== undefined ? cmpData.actions[0].total : ' - ', <span className="info-box-icon bg-success"><i className="fas fa-address-book" /></span>, () => {   },<a href={G.nodeServerSite + "exportTargetCustomers?campaignID=" +selectedCampaign.id+"&a=" + G.getUserToken()} target="_blank"  style={{position:"absolute", right:"10px", bottom:"0px", color:"black"}} title={ts('Download customers', this.props.userLogin.language)}><i class="fas fa-file-download"></i></a>)}
                {this.renderButton(ts('Reached Emails',this.props.userLogin.language  ), cmpData.actions != null ? cmpData.actions[0].Reach : ' - ', <span className="info-box-icon bg-warning"><i class="far fa-thumbs-up"></i></span>, () => {   },<a href={G.nodeServerSite + "exportReachedEmails?campaignID=" +selectedCampaign.id+"&a=" + G.getUserToken()} target="_blank"  style={{position:"absolute", right:"10px", bottom:"0px", color:"black"}} title={ts('Download customers', this.props.userLogin.language)}><i class="fas fa-file-download"></i></a>)}
                {this.renderButton(ts('Opened Emails',this.props.userLogin.language  ), cmpData.actions != null ? cmpData.actions[0].Open : ' - ', <span className="info-box-icon bg-danger"><i class="far fa-envelope-open"></i></span>, () => {  },<a href={G.nodeServerSite + "exportOpenedEmails?campaignID=" +selectedCampaign.id+"&a=" + G.getUserToken()} target="_blank"  style={{position:"absolute", right:"10px", bottom:"0px", color:"black"}} title={ts('Download customers', this.props.userLogin.language)}><i class="fas fa-file-download"></i></a>)}
                {this.renderButton(ts('Total hotspots clicks',this.props.userLogin.language  ), this.state.totalclicks, <span className="info-box-icon bg-danger"><i class="fa fa-mouse-pointer"></i></span>, () => {  })}
            </div>

            <div className='  stitle bold p-3 pb-0 mt-5  '>{ts('Hotspots Statistics',this.props.userLogin.language  )}
                <p className='  ssstitle p-2  '><Badge className=' ssstitle' variant='warning'>{G.countItems(cmpData.hotspots ? cmpData.hotspots.length : 0, ts('Hotspots Available',this.props.userLogin.language))}</Badge> </p>
            </div>


            <div className='flexrow fullWidth' >
                <div className=' col-12  '>
                    {

                        cmpData && cmpData.hotspots &&
                        cmpData.hotspots.map(element => {
                            return (
                                <div>
                                    {this.loadhotSpots(element,selectedCampaign.id)}
                                </div>
                            )
                        })


                    }

                </div>
            </div>


            <div className='flexrow spacebetween fullWidth pt-5 pb-5 ' >
                <div className='col-8'>
                    <p className='ssstitle bold mb-3  '>{ts('Customers Reaction History',this.props.userLogin.language)}</p>
                    <div   >
                        <Chart options={this.state.optionHistory} series={this.state.optionHistory.series} type="line" width='100%' />
                    </div>
                </div>
                <div className='pl-4 flexcolumn col-4'>
                    <p className='ssstitle bold'>{ts('Customers Reach/Open Summary',this.props.userLogin.language)} </p>
                    <span><Badge variant='dark' className='cunlayer mb-3' >{ts('Total Customers Target is',this.props.userLogin.language)} {cmpData.actions !== undefined ? cmpData.actions[0].total : ' - '}</Badge></span>
                    <Chart options={this.state.optionsReach} series={this.state.optionsReach.series} type="pie" width="380" />
                    <Chart options={this.state.optionsOpen} series={this.state.optionsOpen.series} type="donut" width="380" />

                </div>
            </div>

            <p className='  stitle bold p-3 pb-0  '>{ts('Customers Reaction Statistics',this.props.userLogin.language)}</p>
            <div className='flexrow  spacebetween fullWidth col-12  '  >
                <div className=' mr-2 fullWidth ' >
                    <p className='  ssstitle bold boxTopRadius5 cunlayer   p-3   '> {ts('Ages Statistics',this.props.userLogin.language)} </p>
                    <div style={{ maxHeight: 500, overflow: 'auto' }}>
                        {this.renderGrid(ts('Age Ranges',this.props.userLogin.language), this.state.distinctAges)}

                    </div>
                </div>
                <div className='flexcolumn  col-3 ' >
                    <p className='ssstitle bold   boxTopRadius5 p-3 cunlayer  '>{ts('Gender Statistics',this.props.userLogin.language)} </p>
                    <div className='card   ' >
                        {this.renderGenderRow("Reached Emails", cmpData.statisticDetailsGender)}
                        {this.renderGenderRow("Unreached Emails", cmpData.statisticDetailsGender)}
                        {this.renderGenderRow("Opened Emails", cmpData.statisticDetailsGender)}
                        {this.renderGenderRow("Unopened Emails", cmpData.statisticDetailsGender)}
                    </div>

                </div>


            </div>
            <p className='  ssstitle bold mt-5 boxTopRadius5  cunlayer  p-3  '>{ts('Countries Statistics',this.props.userLogin.language)} </p>
            <div className=' mr-2 fullWidth ' style={{ maxHeight: 900, overflow: 'auto' }}>
                {this.renderGrid(ts('Countries',this.props.userLogin.language), this.state.distinctCountry, 1)}

            </div>
            <p className='  ssstitle bold  mt-5 boxTopRadius5 cunlayer  p-3  '>{ts('Cities Statistics',this.props.userLogin.language)} </p>
            <div className=' mr-2 fullWidth ' style={{ maxHeight: 900, overflow: 'auto' }}>
                {this.renderGrid(ts('Cities',this.props.userLogin.language), this.state.distinctCity,2)}

            </div>


            




        </div>)
}

            
    </section>
    
                {/*commented by youssef -{this.state.activeScreen == 0 && <div className='p-3 mr-1  col-2 '>
                    <h5 className='mb-3'>{ts("Clients List",this.props.userLogin.language  )}</h5>
                    {this.state.loadCompany &&
                        <div className='flexcolumn center verticalcenter p-5 fullWidth hint '>
                            {ts2('Loading Companies', this.props.userLogin.language)}
                            <Spinner animation="border" className='mt-3' variant="warning" />

                        </div>
                    }
                    {!this.state.loadCompany && this.state.allCompanies.map(element => {
                        return (
                            <p key={element.id} className={this.state.activeCompany.companyName === element.companyName ? ' handCursor ctheme boxRadius5 p-2 whiteColor  ' : ' handCursor '}
                                onClick={() => { this.listTemplates(element.id, element) }}>
                                {element.companyName}
                            </p>
                        )
                    })}
                </div>}*/}

                {/*{this.state.activeScreen == 0 && <div className='miniContentHeight colSupLigGra pl-1 mt-5 mb-5 ml-2 mr-2'></div>}*/}
            </>
        )
    }
}
//#region "Redux"
const mapStateToProps = state => {

    return {
        userLogin: state.SaveLogin.userData
    };
};
export default connect(
    mapStateToProps
)(campaignStatistics)
//#endregion
