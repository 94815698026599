import React, { Component } from 'react';
import { Badge, Container, Spinner } from 'react-bootstrap'
import '../styles/glob.css';
import * as G from '../components/global';
import { connect } from 'react-redux';
import 'rsuite/dist/rsuite.min.css';
import {DateRangePicker} from 'rsuite';
import { translateScreen as ts , translateScreen2 as ts2 , translateDate as td} from '../components/dictionary'
import { Chart } from "react-google-charts";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import dateFormat from 'dateformat';
class mainContent extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            startdate:null,
            enddate:null,
            stats:{},
            nodemographics:false
        }

    }
    
    getSnapshotBeforeUpdate(prevProps) {
        
        return { notifyRequired: prevProps.userLogin !== this.props.userLogin };
      }
      
      async componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot.notifyRequired === true) {
            this.settleDate();
            var _this = this;
            setTimeout(function(){
                if(_this.props.userLogin)
                    _this.getData();
            },500);
        }
      }
    componentDidMount() {
        this.settleDate();
        var _this = this;
        setTimeout(function(){
            if(_this.props.userLogin)
                _this.getData();
        },500);
        
    }
    settleDate = ()=>{
        var sdate = localStorage.getItem("dashboardStartDate");
        var edate = localStorage.getItem("dashboardEndDate");
        var myCurrentDate=edate?new Date(edate):new Date();
        var myPastDate=sdate?new Date(sdate):new Date(myCurrentDate);
        if(!sdate)
            myPastDate.setDate(myPastDate.getDate() - 7);
        this.setState({startdate:myPastDate, enddate:myCurrentDate});
    }
        exportToCSV = (apiData, fileName) => {
            const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
        const ws = XLSX.utils.json_to_sheet(apiData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
      };
    getData = async()=>{
        var startdate = new Date(this.state.startdate)
            var enddate = new Date(this.state.enddate)
                var starts = td(dateFormat(startdate,"yyyy-mm-dd"));
                var ends = td(dateFormat(enddate,"yyyy-mm-dd"))
                var data = [["startdate",starts],["enddate",ends]];
                console.log("UserLogin",this.props.userLogin)
                if (this.props.userLogin && this.props.userLogin.site)
                { 
                    data.push(["siteid", this.props.userLogin.site.id])
                    /*var accountType = this.props.userLogin.accountType
                    if(accountType>0)
                    {
                        if(accountType == 1)
                        {
                            data.push(["groupid", this.props.userLogin.groupid])
                        }
                        else if(accountType == 2)
                        {
                            data.push(["siteid", this.props.userLogin.site.id])
                        }
                    }*/
                }
        var results = await G.getData("dashdoard/stats",data)
        results = await results.data;
        console.log(results)
        if(results.genderdemographics[1][1] == 0 && results.genderdemographics[2][1] == 0 && results.genderdemographics[3][1] == 0)
        {
            this.setState({nodemographics:true})
        }
        this.setState({stats:results});
    }
    handleDateRangeChange = async(range)=>{
        /*var company = this.props.userLogin.site.id;
        let result = await getData('campaigntemplate/query', [['filter', 'clientID = ' + company + ' and creationDate>' + startdate]]);
        result = await result.data;
        this.setState({ ClientTemplates: result })
        this.setState({ loadingTemplates: false })*/
        if(range!=undefined && range!=null && range[0]!=undefined && range[0]!="" && range[1]!=undefined && range[1]!="")
        {
            var startdate = new Date(range[0])
            var enddate = new Date(range[1])
            localStorage.setItem("dashboardStartDate",range[0]);
            localStorage.setItem("dashboardEndDate",range[1])
            this.setState({ startdate:startdate, enddate:enddate });
            var _this = this;
            setTimeout(function(){
                _this.getData();
            },500);
            
        }
        else{
            this.settleDate();
            var _this = this;
            setTimeout(function(){
                _this.getData();
            },500);
            
        }
        
        
    }
    renderCard = (title, type, data, options, height="400px", shownodata = false)=>{
        return(
<div className="card">
<div className="card-body">
    <div style={{float:"right"}}>
        {data && <a className='handCursor' onClick={()=>{this.exportToCSV(data,title)}}><span className='fa fa-download' style={{color:"gray"}}></span></a>}
    </div>
<h5>{title} </h5>
{shownodata == true && <div style={{height:"620px", width:"100%", textAlign:"center", paddingTop:"50%"}}>No data available</div>}
{!shownodata && data && <Chart
      chartType={type}
      width="100%"
      height={height}
      style={{marginTop:"20px"}}
      data={data}
      options={options}
      legendToggle={true}
      legend_toggle={true}
    />}
    {!data && <center style={{paddingTop:"20px"}}>Loading...</center>}
</div>


</div>)
    }


    render() {
        

        let i = 0;
        let accountType = 10;
        if (this.props.userLogin) accountType = this.props.userLogin.accountType;
        
        return (
            <>
            <div className='content-header'>
                                <div className='container-fluid'>
                                    <div className='row mb-2'>
                                        
                                        <div className='col-sm-12'>{this.state.startdate &&<DateRangePicker cleanable={false} placement="auto" style={{float:"right", width:"300px"}} onChange={(value)=>{this.handleDateRangeChange(value)}} defaultValue={[this.state.startdate, this.state.enddate]}   />}</div>
                                    </div>
                                </div>
                            </div>
                            <section className='content'> 
            <div className='row'>
                <div className='col-md-7'>
                {this.renderCard(this.props.userLogin && ts("Number of scans count per day for selected period", this.props.userLogin.language), "Line",this.state.stats && this.state.stats.scanscountperday,{legend: {position:'left'}})}
                </div>
                <div className='col-md-5'>
                {this.renderCard(this.props.userLogin && ts("Total number of scans per selected period", this.props.userLogin.language), "BarChart",this.state.stats && this.state.stats.totalnumberscans,{legend: {position:'bottom'}})}
                
                </div>
            </div>
            <div className='row'>
                <div className='col-md-7'>
                {this.renderCard(this.props.userLogin && ts("Number of distinct user scanning per day for selected period", this.props.userLogin.language), "Line",this.state.stats && this.state.stats.distinctusersperday,{legend: {position:'left'}})}
                
                </div>
                <div className='col-md-5'>
                {this.renderCard(this.props.userLogin && ts("Total number of distinct user scanning per selected period", this.props.userLogin.language), "BarChart",this.state.stats && this.state.stats.totalnumberdistinctusersscans,{legend: {position:'bottom'}})}
                </div>
            </div>
            <div className='row'>
                <div className='col-md-7'>
                {this.renderCard(this.props.userLogin && ts("Spending per day (EUR)", this.props.userLogin.language), "Line",this.state.stats && this.state.stats.spendingperday,{legend: {position:'left'}})}
                </div>
                <div className='col-md-5'>
                {this.renderCard(this.props.userLogin && ts("Total scanned amount per selected period (EUR)", this.props.userLogin.language), "BarChart",this.state.stats && this.state.stats.totalspending,{legend: {position:'bottom'}})}
                
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6'>
                {this.renderCard(this.props.userLogin && ts("Scans by store per selected period", this.props.userLogin.language), "Table",this.state.stats && this.state.stats.scansbystore,{title: "Scan count by store",
  curveType: "function",
  legend: { position: "bottom" },
  pageSize: 10,width:"100%",legend: {position:'left'}})}
                
                </div>
                <div className='col-md-6'>
                {this.renderCard(this.props.userLogin && ts("Scan count by day of week per selected period", this.props.userLogin.language), "BarChart",this.state.stats && this.state.stats.scansperdayofweek,{legend: {position:'bottom'}})}
                
                </div>
            </div>
            <div className='row'>
                <div className='col-md-7'>
                {this.renderCard(this.props.userLogin && ts("Scanners age demographics per selected period", this.props.userLogin.language), "BarChart",this.state.stats && this.state.stats.agedemographics,{legend: {position:'bottom'}}, "600px")}
                
                </div>
                <div className='col-md-5'>
                {this.renderCard(this.props.userLogin && ts("Scanners Gender demographics", this.props.userLogin.language), "PieChart",this.state.stats && this.state.stats.genderdemographics,{legend: {position:'bottom'}}, "600px", (this.state.stats && this.state.stats.genderdemographics &&(this.state.stats.genderdemographics[1][1] == 0 && this.state.stats.genderdemographics[2][1] == 0 && this.state.stats.genderdemographics[3][1] == 0)))}
                </div>
            </div>
            </section></>
        )
    }
}
//#region "Redux"
const mapStateToProps = state => {

    return {
        userLogin: state.SaveLogin.userData
    };
};
export default connect(
    mapStateToProps
)(mainContent)
//#endregion


