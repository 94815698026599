import * as G from './global'
const dictionaryList = [
    ['Redeem Promo','Utiliser la promotion'],
    ['Redeem Gifts','Échangez cadeaux'],
    ['Remove','Éliminer'],
    ['Full Screen', 'Plein Ecran'],
    ['Log Out', 'Déconnecter'],
    ['My Dashboards', 'Tableaux De Bord'],
    ['Summary', 'Résumé'],
    ['Third Parties', 'Tiers'],
    ['Client Registration', 'Inscription Des Clients'],
    ['User Management', 'Gestion Des Utilisateurs'],
    ['Customer Management', 'Gestion Des Clients'],
    ['Campaigns', 'Campagnes'],
    ['Campaigns Templates', 'Modèles De Campagnes'],
    ['Campaigns Statistics', 'Statistiques Des Campagnes'],
    ['Hotspot Categories', 'Catégories Hotspot'],
    ['Data Import/Export', 'Importation/Exportation'],
    ['Import Customers', 'Importation Des Clients'],
    ['Export Customers', 'Exportation Des Clients'],
    ['Log', 'Journal'],
    ['Log History', "Historique D'accès"],
    ['Password', 'Mot De Passe'],
    ['Change My Password', 'Changer Mon Mot De Passe'],
    ["Create New Template", "Créer un nouveau modèle"],
    ["Change Template", "Changer de modèle"],
    ['Clients List', 'Liste des clients'],
    ['Available Campaigns List', 'Liste des campagnes disponibles'],
    ['Available Campaigns', 'Liste des campagnes disponibles'],
    ['Created In', 'Créé en'],
    ['Creation Date', "Date de création"],
    ['Subject', 'Sujet'],
    ['Status', 'Statut'],
    ['Created By', 'Créé par'],
    ['Template Created By', 'Modèle créé par'],
    ['No Campaigns Available yet', 'Aucune campagne disponible pour le moment'],
    ['Send Your First Campaign', 'Envoyez votre première campagne'],
    ['Loading Statistics List', 'Chargement de la liste des statistiques'],
    ['for ', 'pour '],
    ['View Details', 'Voir les détails'],
    ['Not Closed', 'Ouverte'],
    ['Closed', 'Fermé'],
    ['Cancel / Return', 'Annuler / Retour'],
    ['Cancel', 'Annuler'],
    ['Campaign ID', 'Campagne ID'],
    ['Applied By', 'Appliqué par'],
    [' in ', ' en '],
    ['Hotspots Found', 'Hotspots Trouvé'],
    ['Target Customers', 'Clients cibles'],
    ['Reached Emails', 'E-mails atteints'],
    ['Opened Emails', 'E-mails ouverts'],
    ['Hotspots Statistics', 'Statistiques des Hotspot'],
    ['Hotspots Available', 'Hotspot disponible'],
    ['Hyperlink', 'Lien hypertexte'],
    ['Country', 'Pays'],
    ['City', 'Ville'],
    ['Countries', 'Pays'],
    ['Cities', 'Ville'],
    ['Age Range', "Tranche d'âge"],
    ['Age Ranges', "Tranche d'âges"],
    ['Gender', 'Genre'],
    ['No Visits Registered', 'Aucune visite enregistrée'],
    ['visits', 'Visites'],
    ['Percentage of Total Clicks', 'Pourcentage du nombre total de clics'],
    ['Visits', 'Visites'],
    ['Range', 'Tranche'],
    ['Customers Reaction History', 'Historique des réactions des clients']
    , ['Number of Opens', "Nombre d'ouvertures"]
    , ['Number of Hotspots Clicks', "Nombre de clics sur Hotspot"]
    , ['Customers Reach/Open Summary', 'Récapitulatif des clients atteints/ouverts']
    , ['Total Customers Target is', 'La cible totale de clients est']
    , ['Customers Reaction Statistics', 'Statistiques de réaction des clients']
    , ['Ages Statistics', 'Âges Statistiques']
    , ['Gender Statistics', 'Statistiques de genre']
    , ['Reached', 'Atteint']
    , ['Not Reached', 'Non Atteint']
    , ['Opened', 'Ouvert']
    , ['Not Opened', 'Pas ouvert']
    , ['No records', "Pas d'enregistrements"]
    , ['Customer', 'client']
    , ['Males', 'Mâles']
    , ['Females', 'Femelles']
    , ['Male', 'Mâle']
    , ['Female', 'Femelle']
    , ['Unreached Emails', 'E-mails non atteints']
    , ['Unopened Emails', 'E-mails non ouverts']
    , ['Countries Statistics', 'Statistiques des pays']
    , ['Cities Statistics', 'Statistiques des villes']
    , ['Select a client to view their email campaign templates', 'Sélectionnez un client pour afficher ses modèles de campagne']
    , ['Client Name', 'Nom du client']
    , ['Contact Person', 'Personne de contact']
    , ['Phone', 'Téléphone']
    , ['Email Address', 'Adresse e-mail']
    ,['Email address','Adresse email']
    , ['Number of Campaigns', 'Nombre de campagnes']
    , ['View Profiles', 'Voir les profils']
    , ['Saved Campaigns List', 'Liste des campagnes enregistrées']
    , ['Loading templates for', 'Chargement des modèles pour']
    , ['No Templates available for', 'Aucun modèle disponible pour']
    , ['Designed Templates', 'Modèles conçus']
    , ['Available Clients', 'Clients disponibles']
    , ['Send Campaigns', 'Envoyer campagnes']
    , ['Create Client First Campaign Template', "Créer un premier modèle de campagne pour le client"]
    , ['Loading Clients List', 'Chargement de la liste des clients']
    , ['Template not found', 'Modèle introuvable']
    , ['Select template from the available list', 'Sélectionnez le modèle dans la liste disponible']
    , ['Name', 'Nom']
    , ['Customers Selection', 'Sélection des clients']
    , ['Filter Based on Customers Definition', 'Filtre basé sur les données démographiques et le comportement des clients']
    , ['Filter Example 18-24,35, 50-54', 'Exemple de filtre 18-24,35, 50-54']
    , ['Filter Example 18-24,35,50-54 is equal for (all ages between 18 and 24) or (age equal 35) or (age between 50 and 54)', 'Exemple de filtre 18-24,35,50-54 est égal pour (tous les âges entre 18 et 24) ou (âge égal 35) ou (âge entre 50 et 54)']
    , ['Add Countries Filter Here', 'Ajouter un filtre de pays ici']
    , ['Add Cities Filter Here', 'Ajouter un filtre de ville ici']
    , ['All Ages', 'Tous les ages']
    , ['Available', 'Disponible']
    , ['All Countries Selected', 'Tous les pays sélectionnés']
    , ['All Cities Selected', 'Toutes les villes sélectionnés']
    , ['Filter Based on Previous Campaigns Statistics', 'Filtre basé sur les statistiques des campagnes précédentes']
    , ['Previous Campaigns Filters', 'Filtres des campagnes précédentes']
    , ['Previous Hotspots Filters', 'Filtres des Hotspots précédentes']
    , ['No Filters Added yet', 'Aucun filtre ajouté pour le moment']
    , ['Filter Emails Reach Status', "Filtrer l'état de la portée des e-mails"]
    , ['Filter Emails Open Status', "Filtrer les e-mails Statut d'ouverture"]
    , ['Add Filter', 'Ajouter un filtre']
    , ['Add A filter from Previous Campaigns', 'Ajouter un filtre à partir des campagnes précédentes']
    , ['Add A filter from Previous Hotspots', 'Ajouter un filtre à partir des hotspots précédentes']
    , ['Filter Based on Hotspots Statistics', "Filtre basé sur les statistiques des Hotspots"]
    , ['Getting Campaigns List', 'Obtenir la liste des campagnes']
    , ['No customers selected for this campaign. Please click below to view filtered customers', "Aucun client sélectionné pour cette campagne. Veuillez cliquer ci-dessous pour afficher les clients filtrés"]
    , ['No result available with these filters, or no customers available in', "Aucun résultat disponible avec ces filtres, ou aucun client disponible dans"]
    , ['Query Profiles', 'Rechercher les Profils']
    , ['Query Profiles Again', 'Rechercher les Profils à nouveau']
    , ['Total Profiles Found in ', 'Nombre total de profils trouvés dans ']
    , ['Profile', 'Profil']
    ,['Subscribed Profile','Profils Souscrit']
    ,['Unsubscribed Profile','Profils désabonné']
    , ['Found', 'Trouvé']
    , ['Hide Profiles List', 'Cacher la liste des profils']
    , ['Show Profiles List', 'Afficher la liste des profils']
    , ['Previous', 'Précédente']
    , ['Next', 'Suivante']
    ,["Your campaign is being submitted.","Votre campagne est entrain d'être envoyée."]
    , ['Your campaign has been successfully sent. You will receive an email when the process will be finished.', 'Votre campagne a été envoyée avec succès. Vous recevrez un e-mail lorsque le processus sera terminé.']
    , ['Campaign results and analytics will appear in ', 'Les résultats de la campagne et les analyses apparaîtront dans ']
    , ['Return', 'Retour']
    , ['Confirm and send campaign', 'Confirmer et envoyer la campagne']
    , ['Start Campaign Now', 'Commencer la campagne maintenant']
    , ['Available Hotspots', 'Les Hotspots disponibles']
    , ['First Name', 'Prénom']
    , ['Last Name', 'Nom']
    , ['Registration Date', "Date d'inscription"]
    , ['Company Name', "Nom de l'entreprise"]
    , ['Phone Number', "Numéro de téléphone"]
    , ['Username', "Nom d'utilisateur"]
    , ['Full Name', "Nom complet"]
    , ['User Type', "Type d'utilisateur"]
    , ['Password', "Mot de passe"]
    , ['Confirm Password', "Confirmez le mot de passe"]
    , ['Enter the birthdate', "Entrez la date de naissance"]
    , ['New Password', "Nouveau mot de passe"]
    ,['Some emails already available. Data will be overwritten','Certains e-mails déjà disponibles. Les données seront écrasées']
    , ['Template Name', "Nom du modèle"]
    , ['Template Subject', "Sujet De Modèle"]
    , ['Template Language', "Langue Du Modèle"]
    , ['Mobile', "Téléphon"]
    , ['Hotspot Name', "Nom du hotspot"]
    , ['Hotspot Category', "Categorie de hotspot"]
    , ['Enter hotspot Name', "Entrez le nom du hotspot"]
    , ['Enter Hyperlink', "Entrez le lien hypertexte"]
    , ['Create Hotspot', "Créer Hotspot"]
    , ['Are you sure you want to close and return to the previous page?', "Voulez-vous vraiment fermer et revenir à la page précédente ?"]
    , ['Close Wihout Save', "Fermer sans enregistrer"]
    , ['Stay', "Rester"]
    , ['Are you sure you want to close and return to the previous page?', "Voulez-vous vraiment fermer et revenir à la page précédente ?"]
    , ['Close Wihout Save', "Fermer sans enregistrer"]
    , ['Stay', "Rester"]
    , ['Confirm Change Password', "Confirmer le changement de mot de passe"]
    , ['Sender Email', "Courriel de l'expéditeur"]
    , ['Enter the sender email address', "Entrez l'adresse e-mail de l'expéditeur"]
    , ['Change Password', "Changer le mot de passe"]
    , ['Are you sure you want to delete profile', "Êtes-vous sûr de vouloir supprimer le profil"]
    ,['Click to Copy Link','Cliquez pour copier le lien']
    ,['Click to Copy Parameter','Cliquez pour copier le paramètre'],
    ['Schedule Date','Date du programme'],
    ['Campaign type','Type de campagne'],
    ['Select','Sélectionner'],
    ['Category ID will be generated Automatically','L’ID sera généré automatiquement'],
    ['Add the Category Name Here','ajoutez le nom de la catégorie ici'],
    ['Company name','Nom de la compagnie'],
    ['Email Templates','Email Templates'],
    ['Edit template','Changer de modèle'],
    ['Yes','Oui'],
    ['No','No'],
    ['Campaigns History','Historique des campagnes'],
    ['Create new campaign','Ajouter une nouvelle compagne'],
    ['Total visits','Visites totales'],
    ['Hotspots','Hotspots'],
    ['Newest to oldest','Du plus récent au plus ancien'],
    ['Oldest to newest','Du plus ancien au plus récent'],
    ['Archived templates','Modèles archivés'],
    ['Filter by date range','Filtrer par plage de dates'],
    ['Gifts','Cadeaux'],
    ['Scanned tickets','Billets scannés'],
    ['Promo codes','Promo codes'],
    ['Competition','Compétition'],
    ['Settings','Settings'],
    ['Tickets scanning','Numérisation des billets'],
    ['Points & Tokens','Points et jetons'],
    ['Ranking','Classement'],
    ['Badges','Badges'],
    ['Avatars','Avatars'],
    ['Gifts categories','Catégories de cadeaux'],
    ['Promo code categories','Catégories de promo codes'],
    ['Gift details','Détails du cadeau'],
    ['Gift info','Info cadeaux'],
    ['Points conversion rate','Taux de conversion des points'],
    ['Tokens conversion rate','Taux de conversion des jetons'],
    ['Edit hotspot category interactions for this customer',`Modifier les interactions des catégories de points d'accès pour ce client`],
    ['Yes','Oui'],
    ['Add number of points','Ajouter le nombre de points'],
    ['Changes are saved','Les modifications sont enregistrées'],
    ["Already have an account?","Vous avez déjà un compte?"],
    ["Login now","Connectez-vous"],
    ["Information on the company to be invoiced","Informations sur l'entreprise à facturer"],
    ["Street number and name","Numéro et nom de la rue"],
    ["Enter street number and name","Entrez le numéro et le nom de la rue"],
    ["Postal code","Code postal"],
    ["Enter postal code","Entrez le code postal"],
    ["Information of contact person","Informations sur la personne de contact"],
    ["Company phone number","Numéro de téléphone de l'entreprise"],
    ["VAT number","Numéro de TVA"],
    ["Enter VAT number","Entrez le numéro de TVA"],
    ["I've read and accepted the","J'ai lu et accepté les"],
    ["Terms & conditions","termes et conditions"],
    ["Please accept terms and conditions","Veuillez accepter les termes et conditions"],
    ["This field is required","Ce champ est obligatoire"],
    ["Terms and conditions content","Contenu des termes et conditions"],
    ["Email address where to send invoices","Adresse email où envoyer les factures "],
    ["Enter city","Entrez la ville"],
    ['needed tokens','jetons nécessaires'],
    ['enter number of tokens','entrez le nombre de jetons'],
    ['Create New Competition','Créer une Nouvelle Compétition'],
    ["Search","Chercher"],
    ["Address combinations","Combinaisons d'adresses"],
    ["Add","Ajouter"],
    ["Number of scans count per day for selected period","Nombre de scans par jour pour la période sélectionnée"],
    ["Total number of scans per selected period","Nombre total d'analyses par période sélectionnée"],
    ["Number of distinct user scanning per day for selected period","Nombre d'utilisateurs distincts scannant par période sélectionnée"],
    ["Total number of distinct user scanning per selected period","Nombre total d'utilisateurs distincts scannant par période sélectionnée"],
    ["Spending per day (EUR)","Dépenses par jour (EUR)"],
    ["Total scanned amount per selected period (EUR)","Montant total scanné par période sélectionnée (EUR)"],
    ["Scans by store per selected period","Scans par magasin par période sélectionnée"],
    ["Scan count by day of week per selected period","Nombre de scans par jour de la semaine par période sélectionnée"],
    ["Scanners age demographics per selected period","Données démographiques sur l'âge des scanners par période sélectionnée"],
    ["Scanners Gender demographics","Démographie de genre"],
    ["Fees per month","Frais par mois"],
    ["Transactional fees","Frais de transaction"],
    ["Are you sure you want to proceed with this action? Your changes will be lost.","Êtes-vous sûr de vouloir procéder à cette action ? Vos modifications seront perdues."]
    // {ts('',this.props.userLogin.language)}
    // ts('',this.props.userLogin.language)
    //import { translateScreen as ts , translateDate as td} from '../components/dictionary'

]
const dictionaryList2 = [
    ["Attention, you are going to modify an important configuration. Please confirm.","Attention, vous aller modifier une configuration importante. Merci de confirmer."]
    ,["List of redeemed gifts","Liste des cadeaux remboursés"]
    ,["List of promo codes","Liste des promo codes"]
    ,["No badges collected yet","Aucun badge collecté pour le moment"]
    ,["Collected Badges","Badges collectés"]
    ,["Create New Alert","Créer une nouvelle alerte"]
    ,["Time","Temps"]
    , ['Download Profiles JSON', "Download les profiles au format JSON"]
    , ['Query For Profiles', 'Donner le total filtré']
    , ['Filter Result', 'Filtrer le résultat']
    , ['Filter', 'Filtre']
    , ['Filter Records', 'Filtrer les enregistrements']
    , ['First Name', 'Prénom']
    , ['Last Name', 'Nom de famille']
    , ['Email', 'E-mail']
    , ['List of Hotspot Categories', 'Liste des catégories de hotspots']
    , ['List of Hotspot Categories', 'Liste des catégories de hotspots']
    , ['Create New Category', "Créer une nouvelle catégorie"]
    , ['Edit', "Modifier"]
    ,['No limit','Pas de limite']
    , ['Delete', "Supprimer"]
    , ['Category Name', "Nom de catégorie"]
    , ['Hotspots Numbers', "Numéros des hotspots utiliser"]
    , ['Created', "Établi"]
    , ['created', "établis"]
    , ['Loading Categories', "Chargement des catégories"]
    , ['Edit Hotspot Name', "Modifier le nom du hotspot"]
    ,['Hotspot category','Catégory du hotspots']
    , ['Edit category', "Modifier la catégorie"]
    , ['Delete Category Hotspot', "Supprimer la catégorie Hotspot"]
    , ['Are you sure you want to delete this hotspot Category?', "Êtes-vous sûr de vouloir cacher cette catégorie de hotspot ?"]
    , ['Are you sure you want to proceed?', "Êtes-vous sur de vouloir continuer?"]
    , ['Cannot Delete', "Impossible de suprimer"]
    , ['This category is in use and cannot be deleted!', "Cette catégorie est en cours d'utilisation et ne peut pas être supprimée !"]
    , ['Delete Category', "Supprimer la catégorie"]
    , ['Save', "Enregistrer"]
    , ['Import Profiles', "Importer des profils"]
    , ['You can create manually customers in Customer Management Screen', "Vous pouvez créer manuellement des clients dans l'écran de gestion des clients"]
    , ['Click to open', "Cliquez pour ouvrir"]
    , ['Uploading data to the your database will require this well defined format', "Le téléchargement des données dans votre base de données nécessitera ce format bien défini"]
    , ['List Characters with maximum Length equal to ', "Liste des caractères avec une longueur maximale égale à "]
    , ['Well defined email ', "E-mail bien défini "]
    , ['Only 2 values.', "Seulement 2 valeurs."]
    , ['Date of birth of the person. Date format will be as Follow DD/MM/YYYY (Day/Month/Year)', "Date de naissance de la personne. Le format de la date sera le suivant JJ/MM/AAAA (Jour/Mois/Année)'"]
    , ['Country must be valid from a this country list', "Le pays doit être valide à partir d'une liste de ce pays"]
    , ['Download List here', "Téléchargez la liste ici"]
    , ['Download a CSV file template from', "Téléchargez un modèle de fichier CSV à partir de"]
    , ['Here', "Ici"]
    , ['Client Selection', "Selection du client"]
    , ['Import Data From Files', "Importer des données à partir de fichiers"]
    , ['A csv file', "Un fichier csv"]
    , ['A comma-separated values (CSV) file is a delimited text file that uses a comma to separate values. Each line of the file is a data record.', "Un fichier de valeurs séparées par des virgules (CSV) est un fichier texte délimité qui utilise une virgule pour séparer les valeurs. Chaque ligne du fichier est un enregistrement de données."]
    , ['You can save your Excel workbook as CSV file by switching to the File tab, and then click Save As and In the Save as type box, choose to save your Excel file as CSV (Comma delimited).', "Vous pouvez enregistrer votre classeur Excel en tant que fichier CSV en passant à l'onglet Fichier, puis cliquez sur Enregistrer sous et dans la zone Enregistrer en tant que type, choisissez d'enregistrer votre fichier Excel au format CSV (délimité par des virgules)."]
    , ['Select your Excel or CSV file', "Sélectionnez votre fichier Excel ou CSV"]
    , ['Filetype', "Type de fichier"]
    , ['Size', "Grandeur"]
    , ['Last Modified', "Dernière modification"]
    , ['Validate File', "Valider le fichier"]
    , ['Error Appeared While Trying to upload File', "Des erreurs sont apparues lors de la tentative de téléchargement du fichier"]
    , ['Error', "Erreur"]
    , ['Error Available', "Erreur disponible"]
    , ['Warning', "Avertissement"]
    , ['Additional Warning', " Avertissement additionnel"]
    , ['Are you sure this is the correct file?', "Es-tu sûr que c'est le bon fichier ?"]
    , ['Additional Error', " Erreur additionnel"]
    , ['out of ', "sur "]
    , [" record is ", "enregistrement est"]
    , [" record are ", "enregistrements sont"]
    , [' available. You can check succeeded records in the grid bellow', " disponible. Vous pouvez vérifier les enregistrements réussis dans la grille ci-dessous"]
    , ['All the records are available', "Tous les enregistrements sont disponibles"]
    , ['Upload Succeeded Records', "Télécharger les enregistrements réussis"]
    , ['Clear', "Effacer"]
    , ['Apply', "Appliquer"]
    , ['Invalid data available at Line', "Données invalides disponibles dans la Ligne"]
    , ['List Of Logs', "Journal"]
    , ['Loading User Logs', "Chargement des journaux"]
    , ['Loading', "Chargement"]
    , ['Traffic Sources', "Sources de trafic"]
    , ['Number of Users Logged in', "Nombre d'utilisateurs ayant accédé"]
    , ['Number of LOG IN', "Nombre d'accès"]
    , ['Your Customers Statistics', "Statistiques des clients"]
    , ['Total Customers Created', "Nombre total de clients créés"]
    , ['Total Countries', "Nombre total de pays"]
    , ['Total Cities', "Nombre total de villes"]

    , ['Total Males', "Total hommes"]
    , ['Total Females', "Total femmes"]
    , ['More info', "Plus d'informations"]
    , ['Hotspots And Email Unsubsription Statistics', "Hotspots et statistiques de désabonnement des e-mails"]
    , ['Click To Open', "Cliquez pour ouvrir"]
    , ['Total Templates Created', "Nombre total de modèles créés"]
    , ['Total Campaigns Sent', "Total des campagnes envoyées"]
    , ['Total Campaigns Reached', "Nombre total de campagnes atteintes"]
    , ['Total Campaigns Opened', "Total des campagnes ouvertes"]
    , ['Total Campaigns for the Customers', "Total des campagnes pour les clients"]
    , ['Total Campaigns Opened by Customers', "Total des campagnes ouvertes par les clients"]
    , ['Total Numbers of Hotspots created', "Nombre total de Hotspots créés"]
    , ['Total Hotspots that clients visits', "Nombre total de hotspots visités par les clients"]
    , ['Total Hotspots visits number', "Nombre total de visites dans les hotspots"]
    , ['Hotspot Visited', "Hotspot Visité"]
    , ['Total Visit Number', "Nombre total de visites"]
    , ['Total Unsubscribe Customers', "Nombre total de clients désabonnés"]
    , ['Total email unsubscribed', "Total des e-mails désabonnés"]
    , ['Total Registration Request', "Demande d'inscription totale"]
    , ['Still Not Registered', "Toujours pas enregistré"]
    , ['Available Clients', "Clients disponibles"]
    , ['Customer Profiles Created', "Profils clients créés"]
    , ['Hello', "Bonjour"]
    , ['Let Us Start', "Commençons"]
    , ['Add Contacts', "Ajouter des contacts"]
    , ['Start Uploading your contacts from a CSV file or you can add them on the screen', "Commencez à télécharger vos contacts à partir d'un fichier CSV ou vous pouvez les ajouter à l'écran"]

    , ['Create Templates', "Créer des modèles"]
    , ['Send Campaings', "Envoyer des campagnes"]
    , ['Analyse Statistics', "Analyser les statistiques"]
    , ['Create your email template and save it to start your first campaign', "Créez votre modèle d'e-mail et enregistrez-le pour démarrer votre première campagne"]
    , ['Send your campaigns based on your created templates and customer selection', "Envoyez vos campagnes en fonction de vos modèles créés et de votre sélection de clients"]
    , ['Start Anyalysing your result by customers area, gender, visits and others', "Commencez à analyser votre résultat par zone client, sexe, visites et autres"]
    , ['Campaigns Statistics', "Statistiques des campagnes"]
    , ['Website Visits', "Visites de site"]
    , ['Top 5 Login Users This Week', "Top 5 des utilisateurs connectés cette semaine"]
    , ['Company', "Compagnie"]
    , ['Account Type', "Type d'utilisateur"]
    , ['List of unregistered clients', "Liste des clients non enregistrés"]
    , ['List of registered clients', "Liste des clients enregistrés"]
    , ['List Of inactive Clients', "Liste des clients inactifs"]
    , ['Unregistered Clients', "Clients non enregistrés"]
    , ['None Active Clients', "Clients inactifs"]
    , ['Registered Clients', "Clients enregistrés"]
    , ['No Registration Available', "Aucune inscription disponible"]
    , ['No Clients Available', "Aucun client disponible"]
    , ['No Deactivated Clients Are Available', "Aucun client désactivé n'est disponible"]
    , ['Loading Clients Info', "Chargement des informations sur les clients"]
    , [' Edit client info of ', " Modifier les informations client de "]
    , [' Register client ', " Enregistrer le client "]

    , ['Admin User Setup', "Configuration de l'utilisateur administrateur"]
    , ['Users can be edited in the', "Les utilisateurs peuvent être modifiés dans le"]
    , ['Users Management Screen', "Écran de gestion des utilisateurs"]
    , ['Save Changes', "Sauvegarder les modifications"]
    , ['Register Client', "Enregistrer le client"]
    , ['Deactivate Account', "Désactiver le compte"]
    , ['Activate Account', "Activer le compte"]
    , ['Company Information', "Informations sur la société"]
    , ['Enter your company name', "Entrez le nom de votre entreprise"]
    , ['Enter your contact person', "Entrez votre personne de contact"]
    , ['Enter your mobile/Phone number', "Entrez votre numéro de téléphone"]
    , ['Enter email address', "Entrez l'address e-mail"]
    , ['Enter customer registered email', "Entrez l'e-mail enregistré du client"]
    , ['Enter user full name', "Entrez le nom complet de l'utilisateur"]
    , ['Enter default username Password', "Entrez le nom d'utilisateur par défaut Mot de passe"]
    , ['Confirm default Password', "Confirmer le mot de passe par défaut"]
    , ['Register User', "Enregistrer l'utilisateur"]
    , ['Edit User', "Modifier l'utilisateur"]
    , ['Not Registred', "non enregistré"]

    , ['Request Date', "Date de la demande"]
    , ['Registration Date', "Date d'inscription"]
    , ['We are sorry, the page you requested could not be found or', "Nous sommes désolés, la page que vous avez demandée est introuvable ou"]
    , ['you do not have access', "tu n'as pas accès"]
    , ['Please go back to the home page', "Merci de revenir à la page d'accueil"]
    , ['Home Page', "Page d'accueil"]
    , ['Page Not Found', "Page Non Trouvée"]
    , ['First name cannot be empty', "Le prénom ne peut pas être vide"]
    , ['Last name cannot be empty', "Le nom de famille ne peut pas être vide"]
    , ['Email cannot be empty', "L'e-mail ne peut pas être vide"]
    , ['Phone cannot be empty', "Le téléphone ne peut pas être vide"]
    , ['City cannot be empty', "La ville ne peut pas être vide"]
    , ['Gender must be selected', "Le sexe doit être sélectionné"]
    , ['Country cannot be empty', "Le pays ne peut pas être vide"]
    , ['Birth date cannot be empty', "La date de naissance ne peut pas être vide"]
    , ['Select a client in order to manage their customer', "Sélectionner un client afin de gérer ses clients"]
    , ['Number of profiles', "Nombre de profils"]
    , ['Loading Available Companies', "Chargement des entreprises disponibles"]
    , ['Customers of ', "Clients de "]
    , ['Create New Profile', "Ajouter un nouveau profil"]
    , ['Import', "Importer"]
    , ['Edit Profile', "Editer le profil"]
    ,['View',"Voir"]
    , ['Delete Profile', "Supprimer le profil"]
    , ['Manage Customers', "Gérer les clients"]
    , ['Loading Available Profiles', "Chargement des profils disponibles"]
    , ['No Profiles Found', "Aucun profil trouvé"]
    , ['Click Here to Create your first Profile', "Cliquez ici pour créer votre premier profil"]
    , ['Create New User In', "Créer un nouvel utilisateur dans"]
    , ['Create New Profile In', "Créer un nouvel profil dans"]
    , ['Edit Profile In', "Modifier le profil dans"]
    , ['Enter profile primary phone', "Entrez le téléphone principal du profil"]
    , ['Select Gender', "Sélectionnez le genre"]
    , ['Enter the birthdate', "Entrez la date de naissance"]
    , ['Enter profile city', "Entrez la ville du profil"]
    , ['Enter the profile country', "Entrez le pays du profil"]
    , ['Enter first name', "Entrez le prénom"]
    , ['Enter last name', "Entrez le nom"]
    , ['Please enter the required info below and send your registration request.', "Veuillez entrer les informations requises ci-dessous et envoyer votre demande d'inscription."]
    , ['Our team will reply as soon as possible. Usually within 4 hours', "Notre équipe vous répondra dans les plus brefs délais."]
    , ['Send Registration', "Envoyer l'inscription"]
    , ['Retry', "Réessayez"]
    , ['This is not a valid Email', "Ceci n'est pas un email valide"]
    , ['Phone number name cannot be empty', "Le nom du numéro de téléphone ne peut pas être vide"]
    , ['Contact Person name cannot be empty', "Le nom de la personne de contact ne peut pas être vide"]
    , ['Company name cannot be empty', "Le nom de l'entreprise ne peut pas être vide"]
    , ['New Password cannot be empty', "Le nouveau mot de passe ne peut pas être vide"]
    , ['Confirm Password cannot be empty', "Confirmer le mot de passe ne peut pas être vide"]
    , ['Password must be set', "Le mot de passe doit être défini"]
    , ['Password is too short. Minumum 8 charcters', "Le mot de passe est trop court. Au moins 8 caractères"]
    , ['Passwords does not match', "Les mot de passes sont différent"]
    , ['New Passwords does not match', "Les nouveaux mot de passes sont différent"]
    , ['Your password has been successfully reset.', "Votre mot de passe a été réinitialisé avec succès."]
    , ['Something hapened while reseting your email. Please try again', "Quelque chose s'est produit lors de la réinitialisation de votre e-mail. Veuillez réessayer"]
    , ['Enter new password', "Entrez un nouveau mot de passe"]
    , ['Confirm new password', "Confirmer le nouveau mot de passe"]
    , ['Reset Password', "Réinitialiser le mot de passe"]
    , ['(Minimum 8 characters)', "(Minimum 8 caractères)"]
    , ['Change Password', "Changer le mot de passe"]
    , ['Ignore Change', "Ignorer le changement"]
    , ['Your username and password are not available', "Votre nom d'utilisateur et votre mot de passe ne sont pas disponibles"]
    , ['Your username is now deactivated. Please contact the Givenmine', "Votre nom d'utilisateur est maintenant désactivé. Veuillez contacter le Givenmine"]
    , ['Sign In', "Connecter"]
    , ['Enter your email and tap on Change password. A reset password email will be sent to your inbox', "Entrez votre e-mail et appuyez sur Modifier le mot de passe. Un e-mail de réinitialisation du mot de passe sera envoyé dans votre boîte de réception"]
    , ['A password reset email has been sent to your email. Please check your inbox', "Un e-mail de réinitialisation de mot de passe a été envoyé à votre adresse e-mail. Veuillez vérifier votre boîte de réception"]
    , ['Sign Up', "Inscrivez-vous maintenant"]
    ,['Register now', "Inscrivez-vous maintenant"]
    , ['Quit', "Quitter"]
    , ['Password cannot be empty', "Le mot de passe ne peut pas être vide"]
    , ['Enter your Email address', "Entrez-votre adresse email"]
    , ['Enter password', "Entrer le mot de passe"]
    , ['Log In', "Connecter"]
    , ["Don't have an Account?", "Vous n'avez pas de compte ?"]
    , ['Forget Password', "J'ai oublié mon mot de passe"]
    , ['Send Password Reset', "Envoyer la réinitialisation"]
    , ['Username cannot be empty', "Le nom d'utilisateur ne peut pas être vide"]
    , ['Full name cannot be empty', "Le nom complet ne peut pas être vide"]
    , ['User Type must be selected', "Le type d'utilisateur doit être sélectionné"]
    , ['Password is too short', "Password is too short"]
    , ['New Password must be set', "Nouveau mot de passe doit être défini"]
    , ['Loading Companies', "Télécharger des entreprises"]
    , ['Company Details', "Détails de l'entreprise"]
    , ['Company Name', ""]
    , ['Company Number', "Numéro d'entreprise"]
    , ['Registered Email', "Email enregistré"]
    , ['Create New User', "Créer un nouvel utilisateur"]
    , ['Users List', "Liste des utilisateurs"]
    , ['Change User Details', "Modifier les détails de l'utilisateur"]
    , ['Enter user email', "Entrez l'e-mail de l'utilisateur"]
    , ['Change user password', "Changez le mot de pass de cet utilisateur"]
    , ['Cancel Change User Password', "Annuler Modifier le mot de passe utilisateur"]
    , ['This template design file was not found. Please try again', "Ce fichier de conception de modèle n'a pas été trouvé. Veuillez réessayer"]
    , ['Template subject cannot be empty', "Le sujet du modèle ne peut pas être vide"]
    , ['Template Name cannot be empty', "Le nom du modèle ne peut pas être vide"]
    , ['Template Language must be selected', "La langue du modèle doit être sélectionnée"]
    , ['Design file has been saved. You can Load it in all screens by pressing on Load Last Workspace button.', "Le fichier de conception a été enregistré. Vous pouvez le charger dans tous les écrans en appuyant sur le bouton Charger le dernier espace de travail."]
    , ['Save Campaign Template', "Enregistrer le modèle de campagne"]
    , ['Enter the Template name here', "Entrez le nom du modèle ici"]
    , ['Enter the email subject her', "Entrez le sujet de l'e-mail ici"]
    , ['Copyright Givenmine', "Droit d'auteur Givenmine"]
    , ['All rights reserved.', "Tous les droits sont réservés."]
    , ['Create New Hotspot', "Créer un nouveau Hotspot"]
    , ['Save Current Workspace', "Enregistrer l'espace de travail"]
    , ['Load Last Workspace', "Charger le dernier espace de travail"]
    , ['Export HTML', "Exporter le HTML"]
    , ['User Parameters', "Paramètres utilisateur"]
    , ['User Information', "Informations de l'utilisateur"]
    , ['Save/Load Design', "Enregistrer/Charger Modèle"]
    , ['My Created Hotspots', "Mes hotspots créés"]
    , ['Birthdate', "Date de naissance"]
    , ['Hotspot Type must be selected', "Hotspot doit être sélectionné"]
    , ['Hotspot category must be selected', "La catégorie Hotspot doit être sélectionnée"]
    , ['Hyperlink name cannot be empty', "Le nom du lien hypertexte ne peut pas être vide"]
    , ['Select from list', "Sélectionner dans la liste"]
    , ['All Genders', "Tous les genres"]
    , ['Only Males', "Seulement des mâles"]
    , ['Only Females', "Seulement des femelle"]
    , ['All Reached and Not Reached', "Tous atteints et non atteints"]
    , ['All Opened And Not Opened', "Tous ouverts et non ouverts"]
    , ['Loading Available Profiles', "Chargement des profils disponibles"]
    , ['You have successfully uploaded the profiles.', 'Vous avez téléchargé les profils avec succès.']
    , ['Loading Data', 'Chargement Des Données']
   ,['Deactivate Client','Désactiver le client']
    ,['Activate Client','Activater le client']
    ,['Are you sure you want to ','Êtes-vous sûr de vouloir ']
    ,['Enter your email that will be used as sender Email', 'Entrez votre email qui sera utilisé comme expéditeur   ' ]
    ,['Thank you for registering in our platform. The registration request has been sent.','Merci pour votre intérêt.']
    ,['We will contact you soon. Usually within 4 hours','Nous reviendrons vers vous le plus rapidement possible avec votre mot de passe. ']
    ,['Are you sure you want to start your campaign now?','Êtes-vous sûr de vouloir commencer votre campagne maintenant ?']
    ,['Start Campaign','Commencer la campagne'],
    ['Hotspot details','Détails du Hotspot'],
    ['Hide','Cacher'],
    ['Hide category','Cacher la categorie'],
    ['Show category','Afficher la categorie'],
    ['Subscription status','Status de l’abonnement'],
    ['Statistics','Statistiques'],
    ['Total sent campaigns','Total des campagnes envoyées'],
    ['Number of categories customer interacted with','Nombre de catégories avec lesquelles le client a interagi'],
    ['List of categories customer interacted with','Liste des catégories avec lesquelles le client a interagi'],
    ['Client ID','ID du client'],
    ['Your client id will be generated automatically','Votre identifiant client sera généré automatiquement'],
    ['Send later','Envoyer plus tard'],
    ['Confirm','Confirmer'],
    ['Sent campaigns','Campagnes envoyées'],
    ['Upcoming campaigns','Campagnes à venir'],
    ['View all','Voir tout'],
    ['All Categories Selected','Toutes les catégories sélectionnées'],
    ['Search for a customer','Chercher un client'],
    ['Schedule Campaign','Programmer la campagne'],
    ['Are you sure you want to schedule your campaign?','Etes vous sûre de planifier la compagne?'],
    ['You can schedule your campaign to go on a specific date. Please assign it below',"Vous pouvez programmer votre campagne pour qu'elle se déroule à une date précise. Veuillez l'attribuer ci-dessous"],
    ['Date & time','Date et heure'],
    ['Duplicate','Dupliquer'],
    ['Enter company name','Entrez le nom de la compagnie'],
    ['Hotspots','Hotspots'],
    ['Total Registered groups','Nombre total de groups enregistrés'],
    ['None registered groups','Groupes pas enregistrés']

    // {ts('',this.props.userLogin.language)} 
    // {ts2('',this.props.userLogin.language)}
    //   ts2(,this.props.userLogin.language)
    // ts('',this.props.userLogin.language)
    //import { translateScreen as ts , translateScreen2 as ts2 , translateDate as td} from '../components/dictionary'

]
//{ts('',this.props.userLogin.language  )}

export const translateScreen = (phrase, language) => {
    
    if (language === 0) return phrase
    let translation = ''
    for (let i = 0; i < dictionaryList.length; i++) {

        if (dictionaryList[i][0] === phrase) {
            translation = dictionaryList[i][1];
        }
        if (translation !== '') i += dictionaryList.length
    }

    if (translation === '') return phrase;
    return translation
}
export const translateScreen2 = (phrase, language) => {

    if (language === 0) return phrase
    let translation = ''
    for (let i = 0; i < dictionaryList2.length; i++) {

        if (dictionaryList2[i][0] === phrase) {
            translation = dictionaryList2[i][1];
        }
        if (translation !== '') i += dictionaryList2.length
    }

    if (translation === '') return phrase;
    return translation
}
export const translateDate = (phrase, language) => {
    if (language === 0) return phrase;
    phrase = phrase.replace("Monday", "Lundi").replace("Tuesday", "Mardi").replace("Wednesday", 'Mercredi')
        .replace("Thursday", 'Jeudi').replace("Friday", 'Vendredi').replace("Saturday", 'Samedi').replace('Sunday', 'Dimanche')
        phrase = phrase.replace("Feb", 'Fevrier').replace("Marc", 'Mars').replace("Apr", 'Avril')
        .replace("May", 'Mai').replace("Jun", 'Juin').replace("Jul", 'Juillet').replace("Aug", 'Août').replace("Dec", 'Décembre')
        phrase = phrase.replace("January", 'Janvier').replace("February", 'Fevrier').replace("March", 'Mars').replace("April", 'Avril')
        .replace("May", 'Mai').replace("June", 'Juin').replace("July", 'Juillet').replace("August", 'Août').replace("September", 'Septembre')
        .replace("October", 'Octobre').replace("November", 'Novembre').replace("December", 'Décembre')
    
    return phrase

}
export const generateFrenchLanguage = ()=>{
    let text = ''
    dictionaryList.map(element=>{
        text+= 'TS' + ";" + element[0] + ";" + element[1] + "\n"
    })
    dictionaryList2.map(element=>{
        text+= 'TS2' + ";" + element[0] + ";" + element[1] + "\n"
    })
    
    G.downloadFile(text, 'Translation Dictionary', 'txt')
}