import React, { Component } from 'react'
import { Container, Spinner, Badge, Button, Form } from 'react-bootstrap'
import * as G from '../components/global'
import MessageBox from '../components/MessageBox';
import { connect } from 'react-redux';
import { translateScreen as ts, translateScreen2 as ts2, translateDate as td } from '../components/dictionary'
import history from '../components/history';
import qs from 'qs'
import dateFormat from 'dateformat';
//import $ from "jquery";
//import "datatables.net/js/jquery.dataTables"
//import "datatables.net-dt/css/jquery.dataTables.css"
  class HotspotCategories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            loadList: true,
            updateMode: false,
            fields: {},
            modalShow: false,
            modalSubject: 'Delete Category Hotspot',
            modalBody: 'Are you sure you want to delete this hotspot Category',
            justinfo: true,
            errors: {},
            deleteID: -1,
            clientsList:[],
            successmodalShow:false

        }
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        let { fields, errors } = this.state;
        fields[e.target.name] = e.target.value;
        delete errors[e.target.name];

        this.setState({ fields });
    }

    
    refreshData = async (siteid) => {
        var _this = this;
        _this.setState({ loadList: true })
        this.setState({ list: [] })
        let result = await G.getData('getHotspotCategories',[['clientID', siteid]]);
        result = await result.data;
        var qid = qs.parse(history.location.search, { ignoreQueryPrefix: true }).id
        result.map(item=>{
            if(item.id == qid)
            {
                let record = { ...item }
                record.creationdate = dateFormat(item.creationDate, "dddd, mmm dd yyyy h:MM tt")
                this.setState({ updateMode: true, fields: { ...record } });
            }
        })
        var _this = this;
        history.listen((location, action) => {
            if(location.pathname == "/HotspotCategories" || location.pathname == "/HotspotCategories/")
            _this.setState({ updateMode: false });
            if(location.pathname == "/HotspotCategories/create" || location.pathname == "/HotspotCategories/create/")
            {
                _this.setState({ updateMode: true, fields: {} });
            }
            //this.setState({fields:this.props.userLogin.site});
          });
        this.setState({ list: result })
        //this.setState({ loadList: false })
        setTimeout(function(){
            try{
                document.removeChild(document.querySelector("#dttablestats"));
            
        }
        catch(e){}
                const script = document.createElement("script");
            script.id = "dttablestats";
            script.src = '/dist/js/dthotspottable.js';
            script.async = true;
            document.body.appendChild(script);
            
            _this.setState({ loadList: false })
        },500)

    }
    getSnapshotBeforeUpdate(prevProps) {
        
        return { notifyRequired: prevProps.userLogin !== this.props.userLogin };
      }
      
      async componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot.notifyRequired === true) {
            if(this.props.userLogin.site)
            {
                await this.refreshData(this.props.userLogin.site.id);
                              
            }   
        }
        /*$(document).ready(function () {
            $('#example1').DataTable();
        });*/
      }
    
    async componentDidMount() {
        if (this.props.userLogin.site){
            await this.refreshData(this.props.userLogin.site.id)
            
        }
        history.listen(async(location, action) => {
            /*if (this.props.userLogin.site){
                await this.refreshData(this.props.userLogin.site.id)
                
            }*/
        });
          /*$(document).ready(function () {
            $('#example1').DataTable();
        });*/
    }

    updateHotspot = async (recordID) => {
        console.log("recordID=", recordID)
        const { name,clientID } = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!name) { formIsValid = false; errors["name"] = "Category Name cannot be empty"; }

        if (formIsValid) {
            let parameters = [['name', name]]
            if (recordID !== -1) parameters.push(['id', recordID])
           parameters.push(['clientID', this.props.userLogin.site.id])
            let result = await G.getData('hotspotCategory/' + (recordID === -1 ? 'insert' : 'update'), parameters)
            console.log(result)
            let success = (result.status === 200)
            if (success) {
                var logtxt = this.props.userLogin.username + (recordID === -1?" added a new hotspot category ":" edited a hotspot category") + " (Name:" + name+")";
          await G.SaveUserLog(logtxt, this.props.userLogin.id);
                this.setState({ updateMode: false, fields: {}, successmodalShow:recordID!==-1 })
                this.refreshData(this.props.userLogin.site.id)
                history.push("/HotspotCategories")
            }
        }
    }


    deleteHotspot =async () => {
        let parameters = [['id', this.state.deleteID]]
        var result = await G.getData('hideHotspotCategory', parameters, true)
        result = await result.status;
        if (result === 200) {
            this.setState({ deleteid: -1, modalShow:false })
            this.refreshData(this.props.userLogin.site.id)
        }else{
            this.setState({
                modalShow: true,
                modalSubject: 'Error Appeared',
                modalBody: 'Problem Occured while hiding Record. Please Try Again',
                justinfo: true
            })
        }
    }
    ShowCategory = async (id)=>{
        let parameters = [['id', id]]
        var result = await G.getData('showHotspotCategory', parameters, true)
        result = await result.status;
        if (result === 200) {
            this.setState({ deleteid: -1, modalShow:false })
            this.refreshData(this.props.userLogin.site.id)
        }else{
            this.setState({
                modalShow: true,
                modalSubject: 'Error Appeared',
                modalBody: 'Problem Occured while updating Record. Please Try Again',
                justinfo: true
            })
        }
    }
    render() {
        const { errors, fields } = this.state;
        var clientslug = this.props.userLogin.site && this.props.userLogin.site.slug;
        var _this = this;
        return (
            <>
                {!this.state.updateMode && <div>
                    <section className='content-header'>
                    <div className='container-fluid'>
                    <div className='row mb-2'>
                <div className='col-sm-6'>
                    <h1 className=''>{ts2('List of Hotspot Categories',this.props.userLogin.language)} </h1>
                    </div>
                    </div>
                    </div>
                    </section>
                    <section className='content'>
                        <div className='row'>
                    <Button variant='success mt-3 mb-5 ml-3' onClick={() => { this.setState({ updateMode: true, fields: {} });history.push("/HotspotCategories/create") }}>{ts2('Create New Category',this.props.userLogin.language)}</Button>
                    </div>
                    <div className='row'>
                    <div className='col-md-12 '>
                        <div className="">
                         <table id="hotspottable" className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>{ts2('Edit',this.props.userLogin.language)}</th>
                                        <th>{ts2('Hide',this.props.userLogin.language)}</th>
                                        <th>{ts2('Registration form',this.props.userLogin.language)}</th>
                                        {/*<th> UID</th>*/}
                                        <th>{ts2('Category Name',this.props.userLogin.language)}</th>
                                        <th>{ts('Client Name',this.props.userLogin.language)}</th>
                                        <th>{ts('Created In',this.props.userLogin.language)}</th>
                                        <th>{ts2('Hotspots Numbers',this.props.userLogin.language)}</th>

                                    </tr>
                                </thead>

                                {!this.state.loadList &&
                                <tbody>
                                    {
                                        (this.state.list).map((item) => {
                                            
                                            /*this.state.clientsList.filter(val => val.key == item.clientID).map(filteredName => (
                                                clientslug = filteredName.clientslug 
                                            ))*/
                                            return (
                                                <tr key={item.id}>
                                                    <td className='xxxtitle whiteColor handCursor'
                                                        onClick={() => {
                                                            let record = { ...item }
                                                            record.creationdate = dateFormat(item.creationDate, "dddd, mmm dd yyyy h:MM tt")
                                                            this.setState({ updateMode: true, fields: { ...record } });
                                                            
                                                            history.push("/HotspotCategories/edit?id=" + record.id)
                                                        }}>
                                                        <Badge variant='info'>{ts2('Edit category',this.props.userLogin.language)}</Badge>
                                                    </td>
                                                    <td className='xxxtitle whiteColor handCursor'
                                                        onClick={() => {
                                                                
                                                                if (item.hide !== 1) {
                                                                    _this.setState({
                                                                        modalShow: true,
                                                                        modalSubject: ts2('Hide category',this.props.userLogin.language),
                                                                        modalBody: ts2('Are you sure you want to delete this hotspot Category?',this.props.userLogin.language),
                                                                        justinfo: false,
                                                                        deleteID: item.id
                                                                    })
                                                                } else {
                                                                    _this.ShowCategory(item.id)
                                                                }


                                                        }}>
                                                            {item.hide!==1 &&
                                                        <Badge variant='danger'>{ts2('Hide category',this.props.userLogin.language)}</Badge>}
                                                        {item.hide===1 && <Badge variant='danger'>{ts2('Show category',this.props.userLogin.language)}</Badge>}
                                                    </td>
                                                    <td className='xxxtitle whiteColor handCursor' onClick={()=>{navigator.clipboard.writeText(G.nodeServerSite+"registercustomer?mode=ver&lang=fr&clientID=" +clientslug +"&categoryID=" + item.uid+"&btncolor=0000ff")}}><Badge variant='info'>{ts2('Copy',this.props.userLogin.language)}</Badge></td>
                                                    {/*<td>{item.uid}</td>*/}
                                                    <td>{item.name}</td>
                                                    <td>
                                                   
                                                  { this.props.userLogin.site && this.props.userLogin.site.companyName }
                                                </td>
                                                    <td data-sort={item.creationDate}>{td(dateFormat(item.creationDate, 'dddd, mmm dd yyyy h:MM tt'),this.props.userLogin.language)}</td>
                                                    <td className='  '>{item.uses === 0 ? "-" : G.countItems(item.uses, 'Hotspot') + ' ' + ts2('Created',this.props.userLogin.language)}</td>


                                                </tr>)
                                        })
                                    }

                                </tbody>
    }
                            </table>
                            {this.state.loadList &&
                                <div className='flexcolumn center verticalcenter p-5 fullWidth hint '>
                                   {ts2('Loading Categories',this.props.userLogin.language)} 
                                    <Spinner animation="border" className='mt-3' variant="warning" />

                                </div>
                            }
                        </div>
                    </div>
                    </div>
                    </section>


                </div>}
                {
                    this.state.updateMode && <>
                    <div className='content-header'>
                    <div className='container-fluid'>
                        <div className='row mb-2'>
                            <div className='col-sm-6'>
                        <h1>{ts2('Hotspot category',this.props.userLogin.language)}</h1>
                        </div>
                        </div>
                        </div>
                        </div>
                        <section className='content'>
                        <div className="row">
                        <Form style={{width:"100%"}}>
                            <div className='col-sm-12 col-md-8 '>
                                {G.renderTextField("UID", 'text', "Category ID will be generated Automatically", this.handleChange, this.state.fields, errors, null, '', true,this.props.userLogin.language,true,"uid")}
                                {G.renderTextField("Creation Date", 'text', "", this.handleChange, fields, errors, null, '', true,this.props.userLogin.language, true,"creationdate")}
                                {G.renderTextField("Name", 'text', "Add the Category Name Here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language,"name")}
                                {/*commented by youssef {this.props.userLogin.accountType===0 && G.renderDropDownWithId("Client Selection","clientID",this.state.clientsList,this.handleChange, fields, errors,null,false,this.props.userLogin.language)}*/}
                            </div>
                        </Form>
                       </div><div className='row'>
                           <div className='col-md-12'>
                        <Button variant='success ml-3 mt-5' onClick={() => {   fields.id == null ? this.updateHotspot(-1) : this.updateHotspot(fields.id) }}>{ts2('Save',this.props.userLogin.language)}</Button>
                        <Button variant='warning ml-3 mt-5' onClick={() => { this.setState({ updateMode: false });history.go(-1);
                        var _this = this;
                    setTimeout(function(){
                        if(document.getElementById("dttablestats")!=null)
                        {
                            document.getElementById("dttablestats").remove();
                        }
                            const script = document.createElement("script");
                        script.id = "dttablestats";
                        script.src = '/dist/js/dthotspottable.js';
                        script.async = true;
                        document.body.appendChild(script);
                        
                        _this.setState({ loadList: false })
                    },500);
                    }}>{ts('Cancel',this.props.userLogin.language)}</Button>
                   </div> </div>
                <div className='row'>
                
                </div>
                </section>
                </>
    }
    <MessageBox
                    show={this.state.modalShow}
                    title={this.state.modalSubject}
                    body={this.state.modalBody}
                    onHide={() => { this.setState({ modalShow: false }) }}
                    middle={!this.state.justinfo && {
                        action: () => {
                            this.deleteHotspot()


                        }, text: ts2('Hide category',this.props.userLogin.language), variant: 'danger'
                    }}
                    right={{ action: () => { this.setState({ modalShow: false }) }, text: this.state.justinfo ? 'OK' : ts('Cancel',this.props.userLogin.language), variant: 'success' }}


                />
            <MessageBox
                    show={this.state.successmodalShow}
                    title={ts("Changes are saved",this.props.userLogin.language)}
                    body={<div><center><Button variant="success" onClick={()=>{this.setState({ successmodalShow: false });}}>OK</Button></center></div>}
                    onHide={() => { this.setState({ modalShow: false }) }}

                />
            </>
        )
    }
}
const mapStateToProps = state => {

    return {
        userLogin: state.SaveLogin.userData
    };
};
export default connect(
    mapStateToProps
)(HotspotCategories)
