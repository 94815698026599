import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Badge, Button, Container, Form, Col, Spinner } from 'react-bootstrap';
import * as G from '../components/global'
import '../styles/glob.css';
import history from '../components/history'
import MessageBox from '../components/MessageBox';
import { translateScreen as ts, translateScreen2 as ts2, translateDate as td } from '../components/dictionary'
import qs from 'qs'
import dateFormat from 'dateformat';
class AdminUsersManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allList: [],
            companyList: [],
            companyUsersList: [],
            fields: {},
            errors: {},
            result: [],
            activeScreen: 0,
            activeCompany: {},
            editUser: -1,
            loadCompany: true,
            loadUsers: true,
            loadSave: false,
            changeUserPassword: false,
            successmodalShow:false

        }
        this.handleChange = this.handleChange.bind(this);
    }
    //#region Didmount & Get Data & Filter
    componentDidMount() {
        this.refreshData()
        history.listen((location, action) => {
            if(location.pathname == "/AdminUsersManagement" || location.pathname == "/AdminUsersManagement/")
            this.setState({ activeScreen: 0 });
            
          });
          if(history.location.pathname == "/AdminUsersManagement/create" || history.location.pathname == "/AdminUsersManagement/create/")
          this.setState({ editUser: -1, activeScreen: 1, fields: {}, errors: {} });

    }
    getSnapshotBeforeUpdate(prevProps) {
        
        return { notifyRequired: prevProps.userLogin !== this.props.userLogin };
      }
      
      async componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot.notifyRequired) {
            await this.refreshData();
        }
        
      }
    getCompanies = (records) => {
        let companies = [];
        records.map(element => {
            let { clientID, companyName } = element;
            if (companies.filter(values => values.clientID === element.clientID).length === 0) companies.push({ clientID, companyName })
        })
        return companies
    }
    getCompanyUsers = (company) => {
        this.setState({ activeCompany: company })
        return (this.state.allList)
    }
    getUserType = () => {
        let result = []

        if (this.props.userLogin != null) { if (this.props.userLogin.accountType === 0) { result = G.userType.filter(value=>value!==G.userType[0]) } else { result = G.userType.filter(value => value !== G.userType[0]) } }
        return result
    }
    refreshData = async () => {
        this.setState({ loadUsers: true })
        var result = await G.getData('getadminlist', []);
        result = await result.data
        this.setState({ allList: result })
        //this.setState({ companyList: this.getCompanies(this.state.allList) })
        //if (this.state.companyList) { if (this.state.companyList.length > 0) { this.setState({ companyUsersList: this.getCompanyUsers(this.state.companyList[0]) }) } }
        this.setState({ companyUsersList: this.getCompanyUsers(this.props.userLogin.site) })
        this.setState({ loadUsers: false })
        this.setState({ loadCompany: false })
        var qid = qs.parse(history.location.search, { ignoreQueryPrefix: true }).id
        result.map((el)=>{
            if(qid == el.id)
            {
                this.editUser(el);
            }
        })
    }
    editUser = (user) => {

        this.setState(
            {
                editUser: user.id,
                fields: {
                    usertype: G.getUserType(user.accountType),
                    email: user.username,
                    fullname: user.fullName
                },
                activeScreen: 1
            }
        )

    }
    blockUser = async (user)=>{
        await G.getData("blockuser",[["userid",user.id]]);
        window.location.reload();
    }
    UnblockUser = async(user)=>{
        await G.getData("unblockuser",[["userid",user.id]]);
        window.location.reload();
    }
    //#endregion

    //#region Form Validation
    handleChange(e) {
        let { fields, errors } = this.state;
        fields[e.target.name] = e.target.value;
        delete errors[e.target.name];

        this.setState({ fields });
    }
    handleValidation = async () => {

        const { password, confirmpassword, email, fullname, changepassword } = this.state.fields;
        const { editUser } = this.state;
        const confirmchangepassword = this.state.fields["confirmchange password"]

        let errors = {};
        let formIsValid = true;

        if (!email) { formIsValid = false; errors["email"] = ts2("Username cannot be empty", this.props.userLogin.language); } else {
            if (!G.isValideEmail(email)) { formIsValid = false; errors["email"] = 'This is not a valid Email'; }
        }
        //if (!email) { formIsValid = false; errors["email"] = ts2("Username cannot be empty", this.props.userLogin.language); }
        if (!fullname) { formIsValid = false; errors["fullname"] = ts2("Full name cannot be empty", this.props.userLogin.language); }
        
        if (editUser < 0) {
            if (!password) { formIsValid = false; errors["password"] = ts2("Password must be set", this.props.userLogin.language); } else {
                if (password.length < 8) { formIsValid = false; errors["password"] = ts2("Password is too short", this.props.userLogin.language); }
                else {
                    if (!confirmpassword) { formIsValid = false; errors["confirmpassword"] = ts2("Passwords does not match", this.props.userLogin.language); } else {
                        if (confirmpassword !== password) { formIsValid = false; errors["confirmpassword"] = ts2("Passwords does not match", this.props.userLogin.language); };

                    }
                }
            }
        }
        if (editUser > 0 && this.state.changeUserPassword) {
            
            if (!changepassword) {
                formIsValid = false; errors["changepassword"] = ts2("New Password must be set", this.props.userLogin.language);
                console.log(ts2("New Password must be set", this.props.userLogin.language))
            } else {
                if (changepassword.length < 8) { formIsValid = false; errors["changepassword"] = ts2("New Password is too short", this.props.userLogin.language); }
                else {
                    if (!confirmchangepassword) { formIsValid = false; errors["confirmchange password"] = ts2("New Passwords does not match", this.props.userLogin.language); } else {
                        if (confirmchangepassword !== changepassword) {console.log(ts2("New Password must be set", this.props.userLogin.language)); formIsValid = false; errors["confirmchange password"] = ts2("New Passwords does not match", this.props.userLogin.language); };

                    }
                }
            }
        }
        console.log(this.state.fields)
        this.setState({ errors: errors });
        //const { registrationRequestID, companyName, contactPerson, contactPersonNo, email, addDate } = req.body
        //const { username, password, fullName, accountType, creationUser } = req.body

        let parameters = []

        if (editUser < 0) {
            parameters = [
                ['clientID', null],
                ['username', email],
                ['password', password],
                ['fullName', fullname],
                ['accountType', 0],
                ['creationUser', this.props.userLogin.id],
                ['groupid',null]
            ]
        } else {
            parameters = [
                ['id', editUser],
                ['username', email],
                ['fullName', fullname],
                ['accountType', 0],
                ['lastEditUser', this.props.userLogin.id],
                ['clientID', null],
                ['groupid',null]
            ]
            if (changepassword != null) {
                parameters.push(['changepassword', changepassword])
            }
        }
        console.log('isbusy', formIsValid);
        console.log("errors",this.state.errors);
        if (formIsValid) {

            G.log('Form is valid. pushing now values...')
            var result = await G.getData('registerAdminUser', parameters)
            result = await result.status;
            console.log('result', result)
            if (result === 601) {

                errors['email'] = "Username already available!"
                this.setState({ errors: errors });
            }
            if(result ===200)
            {
                if(editUser<0)
                {
                    var logtxt = this.props.userLogin.username + " has created a new Super Admin (" + email + ")";
                    await G.SaveUserLog(logtxt, this.props.userLogin.id)
                }
            }
            return (result === 200)
        }
        else {
            return formIsValid;
        }
    }
    submitForm = async (e) => {
        e.preventDefault();
        this.setState({ loadingSave: true })
        G.log('Try to submit form')
        var a = await this.handleValidation();

        G.log('submit form is', a)
        if (a === true) {
            this.refreshData()
            this.setState({ activeScreen: 0, changeUserPassword: false, successmodalShow:true })
            history.push("/AdminUsersManagement")
        } else { G.log('Form Is Not Valid') }
        this.setState({ loadingSave: false });
        
    }

    //#endregion


    render() {
        const { errors, fields } = this.state;
        return (

            <Container fluid  >

                <div>
                    <div className='flexrow start mt-5'>
                        
    
                        {this.state.loadUsers &&
                            <div className='flexrow center verticalcenter p-5 fullWidth hint '>
                                <Spinner animation="grow" className='m-3' variant="warning" />
                                <Spinner animation="grow" className='m-3' variant="warning" />
                                <Spinner animation="grow" className='m-3' variant="warning" />
                            </div>
                        }
                        {!this.state.loadUsers && this.state.activeScreen === 0 &&
                            <div className='p-3 pr-0 mb-5'>

                                <h5 className='mb-5 '>{'List of admin users'}</h5>

                                <Button variant='success' onClick={() => { this.setState({ editUser: -1, activeScreen: 1, fields: {}, errors: {} });history.push("/AdminUsersManagement/create") }} className='mb-5'>{ts2('Create New User', this.props.userLogin.language)}</Button>

                                <div className="table-responsive p-0" style={{ maxHeight: 900 }}>
                                    <table className="table table-head-fixed text-nowrap table-hover ">
                                        <thead>
                                            <tr>
                                                <th>Action</th>
                                                <th>ID</th>
                                                <th>{ts('Full Name', this.props.userLogin.language)}</th>
                                                <th>{ts('Email', this.props.userLogin.language)}</th>
                                                {/*<th>{ts2('Registered Email', this.props.userLogin.language)}</th>*/}
                                                <th>{ts2('Account Type', this.props.userLogin.language)}</th>
                                                <th>{ts('Created In', this.props.userLogin.language)}</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (this.state.companyUsersList).map((item) => {

                                                    return (
                                                        <tr key={item.id}>
                                                            <td className='xxxtitle shiteColor'
                                                                >
                                                                <Badge className='handCursor' variant='info' onClick={() => {
                                                                    this.editUser(item);
                                                                    history.push("/AdminUsersManagement/edit?id=" + item.id)

                                                                }}>{ts2('Edit User', this.props.userLogin.language)}</Badge>
                                                                &nbsp;&nbsp;
                                                                {item.active == 1 ? <Badge variant='danger' className='handCursor' onClick={() => {
                                                                    this.blockUser(item);
                                                                    //history.push("/userManagement/edit?id=" + item.id)

                                                                }}>{ts2('Block User', this.props.userLogin.language)}</Badge>:<Badge variant='success' className='handCursor' onClick={() => {
                                                                    this.UnblockUser(item);
                                                                    //history.push("/userManagement/edit?id=" + item.id)

                                                                }}>{ts2('Unblock User', this.props.userLogin.language)}</Badge>}
                                                            </td>

                                                            <td>{item.id}</td>
                                                            <td>{item.fullName}</td>
                                                            <td>{item.username}</td>
                                                            {/*<td>{item.email}</td>*/}
                                                            <td>{G.getUserType(item.accountType)}</td>
                                                            <td>{dateFormat(item.addDate, 'dddd, mmm dd yyyy h:MM tt')}</td>


                                                        </tr>)
                                                })
                                            }

                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        }
                        {this.state.activeScreen === 1 &&
                            <div className='fullWidth p-3'>

                                <Form name="form" onSubmit={this.submitForm.bind(this)} >

                                    <h5 className='mb-5 '>{ts2(this.state.editUser < 0 ? 'Create New User' : 'Change User Details', this.props.userLogin.language)}  </h5>
                                    <Form.Group>
                                        <Col>
                                            {G.renderTextField("Email", 'email', ts2("Enter user email", this.props.userLogin.language), this.handleChange, fields, errors, '', '', false, this.props.userLogin.language)}
                                        </Col>

                                    </Form.Group>
                                    <Form.Group>
                                        <Col>
                                            {G.renderTextField("Full Name", 'text', ts2("Enter user full name", this.props.userLogin.language), this.handleChange, fields, errors, '', '', false, this.props.userLogin.language)}
                                        </Col>

                                    </Form.Group>


                                    

                                    {this.state.editUser > 0 && <Button className={'mt-2 mb-3'} onClick={() => { this.setState({ changeUserPassword: !this.state.changeUserPassword }); }} variant={'dark'} >{this.state.changeUserPassword ? ts2('Cancel Change User Password', this.props.userLogin.language) : ts2('Change user password', this.props.userLogin.language)}</Button>}

                                    {

                                        (this.state.editUser < 0 || (this.state.changeUserPassword && this.state.editUser > 0)) &&
                                        <Form.Group className={this.state.changeUserPassword && this.state.editUser > 0 ? 'cunlayer pt-5 pb-5' : ''}>
                                            <Col>
                                                {G.renderTextField(this.state.editUser < 0 ? "Password" : "Change Password", 'password', ts2("Enter default username Password", this.props.userLogin.language), this.handleChange, fields, errors, ts2('(Minimum 8 characters)', this.props.userLogin.language), '', false, this.props.userLogin.language)}
                                            </Col>
                                            <Col>
                                                {G.renderTextField(this.state.editUser < 0 ? "Confirm Password" : 'Confirm Change Password', 'password', ts2("Confirm default Password", this.props.userLogin.language), this.handleChange, fields, errors, '', '', false, this.props.userLogin.language)}
                                            </Col>
                                        </Form.Group>
                                    }



                                    <div className='flexrow pt-5 '>
                                        <Button varant='success' type="submit" className='mr-3'>
                                            {this.state.loadSave && <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />}
                                            {ts2(this.state.editUser < 0 ? 'Register User' : 'Save Changes', this.props.userLogin.language)}</Button>
                                        <Button variant='warning' onClick={() => { this.setState({ activeScreen: 0 });history.go(-1) }}>{ts('Cancel', this.props.userLogin.language)}</Button>
                                    </div>

                                </Form>
                            </div>}
                    </div>

                </div>

                <MessageBox
                    show={this.state.successmodalShow}
                    title={ts("Changes are saved",this.props.userLogin.language)}
                    body={<div><center><Button variant="success" onClick={()=>{this.setState({ successmodalShow: false });}}>OK</Button></center></div>}
                    onHide={() => { this.setState({ modalShow: false }) }}

                />
            </Container>
        )
    }
}

//#region Redux & Export
const mapStateToProps = state => {
    return {
        userLogin: state.SaveLogin.userData
    };
};
export default connect(
    mapStateToProps
)(AdminUsersManagement);
//#endregion