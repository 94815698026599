import React, { Component } from 'react';
import { render } from 'react-dom';
import * as G from '../global';
import { translateScreen2 as ts2, translateScreen as ts } from '../dictionary'

import history from '../history';
class RightMenu extends Component{
    render(){
        const type=this.props.accountType;
    return (
        <>
        {type==0 &&<aside className="control-sidebar control-sidebar-dark" style={{bottom:"0px", height:"calc(100vh - 57px)", position:"fixed"}}>
                <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                    <li className="nav-item" style={{marginTop:25,marginBottom:25}}><p style={{textAlign:"left", cursor:"pointer", color:"white", fontWeight:"bold", fontSize:"16px", paddingLeft:"10%"}} onClick={()=>{if(G.checkifCreate(history,this.props.language)){history.push("/ManageUserRegistration?show=1") ;this.props.changePage("/ManageUserRegistration?show=1")}}}>Groups</p></li>
                    <li className="nav-item" style={{marginBottom:25}}><p style={{textAlign:"left", cursor:"pointer", color:"white", fontWeight:"bold", fontSize:"16px", paddingLeft:"10%"}} onClick={()=>{if(G.checkifCreate(history,this.props.language)){history.push("/StoresCategories") ;this.props.changePage("/StoresCategories")}}}>Stores Categories</p></li>
                    <li className="nav-item" style={{marginBottom:25}}><p style={{textAlign:"left", cursor:"pointer", color:"white", fontWeight:"bold", fontSize:"16px", paddingLeft:"10%"}} onClick={()=>{if(G.checkifCreate(history,this.props.language)){history.push("/Stores") ;this.props.changePage("/Stores")}}}>Stores</p></li>
                    <li className="nav-item" style={{marginBottom:25}}><p style={{textAlign:"left", cursor:"pointer", color:"white", fontWeight:"bold", fontSize:"16px", paddingLeft:"10%"}} onClick={()=>{if(G.checkifCreate(history,this.props.language)){history.push("/ManageTerms") ;this.props.changePage("/ManageTerms")}}}>{ts("Terms & conditions",this.props.language)} </p></li>
                    <li className="nav-item" style={{marginBottom:25}}><p style={{textAlign:"left", cursor:"pointer", color:"white", fontWeight:"bold", fontSize:"16px", paddingLeft:"10%"}}>Billing</p></li>
                    <li className="nav-item" style={{marginBottom:25}}><p style={{textAlign:"left", cursor:"pointer", color:"white", fontWeight:"bold", fontSize:"16px", paddingLeft:"10%"}} onClick={()=>{if(G.checkifCreate(history,this.props.language)){history.push("/AdminUsersManagement") ;this.props.changePage("/AdminUsersManagement")}}}>Users</p></li>
                </ul>   
        </aside>}
        </>
      );
    };
}
 
export default RightMenu;