import React, { Component } from 'react'
 import * as G from '../global'
import { connect } from 'react-redux';
import { translateScreen2 as ts2 } from '../dictionary'

 class Footer extends Component {
    render() {
        return (
            <footer className="main-footer">
                <strong>{ts2('Copyright Givenmine',this.props.userLogin.language)}  {new Date().getFullYear()==2021 ? 2021 : 2021 + "-" + new Date().getFullYear()} </strong>
                {ts2('All rights reserved.',this.props.userLogin.language)}
                <div className="float-right d-none d-sm-inline-block">
                    
                </div>
            </footer>

        )
    }
}
const mapStateToProps = state => {

    return {
        userLogin: state.SaveLogin.userData
    };
};
export default connect(
    mapStateToProps
)(Footer)