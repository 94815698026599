import React, { Component, createRef } from 'react';
import { Container, Spinner, Badge, Button, Form } from 'react-bootstrap'
import MessageBox from '../components/MessageBox';
import * as G from '../components/global'
import '../styles/glob.css'
import history from '../components/history';
import { translateScreen as ts , translateScreen2 as ts2 , translateDate as td} from '../components/dictionary'
import { connect } from 'react-redux';
import Select from 'react-select'
import chroma from 'chroma'
import qs from 'qs'
import { Redirect } from 'react-router-dom';
class Stores extends Component {

	constructor(props) {
		super(props);

		this.state = {
				list:[],
				listloading:false,
				updateMode:false,
				fields: {},
                searchfields:{},
				modalShow: false,
				modalSubject: 'Delete Store',
				modalBody: 'Are you sure you want to delete this Store',
				justinfo: true,
				errors: {},
				deleteID: -1,
                languagesList:[],
                imagelink:'',
                selectedFile:null,
                storeCategoryOptions:[],
                storeCategory:[],
                storeCategoryNames:[],
                fieldchanging:false,
                successmodalShow:false
		}
		this.handleChange = this.handleChange.bind(this);        
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
        this.imagechangeHandler = this.imagechangeHandler.bind(this);
        this.onBlur = this.onBlur.bind(this);
	}
    getSnapshotBeforeUpdate(prevProps) {
        
        return { notifyRequired: prevProps.userLogin !== this.props.userLogin };
      }
      
      async componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot.notifyRequired) {
            await this.loadData();
        }
        
      }
	componentDidMount(){
        
		this.selectInputRef = createRef();
        this.getlanguages();
        this.getcategories();
        if(this.props.userLogin.site)
		    this.loadData();
		history.listen((location, action) => {
            if(location.pathname == "/Stores" || location.pathname == "/Stores/")
            {
                this.setState({ updateMode: false });
                if(this.props.userLogin.site)
		        this.loadData();
            }
            if(location.pathname == "/Stores/create" || location.pathname == "/Stores/create/")
            this.setState({ updateMode: true, fields: {} });
          });
          if(history.location.pathname == "/Stores/create" || history.location.pathname == "/Stores/create/")
            this.setState({ updateMode: true, fields: {} });
	}
    getlanguages = async()=>{
		let array = [];
		array = await G.getData("languages/query",[["filter"," 1=1 order by orderindex"]]);
		array = await array.data;
		this.setState({languagesList: array});
	}
    getcategories = async (company) => {
        if(this.selectInputRef.current!=null)
            this.selectInputRef.current.select.clearValue();
        let array = []
        array = await G.getData("storecategory/query", [['filter','']])
        array = await array.data
        this.setState({ storeCategory: array })
        let arrayNames = [];
        array.map(element => {
          arrayNames.push(element.name)
        })
        this.setState({ storeCategoryNames: arrayNames })
        console.log(this.state.storeCategory)
        const categoryOptions = [];
        array.map(element => {
          categoryOptions.push({value:element.id, label:element.name});
        })
        this.setState({ storeCategoryOptions: categoryOptions })
      }
	loadData = async ()=>{
		this.setState({listloading:true})
        let { searchfields } = this.state;
        var filters = '';
        if(searchfields['category'])
        {
            var categories = searchfields['category'];
            var s = "";
            categories.map(elem=>{
                s += "," + elem.value
            })
            if(s!="")
            {
                s = s.substring(1);
                filters += " a.id in (select storeid from storecategoryrelation where categoryid in ("+s+"))"
                
            }
        }
		var results = await G.getData("store/query",[["filter",filters]]);
		results = await results.data;
		this.setState({list:results});
            this.loadDataTables();
            var qid = qs.parse(history.location.search, { ignoreQueryPrefix: true }).id
            results.map((el)=>{
                if(qid == el.id)
                {
                    this.openEdit(el, false);
                }
            })
		
	}
    
	loadDataTables = ()=>{
        this.setState({ listloading: false })
            try{
            if(document.getElementById("dttablestats")!=null)
            {
                document.getElementById("dttablestats").remove();
            }
        }
        catch(e){}
                const script = document.createElement("script");
            script.id = "dttablestats";
            script.src = '/dist/js/dttablereload.js';
            script.async = true;
            document.body.appendChild(script);
            var _this = this;
           
	}
	handleChange(e) {
       let { fields, errors } = this.state;
        fields[e.target.name] = e.target.value;
        delete errors[e.target.name];

        this.setState({ fields });
    }
    onBlur(e){
        this.setState({fieldchanging:false});
    }
    handleLanguageChange(e) {
		let { fields, errors,languagesList } = this.state;
		 fields[e.target.name] = e.target.value;
		 delete errors[e.target.name];

         for(var i=0;i<languagesList.length;i++)
         {
             var elem = languagesList[i];
             
             if(e.target.name.includes('_name'))
             {
                if(!fields[elem.id + "_name"] || fields[elem.id + "_name"]=='' || this.state.fieldchanging)
                {
                fields[elem.id + "_name"] = e.target.value;
                delete errors[elem.id + "_name"];
                this.setState({fieldchanging:true});
                }
             }
             if(e.target.name.includes('_description'))
             {
                if(!fields[elem.id + "_description"] || fields[elem.id + "_description"]=='' || this.state.fieldchanging)
                {
                    fields[elem.id + "_description"] = e.target.value;
                    document.getElementById(elem.id + "_description").value = e.target.value;
                    delete errors[elem.id + "_description"];
                    this.setState({fieldchanging:true});
                }
             }
         }
		 this.setState({ fields });
	 }
    handleCategorySearch = (name, e)=>{
        let { searchfields } = this.state;
        searchfields[name] = e;
		 this.setState({ searchfields });
         this.loadData();
    }
    handleSelectChange(name, e){
		let { fields, errors } = this.state;
		fields[name] = e;
		delete errors[name];
		console.log("fields",fields);
		console.log("field e",e);
		this.setState({ fields });
	 }
    imagechangeHandler(event) {
        var file = event.target.files[0];
        let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({imagelink: e.target.result});
    };
    reader.readAsDataURL(file);
        
        this.setState({selectedFile:file});
	}
    openEdit = async (item, redirect = true)=>{
        let record = { ...item }
        var dbcats = [];
        var result = await G.getData("storecategory/query",[["filter"," id in (select categoryid from storecategoryrelation where storeid ="+item.id+")"]]);
        result = await result.data;
        var resq = 0;
        result.map(elem=>{
            resq ++;
            
            dbcats.push({value:elem.id, label:elem.name})
            if(resq == result.length)
            {
                this.state.fields["category"] = dbcats;
                this.setState({fields:this.state.fields})
            }
        })
        //record.creationdate = dateFormat(item.creationDate, "dddd, mmm dd yyyy h:MM tt")
        var fields = this.state.fields;
        var dres = await G.getData("storetranslation/query",[["filter","storeid="+item.id]])
            dres = await dres.data
        dres.map((elem)=>{
            
            fields[elem.languageid + "_name"] = elem.name;
            fields[elem.languageid + "_description"] = elem.description;
        })
        fields["enabled"] = item.enabled
        fields["email"] = item.email
        fields["website"] = item.website
        fields["facebook"] = item.facebook
        fields["phone"] = item.phone
        fields["instagram"] = item.instagram
        fields["id"] = item.id
        this.setState({ updateMode: true, imagelink:G.nodeServerSite+"mediaFiles/userlogo/" + item.logo, fields:fields });
        if(redirect == true)
            history.push("/Stores/edit?id=" + item.id)
    }
	render() {
		const { errors, fields } = this.state;
        var clientslug = this.props.userLogin.site && this.props.userLogin.site.slug;
        //if (this.props.userLogin.language)language=this.props.userLogin.language;
		return (
			<>
			{!this.state.updateMode && <div>
			<section className='content-header'>
                    <div className='container-fluid'>
                    <div className='row mb-2'>
                <div className='col-sm-6'>
                    <h1 className=''>{ts('Stores',this.props.userLogin.language)} </h1>
                    </div>
                    </div>
                    </div>
                    </section>
					<section className='content'>
                        <div className='row'>
                    <Button variant='success mt-3 mb-5 ml-3' onClick={() => { this.setState({ updateMode: true, fields: {}, selectedFile:null, imagelink:"" });history.push("/Stores/create") }}>{ts2('Create New Store',this.props.userLogin.language)}</Button>
                    </div>
                    <div className='row'>
                    <div className='form-group col-md-3'>
								<label>Filter by store category</label>
								<Select ref={this.selectInputRef} menuPortalTarget={document.body} isClearable={true}
            onChange={(e)=>this.handleCategorySearch("category", e)}
    closeMenuOnSelect={false}
    isMulti    
    styles={colourStyles}
    options={this.state.storeCategoryOptions}
  />
								</div>
                    <div className='col-md-12 '>
                        <div className="">
                        {!this.state.loadList && <table id="example1" className="table table-bordered">
                                <thead>
                                    <tr>
                                    <th>{ts2('Action',this.props.userLogin.language)}</th>
                                        <th>{ts2('Logo',this.props.userLogin.language)}</th>
                                        <th>{ts2('Name',this.props.userLogin.language)}</th>
                                        <th>{ts2('Status',this.props.userLogin.language)}</th>
                                        

                                    </tr>
                                </thead>

{this.state.listloading ==false &&
                                <tbody>
                                    {
                                        (this.state.list).map((item) => {
                                            
                                            /*this.state.clientsList.filter(val => val.key == item.clientID).map(filteredName => (
                                                clientslug = filteredName.clientslug 
                                            ))*/
                                            return (
                                                <tr key={item.id}>
                                                    <td className='xxxtitle whiteColor handCursor'
                                                        >
                                                        <Badge onClick={async() => {
                                                            this.openEdit(item)
                                                        }} variant="success" style={{width:"100px",padding:"10px 0", backgroundColor:"#1A98AC"}}>{ts2('Edit',this.props.userLogin.language)}</Badge><br/>
														{item.enabled == 1 && <Badge onClick={() => {
                                                                
                                                                
                                                                    this.setState({
                                                                        modalShow: true,
                                                                        modalSubject: ts2('Delete Store',this.props.userLogin.language),
                                                                        modalBody: ts2('Are you sure you want to disable this store?',this.props.userLogin.language),
                                                                        justinfo: false,
                                                                        deleteID: item.id
                                                                    })
                                                                

                                                        }} variant="danger" style={{width:"100px",padding:"10px 0", marginTop:"10px"}}>{ts2('Disable',this.props.userLogin.language)}</Badge>}
                                                        {item.enabled != 1 && <Badge onClick={() => {
                                                                
                                                                
                                                                this.enableStore(item.id)
                                                            

                                                    }} variant="primary" style={{width:"100px",padding:"10px 0", marginTop:"10px"}}>{ts2('Enable',this.props.userLogin.language)}</Badge>}
                                                    </td>
													<td><img src={G.nodeServerSite+"mediaFiles/userlogo/" + item.logo} style={{width:"100px", maxWidth:"100%"}}/></td>
                                                    <td>{item.name}</td>
                                                    <td>{item.enabled == 1?"Active":"Disabled"}</td>
                                                    
                                                 

                                                </tr>)
                                        })
                                    }

                                </tbody>
    }
                            </table>}
                            {this.state.loadList &&
                                <div className='flexcolumn center verticalcenter p-5 fullWidth hint '>
                                   {ts2('Loading Stores',this.props.userLogin.language)} 
                                    <Spinner animation="border" className='mt-3' variant="warning" />

                                </div>
                            }
                        </div>
                    </div>
                    </div>
                    </section>
					</div>}
					{
                    this.state.updateMode && <>
                    
                        <section className='content'>
                        <div className='row'>
							<div className='col-md-12'>
							<Button className='mb-4 mt-5' variant='warning' onClick={() => { this.setState({updateMode:false, fields:{}});history.go(-1) }} >{ts('Cancel / Return', this.props.userLogin.language)}</Button>
							</div>
							<div className='col-md-12'>
							<h3>{ts('Store details',this.props.userLogin.language)}
                            </h3>
							</div>
							
							<div className='col-md-12' style={{height:"20px"}}></div>
							<div class="col-12 col-sm-6">
<div class="card card-primary card-outline card-tabs">
<div class="card-header p-0 pt-1 border-bottom-0">
<ul class="nav nav-tabs" id="custom-tabs-two-tab" role="tablist">
<li class="pt-2 px-3"><h6 class="card-title" style={{margin:"0px"}}>Entry by language</h6></li>
{
	this.state.languagesList.map((elem)=>{
		return <li class="nav-item">
<a class={elem.code == "fr"?"nav-link active":"nav-link"} id={"custom-tabs-two-" + elem.code + "-tab"} data-toggle="pill" href={"#custom-tabs-two-" + elem.code} role="tab" aria-controls={"custom-tabs-two-" + elem.code} aria-selected="true">{elem.code}</a>
</li>
	})
}
</ul>
</div>
<div class="card-body">
<div class="tab-content" id="custom-tabs-two-tabContent">
	{
		this.state.languagesList.map((elem)=>{
			return <div class={elem.code == "fr"?"tab-pane fade show active":"tab-pane fade"} id={"custom-tabs-two-" + elem.code} role="tabpanel" aria-labelledby={"custom-tabs-two-"+elem.code+"-tab"}>
				{G.renderTextField("Title", 'text', "enter store title here", (elem.code == "fr"?this.handleLanguageChange:this.handleChange), fields, errors, null, '',false, this.props.userLogin.language, true,elem.id + "_name",this.onBlur)}
				{G.renderTextArea("Description", 'textarea', "enter store description here", (elem.code == "fr"?this.handleLanguageChange:this.handleChange), fields, errors, null, '',false, this.props.userLogin.language, true,elem.id + "_description",this.onBlur)}
			</div>
		})
	}

</div>
</div>

</div>
</div>
<div className='col-md-6' style={{height:"20px"}}></div>
							<div className='col-md-6'>
							<label>Category</label>
								<Select ref={this.selectInputRef} menuPortalTarget={document.body} isClearable={true}
       value={fields["category"]}    
    closeMenuOnSelect={false}
    isMulti
    styles={colourStyles}
    options={this.state.storeCategoryOptions}
	id="category" name="category" onChange={(e)=>this.handleSelectChange("category", e)}
  />
  {this.state.errors['category'] && <div className='remarks notValidColor'>{this.state.errors['category']}</div>}
							</div>
<div className='col-md-12' style={{height:"20px"}}></div>
<div class='col-md-4'>
<div class="form-group">
 <label for="exampleInputFile">Image</label>
<div class="input-group">
<div class="custom-file">
<input type="file" onChange={this.imagechangeHandler} class="custom-file-input" id="logo" name="logo"/>
<label class="custom-file-label" for="exampleInputFile">Choose file</label>
</div>
</div>





</div>
{this.state.imagelink && <img src={this.state.imagelink} style={{width:"150px"}}/>}
</div>
<div className="col-md-12" style={{height:"20px"}}></div>
<div class="form-group col-md-4">
					<label for="enabled">Status</label>
				  <select name="enabled" id="enabled" onChange={this.handleChange} className="custom-select">
                      <option value="">Please select</option>
                      {fields["enabled"] == "1" && <option value="1" selected>Enabled</option>}
					  {fields["enabled"] != "1" && <option value="1">Enabled</option>}
					  {fields["enabled"] == "0" && <option value="0" selected>Disabled</option>}
                      {fields["enabled"] != "0" && <option value="0">Disabled</option>}
				  </select>
                  {errors['enabled'] && <div className='remarks notValidColor'>{errors['enabled']}</div>}
                </div>
<div className='col-md-12' style={{height:"20px"}}></div>
<div className="col-md-4">
{G.renderTextField("Phone", 'link', "enter store phone here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language, true,"phone")}
</div>
<div className='col-md-12' style={{height:"20px"}}></div>
<div className="col-md-4">
{G.renderTextField("Email", 'email', "enter store email here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language, true,"email")}
</div>
<div className='col-md-12' style={{height:"20px"}}></div>
<div className="col-md-4">
{G.renderTextField("Website", 'link', "enter store website here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language, true,"website")}
</div>
<div className='col-md-12' style={{height:"20px"}}></div>
<div className="col-md-4">
{G.renderTextField("Facebook", 'link', "enter store facebook here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language, true,"facebook")}
</div>
<div className='col-md-12' style={{height:"20px"}}></div>
<div className="col-md-4">
{G.renderTextField("Instagram", 'link', "enter store instagram here", this.handleChange, fields, errors, null, '',false, this.props.userLogin.language, true,"instagram")}
</div>

</div>
                        <div className='row'>
                           <div className='col-md-12'>
                        <Button variant='success ml-3 mt-5' onClick={() => {   fields.id==undefined || fields.id == null ? this.updateStore(-1) : this.updateStore(fields.id) }}>{ts2('Save',this.props.userLogin.language)}</Button>
                        <Button variant='warning ml-3 mt-5' onClick={() => { this.setState({ updateMode: false });history.go(-1);
                        
                        this.loadDataTables();
                    }}>{ts('Cancel',this.props.userLogin.language)}</Button>
                   </div> </div>
                <div className='row'>
                
                </div>
                </section>
                </>
    }
	<MessageBox
                    show={this.state.modalShow}
                    title={this.state.modalSubject}
                    body={this.state.modalBody}
                    onHide={() => { this.setState({ modalShow: false }) }}
                    middle={!this.state.justinfo && {
                        action: () => {
                            this.disableStore()


                        }, text: ts2('Disable Store',this.props.userLogin.language), variant: 'danger'
                    }}
                    right={{ action: () => { this.setState({ modalShow: false }) }, text: this.state.justinfo ? 'OK' : ts('Cancel',this.props.userLogin.language), variant: 'success' }}


                />
                <MessageBox
                    show={this.state.successmodalShow}
                    title={ts("Changes are saved",this.props.userLogin.language)}
                    body={<div><center><Button variant="success" onClick={()=>{this.setState({ successmodalShow: false });}}>OK</Button></center></div>}
                    onHide={() => { this.setState({ modalShow: false }) }}

                />
			</>
		)
	}
    
	updateStore = async (recordID) => {
        let errors = {};
        let formIsValid = true;
        console.log("recordID=", recordID)
        let parameters = [];
        var category = this.state.fields["category"];
        var email = this.state.fields["email"]
            var website = this.state.fields["website"]
            var facebook = this.state.fields["facebook"]
            var instagram = this.state.fields["instagram"]
            var phone = this.state.fields["phone"]
            var enabled = this.state.fields["enabled"]
            if(category == undefined || category.length == 0)
            {
                formIsValid = false;
                errors["category"] = "This field is required";
            }
            if(enabled==undefined || enabled == '')
            {
                formIsValid = false;
                errors["enabled"] = "This field is required";
            }
        this.state.languagesList.map((elem)=>{
            var name = this.state.fields[elem.id + "_name"]
            
            var description = this.state.fields[elem.id + "_description"]
            
            if(name == undefined || name == '')
            {
                formIsValid = false;
                errors[elem.id + '_name'] = 'This field is required';
            }
            if(description == undefined || description == '')
            {
                formIsValid = false;
                errors[elem.id + '_description'] = 'This field is required';
            }
            if(elem.code == "en")
            {
                this.state.fields["name"] = name;
                this.state.fields["description"] = description;
            }
            parameters.push(["name_" + elem.code,name]);
            parameters.push(["description_" + elem.code,description]);
        });
        
        
        var {name, description} = this.state.fields; 
        if (!name) { formIsValid = false; errors["name"] = "Store Name cannot be empty"; }
        this.setState({errors});
        if (formIsValid) {
            var cats = JSON.stringify(category);
            parameters.push(["name" ,name]);
            parameters.push(["email" ,email]);
            parameters.push(["website" ,(website?website:'')]);
            parameters.push(["facebook" ,(facebook?facebook:'')]);
            parameters.push(["instagram" ,(instagram?instagram:'')]);
            parameters.push(["phone" ,(phone?phone:'')]);
            parameters.push(["enabled" ,enabled]);
            parameters.push(['description' ,]);
            parameters.push(['logo' ,this.state.selectedFile]);
            parameters.push(['storeId' , recordID]);
            parameters.push(['category',cats]);
            console.log(cats);
           //parameters.push(['clientID', this.props.userLogin.site.id])
            let result = await G.uploadData('store/upload', parameters)
            console.log(result)
            let success = (result.status === 200)
            if (success) {
                
                    var logtxt = this.props.userLogin.username + " has edited to store details of the " + name + " store";
                    await G.SaveUserLog(logtxt, this.props.userLogin.id)
                
                this.setState({ updateMode: false, fields: {}, successmodalShow:recordID!==-1 })
                this.loadData()
                history.push("/Stores")
            }
        }
    }
	disableStore =async () => {
		let parameters = [['id', this.state.deleteID]]
		var result = await G.getData('store/disable', parameters, true)
		result = await result.status;
		if (result === 200) {
			this.setState({ deleteID: -1, modalShow:false })
			this.loadData()
			//window.location.reload();
		}else{
			this.setState({
				modalShow: true,
				modalSubject: 'Error Appeared',
				modalBody: 'Problem Occured while Deleting Record. Please Try Again',
				justinfo: true
			})
		}
	}
    enableStore =async (id) => {
		let parameters = [['id', id]]
		var result = await G.getData('store/enable', parameters, true)
		result = await result.status;
		if (result === 200) {
			this.setState({ deleteID: -1, modalShow:false })
			this.loadData()
			//window.location.reload();
		}else{
			this.setState({
				modalShow: true,
				modalSubject: 'Error Appeared',
				modalBody: 'Problem Occured while Deleting Record. Please Try Again',
				justinfo: true
			})
		}
	}
}


const mapStateToProps = state => {
    return {
        userLogin: state.SaveLogin.userData
    };
};
const colourStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma("#9B00FA");
      const disabledcolor = chroma("gray")
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#9B00FA"
          : isFocused
          ? disabledcolor
          : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : "#9B00FA",
        cursor: isDisabled ? 'not-allowed' : 'default',
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#9B00FA"
              : "#9B00FA30"
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma("#9B00FA");
      return {
        ...styles,
        backgroundColor: "#9B00FA",
        color:"#ffffff"
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#ffffff",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "#ffffff",
      ':hover': {
        backgroundColor: "#9B00FA",
        color: 'white',
      },
    }),
  };
export default connect(
    mapStateToProps
)(Stores);