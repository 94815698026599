import React, { Component } from 'react'
import { Badge, Button, Container, Spinner, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as G from '../components/global'
import ReactPaginate from 'react-paginate';
import { translateScreen as ts ,translateScreen2 as ts2 , translateDate as td} from '../components/dictionary'
import dateFormat from 'dateformat';
class logHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allList: [],
            fields: {},
            errors: {},
            activeScreen: 0,
            displayedList: [],
            offset: 0,
            perPage: 30,
            currentPage: 0,
            pageCount: 0,
            loadprofiles: false,
            doneLoading:false
        }

        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
        this.refreshData();
    }
    
    refreshData = async () => {
        try {

            this.setState({ loadprofiles: true })
            if (this.props.userLogin) {
                this.setState({doneLoading:true})
               
                let result = await G.getData('getLogs', [
                    ['clientID', this.props.userLogin.clientID],
                    ['AccountType', this.props.userLogin.accountType],
                    ['userID', this.props.userLogin.id]
                ]);
                result = await result.data
                this.setState({ allList: result })
                let pageCounts = result.length / this.state.perPage
                this.setState({ allList: result, pageCount: pageCounts, originalList: result })
                this.setdisplayList(0)

                this.setState({ loadprofiles: false })
            }
        } catch (error) {
            //this.refreshData()
        }

    }
    setdisplayList(startnumber) {
        let sliceArray = [...this.state.allList]

        if (this.state.allList.length > this.state.perPage) {
            sliceArray = sliceArray.slice(startnumber * this.state.perPage, startnumber * this.state.perPage + this.state.perPage)
           
        }
        this.setState({ displayedList: sliceArray })
    }
    handleChange(e) {
        let { fields, errors } = this.state;
        fields[e.target.name] = e.target.value;
        delete errors[e.target.name];
        this.setState({ fields });
        if (e.target.name === 'filter' && e.target.value === '') {
            this.filterGrid()
        }

    }
    onKeyDown = e => {
        if (e.keyCode === 13) {
            e.preventDefault();
            this.filterGrid()
        }
    }
    filterGrid = () => {
        let filter = this.state.fields['filter']
        if (filter === "" || filter == null) {
            this.setState({ allList: [...this.state.originalList] }, () => { this.setdisplayList(0) })
            
        } else {
            this.setState({
                allList: [...this.state.originalList.filter(element =>
                    (element.FullName ? element.FullName.toLowerCase().includes(filter.toLowerCase()) : false)
                    || (element.Name ? element.Name.toLowerCase().includes(filter.toLowerCase()) : false)
                    || (element.type ? element.type.toLowerCase().includes(filter.toLowerCase()) : false)
                    || (element.username ? element.username.toLowerCase().includes(filter.toLowerCase()) : false)
                    || (G.getUserType(element.accountTYpe) ? G.getUserType(element.accountTYpe).toLowerCase().includes(filter.toLowerCase()) : false)
                )]
            }, () => { this.setdisplayList(0) })
            
        }
    }
    
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.setdisplayList(selectedPage)
        });

    };
    render() {
        const { errors, fields } = this.state;
        const l = (this.props.userLogin.language===0)
        if (this.props.userLogin && !this.state.doneLoading ){
          
            this.refreshData();
        }
        return (
            <Container fluid >
                <div className='p-3  mr-1 col-12 '>
                    <h4 className='mt-5'> {ts2('List Of Logs',this.props.userLogin.language)} </h4>

                    <div>
                        <h6 className='mb-3'>{G.countItems(this.state.allList.length, 'Record') + ' ' +  ts('Found',this.props.userLogin.language)}</h6>



                        <div className='p-3 pr-0 '>
                            <Form.Label  >{ts2('Filter Result',this.props.userLogin.language  )}</Form.Label>
                            <div className='flexrow col-8'>
                                <Form.Control onKeyDown={this.onKeyDown} name='filter' type={'text'} placeholder={ts2('Filter Result',this.props.userLogin.language  )} onChange={this.handleChange} value={fields['filter'] || ''} />
                                {this.state.fields['filter'] && this.state.fields['filter'] !== '' && <Button variant='warning ml-2' onClick={this.filterGrid}>{ts2('Apply',this.props.userLogin.language)}</Button>}
                                {this.state.fields['filter'] && this.state.fields['filter'] !== '' && <Button variant='info ml-2' onClick={() => {
                                    let { fields } = this.state;
                                    delete fields['filter'];
                                    this.setState({ fields });
                                    
                                    this.filterGrid()
                                }}>{ts('Clear',this.props.userLogin.language)}</Button>}
                            </div>
                        </div>
                        {!this.state.loadprofiles && this.state.allList.length > this.state.perPage && <ReactPaginate
                            previousLabel={ts("Previous",this.props.userLogin.language)}
                            nextLabel={ts("Next",this.props.userLogin.language)}
                            breakLabel={"..."}
                            pageCount={this.state.pageCount}
                            marginPagesDisplayed={this.state.perPage}
                            pageRangeDisplayed={2}
                            onPageChange={this.handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"subPagination"}
                            activeClassName={"activePage "}
                            breakClassName={'eachPage break-me'}
                            previousClassName={'eachPagePrevNex'}
                            nextClassName={'eachPagePrevNex'}
                            pageClassName={'eachPage'}

                        />}
                        <div className='p-3 mr-3 '>
                            <div className="table-responsive p-0" style={{ maxHeight: 900 }}>
                                <table className="table table-head-fixed text-nowrap table-hover">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>{l?'Log Type': 'Type'}</th>
                                            <th>Client</th>
                                            <th>{l?"User Full Name" : 'Utilisateur'}</th>
                                            <th>{l?'Username':'Utilisateur'}</th>
                                            <th>{l?'User Type':"Type d'utilisateur"}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (this.state.displayedList).map((item) => {

                                                return (
                                                    <tr key={item.id}>

                                                        <td>{td(dateFormat(item.signInDate, 'dddd, mmm dd yyyy h:MM tt'),this.props.userLogin.language)}</td>
                                                        <td>{item.type}</td>
                                                        <td>{item.Name}</td>
                                                        <td>{item.FullName}</td>
                                                        <td>{item.username}</td>
                                                        <td>{G.getUserType(item.accountTYpe)}</td>



                                                    </tr>)
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    {this.state.loadprofiles &&
                        <div className='flexcolumn center verticalcenter p-5 fullWidth hint '>
                           {ts2('Loading User Logs',this.props.userLogin.language)} 
                            <Spinner animation="border" className='mt-3' variant="warning" />

                        </div>
                    }
                </div>
            </Container>
        )
    }
}
//#region Redux & Export
const mapStateToProps = state => {
   
    return {
        userLogin: state.SaveLogin.userData
    };
};
export default connect(
    mapStateToProps
)(logHistory);
//#endregion