import React, { Component } from 'react'
import * as G from '../global';
import history from '../history';
import { connect } from 'react-redux';
import { translateScreen as ts } from '../dictionary'
import { useState } from 'react';
import statschart from '../assets/stats-chart.svg'
import mail from '../assets/mail.svg'
import star from '../assets/star.svg'
import people from '../assets/people.svg'
import storefront from '../assets/storefront.svg'
import person from '../assets/person.svg'
import informationcircle from '../assets/information-circle.svg'
import refreshicon from '../assets/refresh.svg'
 class Menu extends Component {
 
    logOut = () => { this.props.logOut() }
    openMarketing=()=>{
      console.log('function entered');
      if(this.state.marketingOpen==true){
      this.setState({marketingOpen:false});
      }
      else{
        this.setState({marketingOpen:true});
      }
    }
    openLoyalty=()=>{
      console.log('function entered');
      if(this.state.loyaltyOpen==true){
      this.setState({loyaltyOpen:false});
      }
      else{
        this.setState({loyaltyOpen:true});
      }
    }
    openSettings=()=>{
      console.log('function entered');
      if(this.state.settingsOpen==true){
      this.setState({settingsOpen:false, loyaltyOpen:true});
      }
      else{
        this.setState({settingsOpen:true, loyaltyOpen:true});
      }
    }
    
    openCustomer=()=>{
      console.log('function entered');
      if(this.state.customerOpen==true){
      this.setState({customerOpen:false});
      }
      else{
        this.setState({customerOpen:true});
      }
    }
    constructor(props) {
      super(props);
    this.state={
    marketingOpen:false,
    loyaltyOpen:false,
    settingsOpen:false,
    CustomerOpen:false
   };
  }
    
    render() {
        const type = this.props.accountType;
        return (
            <aside className="main-sidebar sidebar-dark-primary elevation-4 ">
                {/* Brand Logo */}
                {type < 10
                    && <a className="brand-link">
                        {/* { <img src={logo} alt="SOW Logo" classNameName="  whitesvg ml-2 " width={160} />  } */}
                        {/* <span classNameName="brand-text font-weight-light ml-2">GIVENMINE</span> */}
                    </a>}
                {/* Sidebar */}
                {type < 10
                    && <div className="sidebar">
                        {/* Sidebar user panel (optional) */}
                        <div className="user-panel mt-3 pb-3 pt-3 mb-3 d-flex">
                            {/* <div classNameName="image">
                                <img src="dist/img/user2-160x160.jpg" classNameName="img-circle elevation-2 " alt="User Image" />
                            </div> */}
                            <div className="info ">
                                <a className=" bold whiteColor">{this.props.userFullName}</a>
                                <p className=' midremarks whiteColor'><span className='bold'>{G.getUserType(type)}</span></p>

                            </div>
                        </div >
                        
                        {/* Sidebar Menu */}
                        <nav className="mt-2">
                            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

                            {(type < 3) &&<li className="nav-item">
            <a onClick={() => { if(G.checkifCreate(history, this.props.userLogin.language)){history.push("/") ;this.props.changePage("/")}}} className={`nav-link handCursor ${this.props.currentPage === '/' ?'active':''}`}>
            <img src={statschart} width="25" style={{color:"white", paddingRight:"5px", fill:"white"}}/><p>
              {ts('My Dashboards',this.props.userLogin.language  )}
              </p>
            </a>
            </li>}
             {([0,1,2,4,5,6].includes(type)) &&<li  onClick={()=>{this.openMarketing()}} className={((this.props.currentPage === '/marketing-dashboard')||(this.props.currentPage.includes('/CampaignsTemplates'))||(this.props.currentPage.includes('/CampaignStatistics'))||(this.props.currentPage.includes('/HotspotCategories'))||(this.state.marketingOpen)) ?'nav-item handCursor menu-is-opening menu-open':'nav-item handCursor'}>
            <a href="#" className={`nav-link ${((this.props.currentPage=== '/marketing-dashboard')||(this.props.currentPage.includes('/CampaignsTemplates'))||(this.props.currentPage.includes('/CampaignStatistics'))||(this.props.currentPage.includes('/HotspotCategories'))) ?'active':''}`}>
            <img src={mail} width="25" style={{color:"white", paddingRight:"5px", fill:"white"}}/><p>
              {/*{ts('Third Parties',this.props.userLogin.language)}*/}
              Marketing
                <i className="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul className="nav nav-treeview">
            {(type < 3) &&<li className="nav-item">
                <a onClick={() => { if(G.checkifCreate(history, this.props.userLogin.language)){history.push("/marketing-dashboard") ;this.props.changePage("/marketing-dashboard")}}} className={`nav-link handCursor ${this.props.currentPage === '/marketing-dashboard' ?'active':''}`}>
                <i class="far fa-circle nav-icon"></i>
                  <p>{ts('Summary',this.props.userLogin.language  )}</p>
                </a>
              </li>}
              {([0,1,2,4,5].includes(type)) &&<li className="nav-item">
                <a onClick={() => { if(G.checkifCreate(history, this.props.userLogin.language)){history.push("/CampaignsTemplates") ;this.props.changePage("/CampaignsTemplates")}}} className={`nav-link handCursor ${this.props.currentPage.includes('/CampaignsTemplates') ?'active':''}`}>
                <i class="far fa-circle nav-icon"></i>
                  <p>{ts('Email Templates',this.props.userLogin.language  )}</p>
                </a>
              </li>}
              {([0,1,2,4,6].includes(type)) &&<li className="nav-item">
                <a onClick={() => { if(G.checkifCreate(history,this.props.userLogin.language)){history.push("/CampaignStatistics");this.props.changePage("/CampaignStatistics") }}} className={`nav-link handCursor ${this.props.currentPage.includes('/CampaignStatistics') ?'active':''}`}>
                <i class="far fa-circle nav-icon"></i>
                  <p>{ts('Campaigns',this.props.userLogin.language  )}</p>
                </a>
              </li>}
              {(type < 3) && <li className="nav-item">
                <a onClick={() => { if(G.checkifCreate(history,this.props.userLogin.language)){history.push("/HotspotCategories") ;this.props.changePage("/HotspotCategories") }}} className={`nav-link handCursor ${this.props.currentPage.includes('/HotspotCategories') ?'active':''}`}>
                <i class="far fa-circle nav-icon"></i>
                  <p>{ts('Hotspots',this.props.userLogin.language  )}</p>
                </a>
              </li>}
            </ul>
          </li>}
          {([0,1,2,3].includes(type)) &&<li  onClick={()=>{this.openLoyalty()}} className={((this.props.currentPage.includes('/ScannedTickets'))||(this.props.currentPage.includes('/Gifts'))||(this.props.currentPage.includes('/PromoCode'))||(this.props.currentPage.includes('/Competitions'))||(this.props.currentPage.includes('/Avatars')) || (this.props.currentPage.includes('/TicketsScanning')) || (this.props.currentPage.includes('/PointsTokens'))|| (this.props.currentPage.includes('/Rankings'))|| (this.props.currentPage.includes('/Badges'))|| (this.props.currentPage.includes('/GiftsCategories'))|| (this.props.currentPage.includes('/PromoCodeCategories'))|| (this.props.currentPage.includes('/CompetitionCategories'))|| (this.state.loyaltyOpen) || (this.state.settingsOpen)) ?'nav-item handCursor menu-is-opening menu-open':'nav-item handCursor'}>
            <a href="#" className={`nav-link ${((this.props.currentPage.includes('/ScannedTickets'))||(this.props.currentPage.includes('/Gifts'))||(this.props.currentPage.includes('/PromoCode'))||(this.props.currentPage.includes('/Competitions'))||(this.props.currentPage.includes('/Avatars')) || (this.props.currentPage.includes('/TicketsScanning')) || (this.props.currentPage.includes('/PointsTokens'))|| (this.props.currentPage.includes('/Rankings'))|| (this.props.currentPage.includes('/Badges'))|| (this.props.currentPage.includes('/GiftsCategories'))|| (this.props.currentPage.includes('/PromoCodeCategories'))|| (this.props.currentPage.includes('/CompetitionCategories')))?'active':''}`}>
            <img src={star} width="25" style={{color:"white", paddingRight:"5px", fill:"white"}}/>
              <p>
              {/*{ts('Third Parties',this.props.userLogin.language)}*/}
              Loyalty Program
                <i className="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul className="nav nav-treeview">
            <li className="nav-item">
                <a onClick={() => { if(G.checkifCreate(history,this.props.userLogin.language)){history.push("/ScannedTickets");this.props.changePage("/ScannedTickets") }}} className={`nav-link handCursor ${this.props.currentPage.includes('/ScannedTickets') ?'active':''}`} >
                <i class="far fa-circle nav-icon"></i>
                  <p>{ts('Scanned tickets',this.props.userLogin.language  )}</p>
                </a>
              </li>
              <li className="nav-item">
                <a onClick={() => { if(G.checkifCreate(history,this.props.userLogin.language)){history.push("/Gifts");this.props.changePage("/Gifts") }}} className={`nav-link handCursor ${this.props.currentPage.includes('/Gifts') ?'active':''}`}>
                <i class="far fa-circle nav-icon"></i>
                  <p>{ts('Gifts',this.props.userLogin.language  )}</p>
                </a>
              </li>
              <li className="nav-item">
                <a onClick={() => { if(G.checkifCreate(history,this.props.userLogin.language)){history.push("/PromoCode");this.props.changePage("/PromoCode") }}} className={`nav-link handCursor ${this.props.currentPage.includes('/PromoCode') ?'active':''}`}>
                <i class="far fa-circle nav-icon"></i>
                  <p>{ts('Promo codes',this.props.userLogin.language  )}</p>
                </a>
              </li>
              <li className="nav-item">
                <a onClick={() => { if(G.checkifCreate(history,this.props.userLogin.language)){history.push("/Competitions");this.props.changePage("/Competitions") }}} className={`nav-link handCursor ${this.props.currentPage.includes('/Competitions') ?'active':''}`}>
                <i class="far fa-circle nav-icon"></i>
                  <p>{ts('Competitions',this.props.userLogin.language  )}</p>
                </a>
              </li>
              <li style={{marginLeft:"14px", paddingLeft:"0px"}} onClick={()=>{this.openSettings()}} className={`${(this.props.currentPage.includes('/Avatars')) || (this.props.currentPage.includes('/TicketsScanning')) || (this.props.currentPage.includes('/PointsTokens'))|| (this.props.currentPage.includes('/Rankings'))|| (this.props.currentPage.includes('/Badges'))|| (this.props.currentPage.includes('/GiftsCategories'))|| (this.props.currentPage.includes('/PromoCodeCategories'))|| (this.props.currentPage.includes('/CompetitionCategories'))|| (this.state.settingsOpen) ?'nav-item handCursor menu-is-opening menu-open':'nav-item handCursor'}`}>
                <a href='#' style={{paddingLeft:"0px",paddingRight:"0px",width:"100%"}} className={`nav-link handCursor ${(this.props.currentPage.includes('/Avatars')) || (this.props.currentPage.includes('/TicketsScanning')) || (this.props.currentPage.includes('/PointsTokens'))|| (this.props.currentPage.includes('/Rankings'))|| (this.props.currentPage.includes('/Badges'))|| (this.props.currentPage.includes('/GiftsCategories'))|| (this.props.currentPage.includes('/PromoCodeCategories'))|| (this.props.currentPage.includes('/CompetitionCategories'))?'active':''}`}>
                <i class="fas fa-cog nav-icon"></i>
                  <p>{ts('Settings',this.props.userLogin.language  )}
                  <i className="right fas fa-angle-left"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <a onClick={() => { if(G.checkifCreate(history,this.props.userLogin.language)){history.push("/TicketsScanning");this.props.changePage("/TicketsScanning") }}} className={`nav-link handCursor ${this.props.currentPage.includes('/TicketsScanning') ?'active':''}`}>
                      <i class="far fa-circle nav-icon"></i>
                      <p>{ts('Tickets scanning',this.props.userLogin.language  )}</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a onClick={() => { if(G.checkifCreate(history,this.props.userLogin.language)){history.push("/PointsTokens");this.props.changePage("/PointsTokens") }}} className={`nav-link handCursor ${this.props.currentPage.includes('/PointsTokens') ?'active':''}`}>
                      <i class="far fa-circle nav-icon"></i>
                      <p>{ts('Points & Tokens',this.props.userLogin.language  )}</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a onClick={() => { if(G.checkifCreate(history,this.props.userLogin.language)){history.push("/Rankings");this.props.changePage("/Rankings") }}} className={`nav-link handCursor ${this.props.currentPage.includes('/Rankings') ?'active':''}`}>
                      <i class="far fa-circle nav-icon"></i>
                      <p>{ts('Rankings',this.props.userLogin.language  )}</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a onClick={() => { if(G.checkifCreate(history,this.props.userLogin.language)){history.push("/Badges");this.props.changePage("/Badges") }}} className={`nav-link handCursor ${this.props.currentPage.includes('/Badges') ?'active':''}`}>
                      <i class="far fa-circle nav-icon"></i>
                      <p>{ts('Badges',this.props.userLogin.language  )}</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a onClick={() => { if(G.checkifCreate(history,this.props.userLogin.language)){history.push("/Avatars");this.props.changePage("/Avatars") }}} className={`nav-link handCursor ${this.props.currentPage.includes('/Avatars') ?'active':''}`}>
                      <i class="far fa-circle nav-icon"></i>
                      <p>{ts('Avatars',this.props.userLogin.language  )}</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a onClick={() => { if(G.checkifCreate(history,this.props.userLogin.language)){history.push("/GiftsCategories");this.props.changePage("/GiftsCategories") }}} className={`nav-link handCursor ${this.props.currentPage.includes('/GiftsCategories') ?'active':''}`}>
                      <i class="far fa-circle nav-icon"></i>
                      <p>{ts('Gifts categories',this.props.userLogin.language  )}</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a onClick={() => { if(G.checkifCreate(history,this.props.userLogin.language)){history.push("/PromoCodeCategories");this.props.changePage("/PromoCodeCategories") }}} className={`nav-link handCursor ${this.props.currentPage.includes('/PromoCodeCategories') ?'active':''}`}>
                      <i class="far fa-circle nav-icon"></i>
                      <p>{ts('Promo code categories',this.props.userLogin.language  )}</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a onClick={() => { if(G.checkifCreate(history,this.props.userLogin.language)){history.push("/CompetitionCategories");this.props.changePage("/CompetitionCategories") }}} className={`nav-link handCursor ${this.props.currentPage.includes('/CompetitionCategories') ?'active':''}`}>
                      <i class="far fa-circle nav-icon"></i>
                      <p>{ts('Competition categories',this.props.userLogin.language  )}</p>
                    </a>
                  </li>      
                </ul>
              </li>
               
            </ul>
          </li>}
          {([0,1,2,3,7].includes(type)) &&<li  onClick={()=>{if(G.checkifCreate(history,this.props.userLogin.language)){this.openCustomer();history.push("/ProfileManagement");this.props.changePage("/ProfileManagement") }}} className={((this.props.currentPage.includes('/ProfileManagement'))||(this.props.currentPage.includes('/ImportProfiles'))|| (this.state.customerOpen)) ?'nav-item handCursor menu-is-opening menu-open':'nav-item handCursor'}>
            <a href="#" className={`nav-link ${((this.props.currentPage.includes('/ProfileManagement'))||(this.props.currentPage.includes('/ImportProfiles'))) ?'active':''}`}>
            <img src={people} width="25" style={{color:"white", paddingRight:"5px", fill:"white"}}/>
              <p>
              {/*{ts('Third Parties',this.props.userLogin.language)}*/}
              Customers
                {/*<i className="right fas fa-angle-left"></i>*/}
              </p>
            </a>
            {/*<ul className="nav nav-treeview">
            {(type < 2 || type==4) &&<li className="nav-item">
                <a onClick={() => { history.push("/ProfileManagement");this.props.changePage("/ProfileManagement") }} className={`nav-link handCursor ${this.props.currentPage.includes('/ProfileManagement' ?'active':''}`} >
                <i class="far fa-circle nav-icon"></i>
                  <p>{ts('Manage customers',this.props.userLogin.language  )}</p>
                </a>
              </li>}
              {(type < 2) &&<li className="nav-item">
                <a onClick={() => { history.push("/ImportProfiles");this.props.changePage("/ImportProfiles") }} className={`nav-link handCursor ${this.props.currentPage.includes('/ImportProfiles' ?'active':''}`} >
                <i class="far fa-circle nav-icon"></i>
                  <p>{ts('Import customers',this.props.userLogin.language  )}</p>
                </a>
              </li>}
              {(type < 2) &&<li className="nav-item">
                <a onClick={() => { history.push("/CustomerLogs");this.props.changePage("/CustomerLogs") }} className={`nav-link handCursor ${this.props.currentPage.includes('/CustomerLogs' ?'active':''}`} >
                <i class="far fa-circle nav-icon"></i>
                  <p>{ts('Customers logs',this.props.userLogin.language  )}</p>
                </a>
              </li>}
                          </ul>*/}
          </li>}
          {(type <=3 ) &&<li className="nav-item" >
            <a onClick={() => { if(G.checkifCreate(history,this.props.userLogin.language)){history.push("/SiteStores") ;this.props.changePage("/SiteStores")}}} className={`nav-link handCursor ${this.props.currentPage.includes('/SiteStores') ?'active':''}`}>
            <img src={storefront} width="25" style={{color:"white", paddingRight:"5px", fill:"white"}}/>
              <p>
              {ts('Store',this.props.userLogin.language  )}
              </p>
            </a>
            </li>}
            {(type < 3 ) &&<li className="nav-item">
            <a onClick={() => { if(G.checkifCreate(history,this.props.userLogin.language)){history.push("/SiteInfo") ;this.props.changePage("/SiteInfo")}}} className={`nav-link handCursor ${this.props.currentPage.includes('/SiteInfo') ?'active':''}`}>
            <img src={informationcircle} width="25" style={{color:"white", paddingRight:"5px", fill:"white"}}/>
              <p>
              {ts('Site Info',this.props.userLogin.language  )}
              </p>
            </a>
            </li>}
            {(type < 2 ) &&<li className="nav-item">
            <a onClick={() => { if(G.checkifCreate(history,this.props.userLogin.language)){history.push("/GroupInfo") ;this.props.changePage("/GroupInfo")}}} className={`nav-link handCursor ${this.props.currentPage.includes('/GroupInfo') ?'active':''}`}>
            <img src={informationcircle} width="25" style={{color:"white", paddingRight:"5px", fill:"white"}}/>
              <p>
              {ts('Group Info',this.props.userLogin.language  )}
              </p>
            </a>
            </li>}
            {(type < 2 ) &&<li className="nav-item">
            <a onClick={() => { if(G.checkifCreate(history,this.props.userLogin.language)){history.push("/Sites") ;this.props.changePage("/Sites")}}} className={`nav-link handCursor ${this.props.currentPage.includes('/Sites') ?'active':''}`}>
            <img src={informationcircle} width="25" style={{color:"white", paddingRight:"5px", fill:"white"}}/>
              <p>
              {ts('Sites',this.props.userLogin.language  )}
              </p>
            </a>
            </li>}
            {(type < 3 ) &&<li className="nav-item">
            <a onClick={() => { if(G.checkifCreate(history,this.props.userLogin.language)){history.push("/userManagement") ;this.props.changePage("/userManagement")}}} className={`nav-link handCursor ${this.props.currentPage.includes('/userManagement') ?'active':''}`}>
            <img src={person} width="25" style={{color:"white", paddingRight:"5px", fill:"white"}}/>
              <p>
              {ts('Users',this.props.userLogin.language  )}
              </p>
            </a>
            </li>}
            {(type < 2 ) &&<li className="nav-item">
            <a onClick={() => { if(G.checkifCreate(history,this.props.userLogin.language)){history.push("/UserLogs") ;this.props.changePage("/UserLogs")}}} className={`nav-link handCursor ${this.props.currentPage.includes('/UserLogs') ?'active':''}`}>
            <img src={refreshicon} width="25" style={{color:"white", paddingRight:"5px", fill:"white"}}/>
              <p>
              {ts('Logs',this.props.userLogin.language  )}
              </p>
            </a>
            </li>}
          
          




 {/*commented by youssef old menu
                                <li classNameName="nav-header mt-3">{ts('My Dashboards',this.props.userLogin.language  )}</li>
            <li classNameName="nav-item" onClick={() => { history.push("/") ;this.props.changePage("/")}}>
                                    <div classNameName="nav-link  handCursor " style={this.props.currentPage === '/' ?{color:'white', backgroundColor:'#494e53'} : {}}>
                                        <i classNameName="nav-icon far fa-chart-bar" />
                                        <p>
                                        {ts('Summary',this.props.userLogin.language  )}
                                        </p>
                                    </div>
            </li>*/}
                                {/*old part of menu*/}
                                {/*{(type < 2 || type == 4) && <li classNameName="nav-header mt-5">{ts('Third Parties',this.props.userLogin.language  )}</li>}


                                {type === 0 &&
                                    <li classNameName="nav-item" onClick={() => { history.push("/ManageUserRegistration");this.props.changePage("/ManageUserRegistration") }}>
                                        <div classNameName="nav-link mt-2 handCursor " style={this.props.currentPage.includes('/ManageUserRegistration' ?{color:'white', backgroundColor:'#494e53'} : {}}>
                                            <i classNameName="nav-icon fas fa-user-tie " />
                                            <p >
                                            {ts('Client Registration',this.props.userLogin.language  )}
                                            </p>
                                        </div>
                                    </li>
                                }
                                {type < 2 &&
                                    <li classNameName="nav-item">
                                        <div classNameName="nav-link mt-1 handCursor " style={this.props.currentPage.includes('/userManagement' ?{color:'white', backgroundColor:'#494e53'} : {}}
                                        onClick={() => { history.push("/userManagement") ;this.props.changePage("/userManagement")}}>

                                            <i classNameName="nav-icon fas fa-user-shield " />
                                            <p >
                                            {ts('User Management',this.props.userLogin.language  )}
                                            </p>
                                        </div>
                                    </li>
                                }
                                {(type < 2 || type == 4) &&
                                    <li classNameName="nav-item">
                                        <div classNameName="nav-link mt-1 handCursor " style={this.props.currentPage.includes('/ProfileManagement' ?{color:'white', backgroundColor:'#494e53'} : {}}
                                         onClick={() => { history.push("/ProfileManagement");this.props.changePage("/ProfileManagement") }}>
                                            <i classNameName="nav-icon fas fa-address-book " />
                                            <p >
                                            {ts('Customer Management',this.props.userLogin.language  )}
                                            </p>
                                        </div>
                                    </li>
                                }

                                {(type < 4) && <li classNameName="nav-header mt-5">{ts('Campaigns',this.props.userLogin.language  )}</li>}
                                {(type < 3) && <li classNameName="nav-item" onClick={() => { history.push("/CampaignsTemplates") ;this.props.changePage("/CampaignsTemplates")}}>
                                    <div classNameName="nav-link  handCursor " style={this.props.currentPage.includes('/CampaignsTemplates' ?{color:'white', backgroundColor:'#494e53'} : {}}>

                                        <i classNameName="nav-icon fas fa-envelope-open-text" />
                                        <p >
                                        {ts('Campaigns Templates',this.props.userLogin.language  )}
                                        </p>
                                    </div>
                                </li>}
                                {(type < 2 || type == 3) && <li classNameName="nav-item">

                                    <div classNameName="nav-link  handCursor "  style={this.props.currentPage.includes('/CustomerCampaigns' ?{color:'white', backgroundColor:'#494e53'} : {}}
                                    onClick={() => { history.push("/CustomerCampaigns")  ;this.props.changePage("/CustomerCampaigns")}}>
                                        <i classNameName="nav-icon fas fa-mail-bulk" />
                                        <p >
                                        {ts('Campaigns',this.props.userLogin.language  )}
                                        </p>
                                    </div>
                                </li>}
                                {type < 2 && <li classNameName="nav-item">
                                    <div classNameName="nav-link  handCursor " style={this.props.currentPage.includes('/CampaignStatistics' ?{color:'white', backgroundColor:'#494e53'} : {}}
                                     onClick={() => { history.push("/CampaignStatistics");this.props.changePage("/CampaignStatistics") }}>

                                        <i classNameName="nav-icon fas fa-tachometer-alt" />
                                        <p >
                                        {ts('Campaigns Statistics',this.props.userLogin.language  )}
                                        </p>
                                    </div>
                                </li>}
                                {type <2 &&
                                    <li classNameName="nav-item" onClick={() => { history.push("/HotspotCategories") ;this.props.changePage("/HotspotCategories") }}>
                                        <div classNameName="nav-link mt-2 handCursor " style={this.props.currentPage.includes('/HotspotCategories' ?{color:'white', backgroundColor:'#494e53'} : {}}>
                                            <i classNameName="nav-icon fab fa-hubspot " />
                                            <p >
                                            {ts('Hotspot Categories',this.props.userLogin.language  )} 
                                            </p>
                                        </div>
                                    </li>
                                }

                                {(type < 2 || type == 4) && <li classNameName="nav-header mt-5">{ts('Data Import/Export',this.props.userLogin.language  )}</li>}
                                {(type < 2 || type == 4) && <li classNameName="nav-item" onClick={() => { history.push("/ImportProfiles");this.props.changePage("/ImportProfiles") }}>

                                    <div classNameName="nav-link handCursor " style={this.props.currentPage.includes('/ImportProfiles' ?{color:'white', backgroundColor:'#494e53'} : {}}>
                                        <i classNameName="nav-icon fas fa-file-import" />
                                        <p >
                                        {ts('Import Customers',this.props.userLogin.language  )}
                                        </p>
                                    </div>
                                </li>}
                                {(type < 2) && <li classNameName="nav-item" onClick={() => { history.push("/ExportProfiles");this.props.changePage("/ExportProfiles") }}>
                                    <div classNameName="nav-link handCursor " style={this.props.currentPage.includes('/ExportProfiles' ?{color:'white', backgroundColor:'#494e53'} : {}}>

                                        <i classNameName="nav-icon fas fa-file-export" />
                                        <p >
                                        {ts('Export Customers',this.props.userLogin.language  )}
                                        </p>
                                    </div>
                                </li>}


                                <li classNameName="nav-header mt-5 ">{ts('Log',this.props.userLogin.language  )}</li>
                                <li classNameName="nav-item" onClick={() => { history.push("/LogHistory");this.props.changePage("/LogHistory") }}>
                                    <div classNameName="nav-link handCursor " style={this.props.currentPage.includes('/LogHistory' ?{color:'white', backgroundColor:'#494e53'} : {}}>
                                        <i classNameName="nav-icon fas fa-history" />
                                        <p >
                                        {ts('Log History',this.props.userLogin.language  )}
                                        </p>
                                    </div>
                                </li>
                                <li classNameName="nav-header mt-5 ">{ts('Password',this.props.userLogin.language  )}</li>
                                <li classNameName="nav-item" onClick={() => { history.push("/ResetPassword") ;this.props.changePage("/ResetPassword")}}>
                                    <div classNameName="nav-link handCursor " style={this.props.currentPage.includes('/ResetPassword' ?{color:'white', backgroundColor:'#494e53'} : {}}>
                                    <i classNameName="nav-icon fas fa-key"></i>
                                        <p >
                                        {ts('Change My Password',this.props.userLogin.language  )}
                                        </p>
                                    </div>
                                </li>
                                
                              <li classNameName="nav-header mt-5 mb-5"> </li>*/}
                            </ul>
                        </nav>
                        {/* /.sidebar-menu */}
                    </div>
                }
                {/* /.sidebar */}
            </aside>


        )
    }
}
const mapStateToProps = state => {

    return {
        userLogin: state.SaveLogin.userData
    };
};
export default connect(
    mapStateToProps
)(Menu)
